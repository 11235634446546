<template>
  <v-alert
    border="right"
    colored-border
    type="error"
    elevation="2"
    dismissible="true"
  >
    {{ this.errorMessage }}  
  </v-alert>
</template>

<script>
export default {
  props: [
    'errorMessage',
  ],
};
</script>