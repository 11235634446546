<template>
  <v-card
    color="#FFFFFF"
    class="card"
  >
    <v-row no-gutters class="order-details
  ">
      <v-col md="6" cols="12" style="height:20px">
        <span class="table-title" >
          {{ $t('DailySummary.OrderDetails.title') }}
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
            {{ $t('DailySummary.OrderDetails.description') }} 
              <data-sources 
                :sources="['vtex']"
              />
          </v-tooltip>
        </span>
      </v-col> 

      <v-col md="2" cols="12" class="right-content">
      </v-col>

      <v-col md="3" cols="12" class="right-content">

      </v-col>

      <v-col md="1" cols="2" class="right-content">
        <v-btn
          tile
          small
          color="success"
          @click="save()"
        >
          <v-icon style="font-size: 20px;">
            mdi-microsoft-excel
          </v-icon>
        </v-btn>
      </v-col>

      <v-col cols="12" style="margin-top: 16px;">
        <wj-flex-grid 
          :initialized="initializeFlexSheet"
          :selectionMode="'ListBox'"
          :headersVisibility="'Column'"
          :itemsSource="data" 
          :alternatingRowStep="1"
          style="max-height: 1090px"
          isReadOnly: true
        >
            <wj-flex-grid-filter />
            <wj-flex-grid-column :header="'ID'" :binding="'orderId'" width="*" format="n2" :isReadOnly=true />
            <wj-flex-grid-column :header="$t('DailySummary.OrderDetails.headers.clientName')" :binding="'clientName'" width="*" format="n2" :isReadOnly=true />
            <wj-flex-grid-column :header="$t('DailySummary.OrderDetails.headers.orderIsComplete')" :binding="'orderIsComplete'" width="*" format="n2" :isReadOnly=true />
            <wj-flex-grid-column :header="$t('DailySummary.OrderDetails.headers.status')" :binding="'status'" width="*" format="n2" :isReadOnly=true />
            <wj-flex-grid-column :header="$t('DailySummary.OrderDetails.headers.paymentNames')" :binding="'paymentNames'" width="*" format="n2" :isReadOnly=true />
            <wj-flex-grid-column :header="$t('DailySummary.OrderDetails.headers.totalItems')" :binding="'totalItems'" width="*" format="n2" :isReadOnly=true />
            <wj-flex-grid-column :header="$t('DailySummary.OrderDetails.headers.revenue')" :binding="'revenue'" width="*" format="n2" :isReadOnly=true />
        </wj-flex-grid>
        </v-col>
        <v-col md="12" cols="12"  >
          <div v-on:click="updateViewPager($event)" class="update-view-pager app-font">
            <flex-grid-page-size-selector 
              style="display:flex;position: relative;inset: -15px 0 -15px -20px"
              @change="onChangeChild"
              :currentPageSize="this.currentPageSize"
              :pageSizeSelect="this.pageSizeSelect"
            />
              <button id="btnFirst" class="btn"><span class="wj-glyph-step-backward" style="position: relative;left: -10px; top: 1px" ></span></button>
              <button id="btnPrev" class="btn"><span class="wj-glyph-left" style="position: relative;left: -3px; " ></span></button>
              &nbsp;&nbsp;&nbsp;{{ $t('FlexGridPageSizeSelector.page')}} {{ this.pageIndex + 1 | number:0 }} {{ $t('FlexGridPageSizeSelector.of')}} {{ this.pageCount | number:0 }}&nbsp;&nbsp;&nbsp;
              <button id="btnNext" class="btn"><span class="wj-glyph-right" style="position: relative;right: -3px; "></span></button>
              <button id="btnLast" class="btn"><span class="wj-glyph-step-forward" style="position: relative;right: -10px; top: 1px"></span></button>
          </div>
        </v-col>
        
    </v-row>
  </v-card>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import "@grapecity/wijmo.vue2.grid";
import '@grapecity/wijmo.vue2.grid.filter';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import DataSources from '../shared/DataSources.vue';
import * as wijmo from '@grapecity/wijmo';
import FlexGridHelper from "@/helpers/flexGrid.helper";
import FlexGridPageSizeSelector from '../shared/FlexGridPageSizeSelector.vue';

export default {
  name: "OrderDetails",
  components: {DataSources, FlexGridPageSizeSelector},
  props: [
    "orderDetailsData"
  ],
  data: function() {
    return {
      maxPageSize: '12',
      currentPageSize: '12',
      pageIndex: 0,
      pageCount: 1,
    }
  },
  computed: {
    data() {
      return new CollectionView(FlexGridHelper.fillData(this._getData(), parseInt(this.currentPageSize)), {
              refreshOnEdit: true, // on-demand sorting and filtering
              pageSize: parseInt(this.currentPageSize)
            })
    },
    pageSizeSelect() {
      return FlexGridHelper.makeList()
    },
    visualization: {
      get () {
        return this.$store.state.OrderDetailsVisualization
      },
      set (value) {
        this.$store.commit('updateOrderDetailsVisualization', value)
      }
    }
  },
  methods: {
    onChangeChild(value) {
      this.currentPageSize = value
      this.updatePagesIndex()
    },
    updatePagesIndex() {
      this.pageIndex = this.data.pageIndex
      this.pageCount = this.data.pageCount
    }, 
    initializeFlexSheet: function(flex) {
      this.flex = flex;
    },
    save() {
      this.data.pageSize = 0;
      wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
          this.flex,
          {
              includeColumnHeaders: true,
              includeCellStyles: false,
              formatItem: null
          },
          'order-details.xlsx'
      );
      this.data.pageSize = parseInt(this.currentPageSize);
    },
    _getData: function() {
        let data = [];

        for (let i = 0; i < this.orderDetailsData.length; i++) {
            data.push({
                id: i,
                'orderId': this.orderDetailsData[i].orderId,
                'clientName': this.orderDetailsData[i].clientName,
                'orderIsComplete': this.orderDetailsData[i].orderIsComplete,
                'status': this.orderDetailsData[i].status,
                'paymentNames': this.orderDetailsData[i].paymentNames,
                'totalItems': this.orderDetailsData[i].totalItems,
                'revenue': this.orderDetailsData[i].revenue,
            });
        }
        return data;
    },
    updateViewPager(e) {
        let btn = wijmo.closest(e.target, 'button'),
            id = btn ? btn.id : '';
        //
        switch (id) {
            case 'btnFirst':
                this.data.moveToFirstPage();
                break;
            case 'btnPrev':
                this.data.moveToPreviousPage();
                break;
            case 'btnNext':
                this.data.moveToNextPage();
                break;
            case 'btnLast':
                this.data.moveToLastPage();
                break;
        }
        this.updatePagesIndex()
    },
  },
  created: function () {
    this.updatePagesIndex()
  },
};
</script>

<style scoped lang="scss">
.order-details {

  .wj-flexchart {
    padding: 0px !important;
  }
}
.order-details-title {
  color: #6f6f6f;
  padding-left: 0px;
  height: 20px;
}
.right-content {
  margin: auto; 
  text-align: right; 
  padding-right: 20px !important;
  position:relative
}

.card {
  padding:24px;
  max-height: 1200px;
  }

.update-view-pager {
  align-items: center; 
  text-align: right; 
  padding-right: 16px;
  display:flex;
  float:right;
  height:30px;
  font-size: 13px;
}


</style>