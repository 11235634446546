import axios from './api.js'

export default {
  /**
  * @desc User Login
  * @param String $email
  * @param String $password
  */
  async login(email, password) {
      let response = {
        'error': null,
        'data': null,
      };

      try {
        response = await (axios.get('/login?email=' + email + '&password=' + password));
      } 
      catch (err) {
          response.error = err.response.status;
          console.error(err);

          return Promise.resolve(response);
      }
     
      return Promise.resolve(response);
  },

  /**
  * @desc Orders Page Metrics
  * @param String $timeRange
  * @param String $compareTimeRange
  * @param String $statusParams
  * @param String $originParams
  */
   async getOrderPageMetrics(timeRange, compareTimeRange, statusParams, originParams) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/orders_page?key=' + timeRange+'&keyCompare='+compareTimeRange + statusParams + originParams));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }

    return Promise.resolve(response);
},  

  /**
  * @desc Orders Page Metrics
  * @param String $timeRange
  * @param String $statusParams
  * @param String $originParams
  */
   async getDailySummaryPageMetrics(timeRange, statusParams, originParams) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/summary_page?date=' + timeRange + statusParams + originParams));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }

    return Promise.resolve(response);
},  
  /**
  * @desc Orders Page Metrics
  * @param String $timeRange
  * @param String $statusParams
  * @param String $originParams
  */
   async getPaymentsPageMetrics(timeRange, compareTimeRange, statusParams, originParams) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/payments_page?key=' + timeRange+'&keyCompare='+compareTimeRange + statusParams + originParams));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }

    return Promise.resolve(response);
},  
  /**
  * @desc Orders Page Metrics
  * @param String $timeRange
  */
   async getStockPageMetrics(timeRange, categories, departments) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/stock_page?date=' + timeRange + categories + departments));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }

    return Promise.resolve(response);
},  

  /**
  * @desc Orders Page Metrics
  * @param String $timeRange
  * @param String $compareTimeRange
  * @param String $statusParams
  * @param String $originParams
  */
   async getProductsPageMetrics(timeRange, compareTimeRange, statusParams, originParams) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/products_page?key=' + timeRange+'&keyCompare='+compareTimeRange + statusParams + originParams));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }

    return Promise.resolve(response);
},  

  /**
  * @desc Sessions Page Metrics
  * @param String $timeRange
  * @param String $compareTimeRange
  */
   async getSessionsPageMetrics(timeRange, compareTimeRange) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/sessions_page?key=' + timeRange+'&keyCompare='+compareTimeRange));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }

    return Promise.resolve(response);
  },  

  /**
  * @desc Sessions Page Metrics
  * @param String $timeRange
  * @param String $compareTimeRange
  */
   async getTrafficPageMetrics(timeRange, compareTimeRange) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/traffic_page?key=' + timeRange+'&keyCompare='+compareTimeRange));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }

    return Promise.resolve(response);
  },  

  /**
  * @desc Sessions Page Metrics
  * @param String $timeRange
  * @param String $compareTimeRange
  */
   async getPaidTrafficPageMetrics(timeRange, compareTimeRange) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/paid_traffic_page?key=' + timeRange+'&keyCompare='+compareTimeRange));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }

    return Promise.resolve(response);
  },  

  /**
  * @desc Array of filters ID and Labels
  */
   async getFilters() {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/filters'));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }

    return Promise.resolve(response);
},  

  /**
  * @desc item Metric 
  * @param String $metric stock
  * @param String $timeRange
  * @param String $itemId
  */
   async getItemStockMetric(timeRange, itemId) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/stock?key=' + timeRange + itemId));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }

    return Promise.resolve(response);
},  

  /**
  * @desc item Metric 
  * @param String $metric prices
  * @param String $timeRange
  * @param String $itemId
  */
   async getItemPricesMetric(timeRange, itemId) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/prices?key=' + timeRange + itemId));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }

    return Promise.resolve(response);
},  

  /**
  * @desc item Metric 
  * @param String $metric sales
  * @param String $timeRange
  * @param String $itemId
  */
   async getItemSalesMetric(timeRange, itemId) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/sales?key=' + timeRange + itemId));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }

    return Promise.resolve(response);
},  

  /**
  * @desc item Metric 
  * @param String $metric stock
  * @param String $timeRange
  * @param String $itemId
  */
   async getCrossSellingItemDataMetric(timeRange, itemId) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/crossSelling?key=' + timeRange + itemId));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }

    return Promise.resolve(response);
},  

  /**
  * @desc 
  */
   async getUsersMetric() {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/users_by_client'));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  


  /**
  * @desc Create new user
  */
   async postNewUserService(data) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.post('/user', data));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  

  /**
  * @desc update user
  */
   async updatePasswordService(password) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.put('/users_by_client', password));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  

  /**
  * @desc Delete user
  */
   async deleteUserService(email) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.delete('/user/'+email+''));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  

  /**
  * @desc job execution data
  */
   async getJobsExecutionMetrics() {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/integrations'));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  

  /**
  * @desc 
  */
   async getSettingsMetric() {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/user_settings'));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  


  /**
  * @desc update user
  */
   async updateSettingsService(settings) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.put('/user_settings', settings));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  

  /**
  * @desc Create new integration
  */
   async postVtexIntegrationService(data) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.post('/integration_vtex', data));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  


  /**
  * @desc Create facebook integration
  */
   async postFacebookIntegrationService(data) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.post('/integration_facebook', data));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  

  /**
  * @desc Create google analytics integration
  */
   async postGoogleAnalyticsIntegrationService(data) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.post('/integration_google_analytics', data));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  

  /**
  * @desc Create facebook integration
  */
   async postGoogleAdsIntegrationService(data) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.post('/integration_google_ads', data));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  

  /**
  * @desc Delete integration
  */
   async deleteIntegrationService(data_source) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.delete('/integrations/'+data_source+''));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  

 /**
  * @desc get integration
  */
  async getIntegrationDataService(data_source) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/integration_data' + data_source));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
  },  

 /**
  * @desc get integration
  */
  async getAgencyIntegrationDataService(data_source, agency_client) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/agency_integration_data' + data_source + agency_client));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
  },  

  /**
  * @desc Create new integration
  */
   async putVtexIntegrationEditService(data) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.put('/integration_vtex', data));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  


  /**
  * @desc update facebook integration
  */
   async putFacebookIntegrationEditService(data) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.put('/integration_facebook', data));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  

  /**
  * @desc Create google analytics integration
  */
   async putGoogleAnalyticsIntegrationEditService(data) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.put('/integration_google_analytics', data));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
  },  

  /**
  * @desc Create facebook integration
  */
   async putGoogleAdsIntegrationEditService(data) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.put('/integration_google_ads', data));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
  },  

  /**
  * @desc Create new script
  */
   async postScriptService(data) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.post('/script_builder', data));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  

  /**
  * @desc 
  */
   async getScriptRegisterService() {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/script_register'));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
}, 

 /**
  * @desc Delete integration
  */
  async getScript(date, time) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/script_fetcher' + date + time));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
  },  

    /**
  * @desc Get agency clients
  */
   async getAgencyClientsService() {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/agency_clients'));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
  },  

  /**
  * @desc Create new agency client
  */
   async postAgencyClientService(data) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.post('/agency_client', data));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  

  /**
  * @desc Delete agency client
  */
   async deleteAgencyClientService(email) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.delete('/agency_client/'+email+''));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  

  /**
  * @desc Delete new user
  */
   async deleteScriptService(identifier) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.delete('/script/'+identifier+''));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  

    /**
  * @desc Get agency integrations
  */
     async getAgencyIntegrationsService() {
      let response = {
        'error': null,
        'data': null,
      };
  
      try {
        response = await (axios.get('/agency_integrations'));
      } catch (err) {
          response.error = err.response.status;
          console.error(err);
  
          return Promise.resolve(response);
      }
      return Promise.resolve(response);
  },  

    /**
  * @desc Delete agency integration
  */
   async deleteAgencyIntegrationService(data_source, agency_client) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.delete('/agency_integrations/'+data_source+'/'+ agency_client));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
},  

  /**
  * @desc update facebook agency integration
  */
   async putFacebookAgencyIntegrationEditService(data) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.put('/agency_integration_facebook', data));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
  },  


  /**
  * @desc Create google analytics integration
  */
   async putGoogleAnalyticsAgencyIntegrationEditService(data) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.put('/agency_integration_google_analytics', data));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
  },  

  /**
  * @desc Create facebook integration
  */
   async putGoogleAdsAgencyIntegrationEditService(data) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.put('/agency_integration_google_ads', data));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
  },  

  /**
  * @desc 
  */
   async getScriptExecutionsService(offset) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/scripts_executions' + offset));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
}, 

    /**
  * @desc Get budget control report
  */
     async getBudgetReportService(queryParameter) {
      let response = {
        'error': null,
        'data': null,
      };
  
      try {
        response = await (axios.get('/budget_control_information_report' + queryParameter));
      } catch (err) {
          response.error = err.response.status;
          console.error(err);
  
          return Promise.resolve(response);
      }
      return Promise.resolve(response);
    },

        /**
    * @desc Get budget control report
    */
    async getBudgets(queryParameter) {
    let response = {
      'error': null,
      'data': null,
    };

    try {
      response = await (axios.get('/budget_control_get' + queryParameter));
    } catch (err) {
        response.error = err.response.status;
        console.error(err);

        return Promise.resolve(response);
    }
    return Promise.resolve(response);
  },

    /**
  * @desc Create new agency client
  */
  async postBudget(data) {      
      let response = {
        'error': null,
        'data': null,
      };
  
      try {
        response = await (axios.post('/budget_control_post', data));
      } catch (err) {
          response.error = err.response.status;
          console.error(err);
  
          return Promise.resolve(response);
      }
      return Promise.resolve(response);
  },
}