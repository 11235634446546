<template>
  <v-card
    color="#FFFFFF"
    class="card"
  >
    <v-row no-gutters class="best-seller-items">
      <v-col md="6" cols="12" style="height:20px">
        <span class="table-title">
          {{$t('Products.BestSellerProducts.title')}}
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
          {{$t('Products.BestSellerProducts.description')}}
            <data-sources 
              :sources="['vtex']"
            />
          </v-tooltip>
        </span>
      </v-col> 

      <v-col md="5" cols="12" class="right-content">
      </v-col>

      <v-col md="1" cols="12" class="right-content">
        <v-btn
          tile
          small
          color="success"
          @click="save()"
        >
          <v-icon style="font-size: 20px;">
            mdi-microsoft-excel
          </v-icon>
        </v-btn>
      </v-col>

      <v-col md="12" cols="12" style="margin-top: 16px">
        <wj-flex-grid 
          :initialized="initializeFlexSheet"
          :selectionMode="'ListBox'"
          :headersVisibility="'Column'"
          :itemsSource="this.data" 
          :alternatingRowStep="1"
          style="max-height: 1090px"
        >
            <wj-flex-grid-filter />
            <wj-flex-grid-column :header="'Producto'" :binding="'productName'" width="1*" :isReadOnly=true />
            <wj-flex-grid-column :header="'Cantidad'" :binding="'cantidad'" aggregate="Sum" width="*" format="n0" :isReadOnly=true />
            <wj-flex-grid-column :header="'Revenue'" :binding="'revenue'" aggregate="Sum" width="*" format="n0" :isReadOnly=true />
            <wj-flex-grid-column :header="'Profit(P/U)'" :binding="'profit'" aggregate="Sum"  width="*" format="n0" :isReadOnly=true />
            <wj-flex-grid-column :header="'Total Profit'" :binding="'totalProfit'" aggregate="Sum" width="*" format="n0" :isReadOnly=true />
            <wj-flex-grid-column :header="'Profit(%)'" :binding="'profitPercentage'" aggregate="Avg" width="*" format="n1" :isReadOnly=true />
        </wj-flex-grid>
      </v-col>
        <v-col md="12" cols="12"  >
          <div v-on:click="updateViewPager($event)" class="update-view-pager">
            <flex-grid-page-size-selector 
              style="display:flex;position: relative;inset: -15px 0 -15px -20px"
              @change="onChangeChild"
              :currentPageSize="this.currentPageSize"
              :pageSizeSelect="this.pageSizeSelect"
            />
              <button id="btnFirst" class="btn"><span class="wj-glyph-step-backward" style="position: relative;left: -10px; top: 1px" ></span></button>
              <button id="btnPrev" class="btn"><span class="wj-glyph-left" style="position: relative;left: -3px; " ></span></button>
              &nbsp;&nbsp;&nbsp;{{ $t('FlexGridPageSizeSelector.page')}} {{ this.pageIndex + 1 | number:0 }} {{ $t('FlexGridPageSizeSelector.of')}} {{ this.pageCount | number:0 }}&nbsp;&nbsp;&nbsp;
              <button id="btnNext" class="btn"><span class="wj-glyph-right" style="position: relative;right: -3px; "></span></button>
              <button id="btnLast" class="btn"><span class="wj-glyph-step-forward" style="position: relative;right: -10px; top: 1px"></span></button>
          </div>
        </v-col>

    </v-row>
  </v-card>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import "@grapecity/wijmo.vue2.grid";
import '@grapecity/wijmo.vue2.grid.filter';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcGrid from '@grapecity/wijmo.grid';
import DataSources from '../shared/DataSources.vue';
import * as wijmo from '@grapecity/wijmo';
import FlexGridPageSizeSelector from '../shared/FlexGridPageSizeSelector.vue';
import FlexGridHelper from "@/helpers/flexGrid.helper";

export default {
  name: "BestSellerProducts",

  components: {
    DataSources,
    FlexGridPageSizeSelector

  },
  props: [
    'bestSellerProductsData'
  ],
  data: function() {
    return {
      maxPageSize: '7',
      currentPageSize: '7',
      pageIndex: 0,
      pageCount: 1,
      pieHeader: '2018 Product Sales',
      description: '',
    }
  },
  computed: {
    data() {
      return new CollectionView(FlexGridHelper.fillData(this.bestSellerProductsData, parseInt(this.currentPageSize)), {
              refreshOnEdit: true, // on-demand sorting and filtering
              pageSize: parseInt(this.currentPageSize)
            })
    },
    pageSizeSelect() {
      return FlexGridHelper.makeList(parseInt(this.maxPageSize))
    },
  },
  methods: {
    initializeFlexSheet: function(flex) {
      flex.columnFooters.rows.push(new wjcGrid.GroupRow());
      this.flex = flex;
    },
    onChangeChild(value) {
      this.currentPageSize = value
      this.updatePagesIndex()
    },
    save() {
      this.data.pageSize = 0;
      wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
          this.flex,
          {
              includeColumnHeaders: true,
              includeCellStyles: false,
              formatItem: null
          },
          'BestSellerProducts.xlsx'
      );
      this.data.pageSize = parseInt(this.currentPageSize);
    },
    updatePagesIndex() {
      this.pageIndex = this.data.pageIndex
      this.pageCount = this.data.pageCount
    },    
    updateViewPager(e) {
        let btn = wijmo.closest(e.target, 'button'),
            id = btn ? btn.id : '';
        //
        switch (id) {
            case 'btnFirst':
                this.data.moveToFirstPage();
                break;
            case 'btnPrev':
                this.data.moveToPreviousPage();
                break;
            case 'btnNext':
                this.data.moveToNextPage();
                break;
            case 'btnLast':
                this.data.moveToLastPage();
                break;
        }
        this.updatePagesIndex()
    },
created: function () {
  this.updatePagesIndex()
}
  }
};
</script>

<style scoped lang="scss">
.best-seller-items {

  .wj-flexchart {
    padding: 0px !important;
  }
}
.status-title {
  color: #6f6f6f;
  padding-left: 20px;
}
.right-content {
  margin: auto; 
  text-align: right; 
  padding-right: 20px !important;
  position:relative
}

.card {
  max-height: 1200px;
  padding:24px;
  background: white;
}

.update-view-pager {
  align-items: center; 
  text-align: right; 
  padding-right: 16px;
  display:flex;
  float:right;
  height:30px;
}
</style>