export default {
    /** if the year is the same for all the rawData, it removes the year from dates
     * @param {Float} rawData
     * @returns {String} dataWithFormatedDate
     */ 
    
    formatNumber(n) {
        var ranges = [
            { divider: 1e18 , suffix: 'E' },
            { divider: 1e15 , suffix: 'P' },
            { divider: 1e12 , suffix: 'T' },
            { divider: 1e9 , suffix: 'G' },
            { divider: 1e6 , suffix: 'M' },
            { divider: 1e3 , suffix: 'k' }
        ];
        if (n < 0) {
            return '-' + this.formatNumber(-n);
        }
        for (var i = 0; i < ranges.length; i++) {
            
            if (n >= ranges[i].divider) {
            return (n / ranges[i].divider).toFixed(2).toString().replace('.',',') + ranges[i].suffix;
            }
        }
        return n.toString().replace('.',',');
        }
}