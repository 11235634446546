<template>
  <v-card
    color="#FFFFFF"
    class="card"
  >
    <v-row no-gutters class="metrics-by-campaign">

    <v-col md="6" cols="10" style="height:20px">
        <span class="table-title">
          {{$t('PaidTraffic.MetricsByCampaign.title')}}  
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
          {{$t('PaidTraffic.MetricsByCampaign.description')}}
          <data-sources
            :sources="['googleAnalytics']"
          />
          </v-tooltip>
        </span>
    </v-col> 

      <v-col md="6" cols="2" class="right-content">
        <v-btn
          tile
          small
          color="success"
          @click="save()"
        >
          <v-icon style="font-size: 20px;">
            mdi-microsoft-excel
          </v-icon>
        </v-btn>
      </v-col>
      
    <v-col md="12" cols="12" class="d-flex text-field-col" style="margin-top: 16px">
        <v-text-field
            class="shrink"
            prepend-inner-icon="mdi-magnify"
            label="Buscar adGroup"
            rounded
            dense
            outlined
            hide-details
            @input="adGroupFilter($event)"
        ></v-text-field>
    </v-col>

      <v-col md="12" cols="12"  >
        <wj-flex-grid 
          :initialized="initializeFlexSheet"
          :headersVisibility="'Column'"
          :selectionMode="'ListBox'"
          :itemsSource="this.data" 
          :alternatingRowStep="1"
          style="max-height: 1090px"
        >
            <wj-flex-grid-filter />
            <wj-flex-grid-column :header="'Campaign'" :binding="'campaign'" width="*" :isReadOnly=true  />
            <wj-flex-grid-column :header="'Ad Group'" :binding="'adGroup'" width="1*" :isReadOnly=true />
            <wj-flex-grid-column :header="'Sessions'" :binding="'sessions'" width="1*" aggregate="Sum" :isReadOnly=true />
            <wj-flex-grid-column :header="'Bounce Rate'" :binding="'bounceRate'" width="1*"  format="p2" aggregate="Avg" :isReadOnly=true />
            <wj-flex-grid-column :header="'Users'" :binding="'users'" width="*" format="n2" aggregate="Sum" :isReadOnly=true  />
            <wj-flex-grid-column :header="'Ad Clicks'" :binding="'adClicks'" width="*" format="n2" aggregate="Sum" :isReadOnly=true  />
            <wj-flex-grid-column :header="'Ad Cost'" :binding="'adCost'" width="*" format="n2" aggregate="Sum" :isReadOnly=true />
        </wj-flex-grid>
      </v-col>  
        <v-col md="12" cols="12"  >
        <div v-on:click="updateViewPager($event)" class="update-view-pager">
            <flex-grid-page-size-selector 
            style="display:flex;position: relative;inset: -15px 0 -15px -20px"
            @change="onChangeChild"
            :currentPageSize="this.currentPageSize"
            :pageSizeSelect="this.pageSizeSelect"
            />
            <button id="btnFirst" class="btn"><span class="wj-glyph-step-backward" style="position: relative;left: -10px; top: 1px" ></span></button>
            <button id="btnPrev" class="btn"><span class="wj-glyph-left" style="position: relative;left: -3px; " ></span></button>
              &nbsp;&nbsp;&nbsp;{{ $t('FlexGridPageSizeSelector.page')}} {{ this.pageIndex + 1 | number:0 }} {{ $t('FlexGridPageSizeSelector.of')}} {{ this.pageCount | number:0 }}&nbsp;&nbsp;&nbsp;
            <button id="btnNext" class="btn"><span class="wj-glyph-right" style="position: relative;right: -3px; "></span></button>
            <button id="btnLast" class="btn"><span class="wj-glyph-step-forward" style="position: relative;right: -10px; top: 1px"></span></button>
        </div>
        </v-col>

    </v-row>
  </v-card>
</template>


<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import "@grapecity/wijmo.vue2.grid";
import '@grapecity/wijmo.vue2.grid.filter';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import DataSources from '../shared/DataSources.vue';
import * as wijmo from '@grapecity/wijmo';
import FlexGridHelper from "@/helpers/flexGrid.helper";
import FlexGridPageSizeSelector from '../shared/FlexGridPageSizeSelector.vue';

export default {
  name: "MetricsByCampaign",
  props: [
    'campaignData'
  ],
  components: {
    DataSources,
    FlexGridPageSizeSelector
  },
  data: function() {
      return {
        maxPageSize: '5',
        currentPageSize: '5',
        pageIndex: 0,
        pageCount: 1,
        animated: true,

      };
  },

  computed: {
    data() {
      return new CollectionView(FlexGridHelper.fillData(this.campaignData, parseInt(this.currentPageSize)), {
              refreshOnEdit: true, // on-demand sorting and filtering
              pageSize: parseInt(this.currentPageSize)
            })
    },
    pageSizeSelect() {
      return FlexGridHelper.makeList()
    },
  },
  methods: {
    initializeFlexSheet: function(flex) {
      flex.columnFooters.rows.push(new wjcGrid.GroupRow());
      this.flex = flex;
    },
    initializeFlexSheet2: function(flex) {
      flex.columnFooters.rows.push(new wjcGrid.GroupRow());
      this.flex2 = flex;
    },
    onChangeChild(value) {
      this.currentPageSize = value
      this.updatePagesIndex()
    },
    updatePagesIndex() {
      this.pageIndex = this.data.pageIndex
      this.pageCount = this.data.pageCount
    }, 
    save() {
      this.data.pageSize = 0;
      wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
          this.flex,
          {
              includeColumnHeaders: true,
              includeCellStyles: false,
              formatItem: null
          },
          'metrics-by-campaign.xlsx'
      );
      this.data.pageSize = parseInt(this.currentPageSize);
    },
    updateViewPager(e) {
        let btn = wijmo.closest(e.target, 'button'),
            id = btn ? btn.id : '';
        //
        switch (id) {
            case 'btnFirst':
                this.data.moveToFirstPage();
                break;
            case 'btnPrev':
                this.data.moveToPreviousPage();
                break;
            case 'btnNext':
                this.data.moveToNextPage();
                break;
            case 'btnLast':
                this.data.moveToLastPage();
                break;
        }
        this.updatePagesIndex()
    },
    procesed: function(rawData) {
      let data = rawData;
      let resultadoFinal= [];
      var row;
      for (var i=0; i < data.length; i++) {
        if (data[i].campaign == null)
          row = {
            adGroup: data[i].adGroup,
            campaign: 'null',
            bounceRate: data[i].bounceRate,
            sessions: data[i].sessions,
            adClicks: data[i].adClicks,
            users: data[i].users,
            adCost: data[i].adCost,
        };
        else  
          row = {
            adGroup: data[i].adGroup,
            campaign: data[i].campaign,
            bounceRate: data[i].bounceRate,
            sessions: data[i].sessions,
            adClicks: data[i].adClicks,
            users: data[i].users,
            adCost: data[i].adCost,
          };
        resultadoFinal.push(row)
      }
      return resultadoFinal  
    },

    adGroupFilter: function(e) {
        let filter = e;
        this.flex.collectionView.filter = item => {
            return (
                filter.length == 0 ||
                item.adGroup.toLowerCase().indexOf(filter) > -1
            );
        };
    },
   
  },
  created: function () {
    this.updatePagesIndex()
  },
};
</script>

<style scoped lang="scss">

  .metrics-by-campaign {
  .wj-flexchart {
    padding: 0px !important;
  }
}
.metrics-by-campaign-title {
  color: #6f6f6f;

}
.right-content {
  margin: auto; 
  text-align: right; 
  padding-right: 20px !important;
}
.form-control {
  margin-left: 1%;
  margin-top:1%;
  background-color:#add8e6;
  box-shadow: 0 0 3px black;
}

.right-content {
  margin: auto; 
  text-align: right; 
  padding-right: 20px !important;
  position:relative
}

.card {
  padding: 24px;
  max-height: 1200px;
}

.update-view-pager {
  align-items: center; 
  text-align: right; 
  padding-right: 16px;
  display:flex;
  float:right;
  height:30px;
}


</style>


