<template>
  <v-card
    color="#FFFFFF"
    class="card"
  >
    <v-row no-gutters class="sTraffic-revenue">

      <v-col md="12" cols="12">
      <v-col cols="12" class="pa-0" style="margin-bottom: 17px">
        <span class="graph-title">
          {{$t('Traffic.TrafficRevenue.title')}} 
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
          {{$t('Traffic.TrafficRevenue.description')}}
            <data-sources 
              :sources="['googleAnalytics', 'vtex']"
            />
          </v-tooltip>
        </span>
      </v-col> 
        <wj-flex-chart 
            style="height:195px"
            :itemsSource="procesedData"
            :initialized="onChartInitialized"
            :palette="this.palette"
            tooltipContent="" 
            bindingX="date" 
            chartType="Line">
            
            <wj-flex-chart-legend position="Bottom"></wj-flex-chart-legend>
            <wj-flex-chart-axis wjProperty="axisY" title="Revenue" :min="0" ></wj-flex-chart-axis>
            <wj-flex-chart-series binding="revenueVTEX" name="revenueVTEX"></wj-flex-chart-series>
            <wj-flex-chart-series binding="revenueAnalitix" name="revenueAnalytics"></wj-flex-chart-series>
            <wj-flex-chart-animation animationMode="Series"></wj-flex-chart-animation>
            <wj-flex-chart-line-marker :isVisible="showMarker" lines="Vertical" interaction="Move" :content="getMarkerContent">
            </wj-flex-chart-line-marker>
        </wj-flex-chart>
      </v-col>      

    </v-row>
  </v-card>
</template>

<script>
import '@grapecity/wijmo.styles/wijmo.css';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.chart.animation';
import DataFilterHelper from "@/helpers/dataFilter.helper";
import markerContentHelper from "@/helpers/markerContent.helper";
import ui_kit from "@/constants/ui-kit";
import DataSources from '../shared/DataSources.vue';


export default {
  name: "TrafficRevenue",
  components: {
    DataSources
  },
  props: [
  'revenueData'
  ],
  data: function() {
    return {
      lmVisible: false,
      showMarker: false,
      palette: ui_kit.comparePalette
    }
  },
  computed: {
    procesedData() {
      return DataFilterHelper.dateYearFilter(this.revenueData)
    },
  },
  methods: {
    onChartInitialized(sender) {
          sender.hostElement.addEventListener('mouseenter', () => this.showMarker = true);
          sender.hostElement.addEventListener('mouseleave', () => this.showMarker = false);
    },
    getMarkerContent(ht) {
        return markerContentHelper.markerContent(ht, 'compare')
    }
  }
};
</script>

<style scoped lang="scss">
.sTraffic-revenue {
  .wj-flexchart {
    padding: 0px !important;
  }
}
.sTraffic-revenue-title {
  color: #6f6f6f;
  padding-left: 20px;
}

.card {
  padding:24px;
  height: 280px;
}
</style>