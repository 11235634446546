<template>
  <v-card
    color="#FFFFFF"
    class="raw-metric-card"

  >
      <v-card-title >
        <v-row no-gutters style="width:100%;">

        <v-col  >
          <div style="display:flex">
            <div  class="graph-title">
            {{ this.title }}
            </div>
        <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 style="margin-left: 5px">
                mdi-information-outline
              </v-icon>
            </template>
            <span class="tooltip">
              {{ description }}
            </span>
            <data-sources 
              :sources="this.sources"
            />
          </v-tooltip>
          </div>
        </v-col>
      <grid-with-button v-if="!mobile" style="height:350px;padding:16px"
        :cardData="this.cardData"
      />
      <grid-with-button v-if="mobile" style="height:350px;padding:0px;margin-top:10px"
        :cardData="this.cardData"
      />
      </v-row>
    </v-card-title>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import DataSources from '../shared/DataSources.vue';
import GridWithButton from './GridWithButton.vue';

export default {
  components: { DataSources, GridWithButton },
  props: [
    'description',
    'sources',
    'cardData',
    'title'
  ],
  data: function() {
    return {
    }
  },
  computed: {
    ...mapState([
      "mobile"
    ])
  },
  methods: {

  },

};
</script>

<style scoped lang="scss">
.raw-metric-card {
  max-height: 420px;
  padding:24px;

}

.v-card__title {
  padding: 0px 0px 12px;
  text-align: left;
  //padding-bottom: 12px;
}

.card-title {
  margin: 5px;
}

</style>