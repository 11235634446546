<template>
  <v-card
    color="#FFFFFF"
    class="graph-area"
  >  
  <v-row no-gutters class="bounce-rate">
      <v-col cols="12" style="height:20px;margin-bottom:17px">
        <span class="graph-title" >
          <strong style="color:black "> {{ $t('Sessions.BounceRate.title')}}  </strong>   
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
          {{ $t('Sessions.BounceRate.description')}}  
          <data-sources 
            :sources="['googleAnalytics']"
          />
          </v-tooltip>
        </span>
      </v-col> 

      <v-col md="12" cols="12" style="height: 200px">
        <wj-flex-chart style="height: 100%" 
          :itemsSource="procesedData" 
          bindingX="date"
          chartType="SplineArea"
          :legendToggle="true"
          :palette="this.palette"
          tooltipContent=""  
          :initialized="onChartInitialized"
        >
            <wj-flex-chart-legend position="Bottom"></wj-flex-chart-legend>
            <wj-flex-chart-axis wjProperty="axisY" :min="0" format='n2'></wj-flex-chart-axis>
            <wj-flex-chart-series  binding="bounceRate" :name="$t('keys.bounceRate')" :style="{ fill:this.lightPalette[0], stroke:this.palette[0], 'stroke-width': '2' }" ></wj-flex-chart-series>
            <wj-flex-chart-animation animationMode="Point"></wj-flex-chart-animation>
            <wj-flex-chart-line-marker :isVisible="showMarker" lines="Vertical" interaction="Move" :content="getMarkerContent">
            </wj-flex-chart-line-marker>
        </wj-flex-chart>
      </v-col>      
    </v-row>
  </v-card>
</template>

<script>
import '@grapecity/wijmo.styles/wijmo.css';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.chart.animation';
import DataFilterHelper from "@/helpers/dataFilter.helper";
import ui_kit from "@/constants/ui-kit";
import DataSources from '../shared/DataSources.vue';
import ThousandSeparator from "@/helpers/thousandSeparator.helper";
import i18n from '@/i18n.js' 

export default {
  name: "BounceData",
  components: {
    DataSources
  },
  props: [
  'bounceData'
  ],
  data: function() {
    return {
      lmVisible: false,
      showMarker: false,
      palette: ui_kit.palette,
      lightPalette: ui_kit.lightPalette
      }
      },
  computed: {
    procesedData() {
      return DataFilterHelper.dateYearFilter(this.bounceData)
    },

  },
  methods: {
    onChartInitialized(sender) {
          sender.hostElement.addEventListener('mouseenter', () => this.showMarker = true);
          sender.hostElement.addEventListener('mouseleave', () => this.showMarker = false);
    },
    getMarkerContent(ht) {
        if (ht.item) {
          var localeKeys = 'keys.'
            let s = `<p style=" color: rgba(2, 8, 15, 0.5); contrast(50%); 
                                font-family: Open Sans, sans-serif;
                                font-weight: normal;
                                "> 
                                ${ht.item.date} </p>
                                <div class="row" >`;
            var i=0;
            var firstColumn = `<div class="col-lg-8 col-md-8 " >`
            var secondColumn = `<div class="col-lg-4 col-md-4 ">`
            var endCol = `</div>`
            var endRow = `</div>`
            
            for (let key in ht.item) {
              var keyString = localeKeys + key
                if (key !== 'date') {
                    firstColumn += `
                    <div style=" color: rgba(2, 8, 15, 0,85); contrast(85%); 
                    font-family: Open Sans, sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    float:left;
                    width:150px
                    ">
                    <div style="  
                            width:12px;
                            height:12px;
                            border:solid white 1px;
                            float:left;
                            margin:5px;
                            background-color:${ui_kit.palette[i]}">
                        </div>
                    <b>${i18n.t(keyString)} </b> </div> </br> `;
                    secondColumn += `
                    <div style=" rgba(2, 8, 15, 0.85);
                    font-family: Open Sans, sans-serif;
                    font-weight: normal;
                    font-size: 14px;
                    text-align:right;
                    float:right;
                    
                    "> ${ThousandSeparator.formatNumber(ht.item[key].toFixed(3))}</div>
                      </br>  `;
                }
                i++;
            }
            return s + firstColumn + endCol + secondColumn + endCol + endRow;
        } else {
            return 'No items here...';
        }
    },
  }
};
</script>

<style scoped lang="scss">
.bounce-rate {
  .wj-flexchart {
    padding: 0px !important;
  }
}
.bounce-rate-title {
  color: #6f6f6f;
  padding-left: 20px;
}

.graph-area {
  height:280px;
  padding: 24px;
}
</style>