<template>
    <v-menu
      v-model="showCalendar"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn                  
          v-bind="attrs"
          v-on="on"
          depressed
          height="28"
          color="#e7f7f9"
        >
          {{ moment(date).format('YYYY/MM/DD') }}
          <v-icon right>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-card>
        <wj-calendar 
          :value="date"
          :max="maxDate"
          :min="minDate"
          :initialized="initCalendar"
          :valueChanged="updateValue()"
        >
        </wj-calendar>
      </v-card>
    </v-menu>
</template>

<script>
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.input';

export default {
  data: () => ({
    showCalendar: false,
    calendar: {}
  }),
  props: {
    date: {
      type: Date,
      required: true
    },
    maxDate: {
      type: Date,
      required: false,
      default: null
    },
    minDate: {
      type: Date,
      required: false,
      default: null
    }
  },
  methods: {
    initCalendar: function(calendar) {
        this.calendar = calendar;
    },
    updateValue() {
      let selectedDate = this.calendar.value;

      if (selectedDate) {
        if (selectedDate.getDate() != this.date.getDate()) {
          this.$emit('updateDate', selectedDate);                
          this.showCalendar = false;
        }        
      } 
    }
  }
}
</script>