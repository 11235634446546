<template>
  <v-card
    color="#FFFFFF"
    class="script-builder-card"
  >
      <v-card-title >
        <v-row  style="width:100%;">

        <v-col  md="12" cols="12">
          <div style="display:flex">
            <div  class="graph-title">
            {{ this.title }}
            </div>
        <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 style="margin-left: 5px">
                mdi-information-outline
              </v-icon>
            </template>
            <span class="tooltip">
              {{ description }}
            </span>
          </v-tooltip>
          </div>
        </v-col>

        <v-col  md="12" cols="10" style="display: flex ;height:64px;">
            <v-col md=9 style="display: inline-block;padding-left:0px!important">
                <v-select
                :items="avaibleScripts"
                :value="scriptId"
                :label="$t('OrderStatusFilter.select')"
                @change="setScriptId"
                solo
                flat
                dense
                background-color="transparent"
                hide-details
                class="custom-selector ml-1 capitalized-text"
                :menu-props="['offset-y']"
                >
                <template v-slot:item="data">
                <div class="select-option-item">
                    {{ data.item.text }}
                </div>
                </template>
                </v-select>
            </v-col>
            <v-col md=3>
                <div>
                    <google-ads-script-form
                        style="margin-top: -12px;margin-left:14px"
                        :scriptId="scriptId"
                        :scriptName="scriptName"
                        :clients="clients"
                        :scriptDescription="scriptDescription"
                    />
                </div>
            </v-col>
        </v-col>

      </v-row>
    </v-card-title>

  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import GoogleAdsScriptForm from './GoogleAdsScriptForm.vue';



export default {
  components: {
    GoogleAdsScriptForm
      },
  props: [
    'description',
    'title',
    'agencyClientsData'
  ],
  data: function() {
    return {
      dialog: false,
      avaibleScripts: [
        {
          text: 'Google Ads Budget Control',
          value: '1'
        },
        {
          text: 'Quality Score Tracker',
          value: '2'
        },
        {
          text: 'Broken Destination URLs',
          value: '3'
        },
        {
          text: '24 hour device performance heat map',
          value: '4'
        },
        {
          text: 'Duplicate Keywords',
          value: '5'
        },
        {
          text: 'Account Budget Control',
          value: '6'
        },
        {
          text: 'Keyword Suggestion',
          value: '7'
        },
        {
          text: 'UTM adgroup campaign',
          value: '8'
        },
        {
          text: 'Negative keywords conflict',
          value: '9'
        },
        {
          text: 'Account Summary Report',
          value: '10'
        },
        {
          text: 'Flexible Budgets',
          value: '11'
        },
        {
          text: 'MCC Ad Performance Report',
          value: '12'
        },
        {
          text: 'Account anomaly detector',
          value: '13'
        },
        {
          text: 'MCC Declining Ad Group Report',
          value: '14'
        },
        {
          text: 'Cumulative Budget Increase',
          value: '15'
        },
      ],
      scriptId:'1',
      scriptName:'Campaign Budget Control',
      clients: [],
      scriptDescriptions: {
        '1': 'Este script etiqueta las campañas cuyo gasto actual es mayor que sus presupuestos diarios. Opcionalmente, también detiene las campañas cuyo gasto supera el presupuesto. A continuación, se envía un correo electrónico con una lista de las campañas recién etiquetadas y en pausa. Cuando el gasto ya no supera el presupuesto, las campañas se reactivan y se eliminan las etiquetas.',
        '2': 'El script guarda los niveles de calidad de sus palabras clave en una sheet. Realiza un seguimiento de todas ellas. Esto le permite volver atrás y buscar el historial de cualquiera de sus palabras clave más adelante.',
        '3': 'El script pasará por su cuenta de Google Ads para verificar si hay alguna URL rota en ella, y le enviará una lista CSV de estas URL por correo electrónico.',
        '4': 'Este script calcula el rendimiento promedio suavizado de cada hora de cada día de la semana y lo convierte en un mapa de calor y un gráfico en una sheet de Google. Este se puede hacer para todos los datos y para los datos del dispositivo. También sugiere calendarios de anuncios y ajustes de la oferta por dispositivo en función de las tasas de conversión.',
        '5': 'Este script revisa todas sus palabras clave y genera una Google sheet con las palabras clave duplicadas y otra información adicional, como: Campaign name, Ad Group name, match typee, clicks, impressions, CTR, Avg. CPC, conversions, cost, cost per conversion y avg. position.',
        '6': 'Este script controla el presupuesto a nivel de cuenta, enviando correos electrónicos detallando el presupuesto diario y mensual.',
        '7': 'Este script envia a las direcciones de mail incluidas un reporte con sugerencias de keywords efectivas y sugerencias de keywords para negativizar en base a los parametros',
        '8': 'Este script administra los nombres de adgroup y campaña dinamicamente de forma automatica',
        '9': 'Este script genera un reporte en el spreadsheet sumistrado con las keywords negativas que estan en conflicto',
        '10': 'El account summary report es un reporte que muestra el rendimiento de una cuenta completa de Google Ads. Además, envía un correo electrónico diario en formato HTML con las estadísticas actuales de la cuenta. Un administrador de cuentas podría usar una técnica similar para enviar por correo electrónico informes con formato profesional directamente a los clientes.',
        '11': "Este script ajustará dinámicamente el presupuesto de su campaña diariamente con un esquema de distribución de presupuesto personalizado. Para mas informacion visiar 'https://developers.google.com/google-ads/scripts-legacy/docs/solutions/flexible-budgets'",
        '12': 'A veces, comparar el rendimiento de un título o una URL final determinados con otros proporcionará información para crear nuevos anuncios. El informe de rendimiento de los anuncios de la cuenta de administrador genera una hoja de cálculo de Google con varios gráficos de distribución interesantes.',
        '13': 'El script compara las estadísticas observadas hasta el momento con las estadísticas históricas del mismo día de la semana. Por ejemplo, las estadísticas de un martes a la 1 p. m. se comparan con las estadísticas de los 26 martes anteriores. Ajuste la cantidad de semanas para mirar hacia atrás según la antigüedad y la estabilidad de su cuenta.',
        '14': 'El informe genera una hoja de cálculo con varias pestañas. Para cada cuenta, se crea una pestaña separada, que muestra el informe de cuenta decreciente para esa cuenta. También hay una pestaña de resumen que brinda algunos datos agregados entre cuentas. Muestra la cantidad de grupos de anuncios en declive, la caída promedio en la tasa de clics, el cambio promedio en el costo y el cambio total en el costo de cada cuenta. Si lo desea, puede ingresar una dirección de correo electrónico en la pestaña de resumen del informe y se enviará un correo electrónico cada vez que se ejecute este informe, resumiendo las cuentas que tienen la mayor cantidad de grupos de anuncios en declive.',
        '15': 'El script Cumulative Budget Script permite establecer un período y un aumento de presupuesto. Fijará el presupuesto de la campaña, aumentándolo cada cantidad de días fijados en el periodo.'
      },
    }
  },
  computed: {
    ...mapState([
      "mobile",
      "agencyClients"
    ]),
    scriptDescription(){
      return this.scriptDescriptions[this.scriptId]
    }
  },
  methods: {
    ...mapActions([
      "",
    ]),
    ...mapMutations([
      "setCurrentScript",
    ]),
    getAgencyClientsData(){
      for (var i=0;i<this.agencyClients.length;i++){
        this.clients.push(this.agencyClients[i].agency_client_name)
      }
    },
    close () {
      this.dialog = false
    },
    setScriptId(value){
        this.scriptId = value
        var index = this.avaibleScripts.map(function(e) { return e.value; }).indexOf(value);
        this.scriptName = this.avaibleScripts[index]['text']
        this.setCurrentScript(this.scriptId)
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
created(){
  this.getAgencyClientsData()
}
};
</script>

<style scoped lang="scss">
.script-builder-card {
  max-height: 420px;
  padding:24px;
  width: 60%;
}

.v-card__title {
  padding:0!important;
  text-align: left;
  //padding-bottom: 12px;
}

.card-title {
  margin: 5px;
}

.raw-metric-card{
  padding-bottom: 0!important;
}

.custom-selector {
  background: #ededed;
  display: inline-block;
  width:100%;
  padding-left:0px!important;
}

.label {
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  color: red!important;  
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 18px;
  letter-spacing: 0px;
}
</style>