import moment from 'moment';

export default {
  /**
   * @param {String} string
   * @returns {Date}
   */
  toDate(string) {
    var date = new Date(string)
    const result = this.clone(date);
    result.setDate(result.getDate() + 1);
    return result;
  },

  /**
   * @param {Date} date
   * @returns {Date}
   */
  startOfDay(date) {
    const result = this.clone(date);
    result.setHours(0, 0, 0, 0);
    return result;
  },

  /**
   * @param {Date} date
   * @returns {Date}
   */
  endOfDay(date) {
    const result = this.clone(date);
    result.setHours(23, 59, 59, 999);
    return result;
  },

  /**
   * @param {Date} date
   * @returns {Date}
   */
  clone(date) {
    return new Date(date.getTime());
  },

  /**
   * @param {Date} date
   * @param {Date} another
   * @returns {Boolean}
   */
  isEqual(date, another) {
    return date.getTime() === another.getTime();
  },

  /**
   * @param {Date} date
   * @param {Number} amount
   * @returns {Date}
   */
  subtractHours(date, amount) {
    const result = this.clone(date);
    result.setHours(result.getHours() - amount);
    return result;
  },

  /**
   * @param {Date} startDate
   * @param {Date} endDate
   * @returns {Array}
   */
  createRangeBetween(startDate, endDate) {
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dateArray.push(this.clone(currentDate));

      currentDate = this.addHours(currentDate, 4);
    }

    dateArray.push(this.clone(endDate));
    return dateArray;
  },

  /**
   * @param {Date} date
   * @param {Number} amount
   * @returns {Date}
   */
  subtractDays(date, amount) {
    const result = this.clone(date);
    result.setDate(result.getDate() - amount);
    return result;
  },

  /**
   * @param {Date} date
   * @param {Number} amount
   * @returns {Date}
   */
  addDays(date, amount) {
    return this.subtractDays(date, -amount);
  },

  /**
   * @param {Date} date
   * @param {Number} amount
   * @returns {Date}
   */
  addHours(date, amount) {
    return this.subtractHours(date, -amount);
  },

  /**
   * @param {Date} date
   * @param {Number} amount
   * @returns {Date}
   */
  subtractMonths(date, amount) {
    const result = this.clone(date);
    result.setMonth(result.getMonth() - amount);
    return result;
  },

  /**
   * @param {Date} date
   * @param {Number} amount
   * @returns {Date}
   */
  startOfMonth(date) {
    const result = this.startOfDay(date);
    result.setDate(1);
    return result;
  },

  /**
   * @param {Date[]} dateRange
   * @returns {Date[][]}
   */
  getWeekends(dateRange) {
    const [from, to] = dateRange;
    const weekends = [];

    const isWeekend = weekend => [0, 6].includes(weekend.getDay());
    const addWeekend = (weekend) => {
      let weekendFrom = weekend;
      let weekendTo = this.endOfDay(this.addDays(weekendFrom, 1));

      if (weekendFrom < from) {
        weekendFrom = from;
      }

      if (weekendTo > to) {
        weekendTo = to;
      }

      weekends.push([weekendFrom, weekendTo]);
    };

    let date = this.startOfDay(from);

    if (date.getDay() === 0) {
      date = this.subtractDays(date, 1);
    }

    while (date < to) {
      if (isWeekend(date)) {
        addWeekend(date);

        date = this.addDays(date, 7);
      } else {
        date = this.addDays(date, 1);
      }
    }

    return weekends;
  },

  FormatDateTime(timestamp) {
    return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
  },

  FormatDate(timestamp) {
    return moment(timestamp).format("YYYY-MM-DD");
  },

  /**
   * @param {Date} minDate
   * @param {Date} maxDate
   * @returns {Number}
   */
  getMilliSeconsBetweetRange(minDate, maxDate) {
    return ((maxDate.getTime() - minDate.getTime()));
  },

    /**
   * @param {Date} minDate
   * @param {Date} maxDate
   * @returns {Number}
   */
    getSeconsBetweetRange(minDate, maxDate) {
      return ((maxDate.getTime() - minDate.getTime()) / 1000);
    },

  /**
  * @desc add the timestamp to the datapoint value based on the dateRange
  * @param array $values - datapoint values
  * @param Object $dateRange - the current selected dateRange
  */
  addTimestampToValues(values, dateRange) {
    let size = values.length;
    const startDate = new Date(dateRange.startTime);
    const endDate = new Date(dateRange.endTime);    
    var milliseconds = this.getMilliSeconsBetweetRange(startDate, endDate);
    var stepInmilliseconds = milliseconds/size;
    var data = [];

    var curretDate = new Date(startDate);
    values.forEach(value => {
      data.push({
        date: new Date(curretDate),
        value: value
      });
      curretDate.setMilliseconds(curretDate.getMilliseconds() + stepInmilliseconds);
    });

    return data;
  },
  
};
