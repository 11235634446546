import ApiService from "@/services/api.service";
import router from "../router";
import state from "./state";
import Lenguages from "@/enums/lenguages.enum";
import DateHelper from "../helpers/date.helper";
import i18n from '@/i18n.js' 

export default {
  /**
  * @desc User login
  */
  async userLogin({ commit }, { email, password }) {
    var response = await ApiService.login(email, password);
    if (response.data != null) {
      localStorage.setItem('token', response.data.token)
      commit('setUserLogin', localStorage.getItem('token'))
      localStorage.setItem('userRole', response.data.role)
      commit('setUserRole', response.data.role)
      localStorage.setItem('client_type', response.data.client_type)
      commit('setClientType', response.data.client_type)
      if(email=="demo@heroics.ai"){
        commit('setDemo', true)
        localStorage.setItem('demo', true)
      }
      else{
        commit('setDemo', false)
        localStorage.setItem('demo', false)
      }
      if (localStorage.getItem('currentScript') != null){
        commit('setCurrentScript', localStorage.getItem('currentScript'));
        }
      if (localStorage.getItem('language') != null){
        commit('setLenguage', localStorage.getItem('language'));
        }
      else {
        localStorage.setItem('lenguage', Lenguages.Español)
        commit('setLenguage', localStorage.getItem('language'))
      }
      if(response.data.client_type == 'AGENCY')
        router.push('google_ads_scripting')
      else
        router.push('daily_summary')
    } else {
      commit("setLoginError", true);
    }
  },


  /**
  * @desc looks for the accesToken on localStorage
  */
  fetchAccessToken({ commit }) {
    if (localStorage.getItem('token') != null){
      commit('setUserLogin', localStorage.getItem('token'));
      }
    if (localStorage.getItem('userRole') != null){
      commit('setUserRole', localStorage.getItem('userRole'));
      }
      if (localStorage.getItem('language') != null){
        commit('setLenguage', localStorage.getItem('language'));
        }
    if (localStorage.getItem('demo') != null){
      if(localStorage.getItem('demo') == "true")
        commit('setDemo', true);
      else
        commit('setDemo', false);
      }
    if (localStorage.getItem('client_type') != null){
      commit('setClientType', localStorage.getItem('client_type'));
      }
    if (localStorage.getItem('keepMeLoggedIn') != null){
      commit('setKeepMeLoggedIn', localStorage.getItem('keepMeLoggedIn'));
     }
     if (localStorage.getItem('currentScript') != null){
      commit('setCurrentScript', localStorage.getItem('currentScript'));
      }
  },


  /**
  * @desc checks if user is logged in
  */
  loggedInCheck({ commit }) {
    if (!state.loggedIn ) {
        router.push('/')
    }
    if (localStorage.getItem('language') != null){
      commit('setLenguage', localStorage.getItem('language'));
    }
    if (localStorage.getItem('client_type') != null){
      commit('setClientType', localStorage.getItem('client_type'));
      }
  },

  setOrdersQueryParams({ getters }) {
    var queryParams = {}
    queryParams['key'] = getters.getTimeRange  
    queryParams['keyCompare'] = getters.getCompareTimeRange
    if(getters.getTimeRange == "CUSTOM"){
      queryParams['fr'] = getters.getCustomStartDate
      queryParams['to'] = getters.getCustomEndDate
      queryParams['frCompare'] = getters.getCustomCompareStartDate
      queryParams['toCompare'] = getters.getCustomCompareEndDate
    }
    if(getters.getStatusParamsIsolated)
      queryParams['status'] = getters.getStatusParamsIsolated 
    if(getters.getOriginParamsIsolated)
      queryParams['origin'] = getters.getOriginParamsIsolated 
    router.replace({query: queryParams})
  },

  /**
  * @desc Request the orders page metric
  */
  async getOrdersPageData({ commit, getters }) {
    var response;
    var customParameters;
    var customCompareParameters;

    if(router.currentRoute.query.key) {
      commit("setTimeRangeType", router.currentRoute.query.key)
    }
    if(router.currentRoute.query.keyCompare) {
      commit("setCompareTimeRangeType", router.currentRoute.query.keyCompare)
    }
    if(router.currentRoute.query.status) {
      commit("updateCheckedItems", router.currentRoute.query.status.split(","))
    }
    if(router.currentRoute.query.origin) {
      commit("updateCheckedItemsOrigin", router.currentRoute.query.origin.split(","))
    }
    if(router.currentRoute.query.fr) {
      commit("setCustomStartDate", DateHelper.toDate(router.currentRoute.query.fr))
    }
    if(router.currentRoute.query.to) {
      commit("setCustomEndDate", DateHelper.toDate(router.currentRoute.query.to))
    }
    if(router.currentRoute.query.frCompare) {
      commit("setCompareWithCustomStartDate", DateHelper.toDate(router.currentRoute.query.frCompare))
    }
    if(router.currentRoute.query.toCompare) {
      commit("setCompareWithCustomEndDate", DateHelper.toDate(router.currentRoute.query.toCompare))
    }
    commit("setLoadingOrdersPageData", true) 
    if (getters.getTimeRange == 'LastXDays') {
      customParameters = '&fr='+getters.getCustomStartDate+'&to='+getters.getCustomEndDate;
      customCompareParameters = '&frCompare='+getters.getCustomCompareStartDate+'&toCompare='+getters.getCustomCompareEndDate;
      response = await ApiService.getOrderPageMetrics('CUSTOM'+customParameters, getters.getCompareTimeRange+customCompareParameters, getters.getStatusParams, getters.getOriginParams, getters.getOriginParams);
      }
    else if (getters.getTimeRange == 'CUSTOM') {
      customParameters = '&fr='+getters.getCustomStartDate+'&to='+getters.getCustomEndDate;
      customCompareParameters = '&frCompare='+getters.getCustomCompareStartDate+'&toCompare='+getters.getCustomCompareEndDate;
      response = await ApiService.getOrderPageMetrics(getters.getTimeRange+customParameters, getters.getCompareTimeRange+customCompareParameters, getters.getStatusParams, getters.getOriginParams, getters.getOriginParams);
      }
      else
        if (getters.getCompareTimeRange != 'COMPARE_WITH_CUSTOM')
          response = await ApiService.getOrderPageMetrics(getters.getTimeRange, getters.getCompareTimeRange, getters.getStatusParams, getters.getOriginParams);
        else {
          customCompareParameters = '&frCompare='+getters.getCustomCompareStartDate+'&toCompare='+getters.getCustomCompareEndDate;
          response = await ApiService.getOrderPageMetrics(getters.getTimeRange, getters.getCompareTimeRange+customCompareParameters, getters.getStatusParams, getters.getOriginParams);
        }
    if (response.data != null) {
      commit("setOrdersPageData", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingOrdersPageData", false)
      }
    }
  },

  setDailySummaryQueryParams({ getters }) {
    var queryParams = {}
    queryParams['date'] = getters.getDailySummaryDay 
    if(getters.getStatusParamsIsolated)
      queryParams['status'] = getters.getStatusParamsIsolated 
    if(getters.getOriginParamsIsolated)
      queryParams['origin'] = getters.getOriginParamsIsolated 
    router.replace({query: queryParams})
  },

/**
  * @desc Request the dailySummary page metric
  */
  async getDailySummaryPageData({ commit, getters }) {
    commit("setLoadingDailySummaryData", true)
    if(router.currentRoute.query.date) {
      commit("setDailySummaryDate", DateHelper.toDate(router.currentRoute.query.date))
      commit("setDailySummaryDateFormated", router.currentRoute.query.date)
    }
    if(router.currentRoute.query.status) {
      commit("updateCheckedItems", router.currentRoute.query.status.split(","))
    }
    if(router.currentRoute.query.origin) {
      commit("updateCheckedItemsOrigin", router.currentRoute.query.origin.split(","))
    }

    var response = await ApiService.getDailySummaryPageMetrics(getters.getDailySummaryDay, getters.getStatusParams, getters.getOriginParams);

    if (response.data != null) {
      commit("setDailySummaryPageData", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingDailySummaryData", false)
      }
    }
  },

  setPaymentsQueryParams({ getters }) {
    var queryParams = {}
    queryParams['key'] = getters.getTimeRange  
    queryParams['keyCompare'] = getters.getCompareTimeRange
    if(getters.getTimeRange == "CUSTOM"){
      queryParams['fr'] = getters.getCustomStartDate
      queryParams['to'] = getters.getCustomEndDate
      queryParams['frCompare'] = getters.getCustomCompareStartDate
      queryParams['toCompare'] = getters.getCustomCompareEndDate
    }
    if(getters.getStatusParamsIsolated)
      queryParams['status'] = getters.getStatusParamsIsolated 
    if(getters.getOriginParamsIsolated)
      queryParams['origin'] = getters.getOriginParamsIsolated 
    router.replace({query: queryParams})
  },

  /**
  * @desc Request the payments page metric
  */
  async getPaymentsPageData({ commit, getters }) {
    var response;
    var customParameters;
    var customCompareParameters;
    commit("setLoadingPaymentsPageData", true) 
    if(router.currentRoute.query.key) {
      commit("setTimeRangeType", router.currentRoute.query.key)
    }
    if(router.currentRoute.query.keyCompare) {
      commit("setCompareTimeRangeType", router.currentRoute.query.keyCompare)
    }
    if(router.currentRoute.query.status) {
      commit("updateCheckedItems", router.currentRoute.query.status.split(","))
    }
    if(router.currentRoute.query.origin) {
      commit("updateCheckedItemsOrigin", router.currentRoute.query.origin.split(","))
    }
    if(router.currentRoute.query.fr) {
      commit("setCustomStartDate", DateHelper.toDate(router.currentRoute.query.fr))
    }
    if(router.currentRoute.query.to) {
      commit("setCustomEndDate", DateHelper.toDate(router.currentRoute.query.to))
    }
    if(router.currentRoute.query.frCompare) {
      commit("setCompareWithCustomStartDate", DateHelper.toDate(router.currentRoute.query.frCompare))
    }
    if(router.currentRoute.query.toCompare) {
      commit("setCompareWithCustomEndDate", DateHelper.toDate(router.currentRoute.query.toCompare))
    }

    if (getters.getTimeRange == 'LastXDays') {
      customParameters = '&fr='+getters.getCustomStartDate+'&to='+getters.getCustomEndDate;
      customCompareParameters = '&frCompare='+getters.getCustomCompareStartDate+'&toCompare='+getters.getCustomCompareEndDate;
      response = await ApiService.getPaymentsPageMetrics('CUSTOM'+customParameters, getters.getCompareTimeRange+customCompareParameters, getters.getStatusParams, getters.getOriginParams);
      }
    else if (getters.getTimeRange == 'CUSTOM') {
      customParameters = '&fr='+getters.getCustomStartDate+'&to='+getters.getCustomEndDate;
      customCompareParameters = '&frCompare='+getters.getCustomCompareStartDate+'&toCompare='+getters.getCustomCompareEndDate;
      response = await ApiService.getPaymentsPageMetrics(getters.getTimeRange+customParameters, getters.getCompareTimeRange+customCompareParameters, getters.getStatusParams, getters.getOriginParams);
      }
    else
      if (getters.getCompareTimeRange != 'COMPARE_WITH_CUSTOM')
        response = await ApiService.getPaymentsPageMetrics(getters.getTimeRange, getters.getCompareTimeRange, getters.getStatusParams, getters.getOriginParams);
      else {
        customCompareParameters = '&frCompare='+getters.getCustomCompareStartDate+'&toCompare='+getters.getCustomCompareEndDate;
        response = await ApiService.getPaymentsPageMetrics(getters.getTimeRange, getters.getCompareTimeRange+customCompareParameters, getters.getStatusParams, getters.getOriginParams);
      }
    if (response.data != null) {
      commit("setPaymentsPageData", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingPaymentsPageData", false)
      }
    }
  },


  setStockQueryParams({ getters }) {
    var queryParams = {}
    queryParams['date'] = getters.getDailySummaryDay 
    if(getters.getCategoriesParamsIsolated)
      queryParams['categories'] = getters.getCategoriesParamsIsolated 
    if(getters.getDepartmentsParamsIsolated)
      queryParams['departments'] = getters.getDepartmentsParamsIsolated 
    router.replace({query: queryParams})
  },

  /**
  * @desc Request the stocks page metric
  */
  async getStocksPageData({ commit, getters }) {
    commit("setLoadingStocksPageData", true)

    if(router.currentRoute.query.date) {
      commit("setStockDate", DateHelper.toDate(router.currentRoute.query.date))
      commit("setStockDateFormated", router.currentRoute.query.date)
    }
    if(router.currentRoute.query.categories) {
      commit("updateCheckedCategories", router.currentRoute.query.categories.split(","))
    }
    if(router.currentRoute.query.departments) {
      commit("updateCheckedDepartments", router.currentRoute.query.departments.split(","))
    }

    var response = await ApiService.getStockPageMetrics(getters.getStockDay, getters.getDepartmentsParams, getters.getCategoriesParams);
    if (response.data != null) {
      commit("setStocksPageData", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingStocksPageData", false)
      }
    }
  },

  setProductsQueryParams({ getters }) {
    var queryParams = {}
    queryParams['key'] = getters.getTimeRange  
    queryParams['keyCompare'] = getters.getCompareTimeRange
    if(getters.getTimeRange == "CUSTOM"){
      queryParams['fr'] = getters.getCustomStartDate
      queryParams['to'] = getters.getCustomEndDate
      queryParams['frCompare'] = getters.getCustomCompareStartDate
      queryParams['toCompare'] = getters.getCustomCompareEndDate
    }
    if(getters.getStatusParamsIsolated)
      queryParams['status'] = getters.getStatusParamsIsolated 
    if(getters.getOriginParamsIsolated)
      queryParams['origin'] = getters.getOriginParamsIsolated 
    router.replace({query: queryParams})
  },

  /**
  * @desc Request the products page metric
  */
  async getProductsPageData({ commit, getters }) {
  var response;
  var customParameters;
  var customCompareParameters;

  if(router.currentRoute.query.key) {
    commit("setTimeRangeType", router.currentRoute.query.key)
  }
  if(router.currentRoute.query.keyCompare) {
    commit("setCompareTimeRangeType", router.currentRoute.query.keyCompare)
  }
  if(router.currentRoute.query.status) {
    commit("updateCheckedItems", router.currentRoute.query.status.split(","))
  }
  if(router.currentRoute.query.origin) {
    commit("updateCheckedItemsOrigin", router.currentRoute.query.origin.split(","))
  }
  if(router.currentRoute.query.fr) {
    commit("setCustomStartDate", DateHelper.toDate(router.currentRoute.query.fr))
  }
  if(router.currentRoute.query.to) {
    commit("setCustomEndDate", DateHelper.toDate(router.currentRoute.query.to))
  }
  if(router.currentRoute.query.frCompare) {
    commit("setCompareWithCustomStartDate", DateHelper.toDate(router.currentRoute.query.frCompare))
  }
  if(router.currentRoute.query.toCompare) {
    commit("setCompareWithCustomEndDate", DateHelper.toDate(router.currentRoute.query.toCompare))
  }

  commit("setLoadingProductsPageData", true) 
  if (getters.getTimeRange == 'LastXDays') {
    customParameters = '&fr='+getters.getCustomStartDate+'&to='+getters.getCustomEndDate;
    customCompareParameters = '&frCompare='+getters.getCustomCompareStartDate+'&toCompare='+getters.getCustomCompareEndDate;
    response = await ApiService.getProductsPageMetrics('CUSTOM'+customParameters, getters.getCompareTimeRange+customCompareParameters, getters.getStatusParams, getters.getOriginParams);
    }
  else if (getters.getTimeRange == 'CUSTOM') {
    customParameters = '&fr='+getters.getCustomStartDate+'&to='+getters.getCustomEndDate;
    customCompareParameters = '&frCompare='+getters.getCustomCompareStartDate+'&toCompare='+getters.getCustomCompareEndDate;
    response = await ApiService.getProductsPageMetrics(getters.getTimeRange+customParameters, getters.getCompareTimeRange+customCompareParameters, getters.getStatusParams, getters.getOriginParams);
    }
  else
    if (getters.getCompareTimeRange != 'COMPARE_WITH_CUSTOM')
      response = await ApiService.getProductsPageMetrics(getters.getTimeRange, getters.getCompareTimeRange, getters.getStatusParams, getters.getOriginParams);
    else {
      customCompareParameters = '&frCompare='+getters.getCustomCompareStartDate+'&toCompare='+getters.getCustomCompareEndDate;
      response = await ApiService.getProductsPageMetrics(getters.getTimeRange, getters.getCompareTimeRange+customCompareParameters, getters.getStatusParams, getters.getOriginParams);
    }
  if (response.data != null) {
    commit("setProductsPageData", response.data)
  }
  if (response.error) {
    if(response.error == '401')
      commit("setUserLogOut")
    else {
      commit("setError", true)
      commit("setLoadingProductsPageData", false)
    }
  }
},


  setSessionsQueryParams({ getters }) {
    var queryParams = {}
    queryParams['key'] = getters.getTimeRange  
    queryParams['keyCompare'] = getters.getCompareTimeRange
    if(getters.getTimeRange == "CUSTOM"){
      queryParams['fr'] = getters.getCustomStartDate
      queryParams['to'] = getters.getCustomEndDate
      queryParams['frCompare'] = getters.getCustomCompareStartDate
      queryParams['toCompare'] = getters.getCustomCompareEndDate
    }
    router.replace({query: queryParams})
  },

  /**
  * @desc Request the sessions page metric
  */
   async getSessionsPageData({ commit, getters }) {
    var response;
    var customParameters;
    var customCompareParameters;
    commit("setLoadingSessionsPageData", true) 

    if(router.currentRoute.query.key) {
      commit("setTimeRangeType", router.currentRoute.query.key)
    }
    if(router.currentRoute.query.keyCompare) {
      commit("setCompareTimeRangeType", router.currentRoute.query.keyCompare)
    }
    if(router.currentRoute.query.fr) {
      commit("setCustomStartDate", DateHelper.toDate(router.currentRoute.query.fr))
    }
    if(router.currentRoute.query.to) {
      commit("setCustomEndDate", DateHelper.toDate(router.currentRoute.query.to))
    }
    if(router.currentRoute.query.frCompare) {
      commit("setCompareWithCustomStartDate", DateHelper.toDate(router.currentRoute.query.frCompare))
    }
    if(router.currentRoute.query.toCompare) {
      commit("setCompareWithCustomEndDate", DateHelper.toDate(router.currentRoute.query.toCompare))
    }  

    if (getters.getTimeRange == 'LastXDays') {
      customParameters = '&fr='+getters.getCustomStartDate+'&to='+getters.getCustomEndDate;
      customCompareParameters = '&frCompare='+getters.getCustomCompareStartDate+'&toCompare='+getters.getCustomCompareEndDate;
      response = await ApiService.getSessionsPageMetrics('CUSTOM'+customParameters, getters.getCompareTimeRange+customCompareParameters);
      }
    else if (getters.getTimeRange == 'CUSTOM') {
      customParameters = '&fr='+getters.getCustomStartDate+'&to='+getters.getCustomEndDate;
      customCompareParameters = '&frCompare='+getters.getCustomCompareStartDate+'&toCompare='+getters.getCustomCompareEndDate;
      response = await ApiService.getSessionsPageMetrics(getters.getTimeRange+customParameters, getters.getCompareTimeRange+customCompareParameters);
      }
    else
      response = await ApiService.getSessionsPageMetrics(getters.getTimeRange, getters.getCompareTimeRange);
    if (response.data != null) {
      commit("setSessionsPageData", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingSessionsPageData", false)
      }
    }
  },

  setPaidTrafficQueryParams({ getters }) {
    var queryParams = {}
    queryParams['key'] = getters.getTimeRange  
    queryParams['keyCompare'] = getters.getCompareTimeRange
    if(getters.getTimeRange == "CUSTOM"){
      queryParams['fr'] = getters.getCustomStartDate
      queryParams['to'] = getters.getCustomEndDate
      queryParams['frCompare'] = getters.getCustomCompareStartDate
      queryParams['toCompare'] = getters.getCustomCompareEndDate
    }
    router.replace({query: queryParams})
  },

  /**
  * @desc Request the Traffic page metric
  */
   async getPaidTrafficPageData({ commit, getters }) {
    var response;
    var customParameters;
    var customCompareParameters;
    commit("setLoadingPaidTrafficPageData", true) 

    if(router.currentRoute.query.key) {
      commit("setTimeRangeType", router.currentRoute.query.key)
    }
    if(router.currentRoute.query.keyCompare) {
      commit("setCompareTimeRangeType", router.currentRoute.query.keyCompare)
    }
    if(router.currentRoute.query.fr) {
      commit("setCustomStartDate", DateHelper.toDate(router.currentRoute.query.fr))
    }
    if(router.currentRoute.query.to) {
      commit("setCustomEndDate", DateHelper.toDate(router.currentRoute.query.to))
    }
    if(router.currentRoute.query.frCompare) {
      commit("setCompareWithCustomStartDate", DateHelper.toDate(router.currentRoute.query.frCompare))
    }
    if(router.currentRoute.query.toCompare) {
      commit("setCompareWithCustomEndDate", DateHelper.toDate(router.currentRoute.query.toCompare))
    }  

    if (getters.getTimeRange == 'LastXDays') {
      customParameters = '&fr='+getters.getCustomStartDate+'&to='+getters.getCustomEndDate;
      customCompareParameters = '&frCompare='+getters.getCustomCompareStartDate+'&toCompare='+getters.getCustomCompareEndDate;
      response = await ApiService.getPaidTrafficPageMetrics('CUSTOM'+customParameters, getters.getCompareTimeRange+customCompareParameters);
      }
    else if (getters.getTimeRange == 'CUSTOM') {
      customParameters = '&fr='+getters.getCustomStartDate+'&to='+getters.getCustomEndDate;
      customCompareParameters = '&frCompare='+getters.getCustomCompareStartDate+'&toCompare='+getters.getCustomCompareEndDate;
      response = await ApiService.getPaidTrafficPageMetrics(getters.getTimeRange+customParameters, getters.getCompareTimeRange+customCompareParameters);
      }
    else
      response = await ApiService.getPaidTrafficPageMetrics(getters.getTimeRange, getters.getCompareTimeRange);
    if (response.data != null) {
      commit("setPaidTrafficPageData", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingPaidTrafficPageData", false)
      }
    }
  },

  setTrafficQueryParams({ getters }) {
    var queryParams = {}
    queryParams['key'] = getters.getTimeRange  
    queryParams['keyCompare'] = getters.getCompareTimeRange
    if(getters.getTimeRange == "CUSTOM"){
      queryParams['fr'] = getters.getCustomStartDate
      queryParams['to'] = getters.getCustomEndDate
      queryParams['frCompare'] = getters.getCustomCompareStartDate
      queryParams['toCompare'] = getters.getCustomCompareEndDate
    }
    router.replace({query: queryParams})
  },

  /**
  * @desc Request the Traffic page metric
  */
   async getTrafficPageData({ commit, getters }) {
    var response;
    var customParameters;
    var customCompareParameters;
    commit("setLoadingTrafficPageData", true) 

    if(router.currentRoute.query.key) {
      commit("setTimeRangeType", router.currentRoute.query.key)
    }
    if(router.currentRoute.query.keyCompare) {
      commit("setCompareTimeRangeType", router.currentRoute.query.keyCompare)
    }
    if(router.currentRoute.query.fr) {
      commit("setCustomStartDate", DateHelper.toDate(router.currentRoute.query.fr))
    }
    if(router.currentRoute.query.to) {
      commit("setCustomEndDate", DateHelper.toDate(router.currentRoute.query.to))
    }
    if(router.currentRoute.query.frCompare) {
      commit("setCompareWithCustomStartDate", DateHelper.toDate(router.currentRoute.query.frCompare))
    }
    if(router.currentRoute.query.toCompare) {
      commit("setCompareWithCustomEndDate", DateHelper.toDate(router.currentRoute.query.toCompare))
    }  

    if (getters.getTimeRange == 'LastXDays') {
      customParameters = '&fr='+getters.getCustomStartDate+'&to='+getters.getCustomEndDate;
      customCompareParameters = '&frCompare='+getters.getCustomCompareStartDate+'&toCompare='+getters.getCustomCompareEndDate;
      response = await ApiService.getTrafficPageMetrics('CUSTOM'+customParameters, getters.getCompareTimeRange+customCompareParameters);
      }
    else if (getters.getTimeRange == 'CUSTOM') {
      customParameters = '&fr='+getters.getCustomStartDate+'&to='+getters.getCustomEndDate;
      customCompareParameters = '&frCompare='+getters.getCustomCompareStartDate+'&toCompare='+getters.getCustomCompareEndDate;
      response = await ApiService.getTrafficPageMetrics(getters.getTimeRange+customParameters, getters.getCompareTimeRange+customCompareParameters);
      }
    else
      response = await ApiService.getTrafficPageMetrics(getters.getTimeRange, getters.getCompareTimeRange);
    if (response.data != null) {
      commit("setTrafficPageData", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingTrafficPageData", false)
      }
    }
  },

  /**
  * @desc Request the item stock data
  */
   async getItemStock({ commit, getters }) {
    commit("setLoadingItemData", true)
    var itemId = '&item_id=' + getters.getCurrentItem
    var response = await ApiService.getItemStockMetric(getters.getTimeRange, itemId);
    if (response.data != null) {
      commit("setItemData", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingItemData", false)
      }
    }
  },

  /**
  * @desc Request the item prices data
  */
   async getItemPrices({ commit, getters }) {
    commit("setLoadingItemData", true)
    var itemId = '&item_id=' + getters.getCurrentItem
    var response = await ApiService.getItemPricesMetric(getters.getTimeRange, itemId);
    if (response.data != null) {
      commit("setItemData", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingItemData", false)
      }
    }
  },

  /**
  * @desc Request the item sales data
  */
   async getItemSales({ commit, getters }) {
    commit("setLoadingItemData", true)
    var itemId = '&item_id=' + getters.getCurrentItem
    var response = await ApiService.getItemSalesMetric(getters.getTimeRange, itemId);
    if (response.data != null) {
      commit("setItemData", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingItemData", false)
      }
    }
  },
  /**
  * @desc Request the Traffic page metric
  */
   async getFilters({ commit }) {
    var response;
    commit("setLoadingFilterOptions", true) 
    response = await ApiService.getFilters();
    if (response.data != null) {
      commit("setFilterOptions", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingFilterOptions", false)
      }
    }
  },

  /**
  * @desc Request the item sales data
  */
   async getCrossSellingItemData({ commit, getters }) {
    commit("setLoadingItemData", true)
    var itemId = '&item_id=' + getters.getCurrentItem
    var response = await ApiService.getCrossSellingItemDataMetric(getters.getTimeRange, itemId);
    if (response.data != null) {
      commit("setItemData", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingItemData", false)
      }
    }
  },

  /**
  * @desc Request Users Data
  */
   async getUsers({ commit }) {
    var response;
    commit("setLoadingUsers", true) 
    response = await ApiService.getUsersMetric();
    if (response.data != null) {
      commit("setUsers", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingFilterOptions", false)
      }
    }
  },

 /**
  * @desc Update users data
  */
  async postNewUser({ commit }, {data}) {
    var response;
    response = await ApiService.postNewUserService(data);
    if (response.data != null) {
      window.alert("Usuario creado de manera correcta");
      let newUser = {
        "email": data.email,
        "password": data.password,
        "role": data.rol,
        "name": data.user_name
      }
      commit("setNewUser", newUser)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("Usuario no autorizado");
        commit("setUserLogOut")
        return true
      }
      else {
        window.alert("Error");
        commit("setError", true)
        commit("setLoadingFilterOptions", false)
        return false
      }
    }
  },

 /**
  * @desc Update password
  */
  async updatePassword({ commit }, {data}) {
    var response;
    commit("setLoadingScriptBuilder", true)
    response = await ApiService.updatePasswordService(data);
    if (response.data != null) {
      commit("setAlertType", 'success')
      commit("setAlertMessage", 'Contraseña actualizada correctamente!')
      commit("setLoadingScriptBuilder", false)
    }
    if (response.error) {
      if(response.error == '400'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Error')
        commit("setLoadingScriptBuilder", false)
      }
      if(response.error == '401'){
        commit("setAlertType", 'success')
        commit("setAlertMessage", 'Usuario no autorizado')
        commit("setLoadingScriptBuilder", false)
        commit("setUserLogOut")
      }
      else {
        commit("setError", true)
        commit("setLoadingFilterOptions", false)
        commit("setLoadingScriptBuilder", false)
      }
    }
  },

 /**
  * @desc deleteUser
  */
  async deleteUser({ commit }, {email}) {
    var response;

    response = await ApiService.deleteUserService(email);
    if (response.data != null) {
      window.alert("Usuario eliminado");
      commit("setUserDeleted", true)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("El usuario no existe");
        return false
      }
      else {
        commit("setError", true)
        commit("setLoadingFilterOptions", false)
        return false
      }
    }
  },

  /**
  * @desc Request the Traffic page metric
  */
   async getJobsExecution({ commit }) {
    var response;
    commit("setLoadingJobExecution", true) 
    response = await ApiService.getJobsExecutionMetrics();
    if (response.data != null) {
      commit("setJobsExecutionData", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingFilterOptions", false)
      }
    }
  },

  /**
  * @desc Request Users Data
  */
   async getSettings({ commit }) {
    var response;
    commit("setLoadingSettings", true) 
    response = await ApiService.getSettingsMetric();
    if (response.data != null) {
      commit("setSettings", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingFilterOptions", false)
      }
    }
  },


 /**
  * @desc Update password
  */
  async updateSettings({ commit }, {data}) {
    var response;
    commit("setLoadingSettings", true) 
    response = await ApiService.updateSettingsService(data);
    if (response.data != null) {
      response = await ApiService.getSettingsMetric();
      if (response.data != null) {
        commit("setSettings", response.data);
      }
      if (response.error) {
        if(response.error == '401')
          commit("setUserLogOut")
        else {
          commit("setError", true)
          commit("setLoadingFilterOptions", false)
        }
      }
    }
    if (response.error) {
      if(response.error == '400'){
        window.alert("La contraseña nueva debe ser diferente a la anterior");
      }
      if(response.error == '401'){
        window.alert("Contraseña actual invalida");
        commit("setUserLogOut")
      }
      else {
        commit("setError", true)
        commit("setLoadingFilterOptions", false)
      }
    }
  },

 /**
  * @desc post Vtex integration data
  */
  async vtexIntegration({ commit }, {data}) {
    var response;
    response = await ApiService.postVtexIntegrationService(data.integrationData);
    if (response.data != null) {
      window.alert(i18n.t('alerts.integrations.vtex'));
      let jobUpdateData = {
        "idx": data.jobData.index,
        "status": "IN_PROCESS"
      }
      commit("updateJob", jobUpdateData)
      commit("setLoadingSchema", false)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("Usuario no autorizado");
        commit("setUserLogOut")
        commit("setLoadingSchema", false)
        return true
      }
      else {
        window.alert("Error");
        commit("setError", true)
        commit("setLoadingFilterOptions", false)
        commit("setLoadingSchema", false)
        return false
      }
    }
  },

 /**
  * @desc post Facebook integration data
  */
  async facebookIntegration({ commit }, {data}) {
    var response;
    response = await ApiService.postFacebookIntegrationService(data.integrationData);
    if (response.data != null) {
      commit("setAlertType", 'success')
      commit("setAlertMessage", i18n.t('alerts.integrations.facebook'))
      if(state.clientType == 'AGENCY'){
        let jobUpdateData = {
          "idx": data.jobData.index,
          "status": "IN_PROCESS",
          "data_source": data.jobData.data_source
        }
        commit("updateAgencyJob", jobUpdateData)
      }
      else{
        let jobUpdateData = {
          "idx": data.jobData.index,
          "status": "IN_PROCESS"
        }
        commit("updateJob", jobUpdateData)
      }
      commit("setLoadingScriptBuilder", false)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("Usuario no autorizado");
        commit("setUserLogOut")
        commit("setLoadingSchema", false)
        return true
      }
      else {
        window.alert("Error");
        commit("setError", true)
        commit("setLoadingFilterOptions", false)
        commit("setLoadingSchema", false)
        return false
      }
    }
  },

 /**
  * @desc post Google Analytics integration data
  */
  async googleAnalyticsIntegration({ commit }, {data}) {
    var response;
    response = await ApiService.postGoogleAnalyticsIntegrationService(data.integrationData);
    if (response.data != null) {
      commit("setAlertType", 'success')
      commit("setAlertMessage", i18n.t('alerts.integrations.googleAnalytics'))
      if(state.clientType == 'AGENCY'){
        let jobUpdateData = {
          "idx": data.jobData.index,
          "status": "IN_PROCESS",
          "data_source": data.jobData.data_source
        }
        commit("updateAgencyJob", jobUpdateData)
      }
      else{
        let jobUpdateData = {
          "idx": data.jobData.index,
          "status": "IN_PROCESS"
        }
        commit("updateJob", jobUpdateData)
     }
      commit("setLoadingScriptBuilder", false)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("Usuario no autorizado");
        commit("setLoadingSchema", false)
        commit("setUserLogOut")
        return true
      }
      else {
        window.alert("Error");
        commit("setError", true)
        commit("setLoadingSchema", false)
        commit("setLoadingFilterOptions", false)
        return false
      }
    }
  },

 /**
  * @desc post Google Ads integration data
  */
  async googleAdsIntegration({ commit }, {data}) {
    var response;
    response = await ApiService.postGoogleAdsIntegrationService(data.integrationData);
    if (response.data != null) {
      commit("setAlertType", 'success')
      commit("setAlertMessage", i18n.t('alerts.integrations.googleAds'))
      if(state.clientType == 'AGENCY'){
        let jobUpdateData = {
          "idx": data.jobData.index,
          "status": "IN_PROCESS",
          "data_source": data.jobData.data_source
        }
        commit("updateAgencyJob", jobUpdateData)
      }
      else{
        let jobUpdateData = {
          "idx": data.jobData.index,
          "status": "IN_PROCESS"
        }
        commit("updateJob", jobUpdateData)
      }
      commit("setLoadingScriptBuilder", false)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("Usuario no autorizado");
        commit("setLoadingSchema", false)
        commit("setUserLogOut")
        return true
      }
      else {
        window.alert("Error");
        commit("setLoadingSchema", false)
        commit("setError", true)
        commit("setLoadingFilterOptions", false)
        return false
      }
    }
  },

 /**
  * @desc delete param (data source) integration data
  * 
  */
  async deleteIntegration({ commit }, {data}) {
    var response;
    response = await ApiService.deleteIntegrationService(data.data_source);
    if (response.data != null) {
      window.alert(i18n.t('alerts.integrations.deleted'));
      let jobUpdateData = {
        "idx": data.index,
        "status": "DISABLED"
      }
      commit("updateJob", jobUpdateData)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("Usuario no autorizado");
        commit("setUserLogOut")
        return true
      }
      else {
        window.alert("Error");
        commit("setError", true)
        commit("setLoadingSchema", false)
        commit("setLoadingFilterOptions", false)
        return false
      }
    }
  },

   /**
  * @desc get specific integration data
  */
  async getIntegrationData({ commit, getters }) {
    var response;
    response = await ApiService.getIntegrationDataService('?data_source=' + getters.getCurrentDataSource );
    if (response.data != null) {
      commit("setEditModel", response.data)
      commit("setLoadingEditSchema", false)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("Not authorized");
        commit("setLoadingEditSchema", false)
        commit("setUserLogOut")
        return true
      }
      if(response.error == '402'){
        window.alert(response.data);
        commit("setLoadingEditSchema", false)
        return true
      }
      else {
        window.alert("Error");
        commit("setError", true)
        commit("setLoadingEditSchema", false)
        commit("setLoadingSchema", false)
        commit("setLoadingFilterOptions", false)
        return false
      }
    }
  },

 /**
  * @desc put Vtex integration data
  */
  async vtexIntegrationEdit({ commit }, {data}) {
    var response;
    response = await ApiService.putVtexIntegrationEditService(data.integrationData);
    if (response.data != null) {
      window.alert(i18n.t('alerts.integrations.edited'));
      let jobUpdateData = {
        "idx": data.jobData.index,
        "status": "IN_PROCESS"
      }
      commit("updateJob", jobUpdateData)
      commit("setLoadingSchema", false)
      commit("setLoadingScriptBuilder", false)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("Usuario no autorizado");
        commit("setUserLogOut")
        commit("setLoadingSchema", false)
        return true
      }
      else if(response.error == '402'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Integracion ya registrada!')
        commit("setLoadingScriptBuilder", false)
        return false
      }
      else if(response.error == '403'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Campos requeridos incompletos')
        commit("setLoadingScriptBuilder", false)
        return false
      }
      else {
        window.alert("Error");
        commit("setError", true)
        commit("setLoadingFilterOptions", false)
        commit("setLoadingSchema", false)
        return false
      }
    }
  },

 /**
  * @desc put Facebook integration data
  */
  async facebookIntegrationEdit({ commit }, {data}) {
    var response;
    response = await ApiService.putFacebookIntegrationEditService(data.integrationData);
    if (response.data != null) {
      window.alert(i18n.t('alerts.integrations.edited'));
      let jobUpdateData = {
        "idx": data.jobData.index,
        "status": "IN_PROCESS"
      }
      commit("updateJob", jobUpdateData)
      commit("setLoadingSchema", false)
      commit("setLoadingScriptBuilder", false)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("Usuario no autorizado");
        commit("setUserLogOut")
        commit("setLoadingSchema", false)
        return true
      }
      else if(response.error == '402'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Integracion ya registrada!')
        commit("setLoadingScriptBuilder", false)
        return false
      }
      else if(response.error == '403'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Campos requeridos incompletos')
        commit("setLoadingScriptBuilder", false)
        return false
      }
      else {
        window.alert("Error");
        commit("setError", true)
        commit("setLoadingFilterOptions", false)
        commit("setLoadingSchema", false)
        return false
      }
    }
  },

 /**
  * @desc put Google Analytics integration data
  */
  async googleAnalyticsIntegrationEdit({ commit }, {data}) {
    var response;
    response = await ApiService.putGoogleAnalyticsIntegrationEditService(data.integrationData);
    if (response.data != null) {
      window.alert(i18n.t('alerts.integrations.edited'));
      let jobUpdateData = {
        "idx": data.jobData.index,
        "status": "IN_PROCESS"
      }
      commit("updateJob", jobUpdateData)
      commit("setLoadingSchema", false)
      commit("setLoadingScriptBuilder", false)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("Usuario no autorizado");
        commit("setLoadingSchema", false)
        commit("setUserLogOut")
        return true
      }
      else if(response.error == '402'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Integracion ya registrada!')
        commit("setLoadingScriptBuilder", false)
        return false
      }
      else if(response.error == '403'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Campos requeridos incompletos')
        commit("setLoadingScriptBuilder", false)
        return false
      }
      else {
        window.alert("Error");
        commit("setError", true)
        commit("setLoadingSchema", false)
        commit("setLoadingFilterOptions", false)
        return false
      }
    }
  },

 /**
  * @desc put Google Ads integration data
  */
  async googleAdsIntegrationEdit({ commit }, {data}) {
    var response;
    response = await ApiService.putGoogleAdsIntegrationEditService(data.integrationData);
    if (response.data != null) {
      window.alert(i18n.t('alerts.integrations.edited'));
      let jobUpdateData = {
        "idx": data.jobData.index,
        "status": "IN_PROCESS"
      }
      commit("updateJob", jobUpdateData)
      commit("setLoadingSchema", false)
      commit("setLoadingScriptBuilder", false)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("Usuario no autorizado");
        commit("setLoadingSchema", false)
        commit("setUserLogOut")
        return true
      }
      else if(response.error == '402'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Integracion ya registrada!')
        commit("setLoadingScriptBuilder", false)
        commit("setLoadingScriptBuilder", false)
        return false
      }
      else if(response.error == '403'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Campos requeridos incompletos')
        commit("setLoadingScriptBuilder", false)
        commit("setLoadingScriptBuilder", false)
        return false
      }
      else {
        window.alert("Error");
        commit("setLoadingSchema", false)
        commit("setError", true)
        commit("setLoadingFilterOptions", false)
        return false
      }
    }
  },

 /**
  * @desc post script
  */
  async postNewScript({ commit, dispatch }, {data}) {
    commit("setLoadingScriptBuilder", true)
    var response;
    response = await ApiService.postScriptService(data);
    if (response.data != null) {
      commit("setScriptString", response.data)
      commit('setScriptDialog', true)
      dispatch('getScriptRegisterSilent')
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("Usuario no autorizado");
        commit("setUserLogOut")
        return true
      }
      else {
        window.alert("Error");
        commit("setError", true)
        commit("setLoadingScriptBuilder", false)
        return false
      }
    }
  },

  /**
  * @desc Request Users Data
  */
   async getScriptRegister({ commit }) {
    var response;
    commit("setLoadingScriptRegister", true) 
    response = await ApiService.getScriptRegisterService();
    if (response.data != null) {
      commit("setScriptRegister", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingScriptRegister", false)
      }
    }
  },

  /**
  * @desc Request Users Data
  */
   async getScriptRegisterSilent({ commit }) {
    var response;
    response = await ApiService.getScriptRegisterService();
    if (response.data != null) {
      commit("setScriptRegister", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingScriptRegister", false)
      }
    }
  },

 /**
  * @desc post script
  */
  async getScript({ commit }, {data}) {
    commit("setLoadingScriptBuilder", true)
    var date = '?date='+ data.date
    var time = '&time='+ data.time
    var response;
    response = await ApiService.getScript(date, time);
    if (response.data != null) {
      commit("setLoadingScriptBuilder", false)
      commit("setScriptString", response.data)
      commit('setScriptDialog', true)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        commit("setLoadingScriptBuilder", false)
        window.alert("Usuario no autorizado");
        commit("setUserLogOut")
        return true
      }
      else {
        window.alert("Error");
        commit("setError", true)
        commit("setLoadingScriptBuilder", false)
        return false
      }
    }
  },

  /**
  * @desc Request Users Data
  */
   async getAgencyClients({ commit }) {
    var response;
    commit("setLoadingAgencyClients", true) 
    response = await ApiService.getAgencyClientsService();
    if (response.data != null) {
      commit("setAgencyClients", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingAgencyClients", false)
      }
    }
  },

 /**
  * @desc post agency client
  */
  async postAgencyClient({ commit }, {data}) {
    commit("setLoadingScriptBuilder", true)
    var response;
    response = await ApiService.postAgencyClientService(data);
    if (response.data != null) {
      commit("setAlertType", 'success')
      commit("setAlertMessage", 'Cliente creado!')
      commit("setLoadingScriptBuilder", false)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Usuario no authorizado')
        commit("setLoadingScriptBuilder", false)
        commit("setUserLogOut")
        return true
      }
      else if(response.error == '402'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Cliente ya existente')
        commit("setLoadingScriptBuilder", false)
        return true
      }
        else {
          commit("setAlertType", 'error')
          commit("setAlertMessage", response.error)
          commit("setLoadingScriptBuilder", false)
          return false
        }
    }
  },

 /**
  * @desc deleteUser
  */
  async deleteAgencyClient({ commit }, {data}) {
    var response;
    commit("setLoadingScriptBuilder", true)
    response = await ApiService.deleteAgencyClientService(data['name']);
    if (response.data != null) {
      commit("setAlertType", 'success')
      commit("setAlertMessage", 'Cliente eliminado!')
      commit("setLoadingScriptBuilder", false)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'El usuario no existe')
        commit("setLoadingScriptBuilder", false)
        return false
      }
      else {
        commit("setAlertType", 'error')
        commit("setAlertMessage", response.error)
        commit("setLoadingScriptBuilder", false)
        return false
      }
    }
  },

 /**
  * @desc delete script
  */
  async deleteScript({ commit }, {data}) {
    var response;
    commit("setLoadingScriptBuilder", true)
    response = await ApiService.deleteScriptService(data['identifier']);
    if (response.data != null) {
      commit("setAlertType", 'success')
      commit("setAlertMessage", 'Script eliminado!')
      commit("setLoadingScriptBuilder", false)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        commit("setLoadingScriptBuilder", false)
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Usuario no autorizado')
        return false
      }
      else {
        commit("setError", true)
        commit("setLoadingScriptBuilder", false)
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Error')
        return false
      }
    }
  },

  /**
  * @desc Request Users Data
  */
   async getAgencyIntegrations({ commit }) {
    var response;
    commit("setLoadingAgencyIntegrations", true) 
    response = await ApiService.getAgencyIntegrationsService();
    if (response.data != null) {
      commit("setAgencyIntegrations", response.data);
      commit("setLoadingAgencyIntegrations", false)
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingAgencyIntegrations", false)
      }
    }
  },

 /**
  * @desc delete param (data source) integration data
  * 
  */
  async deleteAgencyIntegration({ commit }, {data}) {
    var response;
    commit("setLoadingScriptBuilder", true)
    response = await ApiService.deleteAgencyIntegrationService(data.data_source, data.agency_client);
    if (response.data != null) {
      commit("setAlertType", 'success')
      commit("setAlertMessage", 'Integración Elminiada!')
      if(state.clientType == 'AGENCY'){
        var source_field
        if( data.data_source == 'facebook'){
          source_field = 'facebookAds'
        }
        if( data.data_source == 'google_ads'){
          source_field = 'googleAds'
        }
        if( data.data_source == 'google_analytics'){
          source_field = 'googleAnalytics'
        }
        let jobUpdateData = {
          "idx": data.index,
          "status": "DISABLED",
          "data_source": source_field
        }
        commit("updateAgencyJob", jobUpdateData)
      }
      commit("setLoadingScriptBuilder", false)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("Usuario no autorizado");
        commit("setLoadingScriptBuilder", false)
        commit("setUserLogOut")
        return true
      }
      else {
        window.alert("Error");
        commit("setError", true)
        commit("setLoadingScriptBuilder", false)
        commit("setLoadingSchema", false)
        commit("setLoadingFilterOptions", false)
        return false
      }
    }
  },

   /**
  * @desc get specific integration data
  */
    async getAgencyIntegrationData({ commit, getters }) {
      var response;
      response = await ApiService.getAgencyIntegrationDataService('?data_source=' + getters.getCurrentDataSource, '&agency_client=' + getters.getCurrentAgencyClient );
      if (response.data != null) {
        commit("setEditModel", response.data)
        commit("setLoadingScriptBuilder", false)
        return true
      }
      if (response.error) {
        if(response.error == '401'){
          window.alert("Not authorized");
          commit("setLoadingScriptBuilder", false)
          commit("setUserLogOut")
          return true
        }
        if(response.error == '402'){
          window.alert(response.data);
          commit("setLoadingScriptBuilder", false)
          return true
        }
        else {
          window.alert("Error");
          commit("setError", true)
          commit("setLoadingScriptBuilder", false)
          commit("setLoadingSchema", false)
          commit("setLoadingFilterOptions", false)
          return false
        }
      }
    },

     /**
  * @desc put Facebook integration data
  */
  async facebookAgencyIntegrationEdit({ commit }, {data}) {
    var response;
    response = await ApiService.putFacebookAgencyIntegrationEditService(data.integrationData);
    if (response.data != null) {
      commit("setAlertType", 'success')
      commit("setAlertMessage", 'Integracion modificada!')
      commit("setLoadingScriptBuilder", false)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("Usuario no autorizado");
        commit("setUserLogOut")
        commit("setLoadingSchema", false)
        return true
      }
      else if(response.error == '402'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Integracion ya registrada!')
        commit("setLoadingScriptBuilder", false)
        return false
      }
      else if(response.error == '403'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Campos requeridos incompletos')
        commit("setLoadingScriptBuilder", false)
        return false
      }
      else {
        window.alert("Error");
        commit("setError", true)
        commit("setLoadingFilterOptions", false)
        commit("setLoadingSchema", false)
        return false
      }
    }
  },

 /**
  * @desc put Google Analytics agency integration data
  */
  async googleAnalyticsAgencyIntegrationEdit({ commit }, {data}) {
    var response;
    response = await ApiService.putGoogleAnalyticsAgencyIntegrationEditService(data.integrationData);
    if (response.data != null) {
      commit("setAlertType", 'success')
      commit("setAlertMessage", 'Integracion modificada!')
      commit("setLoadingScriptBuilder", false)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("Usuario no autorizado");
        commit("setLoadingSchema", false)
        commit("setUserLogOut")
        return true
      }
      else if(response.error == '402'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Integracion ya registrada!')
        commit("setLoadingScriptBuilder", false)
        return false
      }
      else if(response.error == '403'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Campos requeridos incompletos')
        commit("setLoadingScriptBuilder", false)
        return false
      }
      else {
        window.alert("Error");
        commit("setError", true)
        commit("setLoadingSchema", false)
        commit("setLoadingFilterOptions", false)
        return false
      }
    }
  },

 /**
  * @desc put Google Ads agency integration data
  */
  async googleAdsAgencyIntegrationEdit({ commit }, {data}) {
    var response;
    response = await ApiService.putGoogleAdsAgencyIntegrationEditService(data.integrationData);
    if (response.data != null) {
      commit("setAlertType", 'success')
      commit("setAlertMessage", 'Integracion modificada!')
      commit("setLoadingScriptBuilder", false)
      return true
    }
    if (response.error) {
      if(response.error == '401'){
        window.alert("Usuario no autorizado");
        commit("setLoadingSchema", false)
        commit("setUserLogOut")
        return true
      }
      else if(response.error == '402'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Integracion ya registrada!')
        commit("setLoadingScriptBuilder", false)
        return false
      }
      else if(response.error == '403'){
        commit("setAlertType", 'error')
        commit("setAlertMessage", 'Campos requeridos incompletos')
        commit("setLoadingScriptBuilder", false)
        commit("setLoadingScriptBuilder", false)
        return false
      }
      else {
        window.alert("Error");
        commit("setLoadingSchema", false)
        commit("setError", true)
        commit("setLoadingFilterOptions", false)
        return false
      }
    }
  },

  /**
  * @desc Request Users Data
  */
   async getScriptExecutions({ commit, getters }) {
    var response;
    commit("setLoadingScriptExecutions", true) 
    response = await ApiService.getScriptExecutionsService('?offset=' + getters.getScriptExecutionOffset );
    if (response.data != null) {
      commit("setScriptExecutions", response.data);
      commit("setLoadingScriptExecutions", false)
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingScriptExecutions", false)
      }
    }
  },

    /**
  * @desc Request Budget control report
  */
  async getBudgetControlReport({ commit }) {
    var response;
    commit("setLoadingBudgetControl", true) 
    response = await ApiService.getBudgetReportService('?client_id=' + state.budgetsControlClient);
    if (response.data != null) {
      commit("setBudgetReport", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingBudgetControl", false)
      }
    }
  },

      /**
  * @desc Request Budget control report
  */
  async getBudgets({ commit }) {
    var response;
    commit("setLoadingBudgetControl", true) 
    response = await ApiService.getBudgets('?client_id=' + state.budgetsControlClient);
    if (response.data != null) {
      commit("setBudgets", response.data);
    }
    if (response.error) {
      if(response.error == '401')
        commit("setUserLogOut")
      else {
        commit("setError", true)
        commit("setLoadingBudgetControl", false)
      }
    }
  },

   /**
  * @desc post budget
  */
  async postBudget({ commit }, {data}) {
      var response = await ApiService.postBudget(data);

      if (response.data != null) {
        commit("setAlertType", 'success')
        commit("setAlertMessage", 'Budget agregado.')
        return true
      }
      if (response.error) {
        if(response.error == '401'){
          commit("setAlertType", 'error')
          commit("setAlertMessage", 'Usuario no authorizado')
          commit("setUserLogOut")
          return true
        }
        else {
          commit("setAlertType", 'error')
          commit("setAlertMessage", response.error)
          return false
        }
      }
    },
}

