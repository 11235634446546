<template>
  <v-card
    color="#FFFFFF"
    class="card"
  >
    <v-row v-if="!mobile" no-gutters class="broked-items
  ">
      <v-col md="8" cols="10" style="height:20px">
        <span class="table-title">
          {{ $t('Stock.BrokedItems.title')}} 
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
            {{ $t('Stock.BrokedItems.description')}} 
            <data-sources
              :sources="['vtex']"
            />
          </v-tooltip>
        </span>
      </v-col> 

      <v-col md="4" cols="2" class="right-content" style="height:20px">
        <v-btn
          tile
          small
          color="success"
          @click="save()"
        >
          <v-icon style="font-size: 20px;">
            mdi-microsoft-excel
          </v-icon>
        </v-btn>
      </v-col>

      <v-col md="12" cols="12" class="d-flex text-field-col" style="margin-top: 16px">
          <v-text-field
                  class="shrink"
                  prepend-inner-icon="mdi-magnify"
                  label="Buscar Item"
                  rounded
                  dense
                  outlined
                  hide-details
                  @input="filter($event)"
          ></v-text-field>
      </v-col>

      <v-col md="12" cols="12" >
        <wj-flex-grid 
          :initialized="initializeFlexSheet"
          :selectionMode="'ListBox'"
          :headersVisibility="'Column'"
          :itemsSource="data" 
          :alternatingRowStep="1"
          style="max-height: 230px"
        >
            <wj-flex-grid-filter />
            <wj-flex-grid-column :header="'id'" :binding="'id'" :width=80 format="n0" :isReadOnly=true />
            <wj-flex-grid-column :header="'Item'" :binding="'name'" width="*" :isReadOnly=true />
        </wj-flex-grid>
      </v-col>
        <v-col md="12" cols="12"  >
          <div v-on:click="updateViewPager($event)" class="update-view-pager">
            <flex-grid-page-size-selector 
              style="display:flex;position: relative;inset: -15px 0 -15px -20px"
              @change="onChangeChild"
              :currentPageSize="this.currentPageSize"
              :pageSizeSelect="this.pageSizeSelect"
            />
              <button id="btnFirst" class="btn"><span class="wj-glyph-step-backward" style="position: relative;left: -10px; top: 1px" ></span></button>
              <button id="btnPrev" class="btn"><span class="wj-glyph-left" style="position: relative;left: -3px; " ></span></button>
              &nbsp;&nbsp;&nbsp;Página {{ this.pageIndex + 1 | number:0 }} de {{ this.pageCount | number:0 }}&nbsp;&nbsp;&nbsp;
              <button id="btnNext" class="btn"><span class="wj-glyph-right" style="position: relative;right: -3px; "></span></button>
              <button id="btnLast" class="btn"><span class="wj-glyph-step-forward" style="position: relative;right: -10px; top: 1px"></span></button>
          </div>
        </v-col>
    </v-row>

    <v-row v-if="mobile"  no-gutters class="broked-items
  ">
      <v-col md="8" cols="10" style="height:20px">
        <span class="table-title">
          {{ $t('Stock.BrokedItems.title')}} 
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
            {{ $t('Stock.BrokedItems.description')}} 
            <data-sources
              :sources="['vtex']"
            />
          </v-tooltip>
        </span>
      </v-col> 

      <v-col md="4" cols="2" class="right-content" style="height:20px">
        <v-btn
          tile
          small
          color="success"
          @click="save()"
        >
          <v-icon style="font-size: 20px;">
            mdi-microsoft-excel
          </v-icon>
        </v-btn>
      </v-col>

      <v-col md="12" cols="12" class="d-flex text-field-col" style="margin-top: 30px">
          <v-text-field
                  class="shrink"
                  prepend-inner-icon="mdi-magnify"
                  label="Buscar Item"
                  rounded
                  dense
                  outlined
                  hide-details
                  @input="filter($event)"
          ></v-text-field>
      </v-col>

      <v-col md="12" cols="12" >
        <wj-flex-grid 
          :initialized="initializeFlexSheet"
          :itemsSource="data" 
          :selectionMode="'ListBox'"
          :headersVisibility="'Column'"
          :alternatingRowStep="1"
          style="max-height: 1090px"
        >
            <wj-flex-grid-filter />
            <wj-flex-grid-column :header="'id'" :binding="'id'"  format="n0" :isReadOnly=true />
            <wj-flex-grid-column :header="'Item'" :binding="'name'"  :isReadOnly=true />
        </wj-flex-grid>
      </v-col>
      <v-row v-if="this.mobile" >
        <v-col cols="12" class="mobile-selector"    >
          <div v-on:click="updateViewPager($event)" class="update-view-pager">
              <button id="btnFirst" class="btn"><span class="wj-glyph-step-backward" style="position: relative;left: -10px; top: 1px" ></span></button>
              <button id="btnPrev" class="btn"><span class="wj-glyph-left" style="position: relative;left: -3px; " ></span></button>
              &nbsp;&nbsp;&nbsp;{{ $t('FlexGridPageSizeSelector.page')}} {{ this.pageIndex + 1 | number:0 }} {{ $t('FlexGridPageSizeSelector.of')}} {{ this.pageCount | number:0 }}&nbsp;&nbsp;&nbsp;
              <button id="btnNext" class="btn"><span class="wj-glyph-right" style="position: relative;right: -3px; "></span></button>
              <button id="btnLast" class="btn"><span class="wj-glyph-step-forward" style="position: relative;right: -10px; top: 1px"></span></button>
          </div>
        </v-col>
        <v-col cols="12" class="mobile-selector " >
          <flex-grid-page-size-selector 
            @change="onChangeChild"
            :currentPageSize="this.currentPageSize"
            :pageSizeSelect="this.pageSizeSelect"
          />
        </v-col>
      </v-row>
    </v-row>

  </v-card>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import "@grapecity/wijmo.vue2.grid";
import '@grapecity/wijmo.vue2.grid.filter';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { CollectionView } from '@grapecity/wijmo';
import DataSources from '../shared/DataSources.vue';
import * as wijmo from '@grapecity/wijmo';
import FlexGridHelper from "@/helpers/flexGrid.helper";
import FlexGridPageSizeSelector from '../shared/FlexGridPageSizeSelector.vue';
import { mapState } from 'vuex';

export default {
  name: "BrokedItems",
  props: [
    'brokedItemsData'
  ],
  components: {
    DataSources,
    FlexGridPageSizeSelector

  },
  data: function() {
    return {
      maxPageSize: '5',
      currentPageSize: '5',
      pageIndex: 0,
      pageCount: 1,
      description: "",
    }
  },
  computed: {
    ...mapState([
      "mobile"
    ]),
    data() {
      return new CollectionView(FlexGridHelper.fillData(this.brokedItemsData, parseInt(this.currentPageSize)), {
              refreshOnEdit: true, // on-demand sorting and filtering
              pageSize: parseInt(this.currentPageSize)
            })
    },
    pageSizeSelect() {
      return FlexGridHelper.makeList(parseInt(this.maxPageSize))
    },
  },
  methods: {
    initializeFlexSheet: function(flex) {
      this.flex = flex;
    },
    onChangeChild(value) {
      this.currentPageSize = value
      this.updatePagesIndex()
    },
    updatePagesIndex() {
      this.pageIndex = this.data.pageIndex
      this.pageCount = this.data.pageCount
    }, 
    save() {
      this.data.pageSize = 0;
      wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
          this.flex,
          {
              includeColumnHeaders: true,
              includeCellStyles: false,
              formatItem: null
          },
          'items-que-quebraron.xlsx'
      );
      this.data.pageSize = parseInt(this.currentPageSize); 
    },
    filter: function(e) {
        let filter = e;
        this.flex.collectionView.filter = item => {
            return (
                filter.length == 0 ||
                item.name.toLowerCase().indexOf(filter) > -1
            );
        };
    },
    updateViewPager(e) {
        let btn = wijmo.closest(e.target, 'button'),
            id = btn ? btn.id : '';
        //
        switch (id) {
            case 'btnFirst':
                this.data.moveToFirstPage();
                break;
            case 'btnPrev':
                this.data.moveToPreviousPage();
                break;
            case 'btnNext':
                this.data.moveToNextPage();
                break;
            case 'btnLast':
                this.data.moveToLastPage();
                break;
        }
        this.updatePagesIndex()
    },
  },
created: function () {
  this.updatePagesIndex()
},    
};
</script>

<style scoped lang="scss">
.broked-items {
  margin-top: 0px !important;

  .wj-flexchart {
    padding: 0px !important;
  }
}

.broked-items-title {
  font-family: Open Sans, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: rgba(2, 8, 15, 1);
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 18px;
  letter-spacing: 0px;
}
.right-content {
  margin: auto; 
  text-align: right; 
  padding-right: 20px !important;
  position:relative
}

.form-control {
  margin-left: 5%;
  margin-top:1%;
  background-color:#add8e6;
  box-shadow: 0 0 3px black;
}


.card {
  padding: 24px;
  max-height: 1200px;
}

.update-view-pager {
  align-items: center; 
  text-align: right; 
  padding-right: 16px;
  display:flex;
  float:right;
  height:30px;
}


</style>