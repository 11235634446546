<template>

  <div class="app-font " style="  display: inherit; align-center; margin-top:-7px" >
    <div class="toolbar-time-range align-center">
    <v-icon style="margin-right: 5px;">
      mdi-group
    </v-icon>
    {{ $t('CategoriesFilter.categories')}}
    <v-select
        v-model="checkedCategories"
        :items="this.categoriesParams"
        item-text="label"  
        item-value="id"      
        :label="$t('CategoriesFilter.select')"
        multiple
        solo
        flat
        dense
        background-color="transparent"
        hide-details
        class="time-range-select ml-1 capitalized-text app-font"
        :menu-props="['offset-y']"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0">
            <span>{{ item.label }}</span>
          </v-chip>
          <span
            v-if="index === 1"
            class="grey--text text-caption"
          >
            (+{{ $t('CategoriesFilter.others')}} {{ checkedCategories.length - 1 }})
          </span>
        </template>
      </v-select>

    </div>

  <div class="toolbar-time-range align-center" style="margin-left:10px">
    <v-icon style="margin-right: 5px;">
      mdi-group
    </v-icon>
    {{ $t('CategoriesFilter.departments')}}
    <v-select
        v-model="checkedDepartments"
        :items="this.departmentsParams"
        item-text="label"  
        item-value="id"      
        :label="$t('CategoriesFilter.select')"
        multiple
        solo
        flat
        dense
        background-color="transparent"
        hide-details
        class="time-range-select ml-1 capitalized-text"
        :menu-props="['offset-y']"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0">
            <span>{{ item.label }}</span>
          </v-chip>
          <span
            v-if="index === 1"
            class="grey--text text-caption"
          >
            (+Otros {{ checkedDepartments.length - 1 }})
          </span>
        </template>
      </v-select>

  </div>
  </div>

</template>

<script>
import {mapState} from "vuex";
export default {
  name: "CategoriesFilter",

  components: {
  },
    data() {
    return {
        }
  },
  computed: {
    ...mapState([
        "categoriesParams",
        "departmentsParams"
        ]),
    checkedCategories: {
      get () {
        return this.$store.state.checkedCategories
      },
      set (value) {
        this.$store.commit('updateCheckedCategories', value)
      }
    },
    checkedDepartments: {
      get () {
        return this.$store.state.checkedDepartments
      },
      set (value) {
        this.$store.commit('updateCheckedDepartments', value)
      }
    }
  },
  methods: {
  },
};
</script>

<style lang="scss">

</style>