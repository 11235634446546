import ui_kit from "@/constants/ui-kit";
import ThousandSeparator from "@/helpers/thousandSeparator.helper";
import i18n from '@/i18n.js' 

var localeKeys = 'keys.'

export default {
    /** returns the html code to show the values of the grafic on a component
     * @param {Array} ht
     * @returns {String} s
     */ 
    

     markerContent(ht, compare) {
         var palette 
        if(compare == 'compare')
            palette = ui_kit.comparePalette
        else
            palette = ui_kit.palette
        if (ht.item) {
            let s = `<p style=" color: rgba(2, 8, 15, 0.5); contrast(50%); 
                                font-family: Open Sans, sans-serif;
                                font-weight: normal;
                                "> 
                                ${ht.item.date} </p>
                                <div class="row" >`;
            var i=-1;
            var firstColumn = `<div class="col-lg-8 col-md-8 " >`
            var secondColumn = `<div class="col-lg-4 col-md-4 ">`
            var endCol = `</div>`
            var endRow = `</div>`
            
            for (let key in ht.item) {
                var keyString = localeKeys + key
                if (key !== 'date') {
                    firstColumn += `
                    <div style=" color: rgba(2, 8, 15, 0,85); contrast(85%); 
                    font-family: Open Sans, sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    float:left;
                    width:150px
                    ">
                    <div style="  
                            width:12px;
                            height:12px;
                            border:solid white 1px;
                            float:left;
                            margin:5px;
                            background-color:${palette[i]}">
                        </div>
                    <b> ${i18n.t(keyString)} </b> </div> </br> `;
                    secondColumn += `
                    <div style=" rgba(2, 8, 15, 0.85);
                    font-family: Open Sans, sans-serif;
                    font-weight: normal;
                    font-size: 14px;
                    text-align:right;
                    float:right;
                    
                    "> ${ThousandSeparator.formatNumber(ht.item[key])}</div>
                      </br>  `;
                }
                i++;
            }
            return s + firstColumn + endCol + secondColumn + endCol + endRow;
        } else {
            return 'No items here...';
        }
    }

}
