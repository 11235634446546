<template>
  <v-card
    color="#FFFFFF"
    class="graph-area"
  >  
  <v-row no-gutters class="stock-evolution">
      <v-col cols="12">
        <span class="graph-title">
          {{ $t('Stock.GraphAreaStock.title')}}
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins" >
                mdi-information-outline
              </v-icon>
            </template>
          {{ $t('Stock.GraphAreaStock.description')}}
            <data-sources
              :sources="['vtex']"
            />
          </v-tooltip>
        </span>
      </v-col> 

      <v-col md="12" cols="12" style="height: 342px">
        <wj-flex-chart style="height: 100%" 
          :itemsSource="procesedData" 
          :initialized="onChartInitialized"
          bindingX="date"
          chartType="Line"
          :legendToggle="true"
          :palette="this.palette"
          tooltipContent=""
        >
            <wj-flex-chart-legend position="Bottom"></wj-flex-chart-legend>
            <wj-flex-chart-axis wjProperty="axisY" :min="0"></wj-flex-chart-axis>
            <wj-flex-chart-series
                v-for="item in this.stockEvolutionData.categories"
                :key="item.categoryName"
                :name="item.categoryName"
                :binding="String(item.categoryId)"
            ></wj-flex-chart-series>
            <wj-flex-chart-animation animationMode="Point"></wj-flex-chart-animation>
            <wj-flex-chart-line-marker :isVisible="showMarker" lines="Vertical" interaction="Move" :content="markerContent">
            </wj-flex-chart-line-marker>
        </wj-flex-chart>
      </v-col>      
    </v-row>
  </v-card>
</template>

<script>
import '@grapecity/wijmo.styles/wijmo.css';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.chart.animation';
import DataFilterHelper from "@/helpers/dataFilter.helper";
import ui_kit from "@/constants/ui-kit";
import DataSources from '../shared/DataSources.vue';
import ThousandSeparator from "@/helpers/thousandSeparator.helper";

export default {
  name: "GraphAreaStock",
  components: {
    DataSources
  },
  props: [
  'stockEvolutionData'
  ],
  data: function() {
    return {
      lmVisible: false,
      showMarker: false,
      palette: ui_kit.palette,
      mapItemsData: this.mapItems(),
    } 
  },
  computed: {
    procesedData() {
      return DataFilterHelper.dateYearFilter(this.stockEvolutionData.dataByDate)
    },
    groups() {
      return this.stockEvolutionData.categories
    },

  },
  methods: {
    onChartInitialized(sender) {
          sender.hostElement.addEventListener('mouseenter', () => this.showMarker = true);
          sender.hostElement.addEventListener('mouseleave', () => this.showMarker = false);
    },
    mapItems() {
      var response = {}
      for(var i=0; i<this.stockEvolutionData.categories.length ; i++){
        response[this.stockEvolutionData.categories[i].categoryId] = this.stockEvolutionData.categories[i].categoryName
      }
      return response
    },
     markerContent(ht) {

        if (ht.item) {
            let s = `<p style=" color: rgba(2, 8, 15, 0.5); contrast(50%); 
                                font-family: Open Sans, sans-serif;
                                font-weight: normal;
                                "> 
                                ${ht.item.date} </p>
                                <div class="row" >`;
            var i=0;
            var firstColumn = `<div class="col-lg-8 col-md-8 " >`
            var secondColumn = `<div class="col-lg-4 col-md-4 ">`
            var endCol = `</div>`
            var endRow = `</div>`

            for (let key in ht.item) {
                if (key !== 'date') {
                    firstColumn += `
                    <div style=" color: rgba(2, 8, 15, 0,85); contrast(85%); 
                    font-family: Open Sans, sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    float:left;
                    width:150px
                    ">
                    <div style="  
                            width:12px;
                            height:12px;
                            border:solid white 1px;
                            float:left;
                            margin:5px;
                            background-color:${ui_kit.palette[i]}">
                        </div>
                    <b>${this.mapItemsData[key]} </b> </div> </br> `;
                    secondColumn += `
                    <div style=" rgba(2, 8, 15, 0.85);
                    font-family: Open Sans, sans-serif;
                    font-weight: normal;
                    font-size: 14px;
                    text-align:right;
                    float:right;
                    
                    "> ${ThousandSeparator.formatNumber(ht.item[key])}</div>
                      </br>  `;
                }
                i++;
                if(i>8){
                  firstColumn += `<div style=" color: rgba(2, 8, 15, 0,85); contrast(85%); 
                    font-family: Open Sans, sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    float:left;
                    width:150px
                    ">+Otros</div>`;
                  return s + firstColumn + endCol + secondColumn + endCol + endRow;
                }
            }
            return s + firstColumn + endCol + secondColumn + endCol + endRow;
        } else {
            return 'No items here...';
        }
    }
  }
};
</script>

<style scoped lang="scss">

.graph-area {
  padding: 24px;
  max-height: 396px;
}
</style>