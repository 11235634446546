
<template>

  <v-progress-circular 
      style="position:absolute; bottom:7px; left:16%"
      v-if="isLoadingSchema"
      indeterminate
      color="cyan"
  ></v-progress-circular>

  <v-container v-else >
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          style="width:50%; position:absolute; bottom:8px; left:0px "
          color="primary"
          dark
          small
          v-bind="attrs"
          v-on="on"
          @click="getSchema"
        >
          {{ $t('Settings.JobStatus.integrate') }} 
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('Settings.JobStatus.integration') }} {{ this.dataSourceFormated }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <router-link :to="'/'+this.dataSource" target="_BLANK">{{$t('integrations.guide') }}</router-link>
                    <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 style="margin-left:4px">
                mdi-information-outline
              </v-icon>
            </template>
            <span class="tooltip">
               {{$t('integrations.guideTooltip') + ' ' + this.dataSourceFormated }}
            </span>
          </v-tooltip>
            <vue-form-json-schema
              class="form"
              v-model="model"
              :schema="schema"
              :ui-schema="uiSchema"
              :options="options"
              v-on:state-change="onChangeState"
              v-on:validated="onValidated"
              ref="form"
              tag="div"
            >
          </vue-form-json-schema>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="save"
          >
            {{ $t('Settings.JobStatus.send') }}
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="close"
          >
            {{ $t('Settings.UsersTable.dialogButtons.cancel') }}
          </v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>  
</template>

<script>
import { mapState , mapActions, mapMutations } from 'vuex'
import i18n from '@/i18n.js'


export default {
  components: { 
   },
  props: [
    "usersData",
    'dataSource',
    "index"
  ],

  data: () => ({
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    // An object which holds the form values
    model: { },
    // options
    options: {
        "castToSchemaType": true,
        showValidationErrors: true
    },
    // A valid JSON Schema object
    schema: {},
    // Array of HTML elements or Vue components
    uiSchema: [],
    //state
    state: {},
    current: 'vtex',
  }),

  computed: {
    ...mapState([
      'userRole',
      'loadingSchema'
    ]),
    dataSourceFormated(){
      if(this.dataSource == 'google_analytics')
        return 'Google Analytics'
      else if(this.dataSource == 'google_ads')
        return 'Google Ads'
      else
        return this.dataSource
    },
    formValidated(){
        let fields = []
        fields = this.schema.required
        if(fields.length != Object.keys(this.model).length)
          return false
        else
          return true
    },
    isLoadingSchema(){
      return this.loadingSchema
    },
    getDataSource(){
      return this.dataSource
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
  },

  methods: {
    ...mapActions([
      'vtexIntegration',
      'googleAdsIntegration',
      'googleAnalyticsIntegration',
      'facebookIntegration'
    ]),
    ...mapMutations([
      'setLoadingSchema'
    ]),

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.model = {};
    },

    save () {
      if(this.formValidated){
        if(this.dataSource == 'vtex')
          this.vtexSubmit();
        if(this.dataSource == 'google_analytics')
          this.googleAnalyticsSubmit();
        if(this.dataSource == 'facebook')
          this.facebookSubmit();
        if(this.dataSource == 'google_ads')
          this.googleAdsSubmit();
        this.close()
        this.model = {};
        }
      else
        alert(i18n.t('Settings.JobStatus.required'))
    },
    onChangeState(value) {
    this.state = value;
    },
    onValidated(value) {
    this.valid = value;
    },
    vtexSubmit(){
      let data = {
        "integrationData": {
          "AppAccount": this.model.AppAccount,
          "AppKey": this.model.AppKey,
          "AppToken": this.model.AppToken,
          "vtex_url": this.model.vtex_url
        },
        "jobData": {
          "index": this.index,
          "status": "IN_PROCESS"
        }
      }
      this.vtexIntegration({ data })
    },
    facebookSubmit(){
      let data = {
        "integrationData": {
          "ACCESS_TOKEN": this.model.ACCESS_TOKEN,
          "ACCOUNT_IDS": this.model.ACCOUNT_IDS,
          "APP_ID": this.model.APP_ID,
          "APP_SECRET": this.model.APP_SECRET,
        },
        "jobData": {
          "index": this.index,
          "status": "IN_PROCESS"
        }
      }

      this.facebookIntegration({ data })
    },
    googleAnalyticsSubmit(){
      let data = {
        "integrationData": {
          "VIEW_ID": this.model.VIEW_ID,
        },
        "jobData": {
          "index": this.index,
          "status": "IN_PROCESS"
        }
      }
      this.googleAnalyticsIntegration({ data })
    },
    googleAdsSubmit(){
      let data = {
        "integrationData": {
          "CUSTOMER_ID": this.model.CUSTOMER_ID,
        },
        "jobData": {
          "index": this.index,
          "status": "IN_PROCESS"
        }
      }
      this.googleAdsIntegration({ data })
    },
    getSchema(){
      let data = {}
      this.current = this.dataSource
      if(this.dataSource=="vtex"){
        data = require('../../jsonSchemas/vtex_schema.json');
        this.schema = data['schema']
        this.uiSchema = data['uiSchema']
        this.state = data['state']
      }
      if(this.dataSource=="facebook"){
        data = require('../../jsonSchemas/facebook_schema.json');
        this.schema = data['schema']
        this.uiSchema = data['uiSchema']
        this.state = data['state']
      }
      if(this.dataSource=="google_analytics"){
        data = require('../../jsonSchemas/google_analytics_schema.json');
        this.schema = data['schema']
        this.uiSchema = data['uiSchema']
        this.state = data['state']
      }
      if(this.dataSource=="google_ads"){
        data = require('../../jsonSchemas/google_ads_schema.json');
        this.schema = data['schema']
        this.uiSchema = data['uiSchema']
        this.state = data['state']
      }
      return data
    }
  },
}
</script>

<style scoped lang="scss">
.form{
    display: block-inline;
    width: 100%;
    padding: 12px;
    border: black;
}
</style>