<template>
    <div style="width:500px;text-align:left;margin:auto" class="app-font guide-font">
        <v-card style="padding:12px">
            <h3 class="app-font " style="margin-bottom:10px">Configuraciones para Facebook</h3>
            <ul>
            <li > Ingresar a <b><a href="https://developers.facebook.com/" target="_BLANK">Facebook para desarrolladores</a> </b> con tu cuenta </li>  
            <li  > Crear APP en Facebook Developers <a href="https://developers.facebook.com/docs/development/create-an-app">Guia</a> </li>  
            <ul >
                <li>La app debe ser tipo Negocios</li>
                <li>Una vez creada, seleccionar producto Marketing Api</li>
            </ul>
            <li  > Generar y obtener Access Token</li>  
            <ul>
                <li>En el menu lateral seleccionar:</li>
                <ul>
                    <li>API de Marketing -> Herramientas</li>
                    <img :src="require('@/assets/access-token1.png')" style="height:200px;margin-bottom:10px">
                    <li>Seleccionar todos los checkbox y click en obtener token</li>
                </ul>
            </ul>
            <li class="app-font guide-font" > Obtener APP ID y APP SECRET</li> 
            <ul>
                <li>En el menu lateral seleccionar Configuracion -> Basica</li>
                <li>Seran los primeros items en aparecer</li>
            </ul>
            </ul>
            <br>
    </v-card>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.guide-font{
    font-style: italic;
}
</style>