<template>
<v-card v-if="!this.mobile"
    color="#FFFFFF"
    class="card"
  >
  <v-data-table
    :headers="headers"
    :items="budgetReport"
    :items-per-page="10"
    sort-by="budget"  
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Reporte</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
    <template v-slot:item.date="{ item }">
      <span>{{ new Date(item.date).toLocaleString("es-AR", {timeZone: 'UTC' }) }}</span>
    </template>
    <template v-slot:item.state="{ item }">
      <v-chip
        :color="getColor(item.state)"
        dark
      >
        {{ item.state }}
      </v-chip>
    </template>
    <template v-slot:item.total_budget="{ item }">
      <span>{{ item.total_budget.toLocaleString() }}</span>
    </template>
    <template v-slot:item.available="{ item }">
      <span>{{ item.available.toLocaleString() }}</span>
    </template>
    <template v-slot:item.total_spend="{ item }">
      <span>{{ item.total_spend.toLocaleString() }}</span>
    </template>
    <template v-slot:item.spend_percentage="{ item }">
      <span>{{ item.spend_percentage * 100 }} %</span>
    </template>
    <template v-slot:item.consume_percentage_alert="{ item }">
      <span>{{ item.consume_percentage_alert * 100 }} %</span>
    </template>
  </v-data-table>
</v-card>
</template>

<script>
  export default {
    props: ['budgetReport'],
    data: () => ({
      name: "BudgetReport",
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),    
      headers: [
        { text: 'Fecha', align: 'start', value: 'date', sortable: false },
        { text: 'Estado', value: 'state', sortable: false },
        { text: 'Budget', value: 'total_budget', sortable: false },
        { text: 'Budget disponible', value: 'available', sortable: false },
        { text: 'Consumido', value: 'total_spend', sortable: false },
        { text: '% Consumido', value: 'spend_percentage', sortable: false },       
        { text: 'Budget alert', value: 'consume_percentage_alert', sortable: false },
      ],
    }),

    methods: {
      getColor (state) {
        if (state != "OK") return 'red'
        else return 'green'
      },
    },
  }
</script>