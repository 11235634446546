<template>
    <v-row no-gutters class="payments-data">

      <v-col md="4" cols="12" style="padding:12px" >
        <horizontal-bar-graph-container
         :title = "$t('Sessions.SessionsPiesData.pie-1.title')"
         :description = "$t('Sessions.SessionsPiesData.pie-1.description')"
         :itemSource="this.deviceData"
         binding="quantity"
         bindingLabel="quantity"
         :sources="['vtex']"
        />
      </v-col> 

      <v-col md="4" cols="12" style="padding:12px">
        <horizontal-bar-graph-container
         :title = "$t('Sessions.SessionsPiesData.pie-2.title')"
         :description = "$t('Sessions.SessionsPiesData.pie-2.description')"
         :itemSource="this.sourceData"
         binding="quantity"
         bindingLabel="quantity"
         :sources="['vtex']"
        />
      </v-col>    

      <v-col md="4" cols="12" style="padding:12px" >
        <horizontal-bar-graph-container
         :title = "$t('Sessions.SessionsPiesData.pie-3.title')"
         :description = "$t('Sessions.SessionsPiesData.pie-3.description')"
         :itemSource="this.channelData"
         binding="quantity"
         bindingLabel="quantity"
         :sources="['vtex']"
        />
      </v-col>    

    </v-row>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import * as wjCore from '@grapecity/wijmo';
import ui_kit from "@/constants/ui-kit";
import HorizontalBarGraphContainer from '../shared/HorizontalBarGraphContainer.vue';

export default {
  name: "SessionsPiesData",
  props: [
    'deviceData',
    'sourceData',
    'channelData'
  ],
  components: {
    HorizontalBarGraphContainer
  },
  data: function() {
    return {
      palette: ui_kit.palette
    }
  },
  computed: {

  },
  methods: {
    initializePie(flex) {
      this.thePie = flex;
    },
    pieLabel(ht) {
      return `${wjCore.Globalize.format(ht.value / (ht.chart._sum || ht.chart._sums[0]), 'p2')}`;
    },
    
  }
};
</script>

<style scoped lang="scss">
.session-pies-data {
  margin-top: 3px !important;
  width: 100%;
  padding-right: 20px;
  height: 390px;
  .wj-flexchart {
    padding: 0px;
  }
}
.payment-data-title {
  color: #6f6f6f;
  padding-left: 5px;
  //styleName: S Semibold;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  
}
.card {
  max-height: 420px;
  padding: 24px;
  width:100%;
}
</style>