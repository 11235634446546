<template>
  <v-card
    color="#FFFFFF"
    class="raw-big-metric-card rounded-lg"
  >
      <v-card-title >
        <v-row no-gutters style="width:100%">

        <v-col md="12" style="display:flex" >

        <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <div v-on="on"  class="card-title metric-title">
                {{ title }}
              </div>
            </template>
            <span class="tooltip">
              {{ title }}
            </span>
          </v-tooltip>



        <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 style="margin: 0 0 1px 0">
                mdi-information-outline
              </v-icon>
            </template>
            <span class="tooltip">
              {{ description }}
            </span>
            <data-sources
              :sources="this.sources"
            />
          </v-tooltip>
        </v-col>
        
      </v-row>
    </v-card-title>

    <v-card-text class="card-text">
      <span class="span-centered current-value"> 
        {{ value }}
      </span>
    </v-card-text>

    <v-card-subtitle v-if="changeRate != null" style="padding-top: 0px">
      <span v-if="invertedChangeRate != null" :class="[{ positive: isNegative  }, { negative: isPositive }, { neutral: isNeutral }, 'span-centered', 'change-rate']"> 
        <v-icon size=18 style="margin-bottom: 2px; font-weight: bold;" :color="invertedColor">
          {{ iconType }}
        </v-icon>
        {{ changeRate }}%
      </span>
      <span v-else :class="[{ positive: isPositive }, { negative: isNegative }, { neutral: isNeutral }, 'span-centered', 'change-rate']"> 
        <v-icon size=18 style="margin-bottom: 2px; font-weight: bold;" :color="color">
          {{ iconType }}
        </v-icon>
        {{ changeRate }}%
      </span>
    </v-card-subtitle>
  </v-card>
</template>

<script>
import DataSources from './DataSources.vue';
export default {
  components: { DataSources },
  name: "BigCard",
  props: [
    'title',
    'description',
    'value',
    'changeRate',
    'cantChar',
    "sources",
    "invertedChangeRate"
  ],
  data: function() {
    return {

    };
  },
  computed: {
    iconType: function() {
      if (this.changeRate >= 0) {
        if(this.changeRate == 0)
          return 'mdi-trending-neutral'
        return 'mdi-trending-up';
      }

      return 'mdi-trending-down';
    },
    color: function() {
      if (this.changeRate > 0) {
        return 'green';
      }
      else if (this.changeRate < 0) {
        return 'red';
      }
      else
        return 'rgba(2, 8, 15, 0.5)'
    },
    invertedColor: function() {
      if (this.changeRate < 0) {
        return 'green';
      }
      else if (this.changeRate > 0) {
        return 'red';
      }
      else
        return 'rgba(2, 8, 15, 0.5)'
    },
    isPositive: function() {
      if (this.changeRate > 0) {
        return true;
      }

      return false;
    },
    isNegative: function() {
      if (this.changeRate < 0) {
        return true;
      }

      return false;
    },
    isNeutral: function() {
      if (this.changeRate == 0) {
        return true;
      }

      return false;
    },
  },
  methods: {
      },
};
</script>

<style scoped lang="scss">
.raw-big-metric-card {
  max-width: 500px;
  padding: 16px;
  height: 188px;
}

.v-card__subtitle, .v-card__text, .v-card__title {
    padding: 10px;
    

}

.card-title {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
}

.span-centered {
  display:table;
  margin: 0 auto;
}

.tooltip-container {
  text-align: left;

}

.metric-title {
  font-family: Open Sans, sans-serif;
  font-size: 20px;
  color: #58667e;
  font-weight: normal;
  text-align: left!important;
  
}

.current-value {
  //styleName: XL Semibold;
  font-family: Open Sans, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(105,41,196,1);

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
  padding-top: 10px;
}

.change-rate {
  font-size: 14px;
  font-weight: bold;
  padding-top: 20px;
  text-align: left;
  display:table;
  margin: 0;
}

.positive {
  color: green;
}

.negative {
  color: red;
}
</style>