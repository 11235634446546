<template>
  <v-card
    color="#FFFFFF"
    class="card"
  >
    <v-row no-gutters class="ads-investments">
      
    <v-col md="6" cols="10" style="height:20px">
      <span class="graph-title">
        {{$t('PaidTraffic.AdsInvestments.title')}}
        <v-tooltip bottom max-width="400">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
              mdi-information-outline
            </v-icon>
          </template>
        {{$t('PaidTraffic.AdsInvestments.description')}}
        <data-sources
          :sources="['googleAnalytics']"
        />
        </v-tooltip>
      </span>
    </v-col> 

    <v-col md="6" cols="2" class="right-content">
      <v-btn
        tile
        small
        color="success"
        @click="save()"
      >
        <v-icon style="font-size: 20px;">
          mdi-microsoft-excel
        </v-icon>
      </v-btn>
    </v-col>

      <v-col md="12" cols="12" class="table" style="margin-top: 16px">
        <wj-flex-grid 
          :initialized="initializeFlexSheet"
          :headersVisibility="'Column'"
          :selectionMode="'ListBox'"
          :itemsSource="data" 
          :alternatingRowStep="1"
          style="max-height: 1090px"
        >
            <wj-flex-grid-filter />
            <wj-flex-grid-column :header="'Date'" :binding="'date'" width="*" format="n2" :isReadOnly=true />
            <wj-flex-grid-column :header="'Ad Cost'" :binding="'adCost'" aggregate="Sum" width="1*" :isReadOnly=true  />
            <wj-flex-grid-column :header="'Total Value'" :binding="'totalValue'" aggregate="Sum" width="*" format="n2" :isReadOnly=true  />
            <wj-flex-grid-column :header="'COR'" :binding="'COR'" width="*" aggregate="Avg" format="p2" :isReadOnly=true />
            <wj-flex-grid-column :header="'Delta COR'" :binding="'deltaCOR'" width="*" aggregate="Avg" format="p2" :isReadOnly=true />
            <wj-flex-grid-column :header="'delta COST'" :binding="'deltaCOST'" width="*" format="n2" :isReadOnly=true />
        </wj-flex-grid>
      </v-col>
        <v-col md="12" cols="12"  >
        <div v-on:click="updateViewPager($event)" class="update-view-pager">
            <flex-grid-page-size-selector 
            style="display:flex;position: relative;inset: -15px 0 -15px -20px"
            @change="onChangeChild"
            :currentPageSize="this.currentPageSize"
            :pageSizeSelect="this.pageSizeSelect"
            />
            <button id="btnFirst" class="btn"><span class="wj-glyph-step-backward" style="position: relative;left: -10px; top: 1px" ></span></button>
            <button id="btnPrev" class="btn"><span class="wj-glyph-left" style="position: relative;left: -3px; " ></span></button>
              &nbsp;&nbsp;&nbsp;{{ $t('FlexGridPageSizeSelector.page')}} {{ this.pageIndex + 1 | number:0 }} {{ $t('FlexGridPageSizeSelector.of')}} {{ this.pageCount | number:0 }}&nbsp;&nbsp;&nbsp;
            <button id="btnNext" class="btn"><span class="wj-glyph-right" style="position: relative;right: -3px; "></span></button>
            <button id="btnLast" class="btn"><span class="wj-glyph-step-forward" style="position: relative;right: -10px; top: 1px"></span></button>
        </div>
        </v-col>

    </v-row>
  </v-card>
</template>


<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import "@grapecity/wijmo.vue2.grid";
import '@grapecity/wijmo.vue2.grid.filter';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import DataSources from '../shared/DataSources.vue';
import * as wijmo from '@grapecity/wijmo';
import FlexGridHelper from "@/helpers/flexGrid.helper";
import FlexGridPageSizeSelector from '../shared/FlexGridPageSizeSelector.vue';

export default {
  name: "AdsInvestments",
  props: [
  'adsData'
  ],
  components: {
    DataSources,
    FlexGridPageSizeSelector
  },
  data: function() {
      return {

        maxPageSize: '5',
        currentPageSize: '5',
        pageIndex: 0,
        pageCount: 1,
        animated: true,

      };
  },
  computed: {
    data() {
      return new CollectionView(FlexGridHelper.fillData(this.adsData, parseInt(this.currentPageSize)), {
              refreshOnEdit: true, // on-demand sorting and filtering
              pageSize: parseInt(this.currentPageSize)
            })
    },
    pageSizeSelect() {
      return FlexGridHelper.makeList()
    },
  },
  methods: {
    initializeFlexSheet: function(flex) {
      flex.columnFooters.rows.push(new wjcGrid.GroupRow());
      this.flex = flex;
    },
    onChangeChild(value) {
      this.currentPageSize = value
      this.updatePagesIndex()
    },
    updatePagesIndex() {
      this.pageIndex = this.data.pageIndex
      this.pageCount = this.data.pageCount
    }, 
    save() {
      this.data.pageSize = 0;
      wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
          this.flex,
          {
              includeColumnHeaders: true,
              includeCellStyles: false,
              formatItem: null
          },
          'ads-investments.xlsx'
      );
      this.data.pageSize = parseInt(this.currentPageSize);
    },
    updateViewPager(e) {
        let btn = wijmo.closest(e.target, 'button'),
            id = btn ? btn.id : '';
        //
        switch (id) {
            case 'btnFirst':
                this.data.moveToFirstPage();
                break;
            case 'btnPrev':
                this.data.moveToPreviousPage();
                break;
            case 'btnNext':
                this.data.moveToNextPage();
                break;
            case 'btnLast':
                this.data.moveToLastPage();
                break;
        }
        this.updatePagesIndex()
    },
  },
  created: function () {
    this.updatePagesIndex()
  },
};
</script>

<style scoped lang="scss">

  .ads-investments {    
  .wj-flexchart {
    padding: 0px !important;
  }
}
.ads-investments-title {
  color: #6f6f6f;
  padding-left: 20px;
}
.right-content {
  margin: auto; 
  text-align: right; 
  padding-right: 20px !important;
  position:relative
}
.form-control {
  margin-left: 5%;
  margin-top:1%;
  background-color:#add8e6;
  box-shadow: 0 0 3px black;
}

.card {
  padding: 24px;
  max-height: 1200px;
}
    
.update-view-pager {
  align-items: center; 
  text-align: right; 
  padding-right: 16px;
  display:flex;
  float:right;
  height:30px;
}
</style>