<template>
    <v-row no-gutters class="payments-data" v-if="this.mobile" >

      <v-col md="4" cols="12"  >
        <horizontal-bar-graph-container
         :title = "$t('DailySummary.PaymentsData.pie-1.title')"
         :description = "$t('DailySummary.PaymentsData.pie-1.description')"
         :itemSource="groupsData"
         :sources="['vtex']"
         vradio="true"
        />
      </v-col> 

      <v-col md="4" cols="12" style="margin-top:24px">
        <horizontal-bar-graph-container
          :title = "$t('DailySummary.PaymentsData.pie-2.title')"
          :description="$t('DailySummary.PaymentsData.pie-2.description')"
          :itemSource= methodsData
          :sources="['vtex']"
          vradio="true"
        />
      </v-col>    

      <v-col md="4" cols="12"  style="margin-top:24px">
        <horizontal-bar-graph-container
          :title = "$t('DailySummary.PaymentsData.pie-3.title')"
          :description="$t('DailySummary.PaymentsData.pie-3.description')"
          :itemSource= orderStatusData
          :sources="['vtex']"
           vradio="true"
        />
      </v-col>    

    </v-row> 
 
    <v-row no-gutters class="payments-data" v-else > 

      <v-col md="4" cols="12" style="padding-right: 12px" >
        <horizontal-bar-graph-container
         :title = "$t('DailySummary.PaymentsData.pie-1.title')"
         :description = "$t('DailySummary.PaymentsData.pie-1.description')"
         :itemSource="groupsData"
         :sources="['vtex']"
         vradio="true"
        />
      </v-col> 

      <v-col md="4" cols="12" style="padding:0 12px 0 12px">
        <horizontal-bar-graph-container
          :title = "$t('DailySummary.PaymentsData.pie-2.title')"
          :description="$t('DailySummary.PaymentsData.pie-2.description')"
          :itemSource= methodsData
          :sources="['vtex']"
          vradio="true"
        />
      </v-col>    

      <v-col md="4" cols="12" style="padding-left: 12px" >
        <horizontal-bar-graph-container
          :title = "$t('DailySummary.PaymentsData.pie-3.title')"
          :description="$t('DailySummary.PaymentsData.pie-3.description')"
          :itemSource= orderStatusData
          :sources="['vtex']"
           vradio="true"
        />
      </v-col>    

    </v-row>


</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import * as wjCore from '@grapecity/wijmo';
import ui_kit from "@/constants/ui-kit";
import HorizontalBarGraphContainer from '../shared/HorizontalBarGraphContainer.vue';
import { mapState} from 'vuex'
 
export default {
  name: "PaymentsData",
  props: [
    'paymentsPiesData'
  ],
  components: {
    HorizontalBarGraphContainer
  },
  data:
    function() {
    return {
      pieData: [], 
      pieHeader: '2018 Product Sales',
      palette: ui_kit.palette
    }
  },
  computed: {
    ...mapState([
      "mobile",
      ]),
    groupsData() {
      return this.paymentsPiesData[0]["data"]
    },
    methodsData() {
      return this.paymentsPiesData[1]["data"]
    },
    orderStatusData() {
      return this.paymentsPiesData[2]["data"]
    },
  },
  methods: {
    initializePie(flex) {
      this.thePie = flex;
    },
    pieLabel(ht) {
      return `${wjCore.Globalize.format(ht.value / (ht.chart._sum || ht.chart._sums[0]), 'p2')}`;
    },
  },

};
</script>

<style scoped lang="scss">
.payments-data {
  margin-top: 3px !important;
  height: 100%;
  .wj-flexchart {
    padding: 0px;
  }
}


.card {
  max-height: 420px;
  padding: 24px;
  width:100%;
}


.wj-flexchart .wj-state-selected {
  stroke: rgba(0,0,0,.5);
  stroke-width: 3;
  stroke-dasharray: 1;
}
</style>