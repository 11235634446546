<template >

    <v-card-text class="raw-metric-card" style="padding:16px;">
      <v-row v-for="(iterationData, idx) in this.itemData['components'][0]['data']" :key="idx" no-gutters style="  max-height: 90px;">
        <v-col v-if="!mobile" md="2" class="column-value " style="width: 100%;position: relative;">
          <div class="span-left-bottom" >
            <span class="first-column-spam" > 
            <img class="first-column-spam" :src="iterationData.imageUrl"
              contain 
              height="60px" 
             > 
          </span>
          </div>
        </v-col>

        <v-col v-if="!mobile" md="6" class="column-value " style="width: 100%;position: relative;">
          <div class="span-left-bottom" >
            <span class="first-column-spam" > 
              {{$t('Products.GridWithButton.id')}}  <b>{{iterationData.skuId}} </b>
            </span>
            <span class="first-column-spam" > 
              {{$t('Products.GridWithButton.item')}}  <b>{{iterationData.itemName}} </b>
            </span>
          </div>
        </v-col>

        <v-col v-if="!mobile" md="4" class=" column-value" style="width: 100%;position: relative;">
          <div class="span-left-bottom" >
          <span class="first-column-spam" > 
            {{$t('Products.GridWithButton.amount')}} 
          </span>
          <span  class="first-column-spam" > 
            {{$t('Products.GridWithButton.crossSelling')}}  <b>{{ iterationData.crossSellingOrders}}</b>
          </span>
          </div>
        </v-col>

        <v-col v-if="mobile" md="9" cols="9" class="column-value " style="width: 100%;position: relative;">
          <div class="span-left-bottom" >
            <span class="first-column-spam" > 
              {{$t('Products.GridWithButton.item')}} <b>{{iterationData.itemName}} </b>
            </span>
            <span  class="first-column-spam" > 
              {{$t('Products.GridWithButton.crossSelling')}}  <b>{{ iterationData.crossSellingOrders}}</b>
            </span>
          </div>
          <hr style="margin-top:60px;border-top: 2px solid #8c8b8b;">
        </v-col>
      
      </v-row>

    </v-card-text>



</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {  },
  props: [
  ],
  data: function() {
    return {
    }
  },
  computed: {
  ...mapState([
    'itemData',
    'mobile'
  ])
  },
  methods: {

  },

};
</script>

<style scoped lang="scss">
.raw-metric-card {
  display: flex !important;
  flex-direction: column;
  overflow-y: auto;
}


.span-left-bottom {
    position: absolute;
    bottom: 6px;
    left: 16px;
}

.first-column-spam {
    display:table;
    text-align: left;
}

.column-value {
  padding:6px 12px 6px 12px!important;
  max-height: 70px;
  min-height: 70px;
}


</style>