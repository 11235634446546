export default Object.freeze({
    AGENCY_CLIENTS: {
      icon: 'mdi-account-group',
      text: 'agencyClients',
      path: '/agency_clients'
    },
    GOOGLE_ADS_SCRIPTING: {
      icon: 'mdi-playlist-check',
      text: 'googleAdsScripting',
      path: '/google_ads_scripting'
    },
    BUDGET_CONTROL: {
      icon: 'mdi-calculator',
      text: 'budgetControl',
      path: '/budget_control'
    },
    EXTRACTORS: {
      icon: 'mdi-database-arrow-down',
      text: 'agencyExtractors',
      path: '/agency_extractors'
    }
  });