<template>
    <v-row no-gutters class="payments-data">
      <v-col md="4" cols="12" style="padding-left: 2.5px" >
        <horizontal-bar-graph-container
          :title="$t('Orders.FlexPie.title')"
          :description="$t('Orders.FlexPie.description')"
          :itemSource="this.paymentsPiesData"
          :sources="['vtex']"
           vradio="true"
        />
      </v-col>    
    </v-row>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import ui_kit from "@/constants/ui-kit";
import HorizontalBarGraphContainer from '../shared/HorizontalBarGraphContainer.vue';

export default {
  name: "PaymentsData",
  props: [
    'paymentsPiesData'
  ],
  components: {
    HorizontalBarGraphContainer
  },
  data:
    function() {
    return {
      pieData: [], 
      pieHeader: '2018 Product Sales',
      palette: ui_kit.palette
    }
  },
  computed: {
  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
.payments-data {
  margin-top: 3px !important;
  height: 393px;
  .wj-flexchart {
    padding: 0px;
  }
}

.card {
  max-height: 420px;
  padding: 24px;
  width:100%;
}


.wj-flexchart .wj-state-selected {
  stroke: rgba(0,0,0,.5);
  stroke-width: 3;
  stroke-dasharray: 1;
}
</style>