<template>

  <v-progress-linear 
      v-if="isLoading"
      indeterminate
      color="cyan"
  ></v-progress-linear>

  <v-container v-else class="main-container">
    <div v-if="!this.unavaibleIntegration">
      <div class="page-title"> {{ $t('DailySummary.PageTitle')}} </div>

      <error v-if="error" style="margin-top: 10px"
        :errorMessage=" $t('errorMessage')"
      />

      <v-row justify-content="flex-end" >
        <DayFilter 
        />
      </v-row>

      <div class="d-flex align-center" v-if="!this.mobile" >
        <v-row justify-content="flex-end" >
          <order-status-filter
          />
        </v-row>
      </div>
      
      <v-row align="center" justify="space-between" style="margin-top: 24px" > 

        <v-col  md="2" cols="6  " >
          <raw-metric-card 
            :title = "$t('DailySummary.dailySummaryCards.card-1.title')"
            :description= "$t('DailySummary.dailySummaryCards.card-1.description')"
            :value= this.thousandSeparatorCall(getCardsData[0].value)
            :changeRate= getCardsData[0].changeRate.toFixed(2)
            :sources="['vtex']"
          />
        </v-col>
        <v-col  md="2" cols="6  " >
          <raw-metric-card 
            :title = "$t('DailySummary.dailySummaryCards.card-2.title')"
            :description= "$t('DailySummary.dailySummaryCards.card-2.description')"
            :value= moneyString.concat(this.thousandSeparatorCall(getCardsData[1].value.toFixed(0))) 
            :changeRate= getCardsData[1].changeRate.toFixed(2)
            :sources="['vtex']"
          />
        </v-col>
        <v-col  md="2" cols="6  " >
          <raw-metric-card 
            :title = "$t('DailySummary.dailySummaryCards.card-3.title')"
            :description= "$t('DailySummary.dailySummaryCards.card-3.description')"
            :value= this.thousandSeparatorCall(getCardsData[2].value)
            :changeRate= getCardsData[2].changeRate.toFixed(2)
            :sources="['vtex']"
          />
        </v-col>
        <v-col md="2" sm="6" xs="6" >
          <raw-metric-card 
            :title = "$t('DailySummary.dailySummaryCards.card-4.title')"
            :description= "$t('DailySummary.dailySummaryCards.card-4.description')"
            :value= this.thousandSeparatorCall(getCardsData[3].value.toFixed(2))
            :changeRate= getCardsData[3].changeRate.toFixed(2)
            :sources="['vtex']"
          />
        </v-col>
        <v-col md="2" sm="6" xs="6" >
          <raw-metric-card 
            :title = "$t('DailySummary.dailySummaryCards.card-5.title')"
            :description= "$t('DailySummary.dailySummaryCards.card-5.description')"
            :value= moneyString.concat(this.thousandSeparatorCall(getCardsData[4].value.toFixed(0)))  
            :changeRate= getCardsData[4].changeRate.toFixed(2)
            :sources="['vtex']"
          />
        </v-col>
        <v-col md="2" sm="6" xs="6" >
          <raw-metric-card 
            :title = "$t('DailySummary.dailySummaryCards.card-6.title')"
            :description= "$t('DailySummary.dailySummaryCards.card-6.description')"
            :value= moneyString.concat(this.thousandSeparatorCall(getCardsData[5].value.toFixed(0))) 
            :changeRate= getCardsData[5].changeRate.toFixed(2)
            :sources="['vtex']"
          />
        </v-col>
      </v-row>

      <v-row >
        <v-col class="pa-0" style=" margin-top: 12px">
          <graph-daily-summary 
            :ordersRevenueData= getRevenuePerHour
            :description= "$t('DailySummary.GraphDailySummary.description')"
          />
        </v-col>      
      </v-row>
    
      <v-row >
        <v-col class="margin-between-components pa-0 ">
          <orders-per-hour 
            :ordersData= getOrdersPerHour
          />
        </v-col>      
      </v-row>

      <v-row >
        <v-col class="margin-between-components pa-0" >
        <payments-data 
          :paymentsPiesData= getPaymentsPiesData
          />
        </v-col>      
      </v-row>

      <v-row >
        <v-col class="margin-between-components pa-0 " style="margin-bottom: 128px">
        <order-details v-if="!this.mobile"
            :orderDetailsData= getOrderDetails
          style="margin:auto"/>
        </v-col>      
      </v-row>
  </div>
  <div  v-else>
    <v-card style="width:600px">
      <h2 class="app-font" style="padding:12px"> {{ $t('integrations.unavaible') }}</h2>
    </v-card>
  </div>
  </v-container>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import RawMetricCard from '@/components/shared/RawMetricCard.vue';
import OrdersPerHour from '../daily-summary/OrdersPerHour.vue';
import OrderDetails from '../daily-summary/OrderDetails.vue';
import PaymentsData from '../daily-summary/PaymentsData.vue';
import GraphDailySummary from '../graphs/GraphDailySummary.vue';
import DayFilter from '../daily-summary/DayFilter.vue';
import OrderStatusFilter from '../shared/OrderStatusFilter.vue';
import Error from '../errors/error.vue';
import ThousandSeparator from "@/helpers/thousandSeparator.helper";

export default {
  name: "DailySummary",

  components: {
    RawMetricCard,
    OrdersPerHour,
    OrderDetails,
    PaymentsData,
    GraphDailySummary,
    DayFilter,
    OrderStatusFilter,
    Error,
  },
  data: () => ({
      moneyString: "$ ",
  }),
  computed:{
    ...mapState([
      "dailySummaryPageData",
      "loadingDailySummaryData",
      "loadingFilterOptions",
      "filterOptions", 
      "checkedItems",
      "error",
      "mobile",
      "demo",
    ]),
    getCardsData () {
      return this.dailySummaryPageData["components"][0]["cards"];
    },
    getRevenuePerHour () {
      return this.dailySummaryPageData["components"][1]["data"];
    },
    getOrdersPerHour () {
      return this.dailySummaryPageData["components"][2]["data"];
    },
    getOrderDetails () {
      return this.dailySummaryPageData["components"][3]["data"];
    },
    getPaymentsPiesData () {
      return this.dailySummaryPageData["components"][4]["charts"]; // [0][data] es grups de pago, [1][data] es metodos de pago, 
    },
    getOrderStatusData () {
      return this.filterOptions["Filters"][0]["values"]
    },
    isLoading() {
      if ( this.dailySummaryPageData != null && !this.loadingDailySummaryData && this.filterOptions != null && !this.loadingFilterOptions) {        
        return false
      }
      return true
    },
    unavaibleIntegration(){
      if (this.dailySummaryPageData == 'integration unavaible')
        return true
      else
        return false
    }
  },
  methods: {
    ...mapActions([
      "getDailySummaryPageData",
      "getFilters",
      "loggedInCheck",
    ]),
    ...mapMutations([
      "setMobile",
      "setDailySummaryPageData"   
    ]),
    thousandSeparatorCall(value) {
      return ThousandSeparator.formatNumber(value)
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.setMobile(true)
        return true
      } else {
        return false
      }
    },

  },
  created: function () {
    this.isMobile();
    if(!this.demo){
        this.getFilters();
        this.loggedInCheck();
        this.getDailySummaryPageData();
      }
    else{
      this.getFilters();
      var data = {};
      data = require('../../json/dailySummary.json');
      this.setDailySummaryPageData(data);
    }
  },
};
</script>

<style scoped lang="scss">
.main-container {
  max-width: 100%;
  padding: 12px 30px 12px 30px;
}

 .margin-between-components {
   margin-top:24px;
 } 

</style>