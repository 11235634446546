<template>
  <div class="app-font " style="  display: inherit; align-center">
    <div class="toolbar-time-range align-center">
    <v-icon style="margin-right: 5px;">
      mdi-clock-time-four-outline
    </v-icon>

    <div class="d-flex align-center app-font">
      <div v-if="!this.mobile" class="mx-1 ">{{$t('StockDayFilter.daySelect')}}</div>
      <date-selector 
        :date="fromDate"
        :maxDate="maxDate"
        @updateDate="setFromDate"
      />
    </div>
    </div>
  <v-btn 
      class="app-font"
      style="margin-left:10px;margin-top:32px;height:40px"
      @click="updatePageData"
      small
      color="primary"
      dark
      :elevation="0"
    >
     <b> {{$t('StockDayFilter.button')}} </b>
  </v-btn>
  </div>

  
   
</template>

<script>
import moment from 'moment'
import { mapMutations, mapState , mapActions} from "vuex";
import DateSelector from "@/components/shared/DateSelector";
import DateHelper from "@/helpers/date.helper";

export default {
  name: "StockDayFilter",
  components: {
    DateSelector
  },
    data() {
    return {}
  },
  computed: {
    ...mapState([
        "timeRange",
        "stockDate",
        "stockMaxDate",
        "loadingStocksPageData",
        "mobile",
        "demo"
        ]),
    fromDate() {
      return this.timeRange.stockDate;
    },
    maxDate() {
      return this.timeRange.stockMaxDate;
    },
  },
  methods: {
    ...mapMutations([
      "setStockDate",
      "setStockDateFormated",
    ]),
    ...mapActions([
      "getStocksPageData",   
      "setStockQueryParams"   
    ]),

    initCalendar: function(calendar) {
        this.theCalendar = calendar;
    },
    setFromDate(date) {
      this.setStockDate(DateHelper.startOfDay(date));
      this.setStockDateFormated(moment(DateHelper.startOfDay(date)).format('YYYY-MM-DD'))
      
    },
    updatePageData() {
      if(!this.demo){
        this.setStockQueryParams();
        this.getStocksPageData();
      }
    },
    
  },
};
</script>

<style lang="scss">

</style>
