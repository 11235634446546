import TimeRangeType from '@/enums/time-range-type.enum';
import Lenguages from '@/enums/lenguages.enum';
import DateHelper from '@/helpers/date.helper.js';
import moment from 'moment'


export default {
  loggedIn: false,
  token: null,
  loginError: false,
  currentSection: 'DAILYSUMMARY', 
  error: false,
  timeRange: {
    timeRangeType: TimeRangeType.Last7Days,
    compareTimeRangeType: TimeRangeType.Last7DaysCompareWithPrevious7Days,
    customStartDate: DateHelper.startOfDay(
        DateHelper.subtractDays(new Date(), 14),
    ),
    customEndDate: DateHelper.endOfDay(new Date()),
    compareWithCustomStartDate: DateHelper.startOfDay(
      DateHelper.subtractDays(new Date(), 14),
    ),
    compareWithCustomEndDate: DateHelper.endOfDay(new Date()),
    xDays: 14,
    dailySummaryDate: DateHelper.subtractDays(new Date(), 1),
    dailySummaryDateFormated: moment(DateHelper.subtractDays(new Date(), 1)).format('YYYY-MM-DD'),
    dailySummaryMaxDate: DateHelper.startOfDay(new Date()),
    stockDate: DateHelper.subtractDays(new Date(), 1),
    stockDateFormated: moment(DateHelper.subtractDays(new Date(), 1)).format('YYYY-MM-DD'),
    stockMaxDate: DateHelper.startOfDay(new Date()),
  },
  orderStatusFilter: "invoiced",
  paymentGroupsVisualization: "quantity",
  orderStatusVisualization: "quantity",
  paymentMethodsVisualization: "quantity",
  orderStatusPaymentsPageVisualization: "quantity",
  paymentsMethodsPaymentsPageVisualization: "quantity",
  paymentsGroupsPaymentsPageVisualization: "quantity",
  installmentPageVisualization: "quantity",
  bestSellerCategoriesVisualization: "quantity",
  ordersPageData: null,
  dailySummaryPageData: null,
  paymentsPageData: null,
  stocksPageData: null,
  productsPageData: null,
  sessionsPageData: null,
  paidTrafficPageData: null,
  trafficPageData:null,
  loadingDailySummaryData: false,
  loadingPaymentsPageData: false,
  loadingOrdersPageData: false,
  loadingProductsPageData: false,
  loadingStocksPageData: false,
  loadingSessionsPageData: false,
  loadingPaidTrafficPageData: false,
  loadingTrafficPageData: false,
  filterOptions: null,
  loadingFilterOptions: false,
  palette:['rgba(105,41,196,1)', 'rgba(17,146,232,1)', 'rgba(0,93,93,1)', 'rgba(159,24,83,1)', 'rgba(250,77,86,1)', 'rgba(87,4,8,1)'],
  statusParams: null,
  checkedItems: [],
  originParams: null,
  checkedItemsOrigin: [],
  categoriesParams: null, 
  checkedCategories: [],
  departmentsParams: null, 
  checkedDepartments: [],
  itemData: null,
  loadingItemData: false,
  itemActionCaller: 'stock',
  currentItem: null,
  currentItemName: null,
  dialogValue: false,
  lenguage: Lenguages.Español,
  keepMeLoggedIn: false,
  mobile: false,
  loadingUsers: false,
  users: null,
  userRole: null,
  userDeleted: false,
  newUser:null,
  jobsExecutionData:null,
  loadingJobsExecution: false,
  loadingSettings:false,
  settings: null,
  loadingLanguage: false,
  demo:false,
  loadingSchema:false,
  formIndex:null,
  loadingEditSchema: false,
  editModel: {},
  currentDataSource: null,
  clientType:null,
  scriptDialog: false,
  currentScript: "2",
  scriptString: 'empty',
  loadingScriptBuilder: false,
  scriptRegister: null,
  loadingScriptRegister: false,
  avaibleScripts: 
  {
    '1': 'Google Ads Budget Control',
    '2': 'Quality Score Tracker',
    '3': 'Broken Destination URLs',
    '4': '24 hour device performance heat map',
    '5': 'Duplicate Keywords',
    '6': 'Account Budget Control',
    '7': 'Keyword Suggestion',
    '8': 'UTM adgroup campaign',
    '9': 'Negative keywords conflict',
    '10': 'Account Summary Report',
    '11': 'Flexible Budgets',
    '12': 'MCC Ad Performance Report',
    '13': 'Account Anomaly Detector',
    '14': 'MCC Declining Ad Group Report',
    '15': 'Cumulative Budget Increase'
  },
  agencyClients: null,
  loadingAgencyClients: false,
  showAlert: false,
  showAlertSmall: false,
  alertMessage: null,
  alertType: "success",
  agencyJobsExecutionData: null,
  loadingAgencyIntegrations: false,
  currentAgencyClient: null,
  scriptExecutions: null,
  scriptExecutionOffset: 1,
  loadingScriptExecutions: false,
  loadingBudgetControl: false,
  loadingBudgetControlReport: false,
  budgetReport: null,
  budgets: null,
  budgetsControlClient: 'CLIENTE_1',
};