<template>
    <v-row no-gutters class="payments-data">

      <v-col md="4" cols="12" style="padding:12px;" >
        <big-card  style="height:188px"
          :title = "$t('PaidTraffic.bigCards.card-1.title')"
          :description="$t('PaidTraffic.bigCards.card-1.description')"
          :value=  moneyString.concat(this.thousandSeparatorCall(this.cardsData[0].value)) 
          :changeRate= this.cardsData[0].changeRate.toFixed(2)
          :sources="['googleAnalytics']"
        />
      </v-col> 

      <v-col md="4" cols="12" style="padding:12px">
        <info-card style="height:188px"
          :title = "$t('PaidTraffic.bigCards.card-2.title')"
          :description="$t('PaidTraffic.bigCards.card-2.description')"
          :sources="['googleAnalytics']"
          cardIcon="ranking"
          :cardData="infoCardsData[0]"
          :moneyChar="true"
        />
      </v-col>    

      <v-col md="4" cols="12" style="padding:12px" >
        <info-card style="height:188px"
          :title = "$t('PaidTraffic.bigCards.card-3.title')"
          :description="$t('PaidTraffic.bigCards.card-3.description')"
          :sources="['googleAnalytics']"
          cardIcon="ranking"
          :cardData="infoCardsData[1]"
          :moneyChar="true"
        />
      </v-col>    

    </v-row>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import BigCard from '../shared/BigCard.vue';
import InfoCard from '../shared/InfoCard.vue';
import ThousandSeparator from "@/helpers/thousandSeparator.helper";

export default {
  name: "BigCards",
  props: [
    'cardsData',
    'infoCardsData'
  ],
  components: {
    BigCard,
    InfoCard
  },
  data:
    function() {
    return {
      moneyString: '$ '
    }
  },
  computed: {

  },
  methods: {
    thousandSeparatorCall(value) {
      return ThousandSeparator.formatNumber(value)
    },
  },

};
</script>

<style scoped lang="scss">
.payments-data {
  margin-top: 3px !important;

  .wj-flexchart {
    padding: 0px;
  }
}


.column {
  height: 340px;
  padding:24px;
}

.wj-flexchart .wj-state-selected {
  stroke: rgba(0,0,0,.5);
  stroke-width: 3;
  stroke-dasharray: 1;
}
</style>