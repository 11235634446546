<template >

<v-card v-if="!this.mobile"
    color="#FFFFFF"
    class="card"
  >
    <v-row  no-gutters class="best-seller-items">
      <v-col md="6" cols="12" style="height:20px">
        <span class="table-title">
          {{ $t('googleAdsScripting.scriptRegisterTable.title') }}
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
          {{ $t('googleAdsScripting.scriptRegisterTable.description') }}
          </v-tooltip>
        </span>
      </v-col> 

      <v-col md="4" cols="12" class="right-content">
      </v-col>

      <v-col md="2" cols="12" class="right-content" v-if="this.userRole == 'ADMIN' || this.userRole == 'admin'" >

      </v-col>

      <v-col v-if="!this.mobile" md="12" cols="12" style="margin-top: 16px">
  <v-data-table  
    :headers="headers"
    :items="this.data"
    :items-per-page="10"
    :sort-by="['update_date']"
    :sort-desc="[true]"
    class="users-table"
    :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        itemsPerPageText: 'Scripts por página'
      }"
  >
    <template v-slot:top>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">{{ $t('googleAdsScripting.scriptRegisterTable.deletScriptConfirm') }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">{{ $t('agencyClients.agencyClientsTable.closeButton')}}</v-btn>
              <v-btn color="green darken-1" text @click="deleteItemConfirm">{{ $t('agencyClients.agencyClientsTable.deleteButton')}}</v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>
    </template>
    <template v-slot:item.actions ="{ item }">
       <v-icon
        small
        class="mr-2"
        @click="viewItem(item)"
      >
        mdi-eye
      </v-icon>     
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>

    </template>

  </v-data-table>
      </v-col>
      </v-row>
</v-card>

</template>

<script>
import i18n from '@/i18n.js' 
import { mapState , mapActions, mapMutations } from 'vuex'

export default {
  components: {  },
  props: [
    "scriptRegisterData"
  ],

  data: () => ({
    dialog: false,
    dialogDelete: false,
    roles:['viewer'],
    headers: [
      { text: 'Cliente', value: 'client' },
      { text: 'Script', value: 'script' },
      { text: 'Ultima actualizacion', value: 'update_date', sortable: true },
      { text: 'Usuario', value: 'user_email'},
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    show1: true,
    rules: {
      required: value => !!value || i18n.t('Settings.UsersTable.newUserDialog.required'),
      min: v => v.length >= 8 || i18n.t('Settings.UsersTable.newUserDialog.minChar'),
    },
    data: [
 
    ],
    editedIndex: -1,
  }),

  computed: {
  ...mapState([
    'userRole', 
    'mobile',
    'avaibleScripts',
    'scriptRegister'
  ]),
  formTitle () {
    return this.editedIndex === -1 ? i18n.t('Settings.UsersTable.newUser')  : i18n.t('Settings.UsersTable.editUser')
  },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    userDeleted(){
      if(this.userDeleted)
        this.scriptRegisterData.splice(this.editedIndex, 1)
    },
    scriptRegister: function() {
      this.getScriptRegisterData()
    }
  },

  created () {
    this.getScriptRegisterData()
  },

  methods: {
    ...mapActions([
      "getScript",
      "deleteScript"
    ]),
    ...mapMutations([
      "setCurrentScript"
    ]),
    getScriptRegisterData(){
      this.data = []
      for(var i=0; i<this.scriptRegisterData.length; i++){
        var script_register =  {
          "client": this.scriptRegisterData[i]['client'],
          "update_date": this.scriptRegisterData[i]['date'] +' '+this.scriptRegisterData[i]['time'],
          "script": this.avaibleScripts[this.scriptRegisterData[i]['script_id']],
          "jsonSchemaData": this.scriptRegisterData[i]['JSONSchema_data'],
          "date": this.scriptRegisterData[i]['date'],
          "time": this.scriptRegisterData[i]['time'],
          "script_id": this.scriptRegisterData[i]['script_id'],
          "user_email": this.scriptRegisterData[i]['user_email'],
        }
        this.data.push(script_register)
      }
    },
    editItem (item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    viewItem(item) {
      var index = this.data.indexOf(item)
      this.setCurrentScript(this.data[index]['script_id'])
      var data = {
        "date": this.data[index]['date'],
        "time": this.data[index]['time']
      }
      this.getScript({data})
    },

    deleteItem (item) {
      this.editedIndex = this.data.indexOf(item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      let data = {
        "identifier": this.data[this.editedIndex]['script_id']+','+this.data[this.editedIndex]['date']+','+this.data[this.editedIndex]['time']
      }
      this.deleteScript({ data })
      this.closeDelete()
      this.data.splice(this.editedIndex, 1)
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  padding:24px!important;
  max-height: 500px;
  }
</style>