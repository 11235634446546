<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="1000"
    >


      <v-card class="card-builded-dialog">
        <v-alert
          v-if="this.clicked == true"
          elevation="9"
          type="success"
          shaped
        >Copiado!</v-alert>
        <v-card-title >
          {{this.avaibleScripts[this.currentScript]}}
        </v-card-title>

        <v-card-text>
            <pre class="script-text"> {{ this.scriptString }} </pre>
          
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions style="padding:0px 24px 16px 24px;">
            <img v-bind:class="{'LogoAnimation': !clicked}" class="LogoAnimation" @click="copyToClipBoard" :src="require('@/assets/copy.png')" style="height:30px"/>
          <v-spacer></v-spacer>
          
          <v-btn
            color="primary"
            text
            @click="close()"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapState } from 'vuex'

  export default {
    data () {
      return {

        clicked:false,
        script: ''
      }
    },
    methods: {
        copyToClipBoard(){
            navigator.clipboard.writeText(this.scriptString);  
            this.clicked = true    
        },   
        close(){
          this.dialog = false
          this.clicked = false
        },

    },
    computed: {
    ...mapState([
      "currentScript",
      "scriptString",
      "avaibleScripts"
    ]),

    dialog: {
      get () {
        return this.$store.state.scriptDialog
      },
      set (value) {
        this.$store.commit('setScriptDialog', value)
      }
    },
  },
    watch: {
    },
created() {
}
  }
</script>

<style>
.script-text {
    overflow-y: scroll;
    height: 330px;
    background-color: #EEEEEE;
}

.LogoAnimation {

  border-radius: 2px;
}

.green {
  background-color: lightgreen;
  border-radius: 2px;
}

.LogoAnimation:hover {
  opacity: 0.75;
  cursor: pointer;
}

.card-builded-dialog{
    padding:0px!important
}
</style>