<template>
    <v-alert
      class="margin-between-components" 
      v-if="this.showAlert == true"
      elevation="9"
      :type="this.alertType"
    > {{ this.alertMessage }}</v-alert>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data: () => ({
    }),
    methods: {
    },
    computed: {
        ...mapState([
            "showAlert",
            "alertMessage",
            "alertType"
        ])
    },


    watch: {
    },
}
</script>