<template>
  <v-card
    color="#FFFFFF"
    class="raw-metric-card"

  >
      <v-card-title >
        <v-row no-gutters style="width:100%;">

        <v-col md="10" >
          <div style="display:flex">
            <div v-if="this.cardIcon != null">   
              <img v-if="cardIcon == 'trending'" :src="require('@/assets/TrendingIcon.png')" style="height:25px"/>
              <img v-if="cardIcon == 'biggestGainners'"  :src="require('@/assets/gainner.png')" style="height:25px"/>
              <img v-if="cardIcon == 'biggestLoosers'"  :src="require('@/assets/loosers.png')" style="height:25px"/>
              <img v-if="cardIcon == 'buyer'"  :src="require('@/assets/buyer.png')" style="height:25px"/>
              <img v-if="cardIcon == 'transactions'"  :src="require('@/assets/transactions.png')" style="height:25px"/>
              <img v-if="cardIcon == 'end-page'"  :src="require('@/assets/exit.png')" style="height:25px"/>
              <img v-if="cardIcon == 'start-page'"  :src="require('@/assets/start-point.png')" style="height:25px"/>
              <img v-if="cardIcon == 'ranking'"  :src="require('@/assets/ranking.png')" style="height:25px"/>
              <img v-if="cardIcon == 'cancelations'"  :src="require('@/assets/cancelations.png')" style="height:25px"/>
              <img v-if="cardIcon == 'installments'"  :src="require('@/assets/installments.png')" style="height:25px"/>
              <img v-if="cardIcon == 'payment-groups'"  :src="require('@/assets/payment-groups.png')" style="height:25px"/>
              <img v-if="cardIcon == 'payment-groups1'"  :src="require('@/assets/payment-groups1.png')" style="height:25px"/>
            </div>
            <div  class="card-title metric-title">
            {{ this.title }}
            </div>
          </div>
        </v-col>

        <v-col md="2" class="tooltip-container">
        <v-tooltip bottom max-width="800">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 style="margin-top: -10px">
                mdi-information-outline
              </v-icon>
            </template>
            <span class="tooltip">
              {{ description }}
            </span>
            <data-sources 
              :sources="this.sources"
            />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text class="card-text" >
      <v-row v-for="(data, index) in cardData.data" :key="data.title">
        <v-col md="1" cols="1" class="column-value">
          <span class="span-centered item-index" style="display: grid;"> 
            {{ index + 1 }}
          </span>
        </v-col>
        <v-col  md="8" cols="8" class="column-value" style="width: 100%;">
        <v-tooltip v-if="itemLink != null" bottom max-width="800">
            <template v-slot:activator="{ on }">
              <div v-on="on"  class=" current-title card-title">
                <a :href="data.item" target="_blank" >  {{ data.item }} </a> 
              </div>
            </template>
            <span class="tooltip">
              {{ data.item }}
            </span>
          </v-tooltip>
        <v-tooltip v-else bottom max-width="800">
            <template v-slot:activator="{ on }">
              <div v-on="on"  class=" current-title card-title">
                {{ data.item }}
              </div>
            </template>
            <span class="tooltip">
              {{ data.item }}
            </span>
          </v-tooltip>
        </v-col>
        <v-col v-if="!mobile" md="3" cols="3" class="pa-0 column-value">
          <span v-if="isChangeRate(data.value_type)" :class="[{ positive: isPositive(data.changeRate) }, { negative: isNegative(data.changeRate) }, { neutral: isNeutral(data.changeRate) }, 'change-rate']"> 
            <v-icon size=18 style="margin-bottom: 2px; font-weight: bold;" :color="color(data.changeRate)">
              {{ iconType(data.changeRate) }}
            </v-icon>
            {{ data.changeRate }}%
          </span>
          <span v-else class="span-centered current-value" style="display: grid;"> 
            <div v-if="moneyChar" > ${{ thousandSeparatorCall(data.quantity) }} </div>
            <div v-else > {{ thousandSeparatorCall(data.quantity) }} </div>
          </span>
        </v-col>
        <v-col v-if="mobile" md="3" cols="2" class="pa-0 column-value" >
          <span v-if="isChangeRate(data.value_type)" :class="[{ positive: isPositive(data.changeRate) }, { negative: isNegative(data.changeRate) }, { neutral: isNeutral(data.changeRate) }, 'change-rate']"> 
            <v-icon size=18 style="margin-bottom: 2px; font-weight: bold;" :color="color(data.changeRate)">
              {{ iconType(data.changeRate) }}
            </v-icon>
            {{ data.changeRate }}%
          </span>
          <span v-else class="span-centered current-value" style="display: grid;margin-left:15px"> 
            <div v-if="moneyChar" > ${{ thousandSeparatorCall(data.quantity) }} </div>
            <div v-else > {{ thousandSeparatorCall(data.quantity) }} </div>
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import DataSources from './DataSources.vue';
import ThousandSeparator from "@/helpers/thousandSeparator.helper";
import {mapState} from 'vuex';

export default {
  components: { DataSources },
  name: "InfoCard",
  props: [
    'itemLink',
    'description',
    'sources',
    'cardIcon',
    'cardData',
    'title',
    'moneyChar'
  ],
  data: function() {
    return {
      moneyString: "$ "
    }
  },
  computed: {
    ...mapState([
      "mobile"
    ])
  },
  methods: {
    iconType: function(value) {
      if (value >= 0) {
        if(value == 0)
          return 'mdi-trending-neutral'
        return 'mdi-trending-up';
      }

      return 'mdi-trending-down';
    },
    thousandSeparatorCall(value) {
      return ThousandSeparator.formatNumber(value)
    },
    color: function(value) {
      if (value > 0) {
        return 'green';
      }
      else if (value < 0) {
        return 'red';
      }
      else
        return 'rgba(2, 8, 15, 0.5)'
    },
    isPositive: function(value) {
      if (value > 0) {
        return true;
      }
      return false;
    },
    isNegative: function(value) {
      if (value < 0) {
        return true;
      }
      return false;
    },
    isNeutral: function(value) {
      if (value == 0) {
        return true;
      }
      return false;
    },
    isChangeRate: function(value) {
      if (value=="RATE") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.raw-metric-card {
  max-width: 500px;
  height: 188px;
  padding:16px;
}


.v-card__title {
  padding: 0px 0px 12px;
  text-align: left;
  //padding-bottom: 12px;
}

.v-card__text {
  padding-top: 0px;
  padding-bottom: 4px;
  padding-right: 0px!important;
}

.card-title {
  margin: 5px;
}

.span-centered {
  display:table;
  margin: 0;
}

.tooltip-container {
  text-align: right ;
  padding-right: 10px!important;
}

.column-value {
  padding:6px 12px 6px 12px!important;

}


.current-value {
  //styleName: XL Semibold;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(2, 8, 15, 0.85);

  /* Inside Auto Layout */
  text-align: right;
  padding-right: 9px;
}

.metric-title { 
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: rgba(2, 8, 15, 0.85);
  margin-left: 10px;
}



.current-title {
  //styleName: XL Semibold;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(2, 8, 15, 0.85);

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;

}

.item-index {
  //styleName: XL Semibold;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(2, 8, 15, 0.5);

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;

}

.change-rate {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  position: absolute;
  right: 16px;
}

.positive {
  color: green;
}

.negative {
  color: red;
}

.card-title {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
}


</style>