export default Object.freeze({
  Today: 'TODAY',
  Yesterday: 'YESTERDAY',
  Last7Days: 'LAST_7_DAYS',
  LastXDays: 'LastXDays',
  LastWeek: 'LAST_WEEK',
  ThisMonth: 'CURRENT_MONTH',
  LastMonth: 'LAST_MONTH',
  Custom: 'CUSTOM',
  //COMPARES
  TodayCompareWithYesterday: 'TODAY_COMPARE_WITH_YESTERDAY',
  TodayCompareWithSameDayLastWeek: 'TODAY_COMPARE_WITH_SAME_DAY_LAST_WEEK',
  TodayCompareWithSameDayLastMonth: 'TODAY_COMPARE_WITH_SAME_DAY_LAST_MONTH',
  TodayCompareWithSameDayLastYear: 'TODAY_COMPARE_WITH_SAME_DAY_LAST_YEAR',
  YesterdayCompareWithDayBeforeYesterday: 'YESTERDAY_COMPARE_WITH_DAY_BEFORE_YESTERDAY',
  YesterdayCompareWithSamePerdiodLastWeek: 'YESTERDAY_COMPARE_WITH_SAME_PERIOD_LAST_WEEK',
  YesterdayCompareWithSamePeriodLastMonth: 'YESTERDAY_COMPARE_WITH_SAME_PERIOD_LAST_MONTH',
  YesterdayCompareWithSamePeriodLastYear: 'YESTERDAY_COMPARE_WITH_SAME_PERIOD_LAST_YEAR',
  LastWeekCompareWithPreviousWeek: 'LAST_WEEK_COMPARE_WITH_PREVIOUS_WEEK',
  LastWeekCompareWithSamePeriodLastMonth: 'LAST_WEEK_COMPARE_WITH_SAME_PERIOD_LAST_MONTH',
  LastWeekCompareWithSamePeriodLastYear: 'LAST_WEEK_COMPARE_WITH_SAME_PERIOD_LAST_YEAR',
  Last7DaysCompareWithPrevious7Days: 'LAST_7_DAYS_COMPARE_WITH_SAME_PERIOD_PREVIOUS_7_DAYS',
  Last7DaysCompareWithSamePeriodLastMonth: 'LAST_7_DAYS_COMPARE_WITH_SAME_PERIOD_LAST_MONTH',
  Last7DaysCompareWithSamePeriodLastYear: 'LAST_7_DAYS_COMPARE_WITH_SAME_PERIOD_LAST_YEAR',
  CurrentMonthCompareWithSamePeriodLastMonth: 'CURRENT_MONTH_COMPARE_WITH_SAME_PERIOD_LAST_MONTH',
  CurrentMonthCompareWithFullLastMonth: 'CURRENT_MONTH_COMPARE_WITH_FULL_LAST_MONTH',
  CurrentMonthCompareWithSamePeriodLastYear: 'CURRENT_MONTH_COMPARE_WITH_SAME_PERIOD_LAST_YEAR',
  LastMonthCompareWithPreviousMonth: 'LAST_MONTH_COMPARE_WITH_PREVIOUS_MONTH',
  LastMonthCompareWithSamePeriodLastYear: 'LAST_MONTH_COMPARE_WITH_SAME_PERIOD_LAST_YEAR',
  CompareWithCustom: 'COMPARE_WITH_CUSTOM',
});
