<template>
    <v-row no-gutters class="payments-data">

      <v-col md="2" cols="12" style="padding:12px 12px 0 12px" >
        <half-big-card  
          :title = "$t('Sessions.ClientCost.card-1.title')"
          :description="$t('Sessions.ClientCost.card-1.description')"
          :value= this.moneyString+this.cardsData[6].value.toFixed(2)
          :changeRate= this.cardsData[6].changeRate.toFixed(2)
          :sources="['googleAnalytics','vtex']"
          :invertedChangeRate="true"
        />
      </v-col> 

      <v-col md="2" cols="12" style="padding:12px 12px 0 12px" >
        <half-big-card  
          :title = "$t('Sessions.ClientCost.card-4.title')"
          :description="$t('Sessions.ClientCost.card-4.description')"
          :value= this.moneyString+this.cardsData[8].value.toFixed(2)
          :changeRate= this.cardsData[8].changeRate.toFixed(2)
          :sources="['googleAnalytics','vtex']"
          :invertedChangeRate="true"
        />
      </v-col> 

      <v-col md="4" cols="12" style="padding:12px 12px 0 12px">
        <big-card  
          :title = "$t('Sessions.ClientCost.card-2.title')"
          :description="$t('Sessions.ClientCost.card-2.description')"
          :value= this.moneyString+this.cardsData[7].value.toFixed(2)
          :changeRate= this.cardsData[7].changeRate.toFixed(2)
          :sources="['googleAnalytics','vtex']"
        />
      </v-col>    

      <v-col md="4" cols="12" style="padding:12px 12px 0 12px" >
        <info-card 
          :title = "$t('Sessions.ClientCost.card-3.title')"
          :description="$t('Sessions.ClientCost.card-3.description')"
          :sources="['vtex']"
          cardIcon="buyer"
          :cardData="infoCardsData"
        />
      </v-col>    

    </v-row>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import BigCard from '../shared/BigCard.vue';
import InfoCard from '../shared/InfoCard.vue';
import HalfBigCard from '../shared/HalfBigCard.vue';


export default {
  name: "ClientCost",
  props: [
    'cardsData',
    'infoCardsData'
  ],
  components: {
    BigCard,
    InfoCard,
    HalfBigCard
  },
  data:
    function() {
    return {
      moneyString: '$'
    }
  },
  computed: {

  },
  methods: {

  },

};
</script>

<style scoped lang="scss">
.payments-data {
  margin-top: 3px !important;

  .wj-flexchart {
    padding: 0px;
  }
}


.column {
  height: 340px;
  padding:24px;
}

.wj-flexchart .wj-state-selected {
  stroke: rgba(0,0,0,.5);
  stroke-width: 3;
  stroke-dasharray: 1;
}
</style>