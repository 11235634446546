<template>
  <v-card
    color="#FFFFFF"
    class="card"
  >
    <v-row no-gutters class="revenue-per-hour">
      <v-col cols="12" style="height: 20px;margin-bottom:17px">
        <span class="graph-title">
          {{ $t('DailySummary.OrdersPerHour.title')}}
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
          {{ $t('DailySummary.OrdersPerHour.description')}}
          <data-sources 
            :sources="['vtex']"
          />
          </v-tooltip>

        </span>
      </v-col> 

      <v-col md="12" cols="12" style="height: 250px">
        <wj-flex-chart style="height: 100%" 
        bindingX="hour" 
        :itemsSource="ordersPerHourData" 
        :legendToggle="true"
        :palette="this.palette"
        tooltipContent=""  
        :initialized="onChartInitialized"
        >
            <wj-flex-chart-legend position="Bottom"></wj-flex-chart-legend>
            <wj-flex-chart-axis wjProperty="axisY" :min="0"></wj-flex-chart-axis>
            <wj-flex-chart-series name="# Ordenes" binding="orders"></wj-flex-chart-series>
            <wj-flex-chart-series name="# Items" binding="items"></wj-flex-chart-series>
            <wj-flex-chart-animation></wj-flex-chart-animation>
            <wj-flex-chart-line-marker :isVisible="showMarker" lines="Vertical" interaction="Move" :content="getMarkerContent">
            </wj-flex-chart-line-marker>
        </wj-flex-chart>
      </v-col>      
    </v-row>
  </v-card>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import { CollectionView } from '@grapecity/wijmo';
import ui_kit from "@/constants/ui-kit";
import DataSources from '../shared/DataSources.vue';
import ThousandSeparator from "@/helpers/thousandSeparator.helper";
import i18n from '@/i18n.js' 

export default {
  name: "OrdersPerHour",
  components: {
    DataSources
  },
  props: [
  'ordersData'
  ],
  data: function() {
    return {
      ordersPerHourData: new CollectionView(this.ordersData, {
              refreshOnEdit: false // on-demand sorting and filtering
            }),
      palette: ui_kit.palette,
      lmVisible: false,
      showMarker: false,
    }
  },
  computed: {
  },
  methods: {
    _getData: function() {
        let data = [];
        let hourAux = [];
        let ordersAux = [];
        let itemsAux = [];

        for (let i = 0; i < 24; i++){
            hourAux[i] = i;
            ordersAux[i] = 0;
            itemsAux[i] = 0;
        }
        for (let i = 0; i < this.ordersData.length; i++){
            ordersAux[this.ordersData[i].hour] = this.ordersData[i].orders;
            itemsAux[this.ordersData[i].hour] = this.ordersData[i].items;

        }
        for (let i = 0; i < 24; i++) {
            data.push({
                hour: hourAux[i] + ":00",
                orders: ordersAux[i],
                items: itemsAux[i],
            });
        }
        return data;
    },
    getMarkerContent(ht) {
      var localeKeys = 'keys.'
      if (ht.item) {
          let s = `<p style=" color: rgba(2, 8, 15, 0.5); contrast(50%); 
                              font-family: Open Sans, sans-serif;
                              font-weight: normal;
                              "> 
                              ${ht.item.hour} </p>
                              <div class="row" >`;
          var i=-1;
          var firstColumn = `<div class="col-lg-8 col-md-8" >`
          var secondColumn = `<div class="col-lg-4 col-md-4" >`
          var endCol = `</div>`
          var endRow = `</div>`
          
          for (let key in ht.item) {
            var keyString = localeKeys + key
              if (key !== 'hour') {
                  
                  firstColumn += `
                              <div style=" color: rgba(2, 8, 15, 0,85); contrast(85%); 
                              font-family: Open Sans, sans-serif;
                              font-weight: 600;
                              font-size: 14px;
                              float:left;
                              
                              ">
                              <div style="  
                                      width:12px;
                                      height:12px;
                                      border:solid white 1px;
                                      float:left;
                                      margin:5px;
                                      background-color:${ui_kit.palette[i]}">
                                  </div>
                              <b>${i18n.t(keyString)} </b></div> `;
                  secondColumn += `
                          <div style=" rgba(2, 8, 15, 0.85);
                          font-family: Open Sans, sans-serif;
                          font-weight: normal;
                          font-size: 14px;
                          text-align:right;
                          float:right;
                          
                          "> ${ThousandSeparator.formatNumber(ht.item[key])}</div>
                            </br>  `;
              }
              i++;
          }
          return s + firstColumn + endCol + secondColumn + endCol + endRow;
      } else {
          return 'No items here...';
      }
    },
    onChartInitialized(sender) {
          sender.hostElement.addEventListener('mouseenter', () => this.showMarker = true);
          sender.hostElement.addEventListener('mouseleave', () => this.showMarker = false);
    },
  }
};
</script>

<style scoped lang="scss">
.revenue-per-hour {
  .wj-flexchart {
    padding: 0px !important;
  }
}

.card {
  padding:24px
}
</style>