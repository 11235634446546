import DailySummary from './components/main-section/DailySummary'
import Orders from './components/main-section/Orders'
import Products from './components/main-section/Products'
import Stock from './components/main-section/Stock'
import Payments from './components/main-section/Payments'
import Sessions from './components/main-section/Sessions'
import Traffic from './components/main-section/Traffic'
import PaidTraffic from './components/main-section/PaidTraffic'
import Login from './components/login/Login'
import PageNotFound from './components/shared/PageNotFound'
import Settings from './components/main-section/Settings'
import FacebookGuide from './components/form-guides/facebook'
import GoogleAdsGuide from './components/form-guides/google_ads'
import GoogleAnalyticsGuide from './components/form-guides/google_analytics'
import VtexGuide from './components/form-guides/vtex'
import ScriptTemplate from './components/form-guides/script_template'
import GoogleAdsScripting from './components/main-section/GoogleAdsScripting'
import AgencyClients from './components/main-section/AgencyClients'
import AgencyExtractors from './components/main-section/AgencyExtractors'
import BudgetControl from './components/main-section/BudgetControl'

import Vue from 'vue'
import Router from 'vue-router'



Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/daily_summary',
      name: 'daily_summary',
      component: DailySummary,
    },
    {
      path: '/orders',
      name: 'orders',
      component: Orders
    },
    {
      path: '/products',
      name: 'products',
      component: Products
    },
    {
      path: '/stock',
      name: 'stock',
      component: Stock
    },
    {
      path: '/payments',
      name: 'payments',
      component: Payments
    },
    {
      path: '/sessions',
      name: 'sessions',
      component: Sessions
    },
    {
      path: '/traffic',
      name: 'traffic',
      component: Traffic
    },
    {
      path: '/paid_traffic',
      name: 'paid_traffic',
      component: PaidTraffic
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings
    },
    { 
      path: '/404', 
      name: 'PageNotFound',
      component: PageNotFound 
    },
    { 
      path: '/vtex', 
      name: 'vtex',
      component: VtexGuide 
    },  
    { 
      path: '/facebook', 
      name: 'facebook',
      component: FacebookGuide 
    },  
    { 
      path: '/google_analytics', 
      name: 'google_analytics',
      component: GoogleAnalyticsGuide 
    },  
    { 
      path: '/google_ads', 
      name: 'google_ads',
      component: GoogleAdsGuide 
    },    
    { 
      path: '/script_template', 
      name: 'scriptTemplate',
      component: ScriptTemplate 
    },   
    { 
      path: '/google_ads_scripting', 
      name: 'GoogleAdsScripting',
      component: GoogleAdsScripting 
    }, 
    { 
      path: '/agency_clients', 
      name: 'AgencyClients',
      component: AgencyClients 
    },
    { 
      path: '/budget_control', 
      name: 'BudgetControl',
      component: BudgetControl 
    },
    {
      path: '/agency_extractors',
      name: 'AgencyExtractors',
      component: AgencyExtractors
    },  
    { 
      path: '*', 
      redirect: '/404' 
    },  
  ]
})
