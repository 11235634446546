var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!this.mobile)?_c('v-card',{staticClass:"card",attrs:{"color":"#FFFFFF"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.budgetReport,"items-per-page":10,"sort-by":"budget"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Reporte")]),_c('v-spacer')],1)]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toLocaleString("es-AR", {timeZone: 'UTC' })))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.state),"dark":""}},[_vm._v(" "+_vm._s(item.state)+" ")])]}},{key:"item.total_budget",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.total_budget.toLocaleString()))])]}},{key:"item.available",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.available.toLocaleString()))])]}},{key:"item.total_spend",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.total_spend.toLocaleString()))])]}},{key:"item.spend_percentage",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.spend_percentage * 100)+" %")])]}},{key:"item.consume_percentage_alert",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.consume_percentage_alert * 100)+" %")])]}}],null,false,2526899583)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }