
<template>

  <v-progress-circular 
      style="position:absolute; bottom:7px; left:16%"
      v-if="isLoadingSchema"
      indeterminate
      color="cyan"
  ></v-progress-circular>

  <v-container v-else >
    <v-dialog 
      v-model="dialog"
      max-width="700px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          style="width:100%;height:38px"
          color="primary"
          dark
          small
          v-bind="attrs"
          v-on="on"
          @click="getSchema"
        >
          {{ $t('googleAdsScripting.scriptBuilder.button') }} 
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <span class="text-h5" > {{this.scriptName}} </span>
          <v-tooltip bottom max-width="600"  >
            <template v-slot:activator="{ on }" >
              <v-icon class="guide-tooltip" v-on="on" color="#a6b0c3" size=14 style="margin-left:10px;padding-top:3px" >
                mdi-information-outline
              </v-icon>
            </template>
            <span class="tooltip" >
               {{ this.scriptDescription }} <br/><br/>
               <em style="color:blue">Click en 'Guia' para ver informacion detallada del script </em>
            </span>
          </v-tooltip>
          <router-link style="position:absolute;right:48px;font-size:14px;color:blue" to="/script_template" target="_BLANK" > Guia </router-link>
        </v-card-title>
        

        <v-card-text>
          <v-container>
            <v-select
              style="padding:12px; margin-bottom:-20px"
              class="json-schema-form"
              v-model="client"
              :items="clients"
              label="Client"
              @change="setCurrentClient"
            ></v-select>
            <vue-form-json-schema
              class="form"
              v-model="model"
              :schema="schema"
              :ui-schema="uiSchema"
              :options="options"
              v-on:state-change="onChangeState"
              v-on:validated="onValidated"
              ref="form"
              tag="div"
            >
          </vue-form-json-schema>
          </v-container>
        </v-card-text>
        
        <v-card-actions>
          <custom-alert-small 
          style="margin:auto"/>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="save"
          >
            {{ $t('Settings.JobStatus.send') }}
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="close"
          >
            {{ $t('Settings.UsersTable.dialogButtons.cancel') }}
          </v-btn>
          <br>
        
        </v-card-actions>
        
      </v-card>
      
    </v-dialog>

  </v-container>  
</template>

<script>
import { mapState , mapActions, mapMutations } from 'vuex'
import i18n from '@/i18n.js'
import CustomAlertSmall from '../shared/CustomAlertSmall.vue'


export default {
  components: {
    CustomAlertSmall 
   },
  props: [
    "scriptId",
    "scriptName",
    "scriptDescription",
    "clients"
  ],

  data: () => ({
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    // An object which holds the form values
    model: { },
    client:null,
    // options
    options: {
        "castToSchemaType": true,
        showValidationErrors: true
    },
    // A valid JSON Schema object
    schema: {},
    // Array of HTML elements or Vue components
    uiSchema: [],
    //state
    state: {},
    current: '2',
  }),

  computed: {
    ...mapState([
      'userRole',
      'loadingSchema'
    ]),
    formValidated(){
        this.checkClient()
        let fields = []
        fields = this.schema.required
        for (let i=0; i < fields.length; i++){
          if(!(fields[i] in this.model))
            return false
        }
        if(this.scriptId == 5){
          return this.dateCheckerForDuplicatedKeywords()
        }
        return true
          
    },
    isLoadingSchema(){
      return this.loadingSchema
    },
    getDataSource(){
      return this.dataSource
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
  },
  methods: {
    ...mapActions([
      "postNewScript"
    ]),
    ...mapMutations([
      'setLoadingSchema',
      'setOverlayLoading',
      'setAlertType',
      'setSmallAlertMessage',
      'setShowAlertSmall'
    ]),
    checkClient() {
      if (this.client != null)
        this.model['client'] = this.client
    },
    setCurrentClient(){
      this.model['client'] = this.client
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.model = {};
    },

    save () {
      if(this.formValidated){
        for (var key in this.model) {
            if (Object.prototype.hasOwnProperty.call(this.model, key)) {
                var val = this.model[key];
                if(typeof val == 'boolean'){
                  this.model[key] = val.toString()
                }
                // use val

            }
        }
        var data = this.model
        data['script_id'] = this.scriptId
        data['client_email'] = 'compania@heroics.ai'
        this.postNewScript({ data })
        this.close()
        this.model = {};
        this.setOverlayLoading(true)
        }
      else
        this.setAlertType('error')
        this.setSmallAlertMessage(i18n.t('Settings.JobStatus.required'))
    },
    onChangeState(value) {
    this.state = value;
    },
    onValidated(value) {
    this.valid = value;
    },
    formSubmit(){
      let data = {
        "formData": this.model,
        "scriptData": {
          "scriptId": this.scriptId,
        }
      }
      this.googleAdsIntegration({ data })
    },
    getSchema(){
      let data = {}
      this.checkClient();
      this.current = this.scriptId

      if (this.scriptId >= 0 && this.scriptId <= 16) {
        var scriptIdString = this.scriptId.toString()
        data = require('../../jsonSchemas/'+scriptIdString+'_schema.json');
        this.schema = data['schema']
        this.uiSchema = data['uiSchema']
        this.state = data['state']
      }


      return data
    },
    dateCheckerForDuplicatedKeywords() {
      if(Object.prototype.hasOwnProperty.call(this.model, 'USE_CUSTOM_DATE_RANGE')){
        if(this.model['USE_CUSTOM_DATE_RANGE'] == true){
          if( (Object.prototype.hasOwnProperty.call(this.model, 'START_DATE')) && (Object.prototype.hasOwnProperty.call(this.model, 'END_DATE')) ){
            this.model['DATE_RANGE'] = '';
          }
          else{
            return false
          }
        }
        if(this.model['USE_CUSTOM_DATE_RANGE'] == false){
          if( (Object.prototype.hasOwnProperty.call(this.model, 'DATE_RANGE'))){
            this.model['START_DATE'] = '';
            this.model['END_DATE'] = '';
          }
          else{
            return false
          }
        }
        return true
      }
      else
        return false
    }
  },
}
</script>

<style scoped lang="scss">
.form{
    display: block-inline;
    width: 100%;
    padding: 12px;
    border: black;
}

.v-label {
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  color: red;  
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 18px;
  letter-spacing: 0px;
}

.v-card__actions{
  padding-left: 36px!important;
}

.guide-tooltip:hover{
  cursor: pointer;
}

.v-tooltip__content {
font-size: 12px !important;
color: rgba(2, 8, 15, 0.85) !important;
background-color: #F7F7F7!important;
border-radius: 8px!important;
padding:12px !important;
max-width: 400px !important;
box-shadow:2px 2px 10.2px grey;
font-family: Open Sans, sans-serif;
font-style: normal;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;

}
</style>