<template>
    <div style="width:500px;text-align:left;margin:auto" class="app-font guide-font">
        <v-card style="padding:12px">
            <h3 class="app-font " style="margin-bottom:10px">Configuraciones para Google Ads</h3>
            <ul style="margin-bottom:10px">
                <li class="app-font guide-font" > Acceso a la cuenta administradora a  <b>@bullmetrix.com</b></li>
            </ul>
        </v-card>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.guide-font{
    font-style: italic;
}
</style>