import TimeRangeType from '@/enums/time-range-type.enum';

//'timeRangeTypeText.Today')
export default Object({
  [TimeRangeType.Today]: 'timeRangeTypeText.Today',
  [TimeRangeType.Yesterday]: 'timeRangeTypeText.Yesterday',
  [TimeRangeType.Last7Days]: 'timeRangeTypeText.Last7Days',
  [TimeRangeType.LastXDays]: 'timeRangeTypeText.LastXDays',
  [TimeRangeType.LastWeek]: 'timeRangeTypeText.LastWeek',
  [TimeRangeType.ThisMonth]: 'timeRangeTypeText.ThisMonth',
  [TimeRangeType.LastMonth]: 'timeRangeTypeText.LastMonth',
  [TimeRangeType.Custom]: 'timeRangeTypeText.Custom',
  // COMPARES
  [TimeRangeType.TodayCompareWithYesterday]: 'timeRangeTypeText.TodayCompareWithYesterday',
  [TimeRangeType.TodayCompareWithSameDayLastWeek]: 'timeRangeTypeText.TodayCompareWithSameDayLastWeek',
  [TimeRangeType.TodayCompareWithSameDayLastMonth]: 'timeRangeTypeText.TodayCompareWithSameDayLastMonth',
  [TimeRangeType.TodayCompareWithSameDayLastYear]: 'timeRangeTypeText.TodayCompareWithSameDayLastYear',
  [TimeRangeType.YesterdayCompareWithDayBeforeYesterday]: 'timeRangeTypeText.YesterdayCompareWithDayBeforeYesterday',
  [TimeRangeType.YesterdayCompareWithSamePerdiodLastWeek]: 'timeRangeTypeText.YesterdayCompareWithSamePerdiodLastWeek',
  [TimeRangeType.YesterdayCompareWithSamePeriodLastMonth]: 'timeRangeTypeText.YesterdayCompareWithSamePeriodLastMonth',
  [TimeRangeType.YesterdayCompareWithSamePeriodLastYear]: 'timeRangeTypeText.YesterdayCompareWithSamePeriodLastYear',
  [TimeRangeType.LastWeekCompareWithPreviousWeek]: 'timeRangeTypeText.LastWeekCompareWithPreviousWeek',
  [TimeRangeType.LastWeekCompareWithSamePeriodLastMonth]: 'timeRangeTypeText.LastWeekCompareWithSamePeriodLastMonth',
  [TimeRangeType.LastWeekCompareWithSamePeriodLastYear]: 'timeRangeTypeText.LastWeekCompareWithSamePeriodLastYear',
  [TimeRangeType.Last7DaysCompareWithPrevious7Days]: 'timeRangeTypeText.Last7DaysCompareWithPrevious7Days',
  [TimeRangeType.Last7DaysCompareWithSamePeriodLastMonth]: 'timeRangeTypeText.Last7DaysCompareWithSamePeriodLastMonth',
  [TimeRangeType.Last7DaysCompareWithSamePeriodLastYear]: 'timeRangeTypeText.Last7DaysCompareWithSamePeriodLastYear',
  [TimeRangeType.CurrentMonthCompareWithSamePeriodLastMonth]: 'timeRangeTypeText.CurrentMonthCompareWithSamePeriodLastMonth',
  [TimeRangeType.CurrentMonthCompareWithFullLastMonth]: 'timeRangeTypeText.CurrentMonthCompareWithFullLastMonth',
  [TimeRangeType.CurrentMonthCompareWithSamePeriodLastYear]: 'timeRangeTypeText.CurrentMonthCompareWithSamePeriodLastYear',
  [TimeRangeType.LastMonthCompareWithPreviousMonth]: 'timeRangeTypeText.LastMonthCompareWithPreviousMonth',
  [TimeRangeType.LastMonthCompareWithSamePeriodLastYear]: 'timeRangeTypeText.LastMonthCompareWithSamePeriodLastYear',
  [TimeRangeType.CompareWithCustom]: 'timeRangeTypeText.CompareWithCustom',
});