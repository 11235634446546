<template>
  <v-app class="grey lighten-4">
    <side-navegation v-if="!this.isCurrentRouteLogin" />

    <v-main class="app-main">
      <router-view></router-view>

      <div class="wj-labeled-input" style="display: none">
          <wj-combo-box id="country" 
              :itemsSource="[]"
              :isRequired="false"
              :isEditable="true"
              :text="''" />
          <label for="country">CODE TO HIDE DONT REMOVE!!!</label>
      </div> 
    </v-main>
    
  </v-app>
</template>

<script>
import SideNavegation from './components/main-section/SideNavegation.vue';
import { mapActions, mapMutations } from 'vuex'
import Lenguages from '@/enums/lenguages.enum';

export default {
  name: 'App',

  components: {
    SideNavegation,
  },
  data: () => ({ 
  }),
  computed: {
    isCurrentRouteLogin() {
        return (this.$route.name == 'login' || this.$route.name == 'PageNotFound');
    },
  },
  mounted: function() {
    // -- Code to hide license DONT REMOVE!!!--
    var elements = document.querySelectorAll( 'body > *' );
    if (elements.length >= 6) {
      elements[4].remove();
      elements[5].remove();
    }
    // -- End code to hide ---
  },
  methods: {
    ...mapActions([
      "fetchAccessToken",  
    ]),
    ...mapMutations([
      "setLenguage",  
    ]),
  },
  created() {
    this.fetchAccessToken();
    this.setLenguage(Lenguages.Español)
  }
};
</script>

<style lang="scss">
body > div[style]:not(.app) > a {
   display: none !important;
}

.app-bar {
  background-color: #FFFFFF !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.app-main {
  background: #ebebeb;
}

.wj-flexchart {
  border: 0 !important;
}

.graph-title {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: rgba(2, 8, 15, 0.85);  
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 18px;
  letter-spacing: 0px;
}

.table-title {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: rgba(2, 8, 15, 0.85);
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 18px;
  letter-spacing: 0px;
}

.page-title {
  font-family: Open Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #02080FD9;
}

.v-tooltip__content {
font-size: 12px !important;
color: rgba(2, 8, 15, 0.85) !important;
background-color: #F7F7F7!important;
border-radius: 8px!important;
padding:12px !important;
max-width: 253px !important;
box-shadow:2px 2px 10.2px grey;
font-family: Open Sans, sans-serif;
font-style: normal;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;

}
.text-field-col {
  margin-bottom: 13px;
}

.app-font{
  font-family: Open Sans, sans-serif!important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  border-radius: 8px;
}

.update-view-pager {
  align-items: center; 
  text-align: right; 
  padding-right: 16px;
  display:flex;
  float:right;
  height:30px;
  font-family: Open Sans, sans-serif!important;
}

.v-label{
  font-family: Open Sans, sans-serif!important;
}
.v-text-field{
  font-family: Open Sans, sans-serif!important;
}

.form-group .wj-flexchart .wj-chart-linemarker {
    background: transparent;
}

.wj-chart-linemarker-content {
    padding: 12px;
    background: white;
    border-radius: 3px;
    max-width:800px
}

.wj-flexchart .wj-chart-linemarker .wj-chart-linemarker-hline,
.wj-flexchart .wj-chart-linemarker .wj-chart-linemarker-vline {
    height: 1px;
    width: 1px;
    opacity: .5;
    background: navy;
}

/* style frozen cells */
.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
    background: rgb(255, 255, 125);
}  

/* images (applying formats to the cell, not to the inner IMG element */
.wj-flexgrid .wj-cell.cell-img {
    padding: 0;
    text-align: center;
}
.wj-flexgrid .wj-cell.cell-img:hover {
    z-index: 1;
    overflow: visible;
}
.wj-flexgrid .wj-cell.cell-img:hover img {
    transform: scale(2);
    transition: all 600ms;
}
.wj-flexgrid {
	border: none;
	height: 100%;
}

.wj-flexgrid .wj-cell {
	border-right: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 0.35rem 1rem;
	font-size: 0.8125rem;
}

.wj-flexchart {
	background: transparent;
	height: calc(100%);
	width: 100%;
	border: none;
	padding: 1rem;
	margin: 0;
	overflow: hidden;
}

.mdi-information-margins{
  margin-bottom: 2px;
}


  .wj-cell-maker {
    background-color: rgb(226, 226, 226);
    border: 1px solid #000;
    border-radius: 3px;
  }

  .wj-cell-maker:hover {
    background-color: rgb(189, 186, 186);
    border: 1px solid #000;
  }

  .wj-cell {
    @extend .app-font
  }

  /* custom width for legend reference lines */
  .wj-flexchart .wj-legend > g > line   {
      stroke-width: 3;
  } 

  /* custom font family  for legend reference texts */
  .wj-flexchart .wj-legend > g > text   {
     @extend .app-font
  } 


.wj-flexchart .wj-gridline, .wj-flexchart .wj-gridline-minor {
    stroke: #666;
    stroke-width: 1px!important;
    opacity: .3!important;
}

.update-view-pager {
  align-items: center; 
  text-align: right; 
  padding-right: 16px;
  display:flex;
  float:right;
  height:30px;
  font-size: 13px;
}

//change color on tables alternated rows
/*
.wj-cell.wj-alt {
  background: grey !important;
}*/

.toolbar-time-range {
  display: inherit;
  background: #ffffff !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: 0 10px 0 10px;
  min-height: 40px;
  height: 40px;
  margin-top: 32px!important;
  margin-bottom: 0px;
  border-radius: 8px!important; 

  &-actions {
    flex-wrap: wrap;

    &__date-select {
      align-items: center;
    }
  }
  .time-range-select {
    min-width: 130px;
    text-transform: capitalize;
    .v-input__append-inner {
      margin-top: 0px !important;
      .mdi {
        color: #202020 !important;
      }
    }
    .v-input__control {
      min-height: 30px !important;
    }
    .v-input__slot {
      background: #ffffff !important;
      padding: 0 !important;
      min-height: 30px;
      height: 30px;
    }
    .v-select__selection--comma {
      text-transform: uppercase;
      overflow: visible;
      font-size: 10pt;
    }

    input {
      display: none;
    }
  }

  .number-input {
    width: 40px;
    height: 28px;
    border: 1px solid #202020;
    background: #fff;
  }
  .number-input:focus {
    outline: none;
    border: 1px solid #1976d2 !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .compare-to {
    padding-left: 1px!important;
  }

  .v-text-field.v-text-field--solo.v-input--dense .v-input__prepend-outer, .v-text-field.v-text-field--solo.v-input--dense .v-input__append-outer {
    margin-top: 3px;
  }
}

.divider {
  border-width: 1px !important;
  border-color: grey !important;
  height: 100%;
  margin:0 4px 0 1px;
 }

.elements-margin-left {
  margin-left:3px
}

.item {
    margin-left: 8px;
    font-size: 90%;
}

.item label {
    display: inline-flex;
}

.item input[type=checkbox] {
    margin-left: 10px;
}



.toolbar-time-range-mobile {
  display: inherit;
  background: #ffffff !important;
  padding: 0 10px 0 10px;
  min-height: 40px;
  height: 40px;
  margin-top: 10px!important;
  margin-bottom: 0px;
  border-radius: 5px;

  &-actions {
    flex-wrap: wrap;

    &__date-select {
      align-items: center;
    }
  }
  .time-range-select {
    max-width: 100%;

    text-transform: capitalize;
    .v-input__append-inner {
      margin-top: 0px !important;
      .mdi {
        color: #202020 !important;
      }
    }
    .v-input__control {
      min-height: 30px !important;
    }
    .v-input__slot {
      background: #ffffff !important;
      padding: 0 !important;
      min-height: 30px;
      height: 30px;
    }
    .v-select__selection--comma {
      text-transform: uppercase;
      overflow: visible;
      font-size: 10pt;
    }

    input {
      display: none;
    }
  }

  .number-input {
    width: 40px;
    height: 28px;
    border: 1px solid #202020;
    background: #ffffff !important;
  }
  .number-input:focus {
    outline: none;
    border: 1px solid #1976d2 !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .compare-to {
    padding-left: 1px!important;
  }

  .v-text-field.v-text-field--solo.v-input--dense .v-input__prepend-outer, .v-text-field.v-text-field--solo.v-input--dense .v-input__append-outer {
    margin-top: 3px;
  }
}


input.json-schema-form {
  width: 100%;
  background-color: #fcfcfc;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  height: 35px;
}


label.json-schema-form{
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  color: rgba(2, 8, 15, 0.85);  
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 18px;
  letter-spacing: 0px;
}

input.json-schema-form:focus {
  outline: none !important;
  outline-style: none !important;
  box-shadow: none !important;
  border-color: #1976d2 !important;
}

input.json-schema-form:hover {
  outline: none !important;
  outline-style: none !important;
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, 0.87) !important;
}

small.json-schema-form{
  font-family: Open Sans, sans-serif;
  font-weight: 100;
  color: rgba(2, 8, 15, 0.85);  
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 18px;
  letter-spacing: 0px;
}
.form-group{
  margin-bottom:20px
}

.campaign-pauser{
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  color: rgba(2, 8, 15, 0.85);  
}


</style>
