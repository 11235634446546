<template>
  <div v-if="!this.mobile" style="  display: inherit; align-center" >
  <div class="toolbar-time-range" >
    <v-icon style="margin-right: 3px;">
      mdi-clock-time-four-outline
    </v-icon>
  
    <v-select
      style="display: inline-block;margin-top:5px"
      :items="timeRangeTypes"
      :value="timeRange.timeRangeType"
      @change="setTimeRangeType"
      solo
      flat
      dense
      background-color="transparent"
      hide-details
      class="time-range-select ml-1 capitalized-text"
      :menu-props="['offset-y']"
    >
    <template v-slot:item="data">
      <div class="select-option-item">
        {{ data.item.text.toUpperCase() }}
      </div>
    </template>
    </v-select>
    <input
      v-if="isLastXDaysSelected"
      id="xDays"
      type="number"
      class="number-input px-2 ma-1 my-md-0"
      :value="timeRange.xDays"      
      v-on:keydown.enter="onLastXDaysChange"
      v-click-outside="clickOutsideHandler"
      min="1"
    />

  </div>

  <div class="toolbar-time-range align-center" style="margin-left:10px">
    <div class="d-flex align-center" v-if="isCustomSelected">
      <date-selector 
        :date="fromDate"
        :maxDate="toDate"
        @updateDate="setFromDate"
      />
      <div class="mx-1 text-lowercase">hasta</div>
      <date-selector 
        :date="toDate"
        :minDate="fromDate"
        @updateDate="setToDate"
      />

    </div>

    <div class="d-flex align-center compare-to" v-if="isTodaySelected">
      <v-icon style="margin-right: 3px;">
        mdi-compare
      </v-icon>
      <div v-if="!this.mobile" > <div v-if="!this.mobile" > {{ $t('TimeRangeFilter.compareWith')}}</div></div>
      <v-select
      :items="compareTodayRangeTypes"
      :value="timeRange.compareTimeRangeType"
      @change="setCompareTimeRangeType"
      solo
      flat
      dense
      background-color="transparent"
      hide-details
      class="time-range-select ml-1 capitalized-text"
      :menu-props="['offset-y']"
    >
    <template v-slot:item="data">
      <div class="select-option-item">
        {{ data.item.text.toUpperCase() }}
      </div>
    </template>
    </v-select>
    </div>

    <div class="d-flex align-center compare-to" v-if="isYesterdaySelected">
      <v-icon style="margin-right: 3px;">
        mdi-compare
      </v-icon>
      <div v-if="!this.mobile" > {{ $t('TimeRangeFilter.compareWith')}}</div> 
      <v-select
      :items="compareYesterdayRangeTypes"
      :value="timeRange.compareTimeRangeType"
      @change="setCompareTimeRangeType"
      solo
      flat
      dense
      background-color="transparent"
      hide-details
      class="time-range-select ml-1 capitalized-text"
      :menu-props="['offset-y']"
    >
    <template v-slot:item="data">
      <div class="select-option-item">
        {{ data.item.text.toUpperCase() }}
      </div>
    </template>
    </v-select>
    </div>

    <div class="d-flex align-center compare-to" v-if="isLast7DaysSelected">
      <v-icon style="margin-right: 3px;">
        mdi-compare
      </v-icon>
      <div v-if="!this.mobile" > {{ $t('TimeRangeFilter.compareWith')}}</div> 
      <v-select
      :items="compareLast7DaysRangeTypes"
      :value="timeRange.compareTimeRangeType"
      @change="setCompareTimeRangeType"
      solo
      flat
      dense
      background-color="transparent"
      hide-details
      class="time-range-select ml-1 capitalized-text"
      :menu-props="['offset-y']"
    >
    <template v-slot:item="data">
      <div class="select-option-item">
        {{ data.item.text.toUpperCase() }}
      </div>
    </template>
    </v-select>
    </div>

    <div class="d-flex align-center compare-to" v-if="isLastWeekSelected">
      <v-icon style="margin-right: 3px;">
        mdi-compare
      </v-icon>
      <div v-if="!this.mobile" > {{ $t('TimeRangeFilter.compareWith')}}</div> 
      <v-select
      :items="compareLastWeekRangeTypes"
      :value="timeRange.compareTimeRangeType"
      @change="setCompareTimeRangeType"
      solo
      flat
      dense
      background-color="transparent"
      hide-details
      class="time-range-select ml-1 capitalized-text"
      :menu-props="['offset-y']"
    >
    <template v-slot:item="data">
      <div class="select-option-item">
        {{ data.item.text.toUpperCase() }}
      </div>
    </template>
    </v-select>
    </div>

    <div class="d-flex align-center compare-to" v-if="isThisMonthSelected">
      <v-icon style="margin-right: 3px;">
        mdi-compare
      </v-icon>
      <div v-if="!this.mobile" > {{ $t('TimeRangeFilter.compareWith')}}</div> 
      <v-select
      :items="compareCurrentMonthRangeTypes"
      :value="timeRange.compareTimeRangeType"
      @change="setCompareTimeRangeType"
      solo
      flat
      dense
      background-color="transparent"
      hide-details
      class="time-range-select ml-1 capitalized-text"
      :menu-props="['offset-y']"
    >
    <template v-slot:item="data">
      <div class="select-option-item">
        {{ data.item.text.toUpperCase() }}
      </div>
    </template>
    </v-select>
    </div>

    <div class="d-flex align-center compare-to" v-if="isLastMonthSelected">
      <v-icon style="margin-right: 3px;">
        mdi-compare
      </v-icon>
      <div v-if="!this.mobile" > {{ $t('TimeRangeFilter.compareWith')}}</div> 
      <v-select
      :items="compareLastMonthRangeTypes"
      :value="timeRange.compareTimeRangeType"
      @change="setCompareTimeRangeType"
      solo
      flat
      dense
      background-color="transparent"
      hide-details
      class="time-range-select ml-1 capitalized-text"
      :menu-props="['offset-y']"
    >
    <template v-slot:item="data">
      <div class="select-option-item">
        {{ data.item.text.toUpperCase() }}
      </div>
    </template>
    </v-select>
    </div>
  
    <v-divider vertical class="divider" v-if="isCompareWithCustomSelected" ></v-divider> 
    <div class="d-flex align-center" v-if="isCompareWithCustomSelected">
      <v-icon style="margin-right: 5px;">
        mdi-compare
      </v-icon>
      con: 
      <date-selector 
        :date="compareFromDate"
        :maxDate="compareToDate"
        @updateDate="setCompareFromDate"
      />
      <div class="mx-1 text-lowercase">hasta</div>
      <date-selector 
        :date="compareToDate"
        :minDate="compareFromDate"
        @updateDate="setCompareToDate"
      />
    </div>
  </div>

  <v-btn style="margin-left:10px;margin-top:32px;height:40px"
      @click="updatePageData"
      small
      color="primary"
      dark
      :elevation="0"
    >
      {{ $t('TimeRangeFilter.button')}}
  </v-btn>
  </div>

<div v-else>
  <div class="toolbar-time-range-mobile align-center" style="margin-top:20px!important">
    <div class="d-flex align-center compare-to">
      <v-icon style="margin-right: 3px;">
        mdi-clock-time-four-outline
      </v-icon>
    
      <v-select
        :items="timeRangeTypes"
        :value="timeRange.timeRangeType"
        @change="setTimeRangeType"
        solo
        flat
        dense
        background-color="transparent"
        hide-details
        class="time-range-select ml-1 capitalized-text"
        :menu-props="['offset-y']"
      >
      <template v-slot:item="data">
        <div class="select-option-item">
          {{ data.item.text.toUpperCase() }}
        </div>
      </template>
      </v-select>
      <input
        v-if="isLastXDaysSelected"
        id="xDays"
        type="number"
        class="number-input px-2 ma-1 my-md-0"
        :value="timeRange.xDays"      
        v-on:keydown.enter="onLastXDaysChange"
        v-click-outside="clickOutsideHandler"
        min="1"
      />
    </div>
  </div>
  
  <div v-if="isCustomSelected" class="toolbar-time-range-mobile align-center">
      <div class="d-flex align-center" >
        <date-selector 
          :date="fromDate"
          :maxDate="toDate"
          @updateDate="setFromDate"
        />
        <div class="mx-1 text-lowercase">hasta</div>
        <date-selector 
          :date="toDate"
          :minDate="fromDate"
          @updateDate="setToDate"
        />

      </div>

  </div>
  <div class="toolbar-time-range-mobile align-center">
      <div class="d-flex align-center compare-to" v-if="isTodaySelected">
        <v-icon style="margin-right: 3px;">
          mdi-compare
        </v-icon>
        <div v-if="!this.mobile" > <div v-if="!this.mobile" > {{ $t('TimeRangeFilter.compareWith')}}</div></div>
        <v-select
        :items="compareTodayRangeTypes"
        :value="timeRange.compareTimeRangeType"
        @change="setCompareTimeRangeType"
        solo
        flat
        dense
        background-color="transparent"
        hide-details
        class="time-range-select ml-1 capitalized-text"
        :menu-props="['offset-y']"
      >
      <template v-slot:item="data">
        <div class="select-option-item">
          {{ data.item.text.toUpperCase() }}
        </div>
      </template>
      </v-select>
      </div>

      <div class="d-flex align-center compare-to" v-if="isYesterdaySelected">
        <v-icon style="margin-right: 3px;">
          mdi-compare
        </v-icon>
        <div v-if="!this.mobile" > {{ $t('TimeRangeFilter.compareWith')}}</div> 
        <v-select
        :items="compareYesterdayRangeTypes"
        :value="timeRange.compareTimeRangeType"
        @change="setCompareTimeRangeType"
        solo
        flat
        dense
        background-color="transparent"
        hide-details
        class="time-range-select ml-1 capitalized-text"
        :menu-props="['offset-y']"
      >
      <template v-slot:item="data">
        <div class="select-option-item">
          {{ data.item.text.toUpperCase() }}
        </div>
      </template>
      </v-select>
      </div>

      <div class="d-flex align-center compare-to" v-if="isLast7DaysSelected">
        <v-icon style="margin-right: 3px;">
          mdi-compare
        </v-icon>
        <div v-if="!this.mobile" > {{ $t('TimeRangeFilter.compareWith')}}</div> 
        <v-select
        :items="compareLast7DaysRangeTypes"
        :value="timeRange.compareTimeRangeType"
        @change="setCompareTimeRangeType"
        solo
        flat
        dense
        background-color="transparent"
        hide-details
        class="time-range-select ml-1 capitalized-text"
        :menu-props="['offset-y']"
      >
      <template v-slot:item="data">
        <div class="select-option-item">
          {{ data.item.text.toUpperCase() }}
        </div>
      </template>
      </v-select>
      </div>

      <div class="d-flex align-center compare-to" v-if="isLastWeekSelected">
        <v-icon style="margin-right: 3px;">
          mdi-compare
        </v-icon>
        <div v-if="!this.mobile" > {{ $t('TimeRangeFilter.compareWith')}}</div> 
        <v-select
        :items="compareLastWeekRangeTypes"
        :value="timeRange.compareTimeRangeType"
        @change="setCompareTimeRangeType"
        solo
        flat
        dense
        background-color="transparent"
        hide-details
        class="time-range-select ml-1 capitalized-text"
        :menu-props="['offset-y']"
      >
      <template v-slot:item="data">
        <div class="select-option-item">
          {{ data.item.text.toUpperCase() }}
        </div>
      </template>
      </v-select>
      </div>

      <div class="d-flex align-center compare-to" v-if="isThisMonthSelected">
        <v-icon style="margin-right: 3px;">
          mdi-compare
        </v-icon>
        <div v-if="!this.mobile" > {{ $t('TimeRangeFilter.compareWith')}}</div> 
        <v-select
        :items="compareCurrentMonthRangeTypes"
        :value="timeRange.compareTimeRangeType"
        @change="setCompareTimeRangeType"
        solo
        flat
        dense
        background-color="transparent"
        hide-details
        class="time-range-select ml-1 capitalized-text"
        :menu-props="['offset-y']"
      >
      <template v-slot:item="data">
        <div class="select-option-item">
          {{ data.item.text.toUpperCase() }}
        </div>
      </template>
      </v-select>
      </div>

      <div class="d-flex align-center compare-to" v-if="isLastMonthSelected">
        <v-icon style="margin-right: 3px;">
          mdi-compare
        </v-icon>
        <div v-if="!this.mobile" > {{ $t('TimeRangeFilter.compareWith')}}</div> 
        <v-select
        :items="compareLastMonthRangeTypes"
        :value="timeRange.compareTimeRangeType"
        @change="setCompareTimeRangeType"
        solo
        flat
        dense
        background-color="transparent"
        hide-details
        class="time-range-select ml-1 capitalized-text"
        :menu-props="['offset-y']"
      >
      <template v-slot:item="data">
        <div class="select-option-item">
          {{ data.item.text.toUpperCase() }}
        </div>
      </template>
      </v-select>
      </div>
  </div>
  
  <div v-if="isCompareWithCustomSelected" class="toolbar-time-range-mobile align-center">
      <div class="d-flex align-center" >
        <v-icon style="margin-right: 5px;">
          mdi-compare
        </v-icon>
        con: 
        <date-selector 
          :date="compareFromDate"
          :maxDate="compareToDate"
          @updateDate="setCompareFromDate"
        />
        <div class="mx-1 text-lowercase">hasta</div>
        <date-selector 
          :date="compareToDate"
          :minDate="compareFromDate"
          @updateDate="setCompareToDate"
        />
      </div>
  </div>
  
  
    <v-btn style="margin-left:10px;margin-top:20px;"
        @click="updatePageData"
        small
        color="primary"
        dark
        :elevation="0"
      >
        {{ $t('TimeRangeFilter.button')}}
    </v-btn>

   
  </div>  
</template>

<script>
import { mapMutations, mapState, mapActions} from "vuex";
import TimeRangeType from "@/enums/time-range-type.enum";
import TimeRangeTypeText from "@/constants/time-range-type-text";
import DateSelector from "@/components/shared/DateSelector";
import DateHelper from "@/helpers/date.helper";
import i18n from '@/i18n.js' 

export default {
  components: {
    DateSelector
  },
  props: [
    'componente',
    'orderStatusData'
  ],
  data() {
    return {
      timeRangeTypes: [
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.Today]),
          value: TimeRangeType.Today
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.Yesterday]),
          value: TimeRangeType.Yesterday
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.Last7Days]),
          value: TimeRangeType.Last7Days
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.LastXDays]),
          value: TimeRangeType.LastXDays
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.LastWeek]),
          value: TimeRangeType.LastWeek
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.ThisMonth]),
          value: TimeRangeType.ThisMonth
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.LastMonth]),
          value: TimeRangeType.LastMonth
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.Custom]),
          value: TimeRangeType.Custom
        }
      ],
      compareTodayRangeTypes: [
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.TodayCompareWithYesterday]),
          value: TimeRangeType.TodayCompareWithYesterday
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.TodayCompareWithSameDayLastWeek]),
          value: TimeRangeType.TodayCompareWithSameDayLastWeek
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.TodayCompareWithSameDayLastMonth]),
          value: TimeRangeType.TodayCompareWithSameDayLastMonth
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.TodayCompareWithSameDayLastYear]),
          value: TimeRangeType.TodayCompareWithSameDayLastYear
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.CompareWithCustom]),
          value: TimeRangeType.CompareWithCustom
        }
      ],
      compareYesterdayRangeTypes: [
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.YesterdayCompareWithDayBeforeYesterday]),
          value: TimeRangeType.YesterdayCompareWithDayBeforeYesterday
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.YesterdayCompareWithSamePerdiodLastWeek]),
          value: TimeRangeType.YesterdayCompareWithSamePerdiodLastWeek
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.YesterdayCompareWithSamePeriodLastMonth]),
          value: TimeRangeType.YesterdayCompareWithSamePeriodLastMonth
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.YesterdayCompareWithSamePeriodLastYear]),
          value: TimeRangeType.YesterdayCompareWithSamePeriodLastYear
      },
      {
        text: i18n.t(TimeRangeTypeText[TimeRangeType.CompareWithCustom]),
        value: TimeRangeType.CompareWithCustom
      }
      ],
      compareLastWeekRangeTypes: [
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.LastWeekCompareWithPreviousWeek]),
          value: TimeRangeType.LastWeekCompareWithPreviousWeek
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.LastWeekCompareWithSamePeriodLastMonth]),
          value: TimeRangeType.LastWeekCompareWithSamePeriodLastMonth
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.LastWeekCompareWithSamePeriodLastYear]),
          value: TimeRangeType.LastWeekCompareWithSamePeriodLastYear
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.CompareWithCustom]),
          value: TimeRangeType.CompareWithCustom
        }
      ],
      compareLast7DaysRangeTypes: [
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.Last7DaysCompareWithPrevious7Days]),
          value: TimeRangeType.Last7DaysCompareWithPrevious7Days
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.Last7DaysCompareWithSamePeriodLastMonth]),
          value: TimeRangeType.Last7DaysCompareWithSamePeriodLastMonth
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.Last7DaysCompareWithSamePeriodLastYear]),
          value: TimeRangeType.Last7DaysCompareWithSamePeriodLastYear
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.CompareWithCustom]),
          value: TimeRangeType.CompareWithCustom
        }],
      compareCurrentMonthRangeTypes: [
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.CurrentMonthCompareWithSamePeriodLastMonth]),
          value: TimeRangeType.CurrentMonthCompareWithSamePeriodLastMonth
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.CurrentMonthCompareWithFullLastMonth]),
          value: TimeRangeType.CurrentMonthCompareWithFullLastMonth
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.CurrentMonthCompareWithSamePeriodLastYear]),
          value: TimeRangeType.CurrentMonthCompareWithSamePeriodLastYear
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.CompareWithCustom]),
          value: TimeRangeType.CompareWithCustom
        }
      ],
      compareLastMonthRangeTypes: [
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.LastMonthCompareWithPreviousMonth]),
          value: TimeRangeType.LastMonthCompareWithPreviousMonth
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.LastMonthCompareWithSamePeriodLastYear]),
          value: TimeRangeType.LastMonthCompareWithSamePeriodLastYear
        },
        {
          text: i18n.t(TimeRangeTypeText[TimeRangeType.CompareWithCustom]),
          value: TimeRangeType.CompareWithCustom
        }
      ],
      checkedItems: [],
    }
  },
  computed: {
    ...mapState([
      "timeRange",
      "orderStatusFilter",
      "mobile",
      "demo"
      ]),
    getOrderStatusFilter() {
      return this.orderStatusFilter;
    },
    orderStatus() {
      return this.getOrderStatus();
    },
    isLastXDaysSelected() {
      return this.timeRange.timeRangeType === TimeRangeType.LastXDays;
    },
    isTodaySelected() {
      return this.timeRange.timeRangeType === TimeRangeType.Today;
    },
    isYesterdaySelected() {
      return this.timeRange.timeRangeType === TimeRangeType.Yesterday;
    },
    isLast7DaysSelected() {
      return this.timeRange.timeRangeType === TimeRangeType.Last7Days;
    },
    isLastWeekSelected() {
      return this.timeRange.timeRangeType === TimeRangeType.LastWeek;
    },
    isThisMonthSelected() {
      return this.timeRange.timeRangeType === TimeRangeType.ThisMonth;
    },
    isLastMonthSelected() {
      return this.timeRange.timeRangeType === TimeRangeType.LastMonth;
    },
    isCustomSelected() {
      return this.timeRange.timeRangeType === TimeRangeType.Custom;
    },
    isCompareWithCustomSelected() {
      return this.timeRange.compareTimeRangeType === TimeRangeType.CompareWithCustom;
    },
    fromDate() {
      return this.timeRange.customStartDate;
    },
    toDate() {
      return this.timeRange.customEndDate;
    },
    compareFromDate() {
      return this.timeRange.compareWithCustomStartDate;
    },
    compareToDate() {
      return this.timeRange.compareWithCustomEndDate;
    },
  },
  methods: {
    ...mapActions([
      "getProductsPageData", 
      "getOrdersPageData",
      "getSessionsPageData",
      "getTrafficPageData",
      "getPaymentsPageData",
      "setOrdersQueryParams",
      "setProductsQueryParams",
      "setPaymentsQueryParams",
      "setSessionsQueryParams",
      "setTrafficQueryParams",
    ]),
    ...mapMutations([
      "setTimeRangeType",
      "setLastXDays",
      "setCustomStartDate",
      "setCustomEndDate",
      "setCompareTimeRangeType",
      "setCompareWithCustomStartDate",
      "setCompareWithCustomEndDate",
      "setOrderStatusFilter",
      "setStatusParams",
    ]),
    onLastXDaysChange(event) {
      this.setLastXDays(event.target.value);
    },
    initCalendar: function(calendar) {
        this.theCalendar = calendar;
    },
    setFromDate(date) {
      this.setCustomStartDate(DateHelper.startOfDay(date));
    },
    setToDate(date) {
      this.setCustomEndDate(DateHelper.endOfDay(date));
    },
    setCompareFromDate(date) {
      this.setCompareWithCustomStartDate(DateHelper.startOfDay(date));
    },
    setCompareToDate(date) {
      this.setCompareWithCustomEndDate(DateHelper.endOfDay(date));
    },
    clickOutsideHandler() {
      let value = parseInt(document.getElementById('xDays').value);
      this.setLastXDays(value);
    },
    updatePageData() {
      if(!this.demo){
        if (this.componente == 'Orders') {
          this.setOrdersQueryParams();
          this.getOrdersPageData();
        }
        if (this.componente == 'Products') {
          this.setProductsQueryParams();
          this.getProductsPageData();
        }
        if (this.componente == 'Payments') {
          this.setPaymentsQueryParams();
          this.getPaymentsPageData();
        }
      }
      //Get Full path : 'http://app.heroics.ai'+this.$router.currentRoute.fullPath
    },
    getOrderStatus() {
      var data = []
      for (let i = 0; i < this.orderStatusData.length; i++){
        data.push({
          text: this.orderStatusData[i].label,
          value: this.orderStatusData[i].id
        })
      }
      return data
    },
    onCheckedItemsChanged(sender) {
      var data = []
      this.checkedItems = sender.checkedItems
      for (let i=0; i< this.checkedItems.length; i++){
        data.push(this.checkedItems[i].value)
      }
      this.setStatusParams(data);
      return this.checkedItems;
    }
  },
};
</script>

<style lang="scss">



</style>