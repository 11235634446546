<template>
    <v-row no-gutters class="session-views">

      <v-col md="6" cols="12" style="padding:12px">
      <v-card
        color="#FFFFFF"
        class="card"
      >
      <v-col cols="12" class="pa-0" style="margin-bottom:12px">
        <span class="graph-title">
          {{ $t('Sessions.SessionsViews.title-1')}}     
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
            {{ $t('Sessions.SessionsViews.description-1')}}  
            <data-sources 
              :sources="['googleAnalytics']"
            />
          </v-tooltip>
        </span>
      </v-col> 
        <wj-flex-chart style="display:block;height:330px"
          :itemsSource="this.sessionsDuration" 
          bindingX="dayOfTheWeek"
          chartType="Line"
          :legendToggle="true"
          :initialized="onChartInitialized"
          :palette="this.palette"
          tooltipContent=""
        >
            <wj-flex-chart-legend position="Bottom"></wj-flex-chart-legend>
            <wj-flex-chart-axis wjProperty="axisY" :min="0" title="(minutos)" ></wj-flex-chart-axis>
            <wj-flex-chart-series binding="desktop" :name="$t('keys.desktop')" ></wj-flex-chart-series>
            <wj-flex-chart-series binding="mobile" :name="$t('keys.mobile')" ></wj-flex-chart-series>
            <wj-flex-chart-series binding="tablet" :name="$t('keys.tablet')" ></wj-flex-chart-series>
            <wj-flex-chart-animation animationMode="Point"></wj-flex-chart-animation>
            <wj-flex-chart-line-marker :isVisible="showMarker" lines="Vertical" interaction="Move" :content="getMarkerContent">
            </wj-flex-chart-line-marker>
        </wj-flex-chart>
      </v-card>
      </v-col>      

      <v-col md="6" cols="12" style="padding:12px" >
      <v-card
        color="#FFFFFF"
        class="card"
      >
      <v-col cols="12" class="pa-0" style="margin-bottom:17px">
        <span class="graph-title">
          <strong style="color:black "> {{ $t('Sessions.SessionsViews.title-2')}} </strong>   
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
            {{ $t('Sessions.SessionsViews.description-2')}}
            <data-sources 
              :sources="['googleAnalytics']"
            />
          </v-tooltip>
        </span>
      </v-col> 
        <wj-flex-chart style="display:block;height:330px" 
          :itemsSource="this.sortDevicePageviewData" 
          bindingX="dayOfTheWeek"
          chartType="Line"
          :legendToggle="true"
          tooltipContent=""
          :initialized="onChartInitialized2"
          :palette="this.palette"
        >
            <wj-flex-chart-legend position="Bottom"></wj-flex-chart-legend>
            <wj-flex-chart-axis wjProperty="axisY" :min="0" title="(cantidad)"></wj-flex-chart-axis>
            <wj-flex-chart-series binding="desktop" :name="$t('keys.desktop')" ></wj-flex-chart-series>
            <wj-flex-chart-series binding="mobile" :name="$t('keys.mobile')" ></wj-flex-chart-series>
            <wj-flex-chart-series binding="tablet" :name="$t('keys.tablet')" ></wj-flex-chart-series>
            <wj-flex-chart-animation animationMode="Point"></wj-flex-chart-animation>
            <wj-flex-chart-line-marker :isVisible="showMarker2" lines="Vertical" interaction="Move" :content="getMarkerContent">
            </wj-flex-chart-line-marker>
        </wj-flex-chart>
      </v-card>
      </v-col>    
    </v-row>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import ui_kit from "@/constants/ui-kit";
import DaysOfTheWeek from "@/enums/days-of-the-week.enum.js";
import DataSources from '../shared/DataSources.vue';
import ThousandSeparator from "@/helpers/thousandSeparator.helper";
import i18n from '@/i18n.js' 

export default {
  name: "SessionsViews",
  components: {
    DataSources
  },
  props: [
  'deviceSessionData',
  'devicePageviewsData'
  ],
  data: function() {
    return {
      showMarker: false,
      showMarker2: false,
      palette: ui_kit.palette
    }
  },
  computed: {
    sessionsDuration() {
      return this.secondsToMinutes(this.sortDays(this.deviceSessionData))
    },
    sortDevicePageviewData() {
      return this.sortDays(this.devicePageviewsData)
    },
    daysOfTheWeek: function() {
      return DaysOfTheWeek;
    },
  },
  methods: {
    onChartInitialized(sender) {
          sender.hostElement.addEventListener('mouseenter', () => this.showMarker = true);
          sender.hostElement.addEventListener('mouseleave', () => this.showMarker = false);
    },
    onChartInitialized2(sender) {
          sender.hostElement.addEventListener('mouseenter', () => this.showMarker2 = true);
          sender.hostElement.addEventListener('mouseleave', () => this.showMarker2 = false);
    },  
    getMarkerContent(ht) {
        if (ht.item) {
          var localeKeys = 'keys.'
            let s = `<p style=" color: rgba(2, 8, 15, 0.5); contrast(50%); 
                                font-family: Open Sans, sans-serif;
                                font-weight: normal;
                                "> 
                                ${ht.item.dayOfTheWeek} </p>
                                <div class="row" >`;
            var i=-1;
            //var aux = [0,2,0,1]
            var firstColumn = `<div class="col-lg-8 col-md-8 " >`
            var secondColumn = `<div class="col-lg-4 col-md-4 ">`
            var endCol = `</div>`
            var endRow = `</div>`
            
            for (let key in ht.item) {
              var keyString = localeKeys + key
                if (key !== 'dayOfTheWeek') {
                    firstColumn += `
                    <div style=" color: rgba(2, 8, 15, 0,85); contrast(85%); 
                    font-family: Open Sans, sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    float:left;
                    width:150px
                    ">
                    <div style="  
                            width:12px;
                            height:12px;
                            border:solid white 1px;
                            float:left;
                            margin:5px;
                            background-color:${ui_kit.palette[i]}">
                        </div>
                    <b>${i18n.t(keyString)} </b> </div> </br> `;
                    secondColumn += `
                    <div style=" rgba(2, 8, 15, 0.85);
                    font-family: Open Sans, sans-serif;
                    font-weight: normal;
                    font-size: 14px;
                    text-align:right;
                    float:right;
                    
                    "> ${ThousandSeparator.formatNumber(ht.item[key].toFixed(3))}</div>
                      </br>  `;
                }
                i++;
            }
            return s + firstColumn + endCol + secondColumn + endCol + endRow;
        } else {
            return 'No items here...';
        }
    },
    sortDays(rawData) {
      var result = []
      for (let i = 0; i < this.daysOfTheWeek.length; i++){
        var index = rawData.findIndex(e => e.dayOfTheWeek == this.daysOfTheWeek[i]);
        if (index != -1){
          result.push(rawData[index])
        }
      }
      return result
    },
    secondsToMinutes(rawData) {
      var result = []
      for (let i = 0; i < rawData.length; i++){
        var elem = {
          "dayOfTheWeek": rawData[i].dayOfTheWeek,
          "desktop": rawData[i].desktop / 60,
          "mobile": rawData[i].mobile / 60,
          "tablet": rawData[i].tablet / 60,
        }
        result.push(elem)
      }
      return result
    }
  },
};
</script>

<style scoped lang="scss">
.session-views {
  .wj-flexchart {
    padding: 0px !important;
  }
}
.session-views-title {
  color: #6f6f6f;
  padding-left: 20px;
}

.card {
  max-height: 420px;
  padding: 24px;
}
</style>
