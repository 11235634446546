<template>
  <v-card
    color="#FFFFFF"
    class="graph-area"
  >  

  <v-row no-gutters class="stock-evolution">
      <v-col cols="12">
        <span class="graph-title">
          {{$t('Stock.HorizontalBarGraph.title')}}
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins" >
                mdi-information-outline
              </v-icon>
            </template>
            {{$t('Stock.HorizontalBarGraph.description')}}
            <data-sources
              :sources="['vtex']"
            />
          </v-tooltip>
        </span>
      </v-col> 


    <v-col cols="12">
    <div  v-if="this.dataLengthMin" class="container-fluid">
        <wj-flex-chart  chartType="Bar" style="height:336px" :bindingX="this.bindingName" stacking="Stacked" :itemsSource="this.dataSource"
            :palette="['rgba(70,107,176,1)', 'rgba(200,180,34,1)', 'rgba(20,136,110,1)', 'rgba(181,72,54,1)', 'rgba(110,89,68,1)', 'rgba(139,56,114,1)', 'rgba(115,178,43,1)', 'rgba(184,115,32,1)', 'rgba(20,20,20,1)']">
            <wj-flex-chart-legend position="Bottom"></wj-flex-chart-legend>
            <wj-flex-chart-series name="Cantidad" binding="stockQuantity"></wj-flex-chart-series>
            <wj-flex-chart-animation></wj-flex-chart-animation>
        </wj-flex-chart>
    </div>
    
    <div  v-else class="container-fluid">
        <wj-flex-chart chartType="Bar" style="height:200px" :bindingX="this.bindingName" stacking="Stacked" :itemsSource="this.dataSource"
            :palette="['rgba(70,107,176,1)', 'rgba(200,180,34,1)', 'rgba(20,136,110,1)', 'rgba(181,72,54,1)', 'rgba(110,89,68,1)', 'rgba(139,56,114,1)', 'rgba(115,178,43,1)', 'rgba(184,115,32,1)', 'rgba(20,20,20,1)']">
            <wj-flex-chart-legend position="Bottom"></wj-flex-chart-legend>
            <wj-flex-chart-series name="Cantidad" binding="stockQuantity"></wj-flex-chart-series>
            <wj-flex-chart-animation></wj-flex-chart-animation>
        </wj-flex-chart>
    </div>
    </v-col> 
  </v-row>
</v-card>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.chart.animation';
import DataSources from './DataSources.vue';
export default {
  props: [
    'dataSource',
    'bindingName'
  ],
  components: {
    DataSources
  },
    data: function () {
        return { 
        }
  },
  computed: {
    dataLengthMin() {
        if (this.dataSource.length > 1)
            return true
        else
            return false
    },
  },
  methods: {
  },
};
</script>

<style>
.graph-area {
  padding: 24px;
  max-height: 396px;
}
</style>