<template>
	<div>
		<pdf
			v-for="i in numPages"
			:key="i"
			:src="src"
			:page="i"
			style="display: inline-block; width: 100%"
		></pdf>
	</div>
</template>

<script>
import pdf from 'vue-pdf' 
import { mapState } from 'vuex'


export default {
    components: {
        pdf
    },
    props: [
        'scriptTitle',
        'scriptText'
    ],
	data() {
		return {
			numPages: undefined,
		}
	},
	mounted() {
        this.printScript
		this.src.promise.then(pdf => {

			this.numPages = pdf.numPages;
		});
	},
    computed: {
        ...mapState([
            "currentScript",
            "avaibleScripts"
        ]),
        src() {
         console.log('pdfs/'+this.avaibleScripts[this.currentScript]+'.pdf')
         return pdf.createLoadingTask('pdfs/'+this.avaibleScripts[this.currentScript]+'.pdf');
        }
    },

}

</script>

<style>
.guide-font{
    font-style: italic;
}

.guide-title{
    color:#1167b1;
    font-size: 42px;
    font-family: Oswald;
    text-align: center;
}
.guide-subtitle{
    color:#aeaeae;
    font-size: 24px;
    font-family: Droid Sherif;
    text-align: center;
}

.script-title{
    color:#1167b1;
    font-size: 24px;
    font-family: Oswald;
    text-align: left;
    text-transform: uppercase; 
}
</style>