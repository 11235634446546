<template >
<v-card v-if="!this.mobile"
    color="#FFFFFF"
    class="card"
  >
    <v-row  no-gutters class="best-seller-items">
      <v-col md="6" cols="12" style="height:20px">
        <span class="table-title">
          {{ $t('Settings.UsersTable.users') }}
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
          {{ $t('Settings.UsersTable.description') }}
            <data-sources 
              :sources="['vtex']"
            />
          </v-tooltip>
        </span>
      </v-col> 

      <v-col md="4" cols="12" class="right-content">
      </v-col>

      <v-col md="2" cols="12" class="right-content" v-if="this.userRole == 'ADMIN' || this.userRole == 'admin'" >
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
             {{ $t('Settings.UsersTable.newUser') }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      :label="$t('Settings.UsersTable.newUserDialog.name')"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.email"
                      :label="$t('Settings.UsersTable.newUserDialog.email')"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                  <v-text-field
                    v-model="editedItem.password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    :label="$t('Settings.UsersTable.newUserDialog.password')"
                    :hint="$t('Settings.UsersTable.newUserDialog.minChar')"
                    counter
                    @click:append="show1 = !show1"
                  ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                  <v-select
                    :items="roles"
                    v-model="editedItem.role"
                    :label="$t('Settings.UsersTable.newUserDialog.role')"
                  ></v-select>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="save"
              >
                {{ $t('Settings.UsersTable.dialogButtons.save') }}
              </v-btn>
              <v-btn
                color="red darken-1"
                text
                @click="close"
              >
                {{ $t('Settings.UsersTable.dialogButtons.cancel') }}
              </v-btn>
              
            </v-card-actions>
          </v-card>
        </v-dialog>
<v-dialog v-model="dialogDelete" max-width="500px">
          <v-card style="text-align:center">
            <v-card-title class="text-h5 " style="text-align:center"> {{ $t('Settings.UsersTable.deleteText') }} </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text  @click="deleteItemConfirm">{{ $t('Settings.UsersTable.dialogButtons.delete') }}</v-btn>
              <v-btn color="red darken-1" text @click="closeDelete" >{{ $t('Settings.UsersTable.dialogButtons.cancel') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>

      <v-col v-if="!this.mobile" md="12" cols="12" style="margin-top: 16px">
  <v-data-table  
    :headers="headers"
    :items="users"
    :items-per-page="5"
    sort-by="name"
    class=" users-table"
    :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-text': $t('Settings.UsersTable.rowText')
      }"
  >
    
    <template v-slot:item.actions ="{ item }">
      <!-- <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon> -->
      
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>

  </v-data-table>
      </v-col>
      </v-row>
</v-card>

<v-dialog v-else
  v-model="dialog"
  max-width="500px"
>
  <template v-slot:activator="{ on, attrs }">
    <v-btn 
      color="primary"
      dark
      class="mb-2"
      v-bind="attrs"
      v-on="on"
    >
      {{ $t('Settings.UsersTable.newUser') }}
    </v-btn>
  </template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <v-text-field
              v-model="editedItem.name"
              :label="$t('Settings.UsersTable.newUserDialog.name')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <v-text-field
              v-model="editedItem.email"
              :label="$t('Settings.UsersTable.newUserDialog.email')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
          <v-text-field
            v-model="editedItem.password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            :label="$t('Settings.UsersTable.newUserDialog.password')"
            :hint="$t('Settings.UsersTable.newUserDialog.minChar')"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
          <v-select
            :items="roles"
            v-model="editedItem.role"
            :label="$t('Settings.UsersTable.newUserDialog.role')"
          ></v-select>
          </v-col>

        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="green darken-1"
        text
        @click="save"
      >
        {{ $t('Settings.UsersTable.dialogButtons.save') }}
      </v-btn>
      <v-btn
        color="red darken-1"
        text
        @click="close"
      >
        {{ $t('Settings.UsersTable.dialogButtons.cancel') }}
      </v-btn>
      
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import i18n from '@/i18n.js' 
import { mapState , mapActions, mapMutations } from 'vuex'
import DataSources from '../shared/DataSources.vue'

export default {
  components: { DataSources },
  props: [
    "usersData"
  ],

  data: () => ({
    dialog: false,
    dialogDelete: false,
    roles:['viewer'],
    headers: [
      { text: 'Nombre', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Rol', value: 'role' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    show1: true,
    password: 'Password',
    rules: {
      required: value => !!value || i18n.t('Settings.UsersTable.newUserDialog.required'),
      min: v => v.length >= 8 || i18n.t('Settings.UsersTable.newUserDialog.minChar'),
    },
    users: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      password: '',
      email: '',
      role: '',
    },
    defaultItem: {
      name: '',
      password: '',
      email: '',
      role: '',
    },
  }),

  computed: {
  ...mapState([
    'userRole', 
    'newUser',
    'userDeleted',
    'mobile'
  ]),
  isFormFilled(){
    if (this.editedItem.name != '' && this.editedItem.email != '' && this.editedItem.password != '' && this.editedItem.role != '' && this.editedItem.password.length > 8 && this.editedItem.email.includes('@'))
      return true
    else
      return false
  },
  formTitle () {
    return this.editedIndex === -1 ? i18n.t('Settings.UsersTable.newUser')  : i18n.t('Settings.UsersTable.editUser')
  },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    newUser(){
      this.users.push(this.newUser)
    },
    userDeleted(){
      if(this.userDeleted)
        this.users.splice(this.editedIndex, 1)
      this.setUserDeleted(false)
    }
  },

  created () {
    this.initialize()
  },

  methods: {
    ...mapActions([
      'postNewUser',
      'deleteUser'
    ]),
    ...mapMutations([
      'setUserDeleted',
    ]),
    initialize () {
      for (let i=0; i<this.usersData.length; i++){
        this.users.push({
          name: this.usersData[i].user_name,
          email: this.usersData[i].email,
          role: this.usersData[i].rol,
        })
      }

    },

    editItem (item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.deleteUser(this.users[this.editedIndex])
      this.closeDelete()

    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if(this.isFormFilled){
        if (this.editedIndex > -1) {
          Object.assign(this.users[this.editedIndex], this.editedItem)
        } else {
          let data = {
            "email": this.editedItem.email,
            "password": this.editedItem.password,
            "rol": this.editedItem.role,
            "user_name": this.editedItem.name
          }
          this.postNewUser({ data })
        }
        this.close()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  padding:24px;
  max-height: 1200px;
  }
</style>