<template>

    <div class="toolbar-time-range align-center">
      <v-icon style="margin-right: 5px;">
        mdi-web
      </v-icon>
      <v-select
        :items="LanguagesList"
        :value="lenguage"
        label="idioma"
        @change="setLenguageLocal"
        solo
        flat
        dense
        background-color="transparent"
        hide-details
        class="time-range-select ml-2 "
        :menu-props="['offset-y']"
      >
        <template v-slot:item="slotProps" >
          <img v-if="slotProps.item.value == 'es'" :src="require('@/assets/espanol.png')" style="height:20px;margin-right:10px"/>
          <img v-if="slotProps.item.value == 'pt'" :src="require('@/assets/portugues.png')" style="height:20px;margin-right:10px"/>
          {{slotProps.item.text}}
        </template>
      </v-select>
    </div>
</template>

<script>
import Languages from "@/enums/lenguages.enum";
import LanguagesText from "@/constants/lenguages-text";
import { mapState, mapMutations, mapActions} from 'vuex'

export default {
  components: {  },
  props: [
  ],
  data: function() {
    return {
     select: null,
     LanguagesList: [
        {
          text: LanguagesText[Languages.Español],
          value: Languages.Español,
        },
        {
          text: LanguagesText[Languages.Portugues],
          value: Languages.Portugues,
        },
      ]
    }
  },
  computed: {
    ...mapState([
      'lenguage',
      'loadingLanguage'
    ]),

  },
  methods: {
    ...mapMutations([
      'setLenguage',
      'setLoadingLanguage'
    ]),
    ...mapActions([
      'updateSettings'
    ]),
    setLenguageLocal(value){
      var data = {
        "language": value
      }
      this.updateSettings({ data })
    }
  },
};
</script>

<style scoped lang="scss">
.toolbar-time-range {
  display: flex;
  min-height: 30px;
  padding:0px;
  height: 30px;
  margin-top: 32px!important;
  margin-bottom: 0px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255)!important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  &-actions {
    flex-wrap: wrap;

    &__date-select {
      align-items: center;
    }
  }
  .time-range-select {
    min-width: 130px;
    max-width: 130px;

    .v-input__append-inner {
      margin-top: 0px !important;
      .mdi {
        color: #202020 !important;
      }
    }
    .v-input__control {
      min-height: 30px !important;
    }
    .v-input__slot {
      padding: 0 !important;
      min-height: 30px;
      height: 30px;
    }
    .v-select__selection--comma {
      overflow: visible;
      font-size: 10pt;
    }

    input {
      display: none;
    }
  }

  .number-input {
    width: 40px;
    height: 28px;
    border: 1px solid #202020;
    background: #fff;
  }
  .number-input:focus {
    outline: none;
    border: 1px solid #1976d2 !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .compare-to {
    padding-left: 1px!important;
  }

  .v-text-field.v-text-field--solo.v-input--dense .v-input__prepend-outer, .v-text-field.v-text-field--solo.v-input--dense .v-input__append-outer {
    margin-top: 3px;
  }
}

.theme--light.v-list {
    background: #FFFFFF;
    color: rgba(0, 0, 0, 0.87);
    margin-left: -10px;
}

.v-list-item--dense, .v-list--dense .v-list-item {
    height: 30px;
}

</style>