<template>
    <v-row no-gutters class="new-users">

      <v-col md="6" cols="12" style="padding:12px">
      <v-card
        color="#FFFFFF"
        class="card"
      >
      <v-col cols="12" style="height: 20px;margin-left:-12px;margin-top:-12px">
        <span class="table-title">
          {{ $t('Sessions.NewUsers.title-1')}} 
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
            {{ $t('Sessions.NewUsers.description-1')}} 
            <data-sources 
              :sources="['googleAnalytics']"
            />
          </v-tooltip>
        </span>
      </v-col> 
        <wj-flex-chart 
        style="height:310px; margin-top: 32px" 
        bindingX="date" 
        :itemsSource="procesedData" 
        :stacking="true"
        :legendToggle="true"
        :palette="this.palette"
        >
            <wj-flex-chart-legend position="Bottom"></wj-flex-chart-legend>
            <wj-flex-chart-axis wjProperty="axisY" :min="0" format="n0" ></wj-flex-chart-axis>
            <wj-flex-chart-series :name="$t('keys.recurrentUSers')" binding="recurrentUSers"></wj-flex-chart-series>
            <wj-flex-chart-series :name="$t('keys.newUsers')" binding="newUsers"></wj-flex-chart-series>
            <wj-flex-chart-animation></wj-flex-chart-animation>
        </wj-flex-chart>
      </v-card>
      </v-col>      

      <v-col md="6" cols="12" style="padding:12px" v-if="!mobile" >
        <v-card
          color="#FFFFFF"
          class="card"
        >
        <v-col cols="12" style="height: 20px;margin-left:-12px;margin-top:-12px">
        <span class="table-title" >
          {{ $t('Sessions.NewUsers.title-2')}} 
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=18>
                mdi-information-outline
              </v-icon>
            </template>
            {{ $t('Sessions.NewUsers.description-2')}}
            <data-sources
              :sources="['googleAnalytics']"
            />
          </v-tooltip>
        </span>
          <v-btn class="right-content"
            tile
            small
            color="success"
            @click="save()"
          >
            <v-icon style="font-size: 20px;">
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </v-col> 

        <wj-flex-grid 
          :initialized="initializeFlexSheet" 
          :headersVisibility="'Column'"
          :selectionMode="'ListBox'"
          :itemsSource="this.data"
          :alternatingRowStep="1"
          style="max-height:1090px ;margin-top: 32px"
        >
            <wj-flex-grid-filter />
            <wj-flex-grid-column :header="'Region'" :binding="'region'"  width="1*" :isReadOnly=true />
            <wj-flex-grid-column :header="'Sessions'" :binding="'sessions'" aggregate="Sum" width="*" format="n0" :isReadOnly=true />
            <wj-flex-grid-column :header="'New Users'" :binding="'newUsers'" aggregate="Sum" width="*" format="n0" :isReadOnly=true />
            <wj-flex-grid-column :header="'Bounce Rate'" :binding="'bounceRate'" aggregate="Avg" width="*"  format="n2" :isReadOnly=true />
            <wj-flex-grid-column :header="'Pageviews'" :binding="'pageViews'" aggregate="Sum" width="*" format="n0" :isReadOnly=true />
        </wj-flex-grid>
        <v-col md="12" cols="12"  >
          <div v-on:click="updateViewPager($event)" class="update-view-pager" style="margin-top:-15px;margin-right:-12px">
            <flex-grid-page-size-selector 
              style="display:flex;position: relative;inset: -15px 0 -15px -20px"
              @change="onChangeChild"
              :currentPageSize="this.currentPageSize"
              :pageSizeSelect="this.pageSizeSelect"
            />
              <button id="btnFirst" class="btn"><span class="wj-glyph-step-backward" style="position: relative;left: -10px; top: 1px" ></span></button>
              <button id="btnPrev" class="btn"><span class="wj-glyph-left" style="position: relative;left: -3px; " ></span></button>
              &nbsp;&nbsp;&nbsp;{{ $t('FlexGridPageSizeSelector.page')}} {{ this.pageIndex + 1 | number:0 }} {{ $t('FlexGridPageSizeSelector.of')}} {{ this.pageCount | number:0 }}&nbsp;&nbsp;&nbsp;
              <button id="btnNext" class="btn"><span class="wj-glyph-right" style="position: relative;right: -3px; "></span></button>
              <button id="btnLast" class="btn"><span class="wj-glyph-step-forward" style="position: relative;right: -10px; top: 1px"></span></button>
          </div>
        </v-col>
      </v-card>
      </v-col>  

    </v-row>

</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import DataFilterHelper from "@/helpers/dataFilter.helper";
import ui_kit from "@/constants/ui-kit";
import DataSources from '../shared/DataSources.vue';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import FlexGridPageSizeSelector from '../shared/FlexGridPageSizeSelector.vue';
import FlexGridHelper from "@/helpers/flexGrid.helper";
import { mapState } from 'vuex';

export default {
  name: "NewUsers",
  components: {
    DataSources,
    FlexGridPageSizeSelector
  },
  props: [
  'usersBarData',
  'usersTableData'
  ],
  data: function() {
    return {
      maxPageSize: '7',
      currentPageSize: '7',
      pageIndex: 0,
      pageCount: 1,
      palette: ui_kit.palette
    }
  },
  computed: {
    procesedData() {
      return DataFilterHelper.dateYearFilter(this.usersBarData)
    },
    data() {
      return new CollectionView(FlexGridHelper.fillData(this.usersTableData, parseInt(this.currentPageSize)), {
              refreshOnEdit: true, // on-demand sorting and filtering
              pageSize: parseInt(this.currentPageSize)
            })
    },
    pageSizeSelect() {
      return FlexGridHelper.makeList()
    },
    ...mapState([
      "mobile"
    ])
  },
  methods: {
      initializeFlexSheet: function(flex) {
      flex.columnFooters.rows.push(new wjcGrid.GroupRow());
      this.flex = flex;
    },
    onChangeChild(value) {
      this.currentPageSize = value
      this.updatePagesIndex()
    },
    updatePagesIndex() {
      this.pageIndex = this.data.pageIndex
      this.pageCount = this.data.pageCount
    }, 
    save() {
      this.data.pageSize = 0;
      wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
          this.flex,
          {
              includeColumnHeaders: true,
              includeCellStyles: false,
              formatItem: null
          },
          'regions.xlsx'
      );
      this.data.pageSize = parseInt(this.currentPageSize);
    },
    updateViewPager(e) {
        let btn = wijmo.closest(e.target, 'button'),
            id = btn ? btn.id : '';
        //
        switch (id) {
            case 'btnFirst':
                this.data.moveToFirstPage();
                break;
            case 'btnPrev':
                this.data.moveToPreviousPage();
                break;
            case 'btnNext':
                this.data.moveToNextPage();
                break;
            case 'btnLast':
                this.data.moveToLastPage();
                break;
        }
        this.updatePagesIndex()
    },
  },
  created: function () {
    this.updatePagesIndex()
  },
};
</script>

<style scoped lang="scss">
.new-users {
  .wj-flexchart {
    padding: 0px !important;
  }
}
.new-users-title {
  color: #6f6f6f;
  padding-left: 20px;
}

.right-content {
  margin: auto; 
  position: absolute;
  right:44px;
  height: 20px;
}

.card {
  max-height: 1200px;
  padding: 24px;
}

.update-view-pager {
  align-items: center; 
  text-align: right; 
  padding-right: 16px;
  display:flex;
  float:right;
  height:30px;
}

.aParent div {
    display: inline-block;
    align-content: right;
}
</style>