import TimeRangeType from "@/enums/time-range-type.enum";
import DateHelper from '@/helpers/date.helper.js';
import Actions from "@/store/actions";
import router from "../router";
import i18n from "../i18n";

export default {
  setUserLogin(state, token) {
    state.loggedIn = true;
    state.token = token;
  },
  setUserLogOut(state) {
    state.loggedIn = false;
    state.token = null;
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    router.push('/')
  },
  errorLogout() {
    localStorage.removeItem('token');
  },
  setError(state, value) {
    state.error = value;
  },
  setLoginError(state, status) {
    state.loginError = status;
  },
  openSection(state, section) {
    state.currentSection = section;
  },
  setTimeRangeType(state, value) {
    
    state.timeRange.timeRangeType = value;
    if (value == TimeRangeType.Today)
      state.timeRange.compareTimeRangeType = TimeRangeType.TodayCompareWithYesterday
    if (value == TimeRangeType.Yesterday)
      state.timeRange.compareTimeRangeType = TimeRangeType.YesterdayCompareWithDayBeforeYesterday
    if (value == TimeRangeType.LastWeek)
      state.timeRange.compareTimeRangeType = TimeRangeType.LastWeekCompareWithPreviousWeek
    if (value == TimeRangeType.Last7Days)
      state.timeRange.compareTimeRangeType = TimeRangeType.Last7DaysCompareWithPrevious7Days
    if (value == TimeRangeType.ThisMonth)
      state.timeRange.compareTimeRangeType = TimeRangeType.CurrentMonthCompareWithSamePeriodLastMonth
    if (value == TimeRangeType.LastMonth)
      state.timeRange.compareTimeRangeType = TimeRangeType.LastMonthCompareWithPreviousMonth
    if (value == TimeRangeType.Custom)
      state.timeRange.compareTimeRangeType = TimeRangeType.CompareWithCustom
    if (value == TimeRangeType.LastXDays)
      state.timeRange.compareTimeRangeType = TimeRangeType.CompareWithCustom
    Actions.getPaymentsPageData(state.timeRange.timeRangeType)
  },
  setCustomStartDate(state, value) {
    state.timeRange.customStartDate = value;
  },
  setCustomEndDate(state, value) {
    state.timeRange.customEndDate = value;
  },
  setLastXDays(state, value) {
    state.timeRange.xDays = value;
    state.timeRange.customStartDate = DateHelper.startOfDay(
      DateHelper.subtractDays(new Date(), value));
    state.timeRange.customEndDate =  DateHelper.endOfDay(new Date());

  },
  setCompareTimeRangeType(state, value) {
    state.timeRange.compareTimeRangeType = value;
  },
  setCompareWithCustomStartDate(state, value) {
    state.timeRange.compareWithCustomStartDate = value;
  },
  setCompareWithCustomEndDate(state, value) {
    state.timeRange.compareWithCustomEndDate = value;
  },
  setDailySummaryDate(state, value) {
    state.timeRange.dailySummaryDate = value;
  },
  setDailySummaryDateFormated(state, value) {
    state.timeRange.dailySummaryDateFormated = value;
  },
  setStockDate(state, value) {
    state.timeRange.stockDate = value;
  },
  setStockDateFormated(state, value) {
    state.timeRange.stockDateFormated = value;
  },
  setOrderStatusFilter(state, value) {
    state.orderStatusFilter = value;
  },
  updatePaymentGroupsVisualization(state, value) {
    state.paymentGroupsVisualization = value;
  },
  updatePaymentMethodsVisualization(state, value) {
    state.paymentMethodsVisualization = value;
  },
  updateOrderStatusVisualization(state, value) {
    state.orderStatusVisualization = value;
  },
  updateOrderStatusPaymentsPageVisualization(state, value) {
    state.orderStatusPaymentsPageVisualization = value;
  },
  updatePaymentsMethodsPaymentsPageVisualization(state, value) {
    state.paymentsMethodsPaymentsPageVisualization = value;
  },
  updatePaymentsGroupsPaymentsPageVisualization(state, value) {
    state.paymentsGroupsPaymentsPageVisualization = value;
  },
  updateInstallmentPageVisualization(state, value) {
    state.installmentPageVisualization = value;
  },
  updateBestSellerCategoriesVisualization(state, value) {
    state.bestSellerCategoriesVisualization = value;
  },
  setOrdersPageData(state, value) {
    state.ordersPageData = value;
    state.loadingOrdersPageData = false;
    state.error = false
  },
  setDailySummaryPageData(state, value) {
    state.dailySummaryPageData = value;
    state.loadingDailySummaryData = false;
    state.error = false
  },
  setPaymentsPageData(state, value) {
    state.paymentsPageData = value;
    state.loadingPaymentsPageData = false;
    state.error = false
  },
  setStocksPageData(state, value) {
    state.stocksPageData = value;
    state.loadingStocksPageData = false;
    state.error = false
  },
  setProductsPageData(state, value) {
    state.productsPageData = value;
    state.loadingProductsPageData = false;
    state.error = false
  },
  setSessionsPageData(state, value) {
    state.sessionsPageData = value;
    state.loadingSessionsPageData = false;
    state.error = false
  },
  setPaidTrafficPageData(state, value) {
    state.paidTrafficPageData = value;
    state.loadingPaidTrafficPageData = false;
    state.error = false
  },
  setItemData(state, value) {
    state.itemData = value;
    state.loadingItemData = false;
    state.error = false
  },
  getProductsPageData(state, value) {
    state.productsPageData = value;
    state.loadingProductsPageData = false;
    state.error = false
  },
  getSessionsPageData(state, value) {
    state.sessionsPageData = value;
    state.loadingSessionsPageData = false;
    state.error = false
  },
  setTrafficPageData(state, value) {
    state.trafficPageData = value;
    state.loadingTrafficPageData = false;
    state.error = false
  },
  getPaidTrafficPageData(state, value) {
    state.paidTrafficPageData = value;
    state.loadingPaidTrafficPageData = false;
    state.error = false
  },
  getItemData(state, value) {
    state.itemData = value;
    state.loadingItemData = false;
    state.error = false
  },
  setLoadingDailySummaryData(state, value) {
    state.loadingDailySummaryData = value;
  },
  setLoadingPaymentsPageData(state, value) {
    state.loadingPaymentsPageData = value;
  },
  setLoadingOrdersPageData(state, value) {
    state.loadingOrdersPageData = value;
  },
  setLoadingStocksPageData(state, value) {
    state.loadingStocksPageData = value;
  },
  setLoadingProductsPageData(state, value) {
    state.loadingProductsPageData = value;
  },
  setLoadingSessionsPageData(state, value) {
    state.loadingSessionsPageData = value;
  },
  setLoadingPaidTrafficPageData(state, value) {
    state.loadingPaidTrafficPageData = value;
  },
  setLoadingTrafficPageData(state, value) {
    state.loadingTrafficPageData = value;
  },
  setLoadingFilterOptions(state, value) {
    state.loadingFilterOptions = value;
  },
  setLoadingItemData(state, value) {
    state.loadingItemData = value;
  },
  setLoadingSettings(state, value) {
    state.loadingSettings = value;
  },
  setCurrentItem(state, value) {
    state.currentItem = value;
  },
  setCurrentItemName(state, value) {
    state.currentItemName = value;
  },
  setFilterOptions(state, value) {
    state.filterOptions = value;
    state.loadingFilterOptions = false;
    state.statusParams = value.Filters[0]["values"]
    state.originParams = value.Filters[1]["values"]
    state.categoriesParams = value.Filters[2]["values"]
    state.departmentsParams = value.Filters[3]["values"]
  },
  setStatusParams(state, value) {
    state.statusParams = value;
  },
  updateCheckedItems(state, value) {
    state.checkedItems = value;
  },
  updateCheckedItemsOrigin(state, value) {
    state.checkedItemsOrigin = value;
  },
  setOriginParams(state, value) {
    state.originParams = value;
  },
  updateCheckedCategories(state, value) {
    state.checkedCategories = value;
  },
  setCategoriesParams(state, value) {
    state.categoriesParams = value;
  },
  updateCheckedDepartments(state, value) {
    state.checkedDepartments = value;
  },
  setDepartmentsParams(state, value) {
    state.departmentsParams = value;
  },
  setDialogValue(state, value) {
    state.dialogValue = value;
  },
  setItemActionCaller(state, value) {
    state.itemActionCaller = value;
  },
  setLenguage(state, value) {
    state.lenguage = value;
    i18n.locale = value
  },
  setKeepMeLoggedIn(state, value) {
    localStorage.setItem('keepMeLoggedIn', value)
    state.keepMeLoggedIn = value;
  },
  setMobile(state, value) {
    state.mobile = value;
  },
  setLoadingUsers(state, value) {
    state.loadingUsers = value;
  },
  setUsers(state, value) {
    state.users = value;
    state.loadingUsers = false;
    state.error = false
  },
  setJobsExecutionData(state, value) {
    state.jobsExecutionData = value;
    state.loadingJobsExecution = false;
    state.error = false
  },
  setUserRole(state, value) {
    state.userRole = value
  },
  setUserDeleted(state, value) {
    state.userDeleted = value
  },
  setNewUser(state, value) {
    state.newUser = value
  },
  setLoadingJobExecution(state, value) {
    state.loadingJobsExecution = value;
  },
  setLoadingLanguage(state, value) {
    state.loadingLanguage = value;
  },
  setSettings(state, value) {
    state.settings = value;
    state.lenguage = state.settings.language;
    i18n.locale = state.settings.language;
    localStorage.setItem('language', state.settings.language)
    state.loadingSettings = false;
    state.error = false
  },
  setDemo(state, value) {
    state.demo = value;
  },
  updateJob(state, jobStatusData){
    state.jobsExecutionData['components'][0]['data']['details'][jobStatusData.idx]['status'] = jobStatusData.status
  },
  updateAgencyJob(state, jobStatusData){
    state.agencyJobsExecutionData['components'][0]['data'][jobStatusData.idx][jobStatusData.data_source+'Status'] = jobStatusData.status
  },
  setLoadingSchema(state, value) {
    state.loadingSchema = value;
  },
  setFromIndex(state, value){
    state.formIndex = value
  },
  setLoadingEditSchema(state, value) {
    state.loadingEditSchema = value;
  },
  setEditModel(state, value){
    state.editModel = value
  },
  setCurrentDataSource(state, value){
    state.currentDataSource = value
  },
  updateEditModel(state,value){
    state.editModel = value
  },
  setClientType(state,value){
    state.clientType = value
  },
  setScriptDialog(state,value) {
    state.scriptDialog = value
  },
  setOverlayLoading(state, value) {
    state.overlayLoading = value
  },
  setCurrentScript(state,value){
    localStorage.setItem('currentScript', value)
    state.currentScript = value
  },
  setScriptString(state, value){
    state.scriptString = value
    state.loadingScriptBuilder = false
  },
  setLoadingScriptBuilder(state, value){
    state.loadingScriptBuilder = value
  },
  setScriptRegister(state, value){
    state.scriptRegister = value
    state.loadingScriptRegister = false
  },
  setLoadingScriptRegister(state, value){
    state.loadingScriptRegister = value
  },
  setAgencyClients(state, value){
    state.agencyClients = value
    state.loadingAgencyClients = false
  },
  setLoadingAgencyClients(state, value){
    state.loadingAgencyClients = value
  },
  setLoadingBudgetControl(state, value){
    state.loadingBudgetControlReport = value
  },
  setBudgetReport(state, value){
    state.budgetReport = value
    state.loadingBudgetControlReport = false
  },
  setBudgets(state, value){
    state.budgets = value
    state.loadingBudgetControl = false
  },
  setBudgetsControlClient(state, value){
    state.budgetsControlClient = value
    state.loadingBudgetControl = true
  },
  setAlertMessage(state, value){
    state.alertMessage = value
    state.showAlert = true 
    setTimeout(() => {
      state.showAlert = false
    }, 5000)
  },
  setSmallAlertMessage(state, value){
    state.alertMessage = value
    state.showAlertSmall = true 
    setTimeout(() => {
      state.showAlertSmall = false
    }, 5000)
  },
  setShowAlert(state, value){
    state.showAlert = value
  },
  setShowAlertSmall(state, value){
    state.showAlertSmall = value
  },
  setAlertType(state, value){
    state.alertType = value
  },
  setAgencyIntegrations(state, value){
    state.agencyJobsExecutionData = value
  },
  setLoadingAgencyIntegrations(state, value){
    state.loadingAgencyIntegrations = value
  },
  setCurrentAgencyClient(state, value){
    state.currentAgencyClient = value
  },
  setScriptExecutions(state, value){
    state.scriptExecutions = value
  },
  setLoadingScriptExecutions(state, value) {
    state.loadingScriptExecutions = value
  },
  updateScriptExecutionOffset(state, value) {
    state.scriptExecutionOffset = value
  }
}