<template>
  <v-card
    color="#FFFFFF"
    class="card"
  >
    <v-row no-gutters class="items-details">
      <v-col md="6" cols="12" style="height:20px">
        <span class="table-title">
          {{$t('Products.ItemsDetails.title')}}
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
              {{$t('Products.ItemsDetails.description')}}
            <data-sources 
              :sources="['vtex', 'googleAnalytics']" />
          </v-tooltip>
        </span>
      </v-col> 

      <v-col md="5" cols="12" class="right-content">
      </v-col>

      <v-col md="1" cols="12" class="right-content">
        <v-btn
          tile
          small
          color="success"
          @click="save()"
        >
          <v-icon style="font-size: 20px;">
            mdi-microsoft-excel
          </v-icon>
        </v-btn>
      </v-col>

      <v-col md="12" cols="12" style="margin-top: 16px">
        <wj-flex-grid 
          :selectionMode="'ListBox'"
          :initialized="initializeFlexSheet"
          :itemsSource="this.data" 
          :headersVisibility="'Column'"
           allowPinning="SingleColumn"
          :isReadOnly="true"
          style="max-height: 1090px"
          :format-item="formatAmount"
          :showMarquee="true"
          :alternatingRowStep="1"
        >
            <wj-flex-grid-filter />
            <wj-flex-grid-column :header="'Imagen'" :binding="'imageUrl'" :cellTemplate="tplImage" cssClass="cell-img" />
            <wj-flex-grid-column :header="'Item'" :binding="'itemName'"   :isReadOnly=true />
            <wj-flex-grid-column :header="'Item ID'" :binding="'itemId'"    :isReadOnly=true />
            <wj-flex-grid-column :header="'Producto'" :binding="'productName'"   :isReadOnly=true />
            <wj-flex-grid-column :header="'Product ID'" :binding="'productId'"    :isReadOnly=true />
            <wj-flex-grid-column :header="'Estado'" :binding="'activeString'"  format="n0"  />
            <wj-flex-grid-column :header="'Ventas'" :binding="'sales'" aggregate="Sum"   format="n0" :isReadOnly=true :cellTemplate="tplSimpleSalesButton" />            
            <wj-flex-grid-column :header="'Stock'" :binding="'stock'" aggregate="Sum"  format="n0" :isReadOnly=true :cellTemplate="tplSimpleStockButton" ref="btnClickClick" />
            <wj-flex-grid-column :header="'Precio'" :binding="'price'" aggregate="Avg"  format="n2" :isReadOnly=true :cellTemplate="tplSimplePriceButton" />
            <wj-flex-grid-column :header="'Revenue'" :binding="'revenue'" aggregate="Sum"  format="n0" :isReadOnly=true />
            <wj-flex-grid-column :header="'Visitas'" :binding="'views'" aggregate="Sum"  format="n0" :isReadOnly=true />
        </wj-flex-grid>
      </v-col>
      <v-row v-if="!this.mobile" no-gutters style="margin-right:1.25%">
        <v-col md="9" cols="12" class="right-content" >
          <flex-grid-page-size-selector 
          style="margin-right:-70px;font-size:13px"
            @change="onChangeChild"
            :currentPageSize="this.currentPageSize"
            :pageSizeSelect="this.pageSizeSelect"
          />
        </v-col>
        <v-col md="3" cols="12" >
          <div v-on:click="updateViewPager($event)" class="update-view-pager">
              <button id="btnFirst" class="btn"><span class="wj-glyph-step-backward" style="position: relative;left: -10px; top: 1px" ></span></button>
              <button id="btnPrev" class="btn"><span class="wj-glyph-left" style="position: relative;left: -3px; " ></span></button>
              &nbsp;&nbsp;&nbsp;{{ $t('FlexGridPageSizeSelector.page')}} {{ this.pageIndex + 1 | number:0 }} {{ $t('FlexGridPageSizeSelector.of')}} {{ this.pageCount | number:0 }}&nbsp;&nbsp;&nbsp;
              <button id="btnNext" class="btn"><span class="wj-glyph-right" style="position: relative;right: -3px; "></span></button>
              <button id="btnLast" class="btn"><span class="wj-glyph-step-forward" style="position: relative;right: -10px; top: 1px"></span></button>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="this.mobile"  style="margin-right:1.25%;">
        <v-col cols="12" class="mobile-selector"    >
          <div v-on:click="updateViewPager($event)" class="update-view-pager">
              <button id="btnFirst" class="btn"><span class="wj-glyph-step-backward" style="position: relative;left: -10px; top: 1px" ></span></button>
              <button id="btnPrev" class="btn"><span class="wj-glyph-left" style="position: relative;left: -3px; " ></span></button>
              &nbsp;&nbsp;&nbsp;Página {{ this.pageIndex + 1 | number:0 }} de {{ this.pageCount | number:0 }}&nbsp;&nbsp;&nbsp;
              <button id="btnNext" class="btn"><span class="wj-glyph-right" style="position: relative;right: -3px; "></span></button>
              <button id="btnLast" class="btn"><span class="wj-glyph-step-forward" style="position: relative;right: -10px; top: 1px"></span></button>
          </div>
        </v-col>
        <v-col cols="12" class="mobile-selector " >
          <flex-grid-page-size-selector 
            @change="onChangeChild"
            :currentPageSize="this.currentPageSize"
            :pageSizeSelect="this.pageSizeSelect"
          />
        </v-col>
      </v-row>

    </v-row>
  </v-card>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import "@grapecity/wijmo.vue2.grid";
import '@grapecity/wijmo.vue2.grid.filter';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcGrid from '@grapecity/wijmo.grid';
import DataSources from '../shared/DataSources.vue';
import * as wijmo from '@grapecity/wijmo';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { mapMutations, mapActions, mapState } from 'vuex'
import FlexGridHelper from "@/helpers/flexGrid.helper";
import FlexGridPageSizeSelector from '../shared/FlexGridPageSizeSelector.vue';

export default {
  name: "ItemsDetails",

  components: {
    DataSources,
    FlexGridPageSizeSelector,
  },
  props: [
    'itemsDetailsData'
  ],
  data: function() {
    return {
      maxPageSize: '12',
      currentPageSize: '12',
      pageIndex: 0,
      pageCount: 1,
      tplImage: CellMaker.makeImage({
          label: 'image for ${item.productName}',
      }),
      btnClickClick: null,
      tplSimpleStockButton: CellMaker.makeButton({
          click: (e, ctx) => this.stockButtonHandler(ctx.item.itemId)
      }),
      tplSimplePriceButton: CellMaker.makeButton({
          click: (e, ctx) => this.pricesButtonHandler(ctx.item.itemId)
      }),
      tplSimpleSalesButton: CellMaker.makeButton({
          click: (e, ctx) => this.salesButtonHandler(ctx.item.itemId)
      }),
    }
  },
  mounted: function(){
      this.btnClickClick = this.$refs.btnClickClick;
  },
  computed: {
    ...mapState([
      "mobile"
    ]),
    data() {
      return new CollectionView(FlexGridHelper.fillData(this.itemsDetailsData, parseInt(this.currentPageSize)), {
              refreshOnEdit: true, // on-demand sorting and filtering
              pageSize: parseInt(this.currentPageSize)
            })
    },
    pageSizeSelect() {
      return FlexGridHelper.makeList(parseInt(this.maxPageSize))
    },
    color: function() {
      if (this.changeRate >= 0) {
        return 'green';
      }

      return 'red';
    },
    isPositive: function() {
      if (this.changeRate >= 0) {
        return true;
      }
      return false;
    },

  },
  methods: {
    ...mapMutations([
      "setCurrentItem",
      "setDialogValue",
      "setItemActionCaller"
    ]),
    ...mapActions([
      "getItemStock",
      "getItemPrices",
      "getItemSales",
    ]),
    onChangeChild(value) {
      this.currentPageSize = value
      this.updatePagesIndex()
    },
    updatePagesIndex() {
      this.pageIndex = this.data.pageIndex
      this.pageCount = this.data.pageCount
    },
    initializeFlexSheet: function(flex) {
      flex.columnFooters.rows.push(new wjcGrid.GroupRow());
      this.flex = flex;
    },
    save() {
      this.data.pageSize = 0;
      wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
          this.flex,
          {
              includeColumnHeaders: true,
              includeCellStyles: false,
              formatItem: null
          },
          'BestSellerProducts.xlsx'
      );
      this.data.pageSize = parseInt(this.currentPageSize);
    },
    updateViewPager(e) {
        let btn = wijmo.closest(e.target, 'button'),
            id = btn ? btn.id : '';
        //
        switch (id) {
            case 'btnFirst':
                this.data.moveToFirstPage();
                break;
            case 'btnPrev':
                this.data.moveToPreviousPage();
                break;
            case 'btnNext':
                this.data.moveToNextPage();
                break;
            case 'btnLast':
                this.data.moveToLastPage();
                break;
        }
        this.updatePagesIndex()
    },
    formatAmount: function (s, e) {
        // format cells in the "cells" panel only (not in headers)
        if (e.panel == s.cells) {

            // start with default color
            var color = '';

            // customize color based on amount
            if (s.columns[e.col].binding == 'activeString') {
                var isActive = s.rows[e.row].dataItem.isActive;
                if (isActive)
                  color = 'darkgreen'
                else
                  color = 'darkred'
            }
            e.cell.style.dataItem = 'activo'
            // always set the color, since cells are recycled
            e.cell.style.color = color;
        }
    },
    stockButtonHandler(item) {
      this.setDialogValue(false)
      this.setItemActionCaller('stock')
      this.setCurrentItem(item)
      this.setDialogValue(true)
      this.getItemStock()
    },
    salesButtonHandler(item) {
      this.setDialogValue(false)
      this.setItemActionCaller('sales')
      this.setCurrentItem(item)
      this.setDialogValue(true)
      this.getItemSales()
    },
    pricesButtonHandler(item) {
      this.setDialogValue(false)
      this.setItemActionCaller('price')
      this.setCurrentItem(item)
      this.setDialogValue(true)
      this.getItemPrices()
    },

},
created: function () {
  this.updatePagesIndex()
},

};
</script>

<style scoped lang="scss">
.items-details {

  .wj-flexchart {
    padding: 0px !important;
  }
}
.status-title {
  color: #6f6f6f;
  padding-left: 20px;
}
.right-content {
  margin: auto; 
  text-align: right; 
  padding-right: 20px !important;
  position:relative
}

.card {
  padding:24px;
  max-height: 1200px;
  }

.update-view-pager {
  align-items: center; 
  text-align: right; 
  padding-right: 1.25%
}


.change-up {
    color: darkgreen;
}
.change-up:before {
    content: '\25b2\00a0';
}
.change-down {
    color: darkred;
}
.change-down:before {
    content: '\25bc\00a0';
}

.positive {
  color: green;
}

.negative {
  color: red;
}

.button {

  .wj-cell-maker {
    background-color: blue!important;
  }
}

.mobile-selector {
  margin: auto; 
  text-align: right; 
  padding-right: 5px!important;
}
</style>