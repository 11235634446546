<template>
  <v-card
    :color="cardColor"
    class="raw-metric-card"
  >
    <v-card-title v-if="!isMobile" >
      <v-row no-gutters style="width:100%; ">

        <v-col md="10" style="paddgin" >
          <div class="card-title metric-title">
            {{ title }}
          </div>
        </v-col>

        <v-col md="2" class="tooltip-container">
        <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 style="margin-top: -20px">
                mdi-information-outline
              </v-icon>
            </template>
            <span class="tooltip">
              {{ description }}
            </span>
            <data-sources 
              :sources="this.sources"
            />
          </v-tooltip>
        </v-col>
        
      </v-row>
    </v-card-title>

    <v-card-title v-else >
      <v-row no-gutters style="width:100%; ">

          <v-col md="12" style="paddgin" >
            <div class="card-title metric-title">
              {{ title }}
            </div>
          </v-col>

        </v-row>
    </v-card-title>

    <v-card-text class="card-text">
      <span class="span-centered current-value" :style="valueStyle"> 
        {{ value }}
      </span>
    </v-card-text>

    <v-card-subtitle v-if="changeRate != null" style="padding-top: 0px">
      <span v-if="invertedChangeRate != null" :class="[{ positive: isNegative  }, { negative: isPositive }, { neutral: isNeutral }, 'span-centered', 'change-rate']"> 
        <v-icon size=18 style="margin-bottom: 2px; font-weight: bold;" :color="invertedColor">
          {{ iconType }}
        </v-icon>
        {{ changeRate }}%
      </span>
      <span v-else :class="[{ positive: isPositive }, { negative: isNegative }, { neutral: isNeutral }, 'span-centered', 'change-rate']"> 
        <v-icon size=18 style="margin-bottom: 2px; font-weight: bold;" :color="color">
          {{ iconType }}
        </v-icon>
        {{ changeRate }}%
      </span>
    </v-card-subtitle>

    <v-card-subtitle v-else style="padding-top: 0px">
      <span :class="[{ positive: isPositive }, { negative: isNegative }, { neutral: isNeutral }, 'span-centered', 'change-rate']"> 
      </span>
    </v-card-subtitle>
  </v-card>
</template>

<script>
import DataSources from './DataSources.vue';
import {mapState} from 'vuex'

export default {
  components: { DataSources },
  name: "RawMetricCard",
  props: {
    title: {
    },
    description: {
    },
    value: {
    },
    changeRate: {
    },
    cantChar: {
    },
    sources: {
    },
    valueColor: {
    },
    invertedChangeRate: {
    },
    cardColor: {
      default: '#FFFFFF'
    }
  },
  data: function() {
    return {

    };
  },
  computed: {
    ...mapState([
      "mobile",
      ]),
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    iconType: function() {
      if (this.changeRate >= 0) {
        if(this.changeRate == 0)
          return 'mdi-trending-neutral'
        return 'mdi-trending-up';
      }

      return 'mdi-trending-down';
    },
    color: function() {
      if (this.changeRate > 0) {
        return 'green';
      }
      else if (this.changeRate < 0) {
        return 'red';
      }
      else
        return 'rgba(2, 8, 15, 0.5)'
    },
    invertedColor: function() {
      if (this.changeRate < 0) {
        return 'green';
      }
      else if (this.changeRate > 0) {
        return 'red';
      }
      else
        return 'rgba(2, 8, 15, 0.5)'
    },
    isPositive: function() {
      if (this.changeRate > 0) {
        return true;
      }

      return false;
    },
    isNegative: function() {
      if (this.changeRate < 0) {
        return true;
      }

      return false;
    },
    isNeutral: function() {
      if (this.changeRate == 0) {
        return true;
      }

      return false;
    },
    valueStyle () {
      return 'color: ' + (this.valueColor != '' ? this.valueColor : 'black');
    }
  },
  methods: {
      },
};
</script>

<style scoped lang="scss">
.raw-metric-card {
  max-width: 500px;
}

.v-card__title {
  padding-bottom: 4px;
}

.v-card__text {
  padding-top: 0px;
  padding-bottom: 4px;
}

.card-title {
  margin: 0;
  word-break: keep-all;
}

.span-centered {
  display:table;
  margin: 0;
}

.tooltip-container {
  text-align: center;
}

.metric-title { 
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: rgba(2, 8, 15, 0.85);
  
}

.current-value {
  //styleName: XL Semibold;
  font-family: Open Sans, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(2, 8, 15, 0.85);

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
}

.change-rate {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.positive {
  color: green;
}

.negative {
  color: red;
}

.neutral {
  color: rgba(2, 8, 15, 0.5);
}
</style>