<template>

    <v-row no-gutters class="orders-status">
      <v-col md="6" cols="12" style="padding-right:12px">
      <v-card
        color="#FFFFFF"
        class="card"
      >
      <v-col md="12" cols="12" style="height:20px;position:relative; margin-top:-10px">
        <span class="graph-title" style="margin-left:-10px;">
        {{$t('Payments.Installments.title')}}
         <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
            {{$t('Payments.Installments.description')}}
            <data-sources 
              :sources="['vtex']"
            />
          </v-tooltip>
          <v-radio-group
                    v-model="bindingData"
                    row
                    class="selector"
                >
                    <v-radio
                    label="#"
                    value="quantity"
                    ></v-radio>
                    <v-radio
                    label="$"
                    value="revenue"
                    ></v-radio>
                </v-radio-group>
        </span>
        </v-col>
        
          <horizontal-bar-graph
          :dataSource= installmentsHorizontalBarsData
          bindingName="bindingX"
          :binding="bindingData"
          />

        
        
      </v-card>  
      </v-col> 

      <v-col md="6" cols="12" style="padding-left:12px">
      <v-card
        color="#FFFFFF"
        class="card"
      >
      <v-col md="12" cols="12" class="right-content" style="height:20px;text-align:right;padding-right:20px">
        <v-btn
          tile
          small
          color="success"
          @click="save()"
        >
          <v-icon style="font-size: 20px;">
            mdi-microsoft-excel
          </v-icon>
        </v-btn>
      </v-col>

      <v-col md="12" cols="12"  style="margin-top:40px" v-if="!this.mobile" >
        <wj-flex-grid 
          :initialized="initializeFlexSheet"
          :selectionMode="'ListBox'"
          :itemsSource="this.truncatedChannelsData" 
          :alternatingRowStep="1"
          style="max-height: 280px"
        >
            <wj-flex-grid-filter />
            <wj-flex-grid-column :header="'Cuotas'" :binding="'cuotas'" width="*" format="n0" :isReadOnly=true />
            <wj-flex-grid-column :header="'Cantidad%'" :binding="'cantidad%'" aggregate="Sum" width="*" format="n2" :isReadOnly=true />
            <wj-flex-grid-column :header="'Cantidad'" :binding="'cantidad'" aggregate="Sum" width="*" format="n0" :isReadOnly=true />
            <wj-flex-grid-column :header="'Revenue'" :binding="'revenue'" aggregate="Sum" width="*" format="n0" :isReadOnly=true />
        </wj-flex-grid>
        <div v-on:click="updateViewPager($event)" class="update-view-pager">
            <button id="btnFirst" class="btn"><span class="wj-glyph-step-backward" style="position: relative;left: -10px; top: 1px" ></span></button>
            <button id="btnPrev" class="btn"><span class="wj-glyph-left" style="position: relative;left: -3px; " ></span></button>
            &nbsp;&nbsp;&nbsp;Página {{ data.pageIndex + 1 | number:0 }} de {{ data.pageCount | number:0 }}&nbsp;&nbsp;&nbsp;
            <button id="btnNext" class="btn"><span class="wj-glyph-right" style="position: relative;right: -3px; "></span></button>
            <button id="btnLast" class="btn"><span class="wj-glyph-step-forward" style="position: relative;right: -10px; top: 1px"></span></button>
        </div>
      </v-col>
      </v-card>
      </v-col>       
    </v-row>
  
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcGrid from '@grapecity/wijmo.grid';
import DataFilterHelper from "@/helpers/dataFilter.helper";
import {mapMutations, mapState} from 'vuex'
import * as wijmo from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import DataSources from '../shared/DataSources.vue';
import HorizontalBarGraph from '../shared/HorizontalBarGraph.vue';
export default {
  name: "Installments",
  props: [
    'installmentsData',
    'installmentsHorizontalBarsData'
  ],
  components: {
    DataSources,
    HorizontalBarGraph
  },
  data: function() {
    return {
      data: new CollectionView(DataFilterHelper.getTruncatedData(this.installmentsData), {
              refreshOnEdit: false, // on-demand sorting and filtering
              pageSize: 7
            }),
      description: '',   
      pieHeader: '2018 Product Sales',
      bindingData: 'quantity'
    }
  },
  computed: {
    ...mapState([
    "installmentPageVisualization",
    "mobile"
    ]),
    truncatedChannelsData() {
      return DataFilterHelper.getTruncatedData(this.installmentsData)
    },
    groups() {
      return this.getGroups()
    },
  },
  methods: {
    ...mapMutations([
    "updateInstallmentPageVisualization",
    ]),
    initializeFlexSheet: function(flex) {
      flex.columnFooters.rows.push(new wjcGrid.GroupRow());
      this.flex = flex;
    },
    save() {
      wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
          this.flex,
          {
              includeColumnHeaders: true,
              includeCellStyles: false,
              formatItem: null
          },
          'Cuotas.xlsx'
      );
    },
    updateViewPager(e) {
        let btn = wijmo.closest(e.target, 'button'),
            id = btn ? btn.id : '';
        //
        switch (id) {
            case 'btnFirst':
                this.data.moveToFirstPage();
                break;
            case 'btnPrev':
                this.data.moveToPreviousPage();
                break;
            case 'btnNext':
                this.data.moveToNextPage();
                break;
            case 'btnLast':
                this.data.moveToLastPage();
                break;
        }
    },
},
    getGroups(){
      let keys = Object.keys(this.barsGraphData.quantityDataByDate[0])
      keys = keys.filter(function(key) {
        return key !== 'date'; 
      });
      let result = []
      for (let i=0; i<=keys.length-1; i++){
        let dicc = {
          grupoName: keys[i],
          grupoId: keys[i]
        }
        result.push(dicc)
      }
      return result;
    },
};
</script>

<style scoped lang="scss">
.Installments {
  margin-top: 0px!important;
  .wj-flexchart {
    padding: 0px !important;
  }
}
.card {
  max-height: 420px;
  padding:24px;
}
.update-view-pager {
  align-items: center; 
  text-align: right; 
  padding-right: 2.5%
}

.selector {
  display: inline-block; 
  margin: 0px; 
  padding-left: 5px; 
  height: 30px; 
  position:absolute; 
  right: 0;
  bottom: -15px;
}
</style>