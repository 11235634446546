import { render, staticRenderFns } from "./ComponentContainer.vue?vue&type=template&id=2faafd06&scoped=true&"
import script from "./ComponentContainer.vue?vue&type=script&lang=js&"
export * from "./ComponentContainer.vue?vue&type=script&lang=js&"
import style0 from "./ComponentContainer.vue?vue&type=style&index=0&id=2faafd06&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2faafd06",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardTitle,VCol,VIcon,VRow,VTooltip})
