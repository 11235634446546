<template>

  <v-progress-linear 
      v-if="isLoading"
      indeterminate
      color="cyan"
  ></v-progress-linear>

  <v-container v-else class="main-container">
  <div v-if="!this.unavaibleIntegration">
    <div class="page-title"> Stock </div>

    <error v-if="error" style="margin-top: 10px"
      errorMessage="ERROR: lo sentimos, vuelva a intentarlo." 
    />

    <v-row justify-content="flex-end">
      <stock-day-filter
      />
    </v-row>

    <v-row justify-content="flex-end" style="margin-top:0">
      <categories-filter v-if="!this.mobile"
      />
    </v-row>

    <v-row align="center" justify="space-between" style="margin-top: 32px">
      <v-col md="2" cols="6">
        <raw-metric-card
          :title = "$t('Stock.stockCards.card-1.title')"
          :description= "$t('Stock.stockCards.card-1.description')"
          :value= this.thousandSeparatorCall(getStockCardsData[2].value.toFixed(2))
          :changeRate="null"
          :sources="['vtex']"          
          cardColor="#5bdfc4"
        />
      </v-col>
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Stock.stockCards.card-2.title')"
          :description= "$t('Stock.stockCards.card-2.description')"
          :value= this.thousandSeparatorCall(getStockCardsData[3].value)
          :changeRate="null"
          :sources="['vtex']"
          cardColor="#faa6ba"
        />
      </v-col>
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Stock.stockCards.card-3.title')"
          :description= "$t('Stock.stockCards.card-3.description')"
          :value= this.thousandSeparatorCall(getStockCardsData[0].value.toFixed(2))
          :changeRate="null"
          :sources="['vtex']"
          cardColor="#25dcf77a"
        />
      </v-col>
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Stock.stockCards.card-4.title')"
          :description= "$t('Stock.stockCards.card-4.description')"
          :value= this.thousandSeparatorCall(getStockCardsData[1].value.toFixed(2))
          :changeRate="null"
          :sources="['vtex']"
          cardColor="#25dcf77a"
        />
      </v-col>
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Stock.stockCards.card-5.title')"
          :description= "$t('Stock.stockCards.card-5.description')"
          :value= this.thousandSeparatorCall(getStockCardsData[4].value.toFixed(0))
          :changeRate="null"
          :sources="['vtex']"
          cardColor="#faa6ba"
        />
      </v-col>
      <v-col md="2" cols="6">
        <raw-metric-card
          :title = "$t('Stock.stockCards.card-6.title')"
          :description= "$t('Stock.stockCards.card-6.description')"
          :value= this.thousandSeparatorCall(getStockCardsData[5].value.toFixed(0))
          :changeRate="null"
          :sources="['vtex']"          
          cardColor="#5bdfc4"
        />
      </v-col>
    </v-row>

    <v-row v-if="!this.mobile" no-gutters class="margin-between-components pa-0 " >
      <v-col md="6" cols="12"  style="padding-right:15px!important;"> 
        <graph-area-stock 
          :stockEvolutionData= "getCategoriesEvolutionData"
        />
      </v-col>      
      <v-col md="6" cols="12"  style="padding-left:15px!important"> 
        <warehouse-bar-graph
          :dataSource="getWarehouseGraphData"
          bindingName="warehouseName"
          binding="stockQuantity"
          bindingLabel="cantidad"
          :sources="['vtex']"
        />
      </v-col>   
    </v-row>

    <v-row v-if="this.mobile" no-gutters class="margin-between-components pa-0 " >
      <v-col md="6" cols="12"  > 
        <graph-area-stock 
          :stockEvolutionData= "getCategoriesEvolutionData"
        />
      </v-col>      
      <v-col md="6" cols="12"  style="margin-top:24px" > 
        <warehouse-bar-graph
          :dataSource="getWarehouseGraphData"
          bindingName="warehouseName"
          binding="stockQuantity"
          bindingLabel="cantidad"
          :sources="['vtex']"
        />
      </v-col>   
    </v-row>

    <v-row no-gutters v-if="!this.mobile">
      <v-col class="margin-between-components pa-0 ">
        <category-stock
          :categoriesStockData= "getCategoriesData"
        />
      </v-col>      
    </v-row>

    <v-row no-gutters>
      <v-col class="margin-between-components pa-0 ">
        <product-stock 
          :productsStockData= "getProductsData"
        />
      </v-col>      
    </v-row>
    
    <v-row no-gutters>
      <v-col class="margin-between-components pa-0 ">
        <item-stock 
          :itemsStockData= "getItemsData"
        />
      </v-col>      
    </v-row>

    <v-row v-if="!this.mobile" no-gutters class=" margin-between-components pa-0 " style="margin-bottom:128px;">
      <v-col md="6" cols="12" style="padding-right:15px!important" > 
        <stock-deposit
          :stockDepositData= "getStockDepositData" 
        />
      </v-col>      
      <v-col md="6" cols="12" style="padding-left:15px!important"> 
        <broked-items 
          :brokedItemsData= "getBrokedItemsData"
        />
      </v-col>   
    </v-row>

    <v-row v-if="this.mobile" no-gutters class=" margin-between-components pa-0 " style="margin-bottom:128px;">
      <v-col md="6" cols="12"  > 
        <stock-deposit
          :stockDepositData= "getStockDepositData" 
        />
      </v-col>      
      <v-col md="6" cols="12" style="margin-top:24px"> 
        <broked-items 
          :brokedItemsData= "getBrokedItemsData"
        />
      </v-col>   
    </v-row>
  </div>
  <div  v-else>
    <v-card style="width:600px">
      <h2 class="app-font" style="padding:12px"> {{ $t('integrations.unavaible') }}</h2>
    </v-card>
  </div>
  </v-container>
  
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import GraphAreaStock from '../graphs/GraphAreaStock.vue';
import CategoryStock from '../stock/CategoryStock.vue';
import ProductStock from '../stock/ProductStock.vue';
import ItemStock from '../stock/ItemStock.vue';
import BrokedItems from '../stock/BrokedItems.vue';
import StockDayFilter from '../stock/StockDayFilter.vue';
import CategoriesFilter from '../shared/CategoriesFilter.vue';
import Error from '../errors/error.vue';
import StockDeposit from '../stock/stockDeposit.vue';
import ThousandSeparator from "@/helpers/thousandSeparator.helper";
import RawMetricCard from '@/components/shared/RawMetricCard.vue';
import WarehouseBarGraph from '../shared/WarehouseBarGraph.vue';

export default {
  name: "Stock",
  computed:{
    ...mapState([
    "stocksPageData",
    "loadingStocksPageData",
    "error",
    "mobile",
    "demo"
    ]),
    isLoading() {
      if ( this.stocksPageData != null && !this.loadingStocksPageData){
        return false
      }
      return true
    },
    getCategoriesData () {
      return this.stocksPageData["components"][0]["data"];
    },
    getProductsData () {
      return this.stocksPageData["components"][1]["data"];
    },
    getItemsData () {
      return this.stocksPageData["components"][2]["data"];
    },
    getBrokedItemsData () {
      return this.stocksPageData["components"][3]["items"];
    },
    getCategoriesEvolutionData () {
      return this.stocksPageData["components"][4]["data"];
    },
    getStockDepositData () {
      return this.stocksPageData["components"][5]["data"];
    },
    getStockCardsData () {
      return this.stocksPageData["components"][6]["cards"];
    },
    getWarehouseGraphData () {
      return this.stocksPageData["components"][7]["data"];
    },
    unavaibleIntegration(){
      if (this.stocksPageData == 'integration unavaible')
        return true
      else
        return false
    }
  },
  components: {
    GraphAreaStock,
    CategoryStock,
    ProductStock,
    ItemStock,
    BrokedItems,
    StockDayFilter,
    CategoriesFilter,
    Error,
    StockDeposit,
    RawMetricCard,
    WarehouseBarGraph,
  },
  data: () => ({
    componentName: "Stock"
    
  }),
  methods: {
    ...mapActions([
      "getStocksPageData",
      "getFilters",      
    ]),
    thousandSeparatorCall(value) {
      return ThousandSeparator.formatNumber(value)
    },
    ...mapMutations([
      "setMobile",  
      "setStocksPageData" 
    ]),
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.setMobile(true)
        return true
      } else {
        return false
      }
    },
  },
  created: function () {
    this.isMobile();
    if(!this.demo){
      this.getFilters();
      this.getStocksPageData();
      }
    else{
      this.getFilters();
      var data = {};
      data = require('../../json/stock.json');
      this.setStocksPageData(data);
    }
  },
};
</script>

<style scoped lang="scss">
.main-container {
  max-width: 100%;
  padding: 12px 30px 12px 30px;
}

.margin-between-components {
  margin-top:24px;
} 

</style>