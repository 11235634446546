<template>
    <div style="width:800px;text-align:left;margin:auto" class="app-font guide-font">
        <v-card style="padding:12px">
            <h3 class="app-font " style="margin-bottom:10px">Configuraciones para Vtex</h3>
            <ul>
                <li class="app-font guide-font" ><a href="https://www.google.com/url?q=https://developers.vtex.com/vtex-rest-api/docs/getting-started-authentication&sa=D&source=docs&ust=1648232368642403&usg=AOvVaw1XDHmm0BO359PHQ98-X0Ym" target="_BLANK" > Guia de referencia</a></li>  
                <ul> 
                    <li class="app-font guide-font" >Crear appKey y appToken</li>
                    <li class="app-font guide-font" > Asignar rol de owner, debe quedar como se ve en la imagen a continuación: </li>
                </ul>
            </ul>
            <br>
            <img :src="require('@/assets/vtex-guide.png')" style="height:300px;margin-bottom:10px">
        </v-card>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.guide-font{
    font-style: italic;
}
</style>