import axios from "axios";

const $axios = axios.create({
  baseURL: "https://heroics-api-c2fcstqbca-uc.a.run.app/api",
  withCredentials: true
});

export default $axios;

//Production -->  baseURL: "https://heroics-api-c2fcstqbca-uc.a.run.app/api",
//Local -->  baseURL: "http://localhost:8080/api",
