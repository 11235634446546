<template>
  <div class="app-font " style="  display: inherit; align-center">
    <div class="toolbar-time-range align-center">
    <v-icon style="margin-right: 5px;">
      mdi-clock-time-four-outline
    </v-icon>

    <div class="d-flex align-center">
      <div v-if="!this.mobile" class="mx-1 label-lowercase app-font">{{ $t('DayFilter.title')}}</div>
      <date-selector 
        :date="fromDate"
        :maxDate="maxDate"
        @updateDate="setFromDate"
      />
    </div>
    </div>
  <v-btn 
      class="app-font"
      style="margin-left:10px;margin-top:32px;height:40px"
      @click="updatePageData"
      small
      color="primary"
      dark
      :elevation="0"
    >
      <b>{{ $t('DayFilter.button')}}</b>
  </v-btn>
</div>


</template>

<script>
import moment from 'moment'
import { mapMutations, mapState , mapActions} from "vuex";
import DateSelector from "@/components/shared/DateSelector";
import DateHelper from "@/helpers/date.helper";

export default {
  name: "DayFilter",
  props: [
  ],
  components: {
    DateSelector
  },
  data: function () {
      return {
        
      }
  },
  computed: {
    ...mapState([
        "timeRange",
        "orderStatusFilter",
        "dailySummaryDate",
        "dailySummaryMaxDate",
        "loadingDailySummaryData",
        "statusParams",
        "originParams",
        "mobile",
        "demo"
        ]),
    fromDate() {
      return this.timeRange.dailySummaryDate;
    },
    maxDate() {
      return this.timeRange.dailySummaryMaxDate;
    },
  },
  methods: {
    ...mapMutations([
      "setDailySummaryDate",
      "setOrderStatusFilter",
      "setDailySummaryDateFormated",
      "setDailySummaryPageData"
    ]),
    ...mapActions([
      "getDailySummaryPageData",  
      "setDailySummaryQueryParams",
      "getFilters" 
    ]),

    initCalendar: function(calendar) {
        this.theCalendar = calendar;
    },
    setFromDate(date) {
      this.setDailySummaryDate(DateHelper.startOfDay(date));
      this.setDailySummaryDateFormated(moment(DateHelper.startOfDay(date)).format('YYYY-MM-DD'))

    },
    updatePageData() {
      if(!this.demo){
        this.setDailySummaryQueryParams();
        this.getDailySummaryPageData();
        }
      else{
        var data = {};
        data = require('../../json/dailySummary.json');
        this.setDailySummaryPageData(data);
      }
    },
  },

};
</script>

<style lang="scss">


</style>
