<template>
  <v-progress-linear 
      v-if="isLoading"
      indeterminate
      color="cyan"
  ></v-progress-linear>

  <v-container v-else class="main-container">
    <overlay-loader />

    <div class="page-title"> Control de presupuesto </div>
  
    <custom-alerts />

    <v-col
      class="d-flex"
      cols="12"
      sm="6"
      style="padding-left: 0px;" 
    >
      <v-select
        v-model="clientState"
        :items="clients"
        outlined
        dense
        label="Seleccionar cliente"       
      ></v-select>
    </v-col>

    <budget-report       
      :budgetReport="getBudgetReportData"
    />

    <budget-table 
      class="margin-between-components" 
      :budgets="getBudgetsData"
    />
    
  </v-container>
</template>

<script>
import BudgetTable from '../agency-budget-control/BudgetTable.vue'
import BudgetReport from '../agency-budget-control/BudgetReport.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import OverlayLoader from '../google-ads-scripting/OverlayLoader.vue'
import CustomAlerts from '../shared/CustomAlerts.vue'

export default {
  components: {
    BudgetTable,
    OverlayLoader,
    CustomAlerts,
    BudgetReport
  },
  data: () => ({
    clients: [
      'CLIENTE_1', 
      'CLIENTE_2', 
      'CLIENTE_3', 
      'CLIENTE_4',
      'CLIENTE_5',
    ],
  }),
  computed: {
    ...mapState([
      "loadingBudgetControl",
      "loadingBudgetControlReport",
      'budgetReport',
      'budgets',
      'showAlert',
      'successAlertMessage',
      'budgetsControlClient'
    ]),
    getBudgetReportData(){
      return this.budgetReport
    },
    getBudgetsData(){
      return this.budgets
    },
    isLoading(){      
      return (this.loadingBudgetControl || this.loadingBudgetControlReport)
    },
    clientState: {
      get(){
        return this.budgetsControlClient
      },
      set(val) {
        this.setBudgetsControlClient(val)
        this.getBudgetControlReport()
        this.getBudgets()
      }
    } 
  },
  methods: {
    ...mapActions([
      "getBudgetControlReport",
      "getBudgets"
    ]),
    ...mapMutations([
      "setBudgetsControlClient",
    ])
  },
  created() {
    this.getBudgetControlReport()
    this.getBudgets()
  }
}
</script>

<style>
.main-container {
  max-width: 100%;
  padding: 12px 30px 12px 30px;
}

 .margin-between-components {
   margin-top:24px;
 } 
</style>