<template>
    <v-row justify="space-around" >
  
      <v-col cols="auto">
        <v-dialog 
          @click:outside="dialogClose()"
          transition="dialog-top-transition"
          max-width="850"
          max-height="400"
          :value="this.dialogValue"
        >
          <template >

          </template>
          <template v-slot:default="dialog">
            <v-progress-linear 
                    v-if="isLoading"
                    indeterminate
                    color="cyan"
            ></v-progress-linear>
            <v-card v-else>
              <v-toolbar v-if="isItemPerformanceHandler"
                color="primary"
                dark
              >
              {{ procesedData[0].itemName }}
              </v-toolbar>
              <v-toolbar v-else
                color="primary"
                dark
              >
              {{ currentItemName }}
              </v-toolbar>
                <v-col  style="padding: 12px; height:300px"  >
            <wj-flex-chart v-if="isItemPerformanceHandler"
            style="height: 100%"
            :itemsSource="procesedData"
             bindingX="date" 
             chartType="Line"
            :legendToggle="true"
            :palette="['rgba(105,41,196,1)', 'rgba(17,146,232,1)', 'rgba(0,93,93,1)', 'rgba(159,24,83,1)', 'rgba(250,77,86,1)', 'rgba(87,4,8,1)']"

            >
                <wj-flex-chart-legend position="Bottom"></wj-flex-chart-legend>
                <wj-flex-chart-axis wjProperty="axisY"></wj-flex-chart-axis>
                <wj-flex-chart-series binding="value" name="Value"></wj-flex-chart-series>
                <wj-flex-chart-animation animationMode="Point"></wj-flex-chart-animation>
            </wj-flex-chart>
            <grid-without-button v-else style="height:310px;padding:16px"
            />
                </v-col>
              <v-card-actions class="justify-end">
                <v-btn @click="dialogClose();dialog.value = false;"
                  text
                
                >{{$t('ItemsDialog.button')}} </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
</template>


<script>
import '@grapecity/wijmo.styles/wijmo.css';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.chart.animation'
import '@grapecity/wijmo.styles/wijmo.css';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.chart.animation'
import DataFilterHelper from "@/helpers/dataFilter.helper";
import { mapActions, mapMutations, mapState } from 'vuex';
import markerContentHelper from "@/helpers/markerContent.helper";
import GridWithoutButton from '../products/GridWithoutButton.vue';

export default {
  components: {GridWithoutButton  },

  name: "ItemsDialog",
  props: [
  'activateDialog',
  'itemName'
  ],
  data: function() {
    return {
      lmVisible: false,
      showMarker: false,
      dialogValue2: false,
    }
  },
  computed: {
    procesedData() {
      return DataFilterHelper.dateYearFilter(this.getItemsStockDataCommputed)
    },
    ...mapState([
      "loadingItemData",
      "itemData",
      "dialogValue",
      "currentItem",
      "itemActionCaller",
      "currentItemName"
    ]),
    getItemsStockDataCommputed () {
      return this.itemData['components'][0]['data']
    },
    isLoading() {
      if ( this.itemData  != null && !this.loadingItemData){
        return false
      }
      return true
    },
    isItemPerformanceHandler() {
      if ( this.itemActionCaller  == 'crossSelling'){
        return false
      }
      return true
    },
  },
  methods: {
    ...mapMutations([
      "setCurrentItem",
      "setDialogValue"
    ]),
    ...mapActions([
      "getItemStock",
      "getItemPrices",
      "getItemSales",
    ]),
    onChartInitialized(sender) {
          sender.hostElement.addEventListener('mouseenter', () => this.showMarker = true);
          sender.hostElement.addEventListener('mouseleave', () => this.showMarker = false);
    },
    getMarkerContent(ht) {
        return markerContentHelper.markerContent(ht)
    },
    dialogClose(){
      this.setDialogValue(false)
    }

  },


}
</script>

<style lang="scss" scoped>



</style>