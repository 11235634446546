<template>
    <div style="width:500px;text-align:left;margin:auto" class="app-font guide-font">
        <v-card style="padding:12px">
            <h3 class="app-font " style="margin-bottom:10px">Configuraciones para Google Analytics</h3>
            <ul>
                <li class="app-font guide-font" >Acceso a la cuenta administradora a <b>team@bullmetrix.com</b></li>  
                <ul> 
                    <li class="app-font guide-font" >Permisos de lectura, escritura, editar, colaborar y <b>administrar usuarios</b></li>
                </ul>
                <li class="app-font guide-font" >Acceso a la cuenta de servicio analytix@analytix-313619.iam.gserviceaccount.com</li>  
                <ul> 
                    <li class="app-font guide-font"  >Permisos de lectura y analizar</li>
                </ul>
                <br >
                <li style="margin-bottom:10px"><a href="https://drive.google.com/file/d/1itJ9CmhkO8SjI6GwoJXcNQazTU6A6zgN/view" target="_BLANK" > Video </a>de referencia</li>
            </ul>
        </v-card>
  </div>
  
</template>

<script>
export default {

}
</script>

<style>
.guide-font{
    font-style: italic;
}
</style>