<template>
  <div class="app-font " style="  display: inherit; align-center;margin-top: 5px">
    <div class="toolbar-time-range align-center">
    <v-icon style="margin-right: 5px;">
      mdi-list-status
    </v-icon>
    {{ $t('OrderStatusFilter.stateTitle')}}
    <v-select
        v-model="checkedItems"
        :items="this.statusParams"
        item-text="label"  
        item-value="id"      
        :label="$t('OrderStatusFilter.select')"
        multiple
        solo
        flat
        dense
        background-color="transparent"
        hide-details
        class="time-range-select ml-1 capitalized-text app-font"
        :menu-props="['offset-y']"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0">
            <span>{{ item.label }}</span>
          </v-chip>
          <span
            v-if="index === 1"
            class="grey--text text-caption app-font"
          >
            ({{ $t('OrderStatusFilter.others')}} {{ checkedItems.length - 1 }})
          </span>
        </template>
      </v-select>

    </div>

    
    <div class="toolbar-time-range align-center" style="margin-left:10px">
    <v-icon style="margin-right: 5px;">
      mdi-shopping
    </v-icon>
    {{ $t('OrderStatusFilter.originTitle')}}
    <v-select
        v-model="checkedItemsOrigin"
        :items="this.originParams"
        item-text="label"  
        item-value="id"      
        :label="$t('OrderStatusFilter.select')"
        multiple
        solo
        flat
        dense
        background-color="transparent"
        hide-details
        class="time-range-select ml-1 capitalized-text"
        :menu-props="['offset-y']"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0">
            <span>{{ item.label }}</span>
          </v-chip>
          <span
            v-if="index === 1"
            class="grey--text text-caption"
          >
            (+{{$t('OrderStatusFilter.others')}} {{ checkedItemsOrigin.length - 1 }})
          </span>
        </template>
      </v-select>
  </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "OrderStatusFilter",
  props: [
    'orderStatusData'
  ],
  components: {
  },
    data() {
    return {
        }
  },
  computed: {
    ...mapState([
        "orderStatusFilter",
        "statusParams",
        "originParams",
        ]),
    checkedItems: {
      get () {
        return this.$store.state.checkedItems
      },
      set (value) {
        this.$store.commit('updateCheckedItems', value)
      }
    },
    checkedItemsOrigin: {
      get () {
        return this.$store.state.checkedItemsOrigin
      },
      set (value) {
        this.$store.commit('updateCheckedItemsOrigin', value)
      }
    },
  },
  methods: {

  },
};
</script>

<style lang="scss">

</style>