<template>
  <v-progress-linear 
      v-if="isLoading"
      indeterminate
      color="cyan"
  ></v-progress-linear>

  <v-container v-else class="main-container">
  <div v-if="!this.unavaibleIntegration">
    <div class="page-title"> {{ $t('PaidTraffic.PageTitle')}} </div>

    <error v-if="error" style="margin-top: 10px"
      :errorMessage=" $t('errorMessage')"
    />

    <v-row justify-content="flex-end">
      <time-range-filter-analytics
      :componente= "componentName" />
    </v-row>

    <v-row >
      <v-col class="pa-0" style="margin-top:24px" >
        <big-cards
          :cardsData="getBigCardsData"
          :infoCardsData="getInfoCardsData"
        />
      </v-col>      
    </v-row>
    
    <v-row v-if="!this.mobile">
      <v-col class="pa-0" style=" margin-top: 12px">
        <ads-investments
        :adsData= "getAdsData"
        />
      </v-col>      
    </v-row>

    <v-row v-if="!this.mobile" >
      <v-col class="margin-between-components pa-0 ">
        <metrics-by-campaign
        :campaignData= "getCampaignData"
        />
      </v-col>      
    </v-row>

    <v-row >
      <v-col class="margin-between-components pa-0 ">
        <SessionsVSRevenue
        :sessionsVsRevenueData= "getSessionsVsRevenue"
        />
      </v-col>      
    </v-row>

    <v-row >
      <v-col class="margin-between-components pa-0 " >
        <ConversionRateVSCostOverRevenue
        :crVsCorData= "getCrVsCor"
        />
      </v-col>      
    </v-row>

    <v-row  v-if="!this.mobile" >
      <v-col class="margin-between-components pa-0 " style="margin-bottom:128px;" >
        <campaign-ranking
        :campaignRankingData= "getCampaignRankingData"
        />
      </v-col>      
    </v-row>    

    <v-row  v-if="this.mobile" >
      <v-col  style="margin-bottom:128px;" >
      </v-col>      
    </v-row>   
  </div>
  <div  v-else>
    <v-card style="width:600px">
      <h2 class="app-font" style="padding:12px"> {{ $t('integrations.unavaible') }}</h2>
    </v-card>
  </div>
  </v-container>
</template>

<script>
import AdsInvestments from '../paid-traffic/AdsInvestments.vue';
import MetricsByCampaign from '../paid-traffic/MetricsByCampaign.vue';
import SessionsVSRevenue from '../paid-traffic/SessionsVSRevenue.vue';
import ConversionRateVSCostOverRevenue from '../paid-traffic/ConversionRateVSCostOverRevenue.vue';
import { mapActions , mapMutations, mapState} from 'vuex';
import Error from '../errors/error.vue';
import CampaignRanking from '../paid-traffic/CampaignRanking.vue';
import TimeRangeFilterAnalytics from '../shared/TimeRangeFilterAnalytics.vue';
import BigCards from '../paid-traffic/BigCards.vue';


export default {
  name: "PaidTraffic",
  components: {
    AdsInvestments,
    MetricsByCampaign,
    SessionsVSRevenue,
    ConversionRateVSCostOverRevenue,
    Error,
    CampaignRanking,
    TimeRangeFilterAnalytics,
    BigCards,
    
  },
  data: () => ({
    componentName: "PaidTraffic"
  }),
  computed:{
    ...mapState([
    "paidTrafficPageData",
    "loadingPaidTrafficPageData",
    "error",
    "mobile",
    "demo"
    ]),
    getAdsData() {
      return this.paidTrafficPageData['components'][0]['data'];
    },
    getCampaignData() {
      return this.paidTrafficPageData['components'][1]['data'];
    },
    getSessionsVsRevenue() {
      return this.paidTrafficPageData['components'][2]['data'];
    },
    getCrVsCor() {
      return this.paidTrafficPageData['components'][3]['data'];
    },
    getCampaignRankingData() {
      return this.paidTrafficPageData['components'][4]['data'];
    },
    getBigCardsData() {
      return this.paidTrafficPageData['components'][5]['cards'];
    },
    getInfoCardsData() {
      return this.paidTrafficPageData['components'][6]['cards'];
    },
    isLoading() {
      if ( this.paidTrafficPageData  != null && !this.loadingPaidTrafficPageData){
        return false
      }
      return true
    },
    unavaibleIntegration(){
      if (this.paidTrafficPageData == 'integration unavaible')
        return true
      else
        return false
    }
  },
  methods: {
    ...mapActions([
      'getPaidTrafficPageData',
      'loggedInCheck'
    ]),
    ...mapMutations([
      "setMobile",
      "setPaidTrafficPageData"   
    ]),
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.setMobile(true)
        return true
      } else {
        return false
      }
    },
  },
  created: function () {
    this.isMobile();
    if(!this.demo){
      this.loggedInCheck();
      this.getPaidTrafficPageData();
    }
    else{
      var data = {};
      data = require('../../json/paidTraffic.json');
      this.setPaidTrafficPageData(data);
    }
  },
};
</script>

<style scoped lang="scss">
.main-container {
  max-width: 100%;
  padding: 12px 30px 12px 30px;
}
  

.margin-between-components {
  margin-top:24px;
} 



</style>

