<template>

  <v-progress-linear 
    v-if="isLoading"
    indeterminate
    color="cyan"
  ></v-progress-linear>

  <v-container v-else class="main-container">
    <div v-if="!this.unavaibleIntegration">
    <div class="page-title"> {{ $t('Payments.PageTitle')}} </div>

    <error v-if="error" style="margin-top: 10px"
      :errorMessage=" $t('errorMessage')"
    />

    <v-row justify-content="flex-end">

      <time-range-filter
      :componente= this.componentName
      />

    </v-row>

    <div class="d-flex align-center" v-if="!this.mobile" >
      <v-row justify-content="flex-end" >
        <order-status-filter
        />
      </v-row>
    </div>

    <v-row align="center" justify="space-between" style="margin-top: 24px">
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Payments.productsCards.card-1.title')"
          :description= "$t('Payments.productsCards.card-1.description')"
          :value= this.thousandSeparatorCall(getPaymentsCardsData[0].value.toFixed(2))
          :changeRate= getPaymentsCardsData[0].changeRate.toFixed(2)
          :sources="['vtex']"
          cardColor="#faa6ba"
          :invertedChangeRate="true"
        />
      </v-col>
      <v-col v-if="cardStringMD == 2" md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Payments.productsCards.card-2.title')"
          :description= "$t('Payments.productsCards.card-2.description')"
          :value= getPaymentsCardsData[1].value
          :changeRate= getPaymentsCardsData[1].changeRate.toFixed(2)
          :sources="['vtex']"
        />
        </v-col>
      <v-col v-else-if="cardStringMD == 4" md="4" cols="6">
      <raw-metric-card 
          :title = "$t('Payments.productsCards.card-2.title')"
          :description= "$t('Payments.productsCards.card-2.description')"
          :value= getPaymentsCardsData[1].value
          :changeRate= getPaymentsCardsData[1].changeRate.toFixed(2)
          :sources="['vtex']"
        />
      </v-col>
      <v-col v-else md="5" cols="6">
        <raw-metric-card 
          :title = "$t('Payments.productsCards.card-2.title')"
          :description= "$t('Payments.productsCards.card-2.description')"
          :value= getPaymentsCardsData[1].value
          :changeRate= getPaymentsCardsData[1].changeRate.toFixed(2)
          :sources="['vtex']"
        />
      </v-col>
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Payments.productsCards.card-3.title')"
          :description= "$t('Payments.productsCards.card-3.description')"
          :value= this.thousandSeparatorCall(getPaymentsCardsData[2].value)
          :changeRate= getPaymentsCardsData[2].changeRate.toFixed(2)
          :sources="['vtex']"
        />
      </v-col>
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Payments.productsCards.card-4.title')"
          :description= "$t('Payments.productsCards.card-4.description')"
          :value= this.thousandSeparatorCall(getPaymentsCardsData[3].value)
          :changeRate= getPaymentsCardsData[3].changeRate.toFixed(2)
          :sources="['vtex']"
        />
      </v-col>
    </v-row>

    <v-row align="center" style="margin-top: 12px">
      <v-col md="4" cols="12">
        <info-card
          :title = "$t('Payments.bigCards.card-1.title')"
          :description= "$t('Payments.bigCards.card-1.description')"
          :sources="['vtex']"
          :cardData="getBigCardsData[0]"
          cardIcon='cancelations'
        />
      </v-col>
      <v-col md="4" cols="12">
        <info-card 
          :title = "$t('Payments.bigCards.card-2.title')"
          :description= "$t('Payments.bigCards.card-2.description')"
          :sources="['vtex']"
          :cardData="getBigCardsData[1]"
          cardIcon='payment-groups'
        />
      </v-col>
      <v-col md="4" cols="12">
        <info-card 
          :title = "$t('Payments.bigCards.card-3.title')"
          :description= "$t('Payments.bigCards.card-3.description')"
          :sources="['vtex']"
          :cardData="getBigCardsData[2]"
          cardIcon='payment-groups1'
        />
      </v-col>
    </v-row>

    <v-row class="margin-between-components pa-0 ">
      <v-col class=" pa-0 " >
        <two-graphs
          :title1="$t('Payments.TwoGraphs-1.title-1')"
          :description1="$t('Payments.TwoGraphs-1.description-1')"
          :title2="$t('Payments.TwoGraphs-1.title-2')"
          :description2="$t('Payments.TwoGraphs-1.description-2')"
          bindingName="orderStatus"
          :pieData= getOrderStatusHorizontalBarsData
          :barsGraphData= getOrderStatusHistorical
          :sources="['vtex']"
          bindingLabel="estados de orden"
        />
      </v-col>      
    </v-row>

    <v-row >
      <v-col class="margin-between-components pa-0 ">
        <two-graphs
          :title1="$t('Payments.TwoGraphs-2.title-1')"
          :description1="$t('Payments.TwoGraphs-2.description-1')"
          :title2="$t('Payments.TwoGraphs-2.title-2')"
          :description2="$t('Payments.TwoGraphs-2.description-2')"
          bindingName="grupo"
          :pieData= getPaymentGroupHorizontalBarsData
          :barsGraphData= getPaymentChannelsHistorical
          bindingLabel="grupo"
        />
      </v-col>      
    </v-row>

    <v-row >
      <v-col class="margin-between-components pa-0 ">
        <two-graphs
          :title1="$t('Payments.TwoGraphs-3.title-1')"
          :description1="$t('Payments.TwoGraphs-3.description-1')"
          :title2="$t('Payments.TwoGraphs-3.title-2')"
          :description2="$t('Payments.TwoGraphs-3.description-2')"
          bindingName="grupo"
          :pieData="this.getPaymentMethodtHorizontalBarsData"
          :barsGraphData="this.getPaymentMethodsHistorical"
          bindingLabel="metodo"
        />
      </v-col>      
    </v-row>

    <v-row >
      <v-col class="margin-between-components pa-0 " style="margin-bottom: 128px">
        <installments 
        :installmentsData= getInstallmentsData
        :installmentsHorizontalBarsData="getInstallmentHorizontalBarsData"
        />
      </v-col>      
    </v-row>
  </div>
  <div  v-else>
    <v-card style="width:600px">
      <h2 class="app-font" style="padding:12px"> {{ $t('integrations.unavaible') }}</h2>
    </v-card>
  </div>
  </v-container>
  
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Error from '../errors/error.vue';
import Installments from '../payments/Installments.vue';
import InfoCard from '../shared/InfoCard.vue';
import OrderStatusFilter from '../shared/OrderStatusFilter.vue';
import RawMetricCard from '../shared/RawMetricCard.vue';
import TimeRangeFilter from '../shared/TimeRangeFilter.vue';
import TwoGraphs from '../shared/TwoGraphs.vue';
import ThousandSeparator from "@/helpers/thousandSeparator.helper";


export default {
  name: "Payments", 

  components: {
    Installments,
    TwoGraphs,
    OrderStatusFilter,
    Error,
    TimeRangeFilter,
    RawMetricCard,
    InfoCard,
  },
  data: () => ({ 
    componentName: "Payments",
    moneyString: "$ ",
  }),
  computed: {
    ...mapState([
    "paymentsPageData",
    "loadingPaymentsPageData",
    "loadingFilterOptions",
    "filterOptions",
    "error",
    "mobile",
    "demo"
    ]),
    getOrderStatusData () {
      return this.filterOptions["Filters"][0]["values"]
    },
    getPaymentsCardsData() {
      return this.paymentsPageData['components'][0]['cards'];
    },
    getBigCardsData() {
      return this.paymentsPageData['components'][1]['cards'];
    },
    getOrderStatus () {
      return this.paymentsPageData["components"][4]["data"];
    },
    getOrderStatusHistorical () {
      return this.paymentsPageData["components"][4]["historicalData"];
    },
    getPaymentChannels () {
      return this.paymentsPageData["components"][2]["data"];
    },
    getPaymentChannelsHistorical () {
      return this.paymentsPageData["components"][2]["historicalData"];
    },
    getPaymentMethods () {
      return this.paymentsPageData["components"][3]["data"];
    },
    getPaymentMethodsHistorical () {
      return this.paymentsPageData["components"][3]["historicalData"];
    },
    getInstallmentsData () {
      return this.paymentsPageData["components"][5]["data"];
    },
    getInstallmentHorizontalBarsData () {
      return this.paymentsPageData["components"][6]["data"];
    },
    getPaymentGroupHorizontalBarsData () {
      return this.paymentsPageData["components"][7]["data"];
    },
    getPaymentMethodtHorizontalBarsData () {
      return this.paymentsPageData["components"][8]["data"];
    },
    getOrderStatusHorizontalBarsData () {
      return this.paymentsPageData["components"][9]["data"];
    },
    cardStringMD () {
      console.log(this.paymentsPageData['components'][0]['cards'][1].value.length)
      if(this.paymentsPageData['components'][0]['cards'][1].value.length < 10 )
        return 2
      else if (this.paymentsPageData['components'][0]['cards'][1].value.length > 10 && this.paymentsPageData['components'][0]['cards'][1].value.length < 20)
        return 4
      else
        return 5
    },

    isLoading() {
      if ( this.paymentsPageData != null && !this.loadingPaymentsPageData){
        return false
      }
      return true
    },
    unavaibleIntegration(){
      if (this.paymentsPageData == 'integration unavaible')
        return true
      else
        return false
    }
  },
  methods: {
    thousandSeparatorCall(value) {
      return ThousandSeparator.formatNumber(value)
    },
    ...mapActions([
      "getPaymentsPageData",
      "getFilters",
      "loggedInCheck"     
    ]),
    ...mapMutations([
      "setMobile",
      "setPaymentsPageData"   
    ]),
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.setMobile(true)
        return true
      } else {
        return false
      }
    },
  },
  created: function () {
    this.isMobile();
    if(!this.demo){
    this.loggedInCheck()
    this.getPaymentsPageData();
    this.getFilters();
      }
    else{
      this.getFilters();
      var data = {};
      data = require('../../json/payments.json');
      this.setPaymentsPageData(data);
    }
  },
};
</script>

<style scoped lang="scss">
.main-container {
  max-width: 100%;
  padding: 12px 30px 12px 30px;
}

.margin-between-components {
  margin-top:24px;
} 
</style>