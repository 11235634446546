<template>
  <v-progress-linear 
      v-if="this.loadingAgencyClients"
      indeterminate
      color="cyan"
  ></v-progress-linear>

  <v-container v-else class="main-container">
    <overlay-loader />

    <div class="page-title"> Clientes </div>
  
    <custom-alerts />

    <agency-clients-table 
      class="margin-between-components" 
      :agencyClients="getAgencyClientsData"
    />
    
  </v-container>
</template>

<script>
import AgencyClientsTable from '../agency-clients/AgencyClientsTable.vue'
import { mapState, mapActions } from 'vuex'
import OverlayLoader from '../google-ads-scripting/OverlayLoader.vue'
import CustomAlerts from '../shared/CustomAlerts.vue'

export default {
  components: {
    AgencyClientsTable,
    OverlayLoader,
    CustomAlerts 

    },
  computed: {
    ...mapState([
      "loadingAgencyClients",
      'agencyClients',
      'showAlert',
      'successAlertMessage'
    ]),
    getAgencyClientsData(){
      return this.agencyClients
    }
  },
  methods: {
    ...mapActions([
      "getAgencyClients"
    ]),
  },
  created() {
    this.getAgencyClients()
  }
}
</script>

<style>
.main-container {
  max-width: 100%;
  padding: 12px 30px 12px 30px;
}

 .margin-between-components {
   margin-top:24px;
 } 
</style>