<template>
  <v-card
    color="#FFFFFF"
    class="job-status-card"

  >
      <v-card-title >
        <v-row no-gutters style="width:100%;">

        <v-col  md="12" cols="12">
          <div style="display:flex">
            <div  class="graph-title">
            {{ this.title }}
            </div>
        <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 style="margin-left: 5px">
                mdi-information-outline
              </v-icon>
            </template>
            <span class="tooltip">
              {{ description }}
            </span>
          </v-tooltip>
          </div>
        </v-col>

        <v-col  md="12" cols="12" >
        <v-card-text class="raw-metric-card" >
        <v-row  no-gutters style="  max-height: 90px; margin-bottom:20px">
            <v-col v-if="!mobile" md="3" cols="3" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" >
                <span class="first-column-spam" > 
                 <b>{{$t('agencyExtractors.extractorsTable.client')}} </b>
                </span>
            </div>
            </v-col> 
            <v-col v-if="!mobile" md="3" cols="3" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" >
                <span class="first-column-spam" > 
                 <b>{{$t('agencyExtractors.extractorsTable.googleAds')}}</b>
                </span>
            </div>
            </v-col>
            <v-col v-if="!mobile" md="3" cols="3" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" >
                <span class="first-column-spam" > 
                 <b>{{$t('agencyExtractors.extractorsTable.facebookAds')}}</b>
                </span>
            </div>
            </v-col>
            <v-col v-if="!mobile" md="3" cols="3" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" >
                <span class="first-column-spam" > 
                 <b>{{$t('agencyExtractors.extractorsTable.googleAnalytics')}}</b>
                </span>
            </div>
            </v-col>


        </v-row>
        <v-row v-for="(iterationData, idx) in this.componentData" :key="idx" no-gutters style="  max-height: 90px;padding-top:10px">
            <v-col md="3" cols="3" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" >
                <span class="first-column-spam" style="position:absolute;bottom:10px;" > 
                 <div class="app-font">{{ iterationData.client }}</div>
                </span>
            </div>
            </v-col>

            <v-col md="3" cols="3" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" >
                <span class="first-column-spam" style=""> 
                  <form-modal 
                    v-if="iterationData.googleAdsStatus == 'DISABLED'" 
                    dataSource="google_ads"
                    :agency_client="iterationData.client"
                    :index="idx"
                    />
                  <div v-else >
                    <span class="first-column-spam" style="position:absolute;bottom:2px;left:0px">
                      <img v-if="iterationData.googleAdsStatus == 'FINISHED'" :src="require('@/assets/finished.png')" style="height:25px"/>
                      <img v-if="iterationData.googleAdsStatus == 'RUNNING'" :src="require('@/assets/running.png')" style="height:25px"/>
                      <img v-if="iterationData.googleAdsStatus == 'ERROR'" :src="require('@/assets/error.png')" style="height:25px"/>
                      <img v-if="iterationData.googleAdsStatus == 'IN_PROCESS'" :src="require('@/assets/in_process.png')" style="height:25px"/>
                    </span>
                <div class="span-left-bottom" style="width:20px;margin-left:85px;margin-bottom:-2px;display:flex" >
                    <edit-modal style="width:20px;margin:auto;padding:0"
                      dataSource="google_ads"
                      source_field="googleAdsStatus"
                      :agency_client="iterationData.client"
                      :index="idx"  
                    />
                    <img class="LogoAnimation" :src="require('@/assets/eliminar.png')" style="height:15px;padding-left:5px;margin-top:1px" v-on:click="deleteItem('google_ads',iterationData.client)"/>
                </div>
                  </div>
                </span>
            </div>
            </v-col>
            <v-col md="3" cols="3" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" >
                  <form-modal 
                    v-if="iterationData.facebookAdsStatus == 'DISABLED'" 
                    style="margin-top: -15px;"
                    dataSource="facebook"
                    :agency_client="iterationData.client"
                    :index="idx"
                    />
                  <div v-else > 
                    <span class="first-column-spam" style="position:absolute;bottom:2px;left:0px">
                      <img v-if="iterationData.facebookAdsStatus == 'FINISHED'" :src="require('@/assets/finished.png')" style="height:25px"/>
                      <img v-if="iterationData.facebookAdsStatus == 'RUNNING'" :src="require('@/assets/running.png')" style="height:25px"/>
                      <img v-if="iterationData.facebookAdsStatus == 'ERROR'" :src="require('@/assets/error.png')" style="height:25px"/>
                      <img v-if="iterationData.facebookAdsStatus == 'IN_PROCESS'" :src="require('@/assets/in_process.png')" style="height:25px"/>
                    </span>
                    <div class="span-left-bottom" style="width:20px;margin-left:85px;margin-bottom:-2px;display:flex" >
                        <edit-modal style="width:20px;margin:auto;padding:0"
                          dataSource="facebook"
                          source_field="facebookAdsStatus"
                          :agency_client="iterationData.client"
                          :index="idx"  
                        />
                        <img class="LogoAnimation" :src="require('@/assets/eliminar.png')" style="height:15px;padding-left:5px;margin-top:1px" v-on:click="deleteItem('facebook',iterationData.client)"/>
                    </div>
                  </div>
            </div>
            </v-col>
            <v-col md="3" cols="3" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom"  >
                <span class="first-column-spam"  style="display:flex;text-align:center"> 
                  <form-modal 
                    v-if="iterationData.googleAnalyticsStatus == 'DISABLED'" 
                    dataSource="google_analytics"
                    :agency_client="iterationData.client"
                    :index="idx"
                    />
                  <div v-else > 
                    <span class="first-column-spam" style="position:absolute;bottom:2px;left:0px"> 
                      <img v-if="iterationData.googleAnalyticsStatus == 'FINISHED'" :src="require('@/assets/finished.png')" style="height:25px"/>
                      <img v-if="iterationData.googleAnalyticsStatus == 'RUNNING'" :src="require('@/assets/running.png')" style="height:25px"/>
                      <img v-if="iterationData.googleAnalyticsStatus == 'ERROR'" :src="require('@/assets/error.png')" style="height:25px"/>
                      <img v-if="iterationData.googleAnalyticsStatus == 'IN_PROCESS'" :src="require('@/assets/in_process.png')" style="height:25px"/>                    
                    </span>
                    <div class="span-left-bottom" style="width:20px;margin-left:85px;margin-bottom:-2px;display:flex;" >
                        <edit-modal style="width:20px;margin:auto;padding:0"
                          dataSource="google_analytics"
                          source_field="googleAnalyticsStatus"
                          :agency_client="iterationData.client"
                          :index="idx"  
                        />
                        <img class="LogoAnimation" :src="require('@/assets/eliminar.png')" style="height:15px;padding-left:5px;margin-top:1px" v-on:click="deleteItem('google_analytics', iterationData.client)"/>
                    </div>
                  </div>

                </span>
            </div>
            </v-col>
        <v-col  md="12" cols="12"><v-divider style="margin-bottom:10px "></v-divider></v-col>
        </v-row>
        </v-card-text>
        </v-col>
      </v-row>
      <v-dialog :retain-focus="false" v-model="dialogDelete" max-width="500px">
        <v-card style="text-align:center">
          <v-card-title class="text-h6 app-font" style="text-align:center;margin-left:10px"> {{ $t('alerts.integrations.deleteConfirmation') }} </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text  @click="deleteItemConfirm">{{ $t('Settings.UsersTable.dialogButtons.delete') }}</v-btn>
            <v-btn color="red darken-1" text @click="closeDelete" >{{ $t('Settings.UsersTable.dialogButtons.cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>
  </v-card>
      
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import EditModal from './editModal.vue';
import FormModal from './FormModal.vue';

export default {
  components: {FormModal, EditModal  },
  props: [
    'description',
    'componentData',
    'title'
  ],
  data: function() {
    return {
      dialog: false,
      dialogDelete: false,
      index: null,
      deleteLoading:false,
      agency_client: null,
      source_field: null,
      current_data_source: null,
    }
  },
  computed: {

    ...mapState([
      'mobile',
    ]),
  },
  methods: {
    ...mapMutations([
      "setDialogValue",
    ]),
    ...mapActions([
      "deleteAgencyIntegration",
    ]),
    close () {
      this.dialog = false
    },
    deleteIntegrationTrigger(){
      if(this.componentData[this.index][this.source_field] != 'DISABLED' ){
        let data = {
          "index": this.index,
          "data_source": this.current_data_source,
          "agency_client": this.agency_client,
          "source_field": this.source_field, 
        }
        this.deleteAgencyIntegration({ data })
      }
    },
    deleteItem (data_source, agency_client) {
      this.agency_client = agency_client
      this.current_data_source = data_source
      this.index = this.componentData.map(function(e) { return e.client; }).indexOf(agency_client);
      if( data_source == 'facebook'){
        this.source_field = 'facebookAdsStatus'
      }
      if( data_source == 'google_ads'){
        this.source_field = 'googleAdsStatus'
      }
      if( data_source == 'google_analytics'){
        this.source_field = 'googleAnalyticsStatus'
      }
      if(this.componentData[this.index][this.source_field] != 'DISABLED' ){
        this.dialogDelete = true
      }

    },
    deleteItemConfirm () {
      this.deleteIntegrationTrigger()
      this.closeDelete() 
    },
    closeDelete () {
      this.dialogDelete = false
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

};
</script>

<style scoped lang="scss">
.job-status-card {
  max-height: 420px;
  padding:24px;
  overflow-y:scroll;
}

.v-card__title {
  padding:0!important;
  text-align: left;
  //padding-bottom: 12px;
}

.card-title {
  margin: 5px;
}

.LogoAnimation:hover {
  opacity: 0.75;
  cursor: pointer;
}

</style>