<template>
  <v-card
    color="#FFFFFF"
    class="card"
  >
    <v-row no-gutters class="sessions-vs-revenue">

      <v-col md="12" cols="12" >
      <v-col cols="12" class="pa-0" style="margin-bottom: 17px">
        <span class="graph-title" >
          {{$t('PaidTraffic.SessionsVSRevenue.title')}}
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
            {{$t('PaidTraffic.SessionsVSRevenue.description')}}
            <data-sources
              :sources="['googleAnalytics', 'vtex']"
            /> 
          </v-tooltip>
        </span>
      </v-col> 
        <wj-flex-chart style="height: 195px" 
        bindingX="date" 
        :itemsSource="procesedData" 
        :stacking="true"
        :legendToggle="true"
        :palette="this.palette"
        :initialized="onChartInitialized"
        tooltipContent="" 
        >
            <wj-flex-chart-legend position="Bottom"></wj-flex-chart-legend>
            <wj-flex-chart-axis wjProperty="axisY" :min="0" format="n0" title="Revenue(VTEX)" :axisLine="true"></wj-flex-chart-axis>
            <wj-flex-chart-series name="Sesiones(G. Analytics)" binding="sessions">
              <wj-flex-chart-axis wjProperty="axisY" position="Right" title="Sesiones(G. Analytics)" format="n0," :min="0" :axisLine="true"></wj-flex-chart-axis>
            </wj-flex-chart-series>
            <wj-flex-chart-series name="Revenue(VTEX)" binding="totalValue" chartType="Line"></wj-flex-chart-series>
            <wj-flex-chart-animation></wj-flex-chart-animation>
            <wj-flex-chart-line-marker :isVisible="showMarker" lines="Vertical" interaction="Move" :content="getMarkerContent">
            </wj-flex-chart-line-marker>
        </wj-flex-chart>
      </v-col>      

    </v-row>
  </v-card>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import DataFilterHelper from "@/helpers/dataFilter.helper";
import markerContentHelper from "@/helpers/markerContent.helper";
import ui_kit from "@/constants/ui-kit";
import DataSources from '../shared/DataSources.vue';

export default {
  name: "SessionsVSRevenue",
  components: {
    DataSources
  },
  props: [
  'sessionsVsRevenueData'
  ],
  data: function() {
    return {
      lmVisible: false,
      showMarker: false,
      palette: ui_kit.palette
    }
  },
  computed: {
    procesedData() {
      return DataFilterHelper.dateYearFilter(this.sessionsVsRevenueData)
    },
  },
  methods: {
   onChartInitialized(sender) {
          sender.hostElement.addEventListener('mouseenter', () => this.showMarker = true);
          sender.hostElement.addEventListener('mouseleave', () => this.showMarker = false);
    },
    getMarkerContent(ht) {
        return markerContentHelper.markerContent(ht)
    }
  }
};
</script>

<style scoped lang="scss">
.sessions-vs-revenue {
  .wj-flexchart {
    padding: 0px !important;
  }
}
.sessions-vs-revenue-title {
  color: #6f6f6f;
  padding-left: 20px;
}

.card {
  padding: 24px;
  height: 280px;
}
</style>