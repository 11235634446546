<template>
  <v-card>
    <v-toolbar
      color="cyan"
      dark
      flat
      height="0px"
    >

      <template v-slot:extension>
        <v-tabs
          v-model="tab"
          align-with-title
        >
          <v-tabs-slider color="yellow"></v-tabs-slider>

          <v-tab
            v-for="item in items"
            :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in items"
        :key="item"
      >
        <v-card v-if="tab == 0">
          <script-register-table 
          :scriptRegisterData = scriptRegisterData
          />
        </v-card>
        <v-card v-if="tab == 1">
          <script-executions
          :scriptExecutions = scriptExecutionsData
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import ScriptRegisterTable from '../google-ads-scripting/ScriptRegisterTable.vue'
import ScriptExecutions from './ScriptExecutions.vue'

  export default {
    props: [
        'scriptRegisterData',
        'scriptExecutionsData'
    ],
    components: { ScriptRegisterTable, ScriptExecutions },
    data () {
      return {
        tab: null,
        items: [
          'Registro', 'Ejecuciones',
        ],
      }
    },
    computed: {
        scriptData(){
            return this.scriptRegisterData
        }
    },

  }
</script>

