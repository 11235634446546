<template>

  <v-progress-linear 
      v-if="isUsersLoading || isJobsExecutionsLoading"
      indeterminate
      color="cyan"
  ></v-progress-linear>

  <v-container v-else class="main-container">

    <div class="page-title" style="margin-bottom:32px;display:flex;position:relative"> 
      {{ $t('Settings.PageTitle')}} 
      <language-selector style="position:absolute;right:-12px;bottom:0"
      />
    </div>

    <custom-alerts />

    <error v-if="error" style="margin-top: 10px"
      :errorMessage=" $t('errorMessage')"
    />

    <v-row >
      <v-col class="margin-between-components pa-0 ">
      <job-status
          v-if="this.clientType == 'CLIENT'"
          :title= "$t('Settings.JobStatus.title')"
          :componentData="getJobsExecutionsData"
          :description= "$t('Settings.JobStatus.description')"
      />
      </v-col>      
    </v-row>

    <v-row >
      <v-col v-if="this.userRole == 'admin' && this.clientType == 'CLIENT'" class="margin-between-components pa-0 " >
      <users-table  
      :usersData="getUsersData"
      />
      </v-col>      
    </v-row>

    <v-row v-if="!this.mobile">
      <v-col class="margin-between-components pa-0 " style="margin-bottom: 128px">
      <update-password 
      />
      </v-col>      
    </v-row>

    <v-row v-if="this.mobile">
      <v-col class="pa-0 " style="margin-top:12px;margin-bottom: 128px">
      <update-password 
      />
      </v-col>      
    </v-row>

  <overlay-loader />

  </v-container>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import Error from '../errors/error.vue';
import OverlayLoader from '../google-ads-scripting/OverlayLoader.vue';
import JobStatus from '../settings/JobStatus.vue';
import LanguageSelector from '../settings/LanguageSelector.vue';
import UpdatePassword from '../settings/UpdatePassword.vue';
import UsersTable from '../settings/UsersTable.vue';
import CustomAlerts from '../shared/CustomAlerts.vue';


export default {
  components: {
    UsersTable,
    Error,
    UpdatePassword,
    JobStatus,
    LanguageSelector,
    OverlayLoader,
    CustomAlerts,
  },
  data: () => ({
  }),
  computed:{
    ...mapState([
      "error",
      "users",
      "loadingUsers",
      "loadingJobsExecution",
      "jobsExecutionData",
      "userRole",
      "mobile",
      "settings",
      'loadingSettings',  
      "demo",
      "clientType"
    ]),
    getUsersData(){
      return this.users
    },
    getJobsExecutionsData(){
      return this.jobsExecutionData
    },
    isUsersLoading() {
      if ( this.users != null && !this.loadingUsers && this.settings != null && !this.loadingSettings) {    
        return false
      }
      return true
    },
    isJobsExecutionsLoading() {
      if ( this.jobsExecutionData != null && !this.loadingJobsExecution) {      
        return false
      }
      return true
    },

  },
  methods: {
    ...mapActions([
      "getUsers",
      "getJobsExecution" ,
      "getSettings"  
    ]),
    ...mapMutations([
      "setMobile", 
      "setUsers",
      "setJobsExecutionData"
    ]),
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.setMobile(true)
        return true
      } else {
        return false
      }
    },

  },

  created: function () {
    this.isMobile();
    if(!this.demo){
        this.getSettings()
        if(this.clientType=="CLIENT"){
          this.getUsers()
          this.getJobsExecution()
        }
        else {
          this.setUsers('proxy');
          this.setJobsExecutionData('proxy')
        }
    }
    else{
      this.getSettings()
      var usersData = {};
      usersData = require('../../json/users.json');
      this.setUsers(usersData);
      var executionsData = {};
      executionsData = require('../../json/executions.json');
      this.setJobsExecutionData(executionsData);
    }
  },
};
</script>

<style scoped lang="scss">
.main-container {
  max-width: 100%;
  padding: 12px 30px 12px 30px;
}

 .margin-between-components {
   margin-top:24px;
 } 

</style>