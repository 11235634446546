<template>
        <div v-if="this.mobile" class="toolbar-time-range align-center" style="display:flex;position:absolute;right:0;bottom:15px">
          <p style="padding-top:17px;font-family: Open Sans, sans-serif!important;font-size: 13px!important" > {{ $t('FlexGridPageSizeSelector.pages')}}  </p>
          <v-select 
            :items="this.pageSizeSelect"
            :value="this.currentPageSize"
            @change="setPages"
            solo
            flat
            dense
            background-color="transparent"
            hide-details
            class="time-range-select ml-1 capitalized-text app-font"
            :menu-props="['offset-y']"
          >
          <template v-slot:item="data">
            <div class="select-option-item app-font">
              {{ data.item.toUpperCase() }}
            </div>
          </template>
          </v-select>
        </div>

        <div v-else class="toolbar-time-range align-center" style="display:flex;position:absolute;right:0;bottom:-15px">
          <p style="padding-top:17px;font-family: Open Sans, sans-serif!important;" > {{ $t('FlexGridPageSizeSelector.pages')}}  </p>
          <v-select 
            :items="this.pageSizeSelect"
            :value="this.currentPageSize"
            @change="setPages"
            solo
            flat
            dense
            background-color="transparent"
            hide-details
            class="time-range-select ml-1 capitalized-text"
            :menu-props="['offset-y']"
          >
          <template v-slot:item="data">
            <div class="select-option-item">
              {{ data.item.toUpperCase() }}
            </div>
          </template>
          </v-select>
        
        </div>
</template>

<script>
import { mapState } from 'vuex';

export default {

  props: [
    "pageSizeSelect",
    "currentPageSize",
  ],
  data: function() {
    //var currentPageSize = '12'
    return {
    }
  },
  computed: {
    ...mapState([
      "mobile"
    ])

  },
  methods: {
    setPages(value) {
      this.$emit('change', value)
    },

  }
};
</script>

<style scoped lang="scss">
.right-content {
  margin: auto; 
  text-align: right; 
  padding-right: 20px !important;
  position:relative
}


.toolbar-time-range {
  display: inherit;
  padding: 0 !important;
  background: #ffffff !important;
  margin: 0 15px 0 10px;
  min-height: 30px;
  height: 30px;
  margin-top: 32px;
  margin-bottom: 0px;
  border-radius: 5px;


  &-actions {
    flex-wrap: wrap;

    &__date-select {
      align-items: center;
    }
  }
  .time-range-select {
    min-width: 40px;
    text-transform: capitalize;
    .v-input__append-inner {
      margin-top: 0px !important;
      .mdi {
        color: #202020 !important;
      }
    }
    .v-input__control {
      min-height: 30px !important;
    }
    .v-input__slot {
      background-color: #eaeaea!important;
      padding: 0 !important;
      min-height: 30px;
      height: 30px;
    }
    .v-select__selection--comma {
      text-transform: uppercase;
      overflow: visible;
      font-size: 10pt;
    }

    input {
      display: none;
    }
  }

  .number-input {
    width: 70px;
    height: 28px;
    border: 1px solid #202020;
    background: #fff;
  }
  .number-input:focus {
    outline: none;
    border: 1px solid #1976d2 !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .compare-to {
    padding-left: 1px!important;
  }

  .v-text-field.v-text-field--solo.v-input--dense .v-input__prepend-outer, .v-text-field.v-text-field--solo.v-input--dense .v-input__append-outer {
    margin-top: 3px;
  }
}


</style>