
<template>

  <v-progress-circular 
      style="position:absolute; bottom:7px; left:16%"
      v-if="isLoadingEditSchema"
      indeterminate
      color="cyan"
  ></v-progress-circular>

  <v-container v-else style="padding:6px">
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <template v-slot:activator="{ on, attrs }">
        <img 
            class="LogoAnimation" 
            :src="require('@/assets/editar.png')" 
            style="height:20px;" 
            v-bind="attrs"
            v-on="on"
            @click="getSchema"
        >
      </template>

      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('Settings.JobStatus.integration') }} {{ this.getDataSource }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <vue-form-json-schema
              class="form"
              v-model="editModel"
              :schema="schema"
              :ui-schema="uiSchema"
              :options="options"
              v-on:state-change="onChangeState"
              v-on:validated="onValidated"
              ref="form"
              tag="div"
            >
          </vue-form-json-schema>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="save"
          >
            {{ $t('Settings.JobStatus.send') }}
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="close"
          >
            {{ $t('Settings.UsersTable.dialogButtons.cancel') }}
          </v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>  
</template>

<script>
import { mapState , mapActions, mapMutations } from 'vuex'
import i18n from '@/i18n.js'


export default {
  components: {  },
  props: [
    "usersData",
    'dataSource',
    "index"
  ],

  data: () => ({
    dialog: false,
    options: {
        "castToSchemaType": true,
        showValidationErrors: true
    },
    // A valid JSON Schema object
    schema: {},
    // Array of HTML elements or Vue components
    uiSchema: [],
    //state
    state: {}
  }),

  computed: {
    ...mapState([
      'userRole',
      'loadingEditSchema',
      'loadingSchema',
      'model',
      'jobsExecutionData'
    ]),
    formValidated(){
        let fields = []
        fields = this.schema.required
        if(fields.length != Object.keys(this.editModel).length)
          return false
        else
          return true
    },
    isLoadingEditSchema(){
        if(!this.loadingEditSchema && !this.loadingSchema)
            return false
        else
            return true
    },
    getDataSource(){
      return this.dataSource
    },
    editModel: {
      get () {
        return this.$store.state.editModel
      },
      set (value) {
        this.$store.commit('updateEditModel', value)
      }
    },
    isIntegrationActive(){
      return this.jobsExecutionData['components'][0]['data']['details'][this.index]['status'] != 'DISABLED'
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
  },

  created () {
  },

  methods: {
    ...mapActions([
      'getIntegrationData',
      'vtexIntegrationEdit',
      'facebookIntegrationEdit',
      'googleAnalyticsIntegrationEdit',
      'googleAdsIntegrationEdit'

    ]),
    ...mapMutations([
      'setLoadingEditSchema',
      'setLoadingSchema',
      'setCurrentDataSource',
      'updateEditModel'
    ]),

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.editModel = {};
    },

    save () {
      if(this.isIntegrationActive){
        if(this.formValidated){
          this.setLoadingSchema(true)
          if(this.dataSource == 'vtex')
            this.vtexSubmit();
          if(this.dataSource == 'google_analytics')
            this.googleAnalyticsSubmit();
          if(this.dataSource == 'facebook')
            this.facebookSubmit();
          if(this.dataSource == 'google_ads')
            this.googleAdsSubmit();
          this.close()
          //this.editModel = {};
          }
        else
          alert(i18n.t('Settings.JobStatus.required'))
        }

    },
    onChangeState(value) {
    this.state = value;
    },
    onValidated(value) {
    this.valid = value;
    },
    vtexSubmit(){
      let data = {
        "integrationData": {
          "AppAccount": this.editModel.AppAccount,
          "AppKey": this.editModel.AppKey,
          "AppToken": this.editModel.AppToken,
          "vtex_url": this.editModel.vtex_url
        },
        "jobData": {
          "index": this.index,
          "status": "IN_PROCESS"
        }
      }
      this.vtexIntegrationEdit({ data })
    },
    facebookSubmit(){
      let data = {
        "integrationData": {
          "ACCESS_TOKEN": this.editModel.ACCESS_TOKEN,
          "ACCOUNT_IDS": this.editModel.ACCOUNT_IDS,
          "APP_ID": this.editModel.APP_ID,
          "APP_SECRET": this.editModel.APP_SECRET,
        },
        "jobData": {
          "index": this.index,
          "status": "IN_PROCESS"
        }
      }
      this.facebookIntegrationEdit({ data })
    },
    googleAnalyticsSubmit(){
      let data = {
        "integrationData": {
          "VIEW_ID": this.editModel.VIEW_ID,
        },
        "jobData": {
          "index": this.index,
          "status": "IN_PROCESS"
        }
      }
      this.googleAnalyticsIntegrationEdit({ data })
    },
    googleAdsSubmit(){
      let data = {
        "integrationData": {
          "CUSTOMER_ID": this.editModel.CUSTOMER_ID,
        },
        "jobData": {
          "index": this.index,
          "status": "IN_PROCESS"
        }
      }
      this.googleAdsIntegrationEdit({ data })
    },
    getSchema(){
    if(this.isIntegrationActive){
        this.setLoadingEditSchema(true)
        this.setCurrentDataSource(this.dataSource)
        let data = {}
        if(this.dataSource=="vtex"){
            data = require('../../jsonSchemas/vtex_schema.json');
            this.schema = data['schema']
            this.uiSchema = data['uiSchema']
            this.state = data['state']
        }
        if(this.dataSource=="facebook"){
            data = require('../../jsonSchemas/facebook_schema.json');
            this.schema = data['schema']
            this.uiSchema = data['uiSchema']
            this.state = data['state']
        }
        if(this.dataSource=="google_analytics"){
            data = require('../../jsonSchemas/google_analytics_schema.json');
            this.schema = data['schema']
            this.uiSchema = data['uiSchema']
            this.state = data['state']
        }
        if(this.dataSource=="google_ads"){
            data = require('../../jsonSchemas/google_ads_schema.json');
            this.schema = data['schema']
            this.uiSchema = data['uiSchema']
            this.state = data['state']
        }
        this.getIntegrationData()
        this.dialog = true;
        return data
        }
    }
  },
}
</script>

<style scoped lang="scss">
.form{
    display: block-inline;
    width: 100%;
    padding: 12px;
    border: black;
}
.LogoAnimation:hover {
  opacity: 0.75;
  cursor: pointer;
}
</style>