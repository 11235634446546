<template>
  <v-card
    color="#FFFFFF"
    class="card"
  >
    <v-row no-gutters class="revenue-by-category">
      <v-col cols="12" style="height: px;margin-bottom:17px">
        <span class="graph-title">
          {{ $t('Orders.RevenuePorCategoria.title')}}
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
          {{ $t('Orders.RevenuePorCategoria.description')}}
          <data-sources 
            :sources="['vtex']"
          />
          </v-tooltip>

        </span>
      </v-col> 

      <v-col md="12" cols="12" style="height: 190px">
        <wj-flex-chart style="height: 100%" 
        bindingX="date" 
        :itemsSource="procesedData" 
        :legendToggle="true"
        :palette="this.palette"
        >
            <wj-flex-chart-legend position="Bottom"></wj-flex-chart-legend>
            <wj-flex-chart-axis wjProperty="axisY" :min="0"></wj-flex-chart-axis>
            <wj-flex-chart-series
                v-for="item in data.departments"
                :key="item.departmentName"
                :name="item.departmentName"
                :binding="item.departmentId"
              ></wj-flex-chart-series>
            <wj-flex-chart-animation></wj-flex-chart-animation>
        </wj-flex-chart>
      </v-col>      
    </v-row>
  </v-card>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import DataFilterHelper from "@/helpers/dataFilter.helper";
import ui_kit from "@/constants/ui-kit";
import DataSources from '../shared/DataSources.vue';

export default {
  name: "OrdersByDate",
  components: {
    DataSources
  },
  props: [
  'revenueByCategoryData'
  ],
  data: function() {
    return  {
      data: this.revenueByCategoryData,
      palette: ui_kit.palette
    }
  },
  computed: {
    procesedData() {
      return DataFilterHelper.dateYearFilter(this.data.totalRevenueDataByDate)
    },
  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
.revenue-by-category {
  .wj-flexchart {
    padding: 0px !important;
  }
}
.revenue-by-category-title {
  color: #6f6f6f;
  padding-left: 20px;
}

.card {
  height: 280px;
  padding:24px;
  background: white;
}
</style>