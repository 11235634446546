<template>
  <div class="text-center">
    <v-overlay :value="loadingScriptBuilder">
      <v-progress-circular
        indeterminate
        size="100"
        width="6"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import {mapMutations } from 'vuex'

export default {
    data: () => ({
    }),
    methods: {
        ...mapMutations([
        ]),
    },
    computed: {
        loadingScriptBuilder: {
            get () {
                return this.$store.state.loadingScriptBuilder
            },
            set (value) {
                this.$store.commit('setLoadingScriptBuilder', value)
            }
        }
    },


    watch: {
    },
}
</script>