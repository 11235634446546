<template>
    <v-row v-if="this.mobile" no-gutters class="payments-data">

      <v-col md="4" cols="12" style="padding:12px 12px 0 12px" >
        <info-card
          :title = "$t('Traffic.bigCards.card-1.title')"
          :description="$t('Traffic.bigCards.card-1.description')"
          :sources="['googleAnalytics']"
          cardIcon="transactions"
          :cardData="infoCardsData[0]"
        />
      </v-col> 

      <v-col md="4" cols="12" style="padding:12px 12px 0 12px">
        <info-card   
          :title = "$t('Traffic.bigCards.card-2.title')"
          :description="$t('Traffic.bigCards.card-2.description')"
          :sources="['googleAnalytics']"
          itemLink="end-page"
          cardIcon="end-page"
          :cardData="infoCardsData[1]"
          
        />
      </v-col>    

      <v-col md="4" cols="12" style="padding:12px 12px 0 12px" >
        <info-card 
          :title = "$t('Traffic.bigCards.card-3.title')"
          :description="$t('Traffic.bigCards.card-3.description')"
          :sources="['googleAnalytics']"
          :itemLink="true"
          cardIcon="start-page"
          :cardData="infoCardsData[2]"
          
        />
      </v-col>    

    </v-row>


    <v-row v-else no-gutters class="payments-data">

      <v-col md="4" cols="12" style="padding:12px 12px 0 12px" >
        <info-card
          :title = "$t('Traffic.bigCards.card-1.title')"
          :description="$t('Traffic.bigCards.card-1.description')"
          :sources="['googleAnalytics']"
          cardIcon="transactions"
          :cardData="infoCardsData[0]"
        />
      </v-col> 

      <v-col md="4" cols="12" style="padding:12px 12px 0 12px" >
        <info-card 
          :title = "$t('Traffic.bigCards.card-3.title')"
          :description="$t('Traffic.bigCards.card-3.description')"
          :sources="['googleAnalytics']"
          cardIcon="start-page"
          :cardData="infoCardsData[2]"
          :itemLink="true"
        />
      </v-col>    

      <v-col md="4" cols="12" style="padding:12px 12px 0 12px">
        <info-card   
          :title = "$t('Traffic.bigCards.card-2.title')"
          :description="$t('Traffic.bigCards.card-2.description')"
          :sources="['googleAnalytics']"
          cardIcon="end-page"
          :cardData="infoCardsData[1]"
          :itemLink="true"
        />
      </v-col>    
      
    </v-row>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import InfoCard from '../shared/InfoCard.vue';
import {mapState} from 'vuex'

export default {
  props: [
    'infoCardsData'
  ],
  components: {
    InfoCard
  },
  data:
    function() {
    return {
      moneyString: '$'
    }
  },
  computed: {
    ...mapState([
      "mobile"
    ])
  },
  methods: {

  },

};
</script>

<style scoped lang="scss">
.payments-data {
  margin-top: 3px !important;

  .wj-flexchart {
    padding: 0px;
  }
}


.column {
  height: 340px;
  padding:24px;
}

.wj-flexchart .wj-state-selected {
  stroke: rgba(0,0,0,.5);
  stroke-width: 3;
  stroke-dasharray: 1;
}
</style>