<template>
  <v-card
    color="#FFFFFF"
    class="card"
  >
    <v-row no-gutters class="channel-grouping">
      
    <v-col md="8" cols="12" >
        <span class="table-title">
          {{$t('Traffic.ChannelGrouping.title')}}
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template> 
          {{$t('Traffic.ChannelGrouping.description')}}
            <data-sources 
              :sources="['googleAnalytics']"
            />
          </v-tooltip>
        </span>
    </v-col>

    <v-col md="3" cols="12" class="right-content" style="height:20px">
    </v-col> 

    <v-col md="1" cols="12" class="right-content">
      <v-btn
        tile
        small
        color="success"
        @click="save()"
      >
        <v-icon style="font-size: 20px;">
          mdi-microsoft-excel
        </v-icon>
      </v-btn>
    </v-col>

    <v-col md="12" cols="12" class="d-flex text-field-col" style="margin-top: 16px">
        <v-text-field class="shrink"
                prepend-inner-icon="mdi-magnify"
                label="Buscar Grupo"
                rounded
                dense
                outlined
                hide-details
                @input="groupFilter($event)"

        ></v-text-field>
    </v-col>


      <v-col md="12" cols="12"  >
        <wj-flex-grid
          :initialized="initializeFlexSheet"
          :headersVisibility="'Column'"
          :selectionMode="'ListBox'"
          :itemsSource="this.data"
          :alternatingRowStep="1"
          style="max-height: 1090px"
          >
            <wj-flex-grid-filter />
            <wj-flex-grid-column :header="'Channel Group'" :binding="'channelGrouping'" width="*"  :isReadOnly=true />
            <wj-flex-grid-column :header="'Campaign'" :binding="'campaign'" width="1*" :isReadOnly=true />
            <wj-flex-grid-column :header="'Sessions'" :binding="'sessions'" width="1*" aggregate="Sum" :isReadOnly=true />
            <wj-flex-grid-column :header="'Page Views'" :binding="'page_views'" width="*" format="n2" aggregate="Sum" :isReadOnly=true  />
            <wj-flex-grid-column :header="'Users'" :binding="'users'" width="*" format="n2" aggregate="Sum" :isReadOnly=true  />
            <wj-flex-grid-column :header="'Transactions'" :binding="'transactions'" width="*" format="n2" aggregate="Sum" :isReadOnly=true />
            <wj-flex-grid-column :header="'Transaction Revenue'" :binding="'transactionRevenue'" width="*" format="n2" aggregate="Sum"  :isReadOnly=true />
        </wj-flex-grid>
      </v-col>  
        <v-col md="12" cols="12"  >
        <div v-on:click="updateViewPager($event)" class="update-view-pager">
            <flex-grid-page-size-selector 
            style="display:flex;position: relative;inset: -15px 0 -15px -20px"
            @change="onChangeChild"
            :currentPageSize="this.currentPageSize"
            :pageSizeSelect="this.pageSizeSelect"
            />
            <button id="btnFirst" class="btn"><span class="wj-glyph-step-backward" style="position: relative;left: -10px; top: 1px" ></span></button>
            <button id="btnPrev" class="btn"><span class="wj-glyph-left" style="position: relative;left: -3px; " ></span></button>
            &nbsp;&nbsp;&nbsp;{{ $t('FlexGridPageSizeSelector.page')}} {{ this.pageIndex + 1 | number:0 }} {{ $t('FlexGridPageSizeSelector.of')}} {{ this.pageCount | number:0 }}&nbsp;&nbsp;&nbsp;
            <button id="btnNext" class="btn"><span class="wj-glyph-right" style="position: relative;right: -3px; "></span></button>
            <button id="btnLast" class="btn"><span class="wj-glyph-step-forward" style="position: relative;right: -10px; top: 1px"></span></button>
        </div>
        </v-col>

    </v-row>
  </v-card>
</template>


<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import "@grapecity/wijmo.vue2.grid";
import '@grapecity/wijmo.vue2.grid.filter';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import DataSources from '../shared/DataSources.vue';
import * as wijmo from '@grapecity/wijmo';
import FlexGridHelper from "@/helpers/flexGrid.helper";
import FlexGridPageSizeSelector from '../shared/FlexGridPageSizeSelector.vue';

export default {
  name: "ChannelGrouping",
  props: [
    'channelGroupingData',
  ],
  components: {
    DataSources,
    FlexGridPageSizeSelector
  },
  data: function() {
      return {
      maxPageSize: '5',
      currentPageSize: '5',
      pageIndex: 0,
      pageCount: 1,
          animated: true,

      };
  },
  computed: {
    data() {
      return new CollectionView(FlexGridHelper.fillData(this.channelGroupingData, parseInt(this.currentPageSize)), {
              refreshOnEdit: true, // on-demand sorting and filtering
              pageSize: parseInt(this.currentPageSize)
            })
    },
    pageSizeSelect() {
      return FlexGridHelper.makeList(parseInt(this.maxPageSize))
    },
  },
  methods: {
    initializeFlexSheet: function(flex) {
      flex.columnFooters.rows.push(new wjcGrid.GroupRow());
      this.flex = flex;
    },
    onChangeChild(value) {
      this.currentPageSize = value
      this.updatePagesIndex()
    },
    updatePagesIndex() {
      this.pageIndex = this.data.pageIndex
      this.pageCount = this.data.pageCount
    }, 
    save() {
      this.data.pageSize = 0;
      wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
          this.flex,
          {
              includeColumnHeaders: true,
              includeCellStyles: false,
              formatItem: null
          },
          'Channel-Grouping.xlsx'
      );
      this.data.pageSize = parseInt(this.currentPageSize);    
    },
    updateViewPager(e) {
        let btn = wijmo.closest(e.target, 'button'),
            id = btn ? btn.id : '';
        //
        switch (id) {
            case 'btnFirst':
                this.data.moveToFirstPage();
                break;
            case 'btnPrev':
                this.data.moveToPreviousPage();
                break;
            case 'btnNext':
                this.data.moveToNextPage();
                break;
            case 'btnLast':
                this.data.moveToLastPage();
                break;
        }
        this.updatePagesIndex()
    },

    getGroupsData: function() {
      let data = this.getData();
      let groupsData = [];
      for (var i=0; i < data.length; i++) {
        if (groupsData[data[i].channelGroup]) {
            groupsData[data[i].channelGroup]["sessions"] += data[i].sessions;
            groupsData[data[i].channelGroup]["pageViews"] += data[i].pageViews;
            groupsData[data[i].channelGroup]["users"] += data[i].users;
            groupsData[data[i].channelGroup]["transactions"] += data[i].transactions;
            groupsData[data[i].channelGroup]["transactionRevenue"] += data[i].transactionRevenue;
        }
        else {
            groupsData[data[i].channelGroup]["channelGroup"] = data[i].channelGroup;
            groupsData[data[i].channelGroup]["campaign"] = data[i].campaign;
            groupsData[data[i].channelGroup]["sessions"] = data[i].sessions;
            groupsData[data[i].channelGroup]["pageViews"] = data[i].pageViews;
            groupsData[data[i].channelGroup]["users"] = data[i].users;
            groupsData[data[i].channelGroup]["transactions"] = data[i].transactions;
            groupsData[data[i].channelGroup]["transactionRevenue"] = data[i].transactionRevenue;
        }
      }
      let resultadoFinal= [];
      for (const item in groupsData) {
          resultadoFinal.push(item)
      }
      console.log(groupsData)
      return groupsData 
    },

    groupFilter: function(e) {
        let filter = e;
        this.flex.collectionView.filter = item => {
            return (
                filter.length == 0 ||
                item.channelGroup.toLowerCase().indexOf(filter) > -1
            );
        };
    },
   
  },
created: function () {
  this.updatePagesIndex()
},    
};
</script>

<style scoped lang="scss">

  .channel-grouping {
  .wj-flexchart {
    padding: 0px !important;
  }
}
.channel-grouping-title {
  color: #6f6f6f;
  padding-left: 20px;
}
.right-content {
  margin: auto; 
  text-align: right; 
  padding-right: 20px !important;
  position:relative
}

.form-control {
  margin-left: 1%;
  margin-top:1%;
  background-color:#add8e6;
  box-shadow: 0 0 3px black;
}

.card {
  padding:24px;
  max-height: 1200px;
}

.divider {
  border-width: 1px !important;
  border-color: grey !important;
  height: 90%;
  margin:0 5px 0 10px;
 }

 .elements-margin-left {
  margin-left:3px;

}

.update-view-pager {
  align-items: center; 
  text-align: right; 
  padding-right: 16px;
  display:flex;
  float:right;
  height:30px;
}

.v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot {
    min-height: 25px;
} 
</style>