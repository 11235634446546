import moment from 'moment'

export default {
  getTimeRange(state) {
     return state.timeRange.timeRangeType
  },
  getCompareTimeRange(state) {
   return state.timeRange.compareTimeRangeType
  },
  getDailySummaryDay(state) {
    return state.timeRange.dailySummaryDateFormated
  },
  getStockDay(state) {
    return state.timeRange.stockDateFormated
  },
  getCustomStartDate(state) {
   return moment(state.timeRange.customStartDate).format('YYYY-MM-DD')
  },
  getCustomEndDate(state){
   return moment(state.timeRange.customEndDate).format('YYYY-MM-DD')
  },
  getCustomCompareStartDate(state) {
   return moment(state.timeRange.compareWithCustomStartDate).format('YYYY-MM-DD')
  },
  getCustomCompareEndDate(state){
   return moment(state.timeRange.compareWithCustomEndDate).format('YYYY-MM-DD')
  },
  getStatusParams(state) {
    var params = ""

    if (state.checkedItems.length > 0) {
      params = "&order_status=" + state.checkedItems.join()
    }
    
    return params
  },
  getOriginParams(state){
    var params = ""

    if (state.checkedItemsOrigin.length > 0) {
      params = "&origin="+state.checkedItemsOrigin.join()
    }
    
    return params
  },
  getCategoriesParams(state){
    var params = ""

    if (state.checkedCategories.length > 0) {
      params = "&categories="+state.checkedCategories.join()
    }
    
    return params
  },
  getDepartmentsParams(state){
    var params = ""

    if (state.checkedDepartments.length > 0) {
      params = "&departments="+state.checkedDepartments.join()
    }
    
    return params
  },
  getCurrentItem(state) {
    return state.currentItem
  },
  getStatusParamsIsolated(state) {
    var params = ""

    if (state.checkedItems.length > 0) {
      params = state.checkedItems.join()
    }
    
    return params
  },
  getOriginParamsIsolated(state){
    var params = ""

    if (state.checkedItemsOrigin.length > 0) {
      params = state.checkedItemsOrigin.join()
    }
    
    return params
  },
  getCategoriesParamsIsolated(state){
    var params = ""

    if (state.checkedCategories.length > 0) {
      params = state.checkedCategories.join()
    }
    
    return params
  },
  getDepartmentsParamsIsolated(state){
    var params = ""

    if (state.checkedDepartments.length > 0) {
      params = state.checkedDepartments.join()
    }
    
    return params
  },
  getCurrentDataSource(state) {
    return state.currentDataSource
  },
  getCurrentAgencyClient(state) {
    return state.currentAgencyClient
  },
  getScriptExecutionOffset(state) {
    return state.scriptExecutionOffset - 1
  }
}