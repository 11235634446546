<template >


    <v-card-text class="raw-metric-card" style="padding:16px;">
      <v-row v-for="(iterationData, idx) in cardData" :key="idx" no-gutters>
        <v-col v-if="!mobile"  md="2" cols="2" class="column-value " style="width: 100%;position: relative;">
          <span class="first-column-spam" > 
            <img 
              style="margin-bottom:-10%"
              :src="iterationData.imageUrl" 
              contain 
              height="60px"> 
          </span>
        </v-col>


        <v-col v-if="!mobile" md="5" sclass="column-value " style="width: 100%;position: relative;">
          <div class="span-left-bottom" >
            <span class="first-column-spam" > 
              {{$t('Products.GridWithButton.id')}} <b>{{iterationData.skuId}} </b>
            </span>
            <span class="first-column-spam" > 
              {{$t('Products.GridWithButton.item')}} <b>{{iterationData.itemName}} </b>
            </span>
          </div>
        </v-col>

        <v-col v-if="!mobile" md="2" class=" column-value" style="width: 100%;position: relative;">
          <div class="span-left-bottom" >
          <span class="first-column-spam" > 
            {{$t('Products.GridWithButton.amount')}} 
          </span>
          <span  class="first-column-spam" > 
            {{$t('Products.GridWithButton.crossSelling')}}  <b>{{ iterationData.crossSellingOrders}}</b>
          </span>
          </div>
        </v-col>

        <v-col v-if="mobile" md="7" cols="9" class="column-value " style="width: 100%;position: relative;margin-top:5px">
          <div class="span-left-bottom" >
            <span class="first-column-spam" > 
              {{$t('Products.GridWithButton.item')}} <b>{{iterationData.itemName}} </b>
            </span>
            <span  class="first-column-spam" > 
              {{$t('Products.GridWithButton.crossSelling')}}  <b>{{ iterationData.crossSellingOrders}}</b>
            </span>
          </div>
          <hr style="margin-top:60px;border-top: 2px solid #8c8b8b;">
        </v-col>

        <v-col v-if="!mobile" md="3" cols="3" class="pa-0 column-value" style="position: relative;">
              <v-btn style="width:50%; position:absolute; bottom:6px; left:25%" 
                @click="updateData(iterationData.skuId, iterationData.itemName)"
                small
                color="primary"
                dark
                :elevation="0"
                >
                {{$t('Products.GridWithButton.button')}} 
            </v-btn>
        </v-col>

        <v-col v-if="mobile" md="3" cols="3" class="pa-0 column-value" style="position: relative;">
              <v-btn style="height:20px;width:20px; " 
                @click="updateData(iterationData.skuId, iterationData.itemName)"
                class="mx-3"
                fab
                dark
                color="primary"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
            </v-btn>
        </v-col>
       
      </v-row>
    </v-card-text>



</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'

export default {
  components: {  },
  props: [
    'cardData',
  ],
  data: function() {
    return {
    }
  },
  computed: {
    ...mapState([
      "mobile"
    ])
  },
  methods: {
    ...mapMutations([
      "setCurrentItem",
      "setDialogValue",
      "setItemActionCaller",
      "setCurrentItemName"
    ]),
    ...mapActions([
      "getCrossSellingItemData",
    ]),
    updateData(item, itemName) {
      this.setCurrentItemName(itemName)
      this.setDialogValue(false)
      this.setItemActionCaller('crossSelling')
      this.setCurrentItem(item)
      this.setDialogValue(true)
      this.getCrossSellingItemData()
    },
  },

};
</script>

<style scoped lang="scss">
.raw-metric-card {
  display: flex !important;
  flex-direction: column;
  overflow-y: auto;
}

.span-left-bottom {
    position: absolute;
    bottom: 6px;
    left: 16px;
}

.first-column-spam {
    display:table;
    text-align: left;
}


.column-value {
  padding:6px 12px 6px 12px!important;
  max-height: 70px;
  min-height: 70px;
}

</style>