export default {
    /** This function fills the array with empty rows until reaches the required size 
     * @param {Array} rawData
     * @returns {Array} data filled with empty rows
     */ 
    
     fillData: function(rawData, pageSize) {
        var data = rawData.slice();
        if(rawData){
            if(rawData.length > pageSize) {
                var mod = (rawData.length) % pageSize 
                if (mod != 0){
                    var row = {}
                    for (var key in rawData[0]){
                        row[key] = ""
                    }
                    for (var i=0; i< (pageSize - mod) ; i++){
                        data.push(row)
                    }
                }
            }
        }
        return data
    },
    makeList: function() {
        var pages = []
        var biggerRowSteps = ['18','24']
        var maxContinuousRows = 12
        for (var i=0; i<maxContinuousRows; i++){
            pages.push((i+1).toString())
        }
        pages = pages.concat(biggerRowSteps)
        return pages
    },
}