<template>
  <v-progress-linear 
      v-if="isLoading"
      indeterminate
      color="cyan"
  ></v-progress-linear>

  <v-container v-else class="main-container">
    <div class="page-title"> {{ $t('agencyExtractors.title') }} </div>

    <custom-alerts />

    <extractors-table
        class="margin-between-components"
        title="Extractor"
        :componentData="this.extractorsData"
        description="Alta baja, modificacion y visualizacion de estado de los extractores de datos"
    />



  <overlay-loader />


  </v-container>
</template>

<script>
import OverlayLoader from '../google-ads-scripting/OverlayLoader.vue'
import { mapActions, mapState} from 'vuex'
import CustomAlerts from '../shared/CustomAlerts.vue'
import ExtractorsTable from '../agency-extractors/ExtractorsTable.vue'

export default {
  components: { 
    OverlayLoader,
    CustomAlerts,
    ExtractorsTable,
    },
methods: {
  ...mapActions([
    "getAgencyIntegrations" 
  ]),
},
computed: {
  ...mapState([
    "showAlert",
    "successAlertMessage",
    "agencyJobsExecutionData",
    "loadingAgencyIntegrations"
  ]),
  isLoading() {
    if ( this.agencyJobsExecutionData != null && !this.loadingAgencyIntegrations){
      return false
    }
    return true
  },
  extractorsData() {
    return this.agencyJobsExecutionData['components'][0]['data']
  }
},
created: function () {
    this.getAgencyIntegrations()
}
}
</script>

<style>
.main-container {
  max-width: 100%;
  padding: 12px 30px 12px 30px;
}

 .margin-between-components {
   margin-top:24px;
 } 
</style>