<template>
  <v-card
    color="#FFFFFF"
    class="job-status-card"

  >
      <v-card-title >
        <v-row no-gutters style="width:100%;">

        <v-col  md="12" cols="12">
          <div style="display:flex">
            <div  class="graph-title">
            {{ this.title }}
            </div>
        <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 style="margin-left: 5px">
                mdi-information-outline
              </v-icon>
            </template>
            <span class="tooltip">
              {{ description }}
            </span>
          </v-tooltip>
          </div>
        </v-col>

        <v-col  md="12" cols="12">
        <v-card-text class="raw-metric-card" >
        <v-row  no-gutters style="  max-height: 90px; margin-bottom:20px">
            <v-col v-if="!mobile" md="4" cols="4" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" >
                <span class="first-column-spam" > 
                 <b>{{$t('Settings.JobStatus.id')}} </b>
                </span>
            </div>
            </v-col> 
            <v-col v-if="!mobile" md="4" cols="4" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" >
                <span class="first-column-spam" > 
                 <b>{{$t('Settings.JobStatus.type')}}</b>
                </span>
            </div>
            </v-col>
            <v-col v-if="!mobile" md="2" cols="2" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" style="margin-left:11%">
                <span class="first-column-spam" > 
                 <b>{{$t('Settings.JobStatus.status')}}</b>
                </span>
            </div>
            </v-col>
            <v-col v-if="!mobile" md="1" cols="1" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" style="margin-left:13%">
                <span class="first-column-spam" > 
                 <b>{{$t('Settings.JobStatus.edit')}}</b>
                </span>
            </div>
            </v-col>
            <v-col v-if="!mobile" md="1" cols="1" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" style="margin-left:7%">
                <span class="first-column-spam" > 
                 <b>{{$t('Settings.JobStatus.delete')}}</b>
                </span>
            </div>
            </v-col>

            <!-- mobile -->
            <v-col v-if="mobile" md="5" cols="5" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" >
                <span class="first-column-spam" > 
                 <b>{{$t('Settings.JobStatus.idMobile')}} </b>
                </span>
            </div>
            </v-col> 
            <v-col v-if="mobile" md="5" cols="4" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" >
                <span class="first-column-spam" > 
                 <b>{{$t('Settings.JobStatus.type')}}</b>
                </span>
            </div>
            </v-col>
            <v-col v-if="mobile" md="2" cols="3" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" style="margin-left:11%">
                <span class="first-column-spam" > 
                 <b>{{$t('Settings.JobStatus.status')}}</b>
                </span>
            </div>
            </v-col>
        </v-row>
        <v-row v-for="(iterationData, idx) in this.componentData['components'][0]['data']['details']" :key="idx" no-gutters style="  max-height: 90px;">
            <v-col md="4" cols="4" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" >
                <span class="first-column-spam" style="position:absolute;bottom:2px"> 
                  <img v-if="iterationData.id == 'vtex'" :src="require('@/assets/vtex.png')" style="height:25px"/>
                  <img v-if="iterationData.id == 'google_analytics'" :src="require('@/assets/google_analytics.png')" style="height:25px"/>
                  <img v-if="iterationData.id == 'facebook'" :src="require('@/assets/facebook.png')" style="height:25px"/>
                  <img v-if="iterationData.id == 'google_ads'" :src="require('@/assets/google_ads.png')" style="height:25px"/>
                </span>
            </div>
            </v-col>

            <v-col md="4" cols="4" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" >
                <span class="first-column-spam" style="position:absolute;bottom:10px" > 
                 {{iterationData.type}}
                </span>
            </div>
            </v-col>
            <v-col md="2" cols="2" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" >
                <span v-if="(iterationData.type != 'HOURLY')" class="first-column-spam" style="margin-left:17%"> 
                  <img v-if="iterationData.status == 'FINISHED'" :src="require('@/assets/finished.png')" style="height:25px"/>
                  <img v-if="iterationData.status == 'RUNNING'" :src="require('@/assets/running.png')" style="height:25px"/>
                  <img v-if="iterationData.status == 'ERROR'" :src="require('@/assets/error.png')" style="height:25px"/>
                  <img v-if="iterationData.status == 'IN_PROCESS'" :src="require('@/assets/in_process.png')" style="height:25px"/>
                  <form-modal 
                    v-if="iterationData.status == 'DISABLED'" 
                    style="margin-top: -15px;"
                    :dataSource="iterationData.id"
                    :index="idx"
                    />
                </span>
                  <v-dialog v-else
                    v-model="dialog"
                    max-width="500px"
                  >
                    <template v-slot:activator="{ on, attrs }" >
                      <v-btn  style="width:50%; position:absolute; bottom:2px; left:0px"
                        color="primary"
                        dark
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                      {{hourlyJobsAmount}}/24
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title>
                        <span style="margin: 10px 0 0 10px" class="text-h5">{{ $t('Settings.JobStatus.VTEXHourlyJobs') }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <!-- Dialog content -->
                          <v-row  no-gutters style="  max-height: 90px; margin-bottom:20px">
                              <v-col v-if="!mobile" md="5" cols="5" class="column-value " style="width: 100%;position: relative;">
                              <div class="span-left-bottom" >
                                  <span class="first-column-spam" > 
                                  <b>{{$t('Settings.JobStatus.startTime')}} </b>
                                  </span>
                              </div>
                              </v-col> 
                              <v-col v-if="!mobile" md="5" cols="5" class="column-value " style="width: 100%;position: relative;">
                              <div class="span-left-bottom" >
                                  <span class="first-column-spam" > 
                                  <b>{{$t('Settings.JobStatus.endTime')}}</b>
                                  </span>
                              </div>
                              </v-col>
                              <v-col v-if="!mobile" md="2" cols="2" class="column-value " style="width: 100%;position: relative;">
                              <div class="span-left-bottom" style="margin-left:11%">
                                  <span class="first-column-spam" > 
                                  <b>{{$t('Settings.JobStatus.status')}}</b>
                                  </span>
                              </div>
                              </v-col>

                              <!-- mobile -->
                              <v-col v-if="mobile" md="4" cols="4" class="column-value " style="width: 100%;position: relative;">
                              <div class="span-left-bottom" >
                                  <span class="first-column-spam" > 
                                  <b>{{$t('Settings.JobStatus.startTime')}} </b>
                                  </span>
                              </div>
                              </v-col> 
                              <v-col v-if="mobile" md="4" cols="4" class="column-value " style="width: 100%;position: relative;">
                              <div class="span-left-bottom" >
                                  <span class="first-column-spam" > 
                                  <b>{{$t('Settings.JobStatus.endTime')}}</b>
                                  </span>
                              </div>
                              </v-col>
                              <v-col v-if="mobile" md="4" cols="4" class="column-value " style="width: 100%;position: relative;">
                              <div class="span-left-bottom" style="margin-left:11%">
                                  <span class="first-column-spam" > 
                                  <b>{{$t('Settings.JobStatus.status')}}</b>
                                  </span>
                              </div>
                              </v-col>
                          </v-row>
                          <v-row v-for="(iterationData, idx) in componentData['components'][0]['data']['details'][hourlyIndex]['executions']" :key="idx" no-gutters style="  max-height: 90px;">
                              <v-col v-if="!mobile" md="5" cols="5" class="column-value " style="width: 100%;position: relative;">
                              <div class="span-left-bottom" >
                                  <span class="first-column-spam" > 
                                  {{iterationData.startTime}} 
                                  </span>
                              </div>
                              </v-col>

                              <v-col v-if="!mobile" md="5" cols="5" class="column-value " style="width: 100%;position: relative;">
                              <div class="span-left-bottom" >
                                  <span class="first-column-spam" > 
                                  {{iterationData.endTime}}
                                  </span>
                              </div>
                              </v-col>
                              <v-col v-if="!mobile" md="2" cols="2" class="column-value " style="width: 100%;position: relative;">
                              <div class="span-left-bottom" >
                                  <span  class="first-column-spam" style="margin-left:17%"> 
                                    <img v-if="iterationData.status == 'FINISHED'" :src="require('@/assets/finished.png')" style="height:25px"/>
                                    <img v-if="iterationData.status == 'RUNNING'" :src="require('@/assets/running.png')" style="height:25px"/>
                                    <img v-if="iterationData.status == 'ERROR'" :src="require('@/assets/error.png')" style="height:25px"/>
                                  </span>

                              </div>
                              </v-col>
                              <!-- mobile -->
                              <v-col v-if="mobile" md="4" cols="4" class="column-value " style="width: 100%;position: relative;">
                              <div class="span-left-bottom" >
                                  <span class="first-column-spam" > 
                                  {{iterationData.startTime}} 
                                  </span>
                              </div>
                              </v-col>
                              <v-col v-if="mobile" md="4" cols="4" class="column-value " style="width: 100%;position: relative;">
                              <div class="span-left-bottom" >
                                  <span class="first-column-spam" > 
                                  {{iterationData.endTime}}
                                  </span>
                              </div>
                              </v-col>
                              <v-col v-if="mobile" md="4" cols="4" class="column-value " style="width: 100%;position: relative;">
                              <div class="span-left-bottom" >
                                  <span  class="first-column-spam" style="margin-left:17%"> 
                                    <img v-if="iterationData.status == 'FINISHED'" :src="require('@/assets/finished.png')" style="height:25px"/>
                                    <img v-if="iterationData.status == 'RUNNING'" :src="require('@/assets/running.png')" style="height:25px"/>
                                    <img v-if="iterationData.status == 'ERROR'" :src="require('@/assets/error.png')" style="height:25px"/>
                                  </span>

                              </div>
                              </v-col>

                          <v-col  md="12" cols="12"><v-divider style="margin-bottom:10px "></v-divider></v-col>
                          
                          </v-row>

                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red darken-1"
                          text
                          @click="close"
                        >
                          {{ $t('Settings.JobStatus.close') }}
                        </v-btn>
                        
                      </v-card-actions>
                    </v-card>

                  </v-dialog>
                  <v-dialog :retain-focus="false" v-model="dialogDelete" max-width="500px">
                    <v-card style="text-align:center">
                      <v-card-title class="text-h6 app-font" style="text-align:center;margin-left:10px"> {{ $t('alerts.integrations.deleteConfirmation') }} </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text  @click="deleteItemConfirm">{{ $t('Settings.UsersTable.dialogButtons.delete') }}</v-btn>
                        <v-btn color="red darken-1" text @click="closeDelete" >{{ $t('Settings.UsersTable.dialogButtons.cancel') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
            </div>
            </v-col>
            <v-col md="1" cols="1" class="column-value " style="width: 100%;position: relative;">
            <div class="span-left-bottom" style="margin-left: 9%">
                <edit-modal
                  :dataSource="iterationData.id"
                  :index="idx"  
                />

            </div>
            </v-col>
            <v-col md="1" cols="1" class="column-value " style="width: 100%;position: relative;padding:6px">
            <div class="span-left-bottom" >
                <img class="LogoAnimation" :src="require('@/assets/eliminar.png')" style="height:20px;left:0" v-on:click="deleteItem(idx)"/>
            </div>
            </v-col>

        <v-col  md="12" cols="12"><v-divider style="margin-bottom:10px "></v-divider></v-col>
        </v-row>
        
        </v-card-text>
        </v-col>
      </v-row>
    </v-card-title>

  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import EditModal from './editModal.vue';
import FormModal from './FormModal.vue';

export default {
  components: {FormModal, EditModal  },
  props: [
    'description',
    'componentData',
    'title'
  ],
  data: function() {
    return {
      dialog: false,
      dialogDelete: false,
      index: null,
      deleteLoading:false,
    }
  },
  computed: {
    hourlyIndex(){
        for(var i=0; i< this.componentData['components'][0]['data']['details'].length; i++){
          if (this.componentData['components'][0]['data']['details'][i]['id'] == 'vtex' && this.componentData['components'][0]['data']['details'][i]['type'] == 'HOURLY')
            return i
        }
        return 0
    },
    hourlyJobsAmount(){
        return this.componentData['components'][0]['data']['details'][this.hourlyIndex]['executions'].length
    },
    ...mapState([
      "mobile",
      "jobsExecutionData"
    ]),
    isIntegrationActive(){
        return this.jobsExecutionData['components'][0]['data']['details'][this.index]['status'] != 'DISABLED'
    }
  },
  methods: {
    ...mapMutations([
      "setDialogValue",
    ]),
    ...mapActions([
      "deleteIntegration",
    ]),
    close () {
      this.dialog = false
    },
    deleteIntegrationTrigger(idx){
      if(this.componentData['components'][0]['data']['details'][idx]['status'] != 'DISABLED' ){
        let data_source = this.componentData['components'][0]['data']['details'][idx]['id']
        let data = {
          "data_source": data_source,
          "index": idx
        }
        this.deleteIntegration({ data })
      }
    },
    deleteItem (idx) {
    this.index = idx
    if(this.componentData['components'][0]['data']['details'][this.index]['status'] != 'DISABLED' ){
      this.dialogDelete = true
    }
    },
    deleteItemConfirm () {
      this.deleteIntegrationTrigger(this.index)
      this.closeDelete() 
    },
    closeDelete () {
      this.dialogDelete = false
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

};
</script>

<style scoped lang="scss">
.job-status-card {
  max-height: 420px;
  padding:24px;

}

.v-card__title {
  padding:0!important;
  text-align: left;
  //padding-bottom: 12px;
}

.card-title {
  margin: 5px;
}

.raw-metric-card{
  padding-bottom: 0!important;
}

.LogoAnimation:hover {
  opacity: 0.75;
  cursor: pointer;
}

</style>