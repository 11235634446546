<template>

    <v-row no-gutters class="orders-status">

      <v-col md="6" cols="12" style="height: 360px;padding-right:12px" >
        <v-card
          color="#FFFFFF"
          class="card"
        >
        <v-col cols="12" style="height: 24px; position:relative;margin-top:-10px">
          <span class="graph-title" style="margin:-10px 0 0 -10px" >
          {{ title1 }}
          <v-tooltip bottom max-width="400">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                  mdi-information-outline
                </v-icon>
              </template>
              {{ description1 }}
              <data-sources 
                :sources="['vtex']"
              />
            </v-tooltip>
            <v-radio-group style=""
                      v-model="dataHorizontalBars"
                      row
                      class="selector"
                  >
                      <v-radio
                      label="#"
                      value="quantity"
                      ></v-radio>
                      <v-radio
                      label="$"
                      value="revenue"
                      ></v-radio>
                  </v-radio-group>
          </span>
        </v-col> 
        <horizontal-bar-graph
         :dataSource="this.pieData"
         bindingName="bindingX"
         :binding="this.dataHorizontalBars"
        />
        </v-card>
      </v-col>  
       
      <v-col md="6" cols="12" style="height: 360px;padding-left:12px">
        <v-card
          color="#FFFFFF"
          class="card"
        >
        <v-col cols="12" style="height: 24px; position:relative;margin-top:-10px">
          <span class="graph-title" >
          {{ title2 }}
          <v-tooltip bottom max-width="400">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                  mdi-information-outline
                </v-icon>
              </template>
              {{ description2 }}
              <data-sources 
                :sources="['vtex']"
              />
            </v-tooltip>
            <v-radio-group style=""
                      v-model="dataVerticalBars"
                      row
                      class="selector"
                  >
                      <v-radio
                      label="#"
                      value="quantity"
                      ></v-radio>
                      <v-radio
                      label="$"
                      value="revenue"
                      ></v-radio>
                  </v-radio-group>
          </span>
        </v-col> 
        <bars-graphic
          :groups="this.groups"
          :barsQuantityData="this.procesedQuantityData"
          :barsRevenueData="this.procesedRevenueData"
          :binding="this.dataVerticalBars"
        />
        </v-card>
      </v-col>      
    </v-row>
  
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import DataFilterHelper from "@/helpers/dataFilter.helper";
import BarsGraphic from './BarsGraphic.vue';
import DataSources from './DataSources.vue';
import HorizontalBarGraph from './HorizontalBarGraph.vue';

export default {
  name: "TwoGraphs",
  props: [
    "title1",
    "description1",
    "title2",
    "description2",
    "bindingName",
    "pieData",
    "barsGraphData",
    "sources",
    "bindingLabel"
  ],
  components: {
    BarsGraphic,
    DataSources,
    HorizontalBarGraph,
  },
  data: function() {
    return {
      dataVerticalBars:"quantity",
      dataHorizontalBars:"quantity",
    }
  },
  computed: {
    truncatedChannelsData() {
      return DataFilterHelper.getTruncatedData(this.pieData)
    },
    procesedQuantityData() {
      return DataFilterHelper.dateYearFilter(this.barsGraphData.quantityDataByDate)
    },
    procesedRevenueData() {
      return DataFilterHelper.dateYearFilter(this.barsGraphData.revenueDataByDate)
    },
    groups() {
      return this.getGroups()
    },
  },
  methods: {
    getGroups(){
      let keys = Object.keys(this.barsGraphData.quantityDataByDate[0])
      keys = keys.filter(function(key) {
        return key !== 'date'; 
      });
      let result = []
      for (let i=0; i<=keys.length-1; i++){
        let dicc = {
          grupoName: keys[i],
          grupoId: keys[i]
        }
        result.push(dicc)
      }
      return result;
    },

  }
};
</script>

<style scoped lang="scss">
.orders-status {

  .wj-flexchart {
    padding: 0px !important;
  }
}
.status-title {
  color: #6f6f6f;
  padding-left: 20px;
  //styleName: S Semibold;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.card {
  padding:24px;
  height: 360px;
}

.selector {
  display: inline-block; 
  margin: 0px; 
  padding-left: 10px; 
  height: 30px; 
  position:absolute; 
  right: 0;
  bottom: -15px;
}
</style>