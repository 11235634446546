<template>

  <v-progress-linear 
      v-if="isLoading"
      indeterminate
      color="cyan"
  ></v-progress-linear>

  <v-container v-else class="main-container">
  <div v-if="!this.unavaibleIntegration">
    <div class="page-title"> {{ $t('Traffic.PageTitle')}}  </div>

    <error v-if="error" style="margin-top: 10px"
      :errorMessage=" $t('errorMessage')" 
    />

    <v-row justify-content="flex-end">
      <time-range-filter-analytics
      :componente= "componentName" />
    </v-row>

    <v-row align="center" justify="space-between" style="margin-top: 32px">

      <v-col md="2" cols="6" >
        <raw-metric-card 
          :title = "$t('Traffic.trafficCards.card-1.title')"
          :description= "$t('Traffic.trafficCards.card-1.description')"
          :value= this.thousandSeparatorCall(getCardsData[0].value.toFixed(0))
          :changeRate= getCardsData[0].changeRate.toFixed(2)
          :sources="['googleAnalytics']"
        />
      </v-col>
      <v-col md="2" cols="6" >
        <raw-metric-card 
          :title = "$t('Traffic.trafficCards.card-2.title')"
          :description= "$t('Traffic.trafficCards.card-2.description')"
          :value= this.thousandSeparatorCall(getCardsData[1].value.toFixed(2))
          :changeRate= getCardsData[1].changeRate.toFixed(2)
          :sources="['googleAnalytics']"
        />
      </v-col>
      <v-col md="2" cols="6" >
        <raw-metric-card 
          :title = "$t('Traffic.trafficCards.card-3.title')"
          :description= "$t('Traffic.trafficCards.card-3.description')"
          :value= this.thousandSeparatorCall(getCardsData[2].value.toFixed(0))
          :changeRate= getCardsData[2].changeRate.toFixed(2)
          :sources="['googleAnalytics']"
        />
      </v-col>
      <v-col md="2" cols="6" >
        <raw-metric-card 
          :title = "$t('Traffic.trafficCards.card-4.title')"
          :description= "$t('Traffic.trafficCards.card-4.description')"
          :value= this.thousandSeparatorCall(getCardsData[3].value.toFixed(0))
          :changeRate= getCardsData[3].changeRate.toFixed(2)
          :sources="['googleAnalytics']"
        />
      </v-col>
    </v-row>

    <v-row >
      <v-col class="pa-0" >
        <info-cards-group
          :infoCardsData= getBigCardsData
        />
      </v-col>      
    </v-row>

    <v-row >
      <v-col  style=" margin-top: 24px;padding:0 12px 0 12px">
        <SessionsVSTransactions
        :sessionVsTransactionsData= "getSessionsVsTransactionsData"
        />
      </v-col>      
    </v-row>

    <v-row >
      <v-col class="margin-between-components " style="padding:0 12px 0 12px">
        <convertion-rate
        :convertionRateData= "getConvertionRateData"
        />
      </v-col>      
    </v-row>

    <v-row >
      <v-col class="margin-between-components" style="padding:0 12px 0 12px">
        <converting-keywords
        :convertionKeywordsData= "getConvertionKeywordsData"
        />
      </v-col>      
    </v-row>
  
    <v-row >
      <v-col class="margin-between-components" style="padding:0 12px 0 12px" >
        <traffic-revenue
        :revenueData = "getRevenueData"
        />
      </v-col>      
    </v-row>
  
    <v-row v-if="!this.mobile" >
      <v-col class="margin-between-components" style="padding:0 12px 0 12px" >
        <source-medium
          :sourceMediumData= "getSourceMediaData"
          :sourceMediumColumns= "getSourceMediaColumns"
        />
      </v-col>      
    </v-row>

    <v-row v-if="!this.mobile" >
      <v-col class="margin-between-components " style="margin-bottom: 128px; padding:0 12px 0 12px ">
        <channel-grouping
        :channelGroupingData = "getChannelGroupingData"
        />
      </v-col>      
    </v-row>

    <v-row v-if="this.mobile" >
      <v-col class="margin-between-components " style="margin-bottom: 128px ">

      </v-col>      
    </v-row>
  </div>
  <div  v-else>
    <v-card style="width:600px">
      <h2 class="app-font" style="padding:12px"> {{ $t('integrations.unavaible') }}</h2>
    </v-card>
  </div>

  </v-container>
</template>

<script>
import RawMetricCard from '@/components/shared/RawMetricCard.vue';
import SessionsVSTransactions from '../traffic/SessionsVSTransactions.vue';
import ConvertionRate from '../traffic/ConvertionRate.vue';
import TrafficRevenue from '../traffic/TrafficRevenue.vue';
import SourceMedium from '../traffic/SourceMedium.vue';
import ChannelGrouping from '../traffic/ChannelGrouping.vue';
import {mapActions, mapState, mapMutations} from 'vuex'
import ConvertingKeywords from '../traffic/ConvertingKeywords.vue';
import TimeRangeFilterAnalytics from '../shared/TimeRangeFilterAnalytics.vue';
import Error from '../errors/error.vue';
import ThousandSeparator from "@/helpers/thousandSeparator.helper";
import InfoCardsGroup from '../traffic/InfoCardsGroup.vue';

export default {
  name: "Traffic",

  components: {
    RawMetricCard,
    SessionsVSTransactions,
    ConvertionRate,
    TrafficRevenue,
    SourceMedium,
    ChannelGrouping,
    ConvertingKeywords,
    TimeRangeFilterAnalytics,
    Error,
    InfoCardsGroup,
    
  },
  data: () => ({
    moneyString: "$",
    componentName: "Traffic"
  }),
  computed:{

    ...mapState([
    "trafficPageData",
    "loadingTrafficPageData",
    "error",
    "mobile",
    "demo"
    ]),
    isLoading() {
        if ( this.trafficPageData != null && !this.loadingTrafficPageData){
          return false
        }
        return true
      },
    getCardsData() {
      return this.trafficPageData['components'][0]['cards'];
    },
    getSessionsVsTransactionsData() {
      return this.trafficPageData['components'][1]['data'];
    },
    getConvertionRateData() {
      return this.trafficPageData['components'][2]['data'];
    },
    getConvertionKeywordsData() {
      return this.trafficPageData['components'][3]['data'];
    },
    getRevenueData() {
      return this.trafficPageData['components'][4]['data'];
    },
    getSourceMediaData() {
      return this.trafficPageData['components'][5]['data'];
    },
    getSourceMediaColumns() {
      return this.trafficPageData['components'][5]['columns'];
    },
    getChannelGroupingData() {
      return this.trafficPageData['components'][6]['data'];
    },
    getBigCardsData() {
      return this.trafficPageData['components'][7]['cards'];
    },
    unavaibleIntegration(){
      if (this.trafficPageData == 'integration unavaible')
        return true
      else
        return false
    }
  },
  methods: {
    ...mapActions([
      "getTrafficPageData",
      "loggedInCheck"      
    ]),
    ...mapMutations([
      "setMobile",   
      "setTrafficPageData"
    ]),
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.setMobile(true)
        return true
      } else {
        return false
      }
    },
    thousandSeparatorCall(value) {
      return ThousandSeparator.formatNumber(value)
    },  
  },
  created: function () {
    this.isMobile();
    if(!this.demo){
    this.loggedInCheck()
    this.getTrafficPageData();
      }
    else{
      var data = {};
      data = require('../../json/traffic.json');
      this.setTrafficPageData(data);
    }
  },
};
</script>

<style scoped lang="scss">
.main-container {
  max-width: 100%;
  padding: 12px 30px 12px 30px;
}
  
.margin-between-components {
  margin-top:24px;
} 
</style>