import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import moment from 'moment'
import * as wjcCore from "@grapecity/wijmo";
import axios from './services/api.js';
import VueGtag from "vue-gtag";
import Hotjar from 'vue-hotjar'
import '@grapecity/wijmo.cultures/wijmo.culture.es-419';
import "@grapecity/wijmo.vue2.input";
import router from './router'
import i18n from './i18n'
import VueFormJsonSchema from 'vue-form-json-schema';



Vue.component('vue-form-json-schema', VueFormJsonSchema);

wjcCore.setLicenseKey(
  "localhost.com|localhost|appspot.com,448449681733495#B0LoRR48kRFVDRvIlazAHMEh5byF7dxpnRoNUQ4RVRvhmWFF7Kpd7S6cGR0xUOjJVVKFzVWNlZyAXTy5WSKZHSJ3CbFNHUmFjTxNTM4ljWjxkdrImNndTQuhme7o6QsFUc7YzNKpnWSZle9MnTmhWMBRlaapXYwMUZkRlaaNVZxpESTdHT9x6Q7QlYjRGW4tEO8lTcTNUZwM7ZzhWOvE5atRWTptEautmN7llNwN6ZUFzb7VWeItSeKZTaY9UVElneJZjQKdENtZTMvtmbpJXO84GZN5GWWNTVnB7VkRGMhllSo9EdkZTNQhXdzwmTTV5K9RWeBRFbm3iQOFWdvx4UWRnZCZmI0IyUiwiI9MEMFlzQ5YjI0ICSiwCO8QzNyATNwITM0IicfJye35XX3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTis7W0ICZyBlIsISM4QDMyADI5EzNwAjMwIjI0ICdyNkIsISbvNmLllXZzV7cuV6cuUmcvNWM7xCdz3GasF6YvxGLt36YuUWelNXdz9WZz9Cdz3GasF6YvxmI0IyctRkIsIiLj9WSgwycjlmblpXRiojIh94QiwiI5kDNzMzNxgjN9QjM8QjMiojIklkIs4nIzYXOxAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TU03WUzFVYzcTbStCbmVzLC36ZxxWbEt6VZRlTrVGV53CdMFFOLBlRuljNTR6KzUFW7RDdwZVewhDcrJnMZJmZ6o7dR9UbEBzVvdmawMGVZNna"
);

axios.interceptors.request.use(
  (config) => {
      const token = localStorage.getItem('token');      
      if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
  }
);

Vue.use(VueGtag, {
  config: { id: "G-KD9QZ2TX39" }
});
Vue.use (Hotjar, {
  id: '2629097',
  isProduction: true
})

Vue.config.productionTip = false;

Vue.prototype.moment = moment

if( window.location.host != 'localhost:8081' && window.location.host != 'localhost:8080')
  if (location.protocol !== 'https:') {
    location.replace(`https:${location.href.substring(location.protocol.length)}`);
  }

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
