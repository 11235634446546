var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!this.mobile)?_c('v-card',{staticClass:"card",attrs:{"color":"#FFFFFF"}},[_c('v-row',{staticClass:"best-seller-items",attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"height":"20px"},attrs:{"md":"6","cols":"12"}},[_c('span',{staticClass:"table-title"},[_vm._v(" "+_vm._s(_vm.$t('googleAdsScripting.scriptRegisterTable.title'))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mdi-information-margins",attrs:{"color":"#a6b0c3","size":"14"}},on),[_vm._v(" mdi-information-outline ")])]}}],null,false,1981463576)},[_vm._v(" "+_vm._s(_vm.$t('googleAdsScripting.scriptRegisterTable.description'))+" ")])],1)]),_c('v-col',{staticClass:"right-content",attrs:{"md":"4","cols":"12"}}),(this.userRole == 'ADMIN' || this.userRole == 'admin')?_c('v-col',{staticClass:"right-content",attrs:{"md":"2","cols":"12"}}):_vm._e(),(!this.mobile)?_c('v-col',{staticStyle:{"margin-top":"16px"},attrs:{"md":"12","cols":"12"}},[_c('v-data-table',{staticClass:"users-table",attrs:{"headers":_vm.headers,"items":this.data,"items-per-page":10,"sort-by":['update_date'],"sort-desc":[true],"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        itemsPerPageText: 'Scripts por página'
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t('googleAdsScripting.scriptRegisterTable.deletScriptConfirm')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$t('agencyClients.agencyClientsTable.closeButton')))]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(_vm._s(_vm.$t('agencyClients.agencyClientsTable.deleteButton')))])],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" mdi-eye ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,false,2551342365)})],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }