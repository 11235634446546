<template>
  <v-card
    color="#FFFFFF"
    class="card"
  >

    <v-row no-gutters class="flex-pie" >
      <v-col cols="12" style = "position:relative;" >
        <span class="graph-title">
          {{ this.title }}
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins" >
                mdi-information-outline
              </v-icon>
            </template>
            {{ this.description }}
            <data-sources
              :sources="['vtex']"
            />
          </v-tooltip>
          <v-radio-group v-if="this.vradio != null"
                    v-model="data"
                    row
                    class="selector"
                >
                    <v-radio
                    label="#"
                    value="quantity"
                    ></v-radio>
                    <v-radio
                    label="$"
                    value="revenue"
                    ></v-radio>
                </v-radio-group>
        </span>
      </v-col> 

      <v-col cols="12">
        <horizontal-bar-graph 
         :dataSource="this.itemSource"
          bindingName="bindingX"
         :binding="this.data"
        />
      </v-col> 
    </v-row>
  </v-card>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import DataSources from './DataSources.vue';
import HorizontalBarGraph from './HorizontalBarGraph.vue';

export default {
  components: { DataSources, HorizontalBarGraph },
  name: "HorizontalBarGraphContainer",
  props: [
    'title',
    'itemSource',
    'description',
    'sources',
    'vradio',
    'binding',
    'bindingLabel',
  ],
  data: function() {
    return {
      data:"quantity",
    };
  },
  computed: {
  },
  methods: {
    setBinding() {
      if (this.binding != null) {
        this.data = this.binding
      }
    }
  },
created() {
  this.setBinding()
}
}
</script>

<style scoped lang="scss">

.card {
  padding:12px;
}

.selector {
  display: inline-block; 
  margin: 0px; 
  padding-left: 5px; 
  height: 30px; 
  position:absolute; 
  right: 0;
  bottom: -2px;
}

</style>