<template>
    <div v-if="!mobile">

      <v-navigation-drawer
        v-model="drawer"
        :mini-variant="mini"
        app

      >
        <v-list-item class="px-2">
          <v-list-item-avatar :tile="true">
            <v-img :contain="true" >
              <svg viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" @click.stop="mini = !mini">
                <path 
                  d="M6.19875 1.65956e-09H11.7987C11.8518 1.65956e-09 11.9027 0.0210743 11.9402 0.0585815C11.9777 0.0960888 11.9987 0.146957 11.9987 0.2V10H6.99875C6.73353 10 6.47918 10.1054 6.29164 10.2929C6.1041 10.4804 5.99875 10.7348 5.99875 11V19.8C5.99875 19.853 5.97768 19.9039 5.94017 19.9414C5.90266 19.9789 5.85179 20 5.79875 20H2.246C2.17173 20 2.09892 19.9793 2.03574 19.9403C1.97255 19.9013 1.92148 19.8454 1.88825 19.779L0.0632477 16.1268C0.0217012 16.0433 5.37205e-05 15.9514 0 15.8583V10.2C0 10.147 0.0210712 10.0961 0.0585785 10.0586C0.0960858 10.0211 0.146957 10 0.2 10H5C5.26522 10 5.51957 9.89464 5.7071 9.7071C5.89464 9.51957 6 9.26522 6 9V0.2C6 0.147173 6.0209 0.0964926 6.05814 0.059021C6.09537 0.0215494 6.14592 0.000330173 6.19875 1.65956e-09ZM17.9987 19.8V11C17.9987 10.7348 18.1041 10.4804 18.2916 10.2929C18.4792 10.1054 18.7335 10 18.9987 10H23.7987C23.8518 10 23.9027 9.97893 23.9402 9.94142C23.9777 9.90391 23.9987 9.85304 23.9987 9.8V4.14175C23.9987 4.04856 23.977 3.95666 23.9355 3.87325L22.1092 0.220996C22.076 0.154578 22.0249 0.098725 21.9618 0.0596924C21.8986 0.0206597 21.8258 -6.76178e-06 21.7515 1.65956e-09H18.1987C18.1457 1.65956e-09 18.0948 0.0210743 18.0573 0.0585815C18.0198 0.0960888 17.9987 0.146957 17.9987 0.2V9C17.9987 9.26522 17.8934 9.51957 17.7059 9.7071C17.5183 9.89464 17.264 10 16.9987 10H11.9987V19.8C11.9987 19.853 12.0198 19.9039 12.0573 19.9414C12.0948 19.9789 12.1457 20 12.1987 20H17.7987C17.8518 20 17.9027 19.9789 17.9402 19.9414C17.9777 19.9039 17.9987 19.853 17.9987 19.8Z" 
                  fill="#02080F" fill-opacity="0.85"
                />              </svg>
              
            </v-img>
          </v-list-item-avatar>

          <v-list-item-title class="graph-title"
            >Heroics</v-list-item-title>

          <v-btn
            icon
            @click.stop="mini = !mini"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list v-if="this.clientType != 'AGENCY'" dense>
          <v-list-item
            v-for="(sectionValue, sectionKey) in pageSections"
            :key="sectionValue.icon"
            @click="openSection(sectionKey)"
            :input-value="isOpenSection(sectionKey)"
            router
            :to="sectionValue.path"
          >
            <v-list-item-icon>
              <v-icon>{{ sectionValue.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title style="
                color: #02080F80;
                font-family: Open Sans, sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;">
              {{ $t('nav.'+sectionValue.text) }}
            </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-if="this.clientType == 'AGENCY'" dense>
          <v-list-item
            v-for="(sectionValue, sectionKey) in agencySections"
            :key="sectionValue.icon"
            @click="openSection(sectionKey)"
            :input-value="isOpenSection(sectionKey)"
            router
            :to="sectionValue.path"
          >
            <v-list-item-icon>
              <v-icon>{{ sectionValue.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title style="
                color: #02080F80;
                font-family: Open Sans, sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;">
              {{ $t('nav.'+sectionValue.text) }}
            </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <v-list dense>
            <v-list-item
            router
            to="/settings"
            >
                <v-list-item-icon>
                  <v-icon>{{ 'mdi-cog-outline' }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ $t('nav.settings')}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list dense>
            <v-list-item
              @click="setUserLogOut()"
            >
                <v-list-item-icon>
                  <v-icon>{{ 'mdi-logout' }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ $t('nav.logout')}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>
      <v-btn v-if="this.mobile"
        color="primary"
        dark
        fab
        fixed right bottom
        @click.stop="drawer = !drawer"
      >
        ☰
      </v-btn>
    </div>

    <div v-else>

      <v-navigation-drawer
        v-model="drawer"
        :mini-variant="true"
        app

      >
        <v-list-item class="px-2">
          <v-list-item-avatar :tile="true">
            <v-img :contain="true" >
              <svg viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path 
                  d="M6.19875 1.65956e-09H11.7987C11.8518 1.65956e-09 11.9027 0.0210743 11.9402 0.0585815C11.9777 0.0960888 11.9987 0.146957 11.9987 0.2V10H6.99875C6.73353 10 6.47918 10.1054 6.29164 10.2929C6.1041 10.4804 5.99875 10.7348 5.99875 11V19.8C5.99875 19.853 5.97768 19.9039 5.94017 19.9414C5.90266 19.9789 5.85179 20 5.79875 20H2.246C2.17173 20 2.09892 19.9793 2.03574 19.9403C1.97255 19.9013 1.92148 19.8454 1.88825 19.779L0.0632477 16.1268C0.0217012 16.0433 5.37205e-05 15.9514 0 15.8583V10.2C0 10.147 0.0210712 10.0961 0.0585785 10.0586C0.0960858 10.0211 0.146957 10 0.2 10H5C5.26522 10 5.51957 9.89464 5.7071 9.7071C5.89464 9.51957 6 9.26522 6 9V0.2C6 0.147173 6.0209 0.0964926 6.05814 0.059021C6.09537 0.0215494 6.14592 0.000330173 6.19875 1.65956e-09ZM17.9987 19.8V11C17.9987 10.7348 18.1041 10.4804 18.2916 10.2929C18.4792 10.1054 18.7335 10 18.9987 10H23.7987C23.8518 10 23.9027 9.97893 23.9402 9.94142C23.9777 9.90391 23.9987 9.85304 23.9987 9.8V4.14175C23.9987 4.04856 23.977 3.95666 23.9355 3.87325L22.1092 0.220996C22.076 0.154578 22.0249 0.098725 21.9618 0.0596924C21.8986 0.0206597 21.8258 -6.76178e-06 21.7515 1.65956e-09H18.1987C18.1457 1.65956e-09 18.0948 0.0210743 18.0573 0.0585815C18.0198 0.0960888 17.9987 0.146957 17.9987 0.2V9C17.9987 9.26522 17.8934 9.51957 17.7059 9.7071C17.5183 9.89464 17.264 10 16.9987 10H11.9987V19.8C11.9987 19.853 12.0198 19.9039 12.0573 19.9414C12.0948 19.9789 12.1457 20 12.1987 20H17.7987C17.8518 20 17.9027 19.9789 17.9402 19.9414C17.9777 19.9039 17.9987 19.853 17.9987 19.8Z" 
                  fill="#02080F" fill-opacity="0.85"
                />              </svg>
              
            </v-img>
          </v-list-item-avatar>

          <v-list-item-title class="graph-title"
            >Heroics</v-list-item-title>

          <v-btn
            icon
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list v-if="this.clientType != 'AGENCY'" dense>
          <v-list-item
            v-for="(sectionValue, sectionKey) in pageSections"
            :key="sectionValue.icon"
            @click="openSection(sectionKey)"
            :input-value="isOpenSection(sectionKey)"
            router
            :to="sectionValue.path"
          >
            <v-list-item-icon>
              <v-icon>{{ sectionValue.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title style="
                color: #02080F80;
                font-family: Open Sans, sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;">
              {{ $t('nav.'+sectionValue.text) }}
            </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>

        <v-list v-if="this.clientType == 'AGENCY'" dense>
          <v-list-item
            v-for="(sectionValue, sectionKey) in agencySections"
            :key="sectionValue.icon"
            @click="openSection(sectionKey)"
            :input-value="isOpenSection(sectionKey)"
            router
            :to="sectionValue.path"
          >
            <v-list-item-icon>
              <v-icon>{{ sectionValue.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title style="
                color: #02080F80;
                font-family: Open Sans, sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;">
              {{ $t('nav.'+sectionValue.text) }}
            </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>

        <template v-slot:append>
          <v-list dense>
            <v-list-item
            router
            to="/settings"
            >
                <v-list-item-icon>
                  <v-icon>{{ 'mdi-cog-outline' }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ $t('nav.settings')}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list dense>
            <v-list-item
              @click="setUserLogOut()"
            >
                <v-list-item-icon>
                  <v-icon>{{ 'mdi-logout' }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ $t('nav.logout')}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>
      <v-btn v-if="this.mobile"
        color="primary"
        dark
        fab
        fixed right bottom
        @click.stop="drawer = !drawer"
      >
        ☰
      </v-btn>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Sections from "@/enums/sections.enum.js";
import AgencySections from "@/enums/agency-sections.enum.js";

export default {
  name: 'SideNavegation',
  components: {
  },
  data: () => ({ 
    drawer: true,
    mini: false,
  }),
  computed: {
    ...mapState([
      "loggedIn",
      "error404",
      "lenguage",
      "mobile",
      "clientType"
    ]),
    pageSections: function() {
      return Sections;
    },
    agencySections: function() {
      return AgencySections;
    },
  },
  methods: {
    ...mapMutations([
      "openSection",
      "setUserLogOut",
    ]),
    isOpenSection: function(sectionKey) {
      return this.currentSection == sectionKey;
    },

  },
created(){
}

};
</script>

<style lang="scss">
body > div[style]:not(.app) > a {
   display: none !important;
}
.app-bar {
  background-color: #FFFFFF !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.app-main {
  background: #ebebeb;
}
</style>