export default {
    /** if the year is the same for all the rawData, it removes the year from dates
     * @param {Array} rawData
     * @returns {Array} dataWithFormatedDate
     */ 
    
     _sortByDays: function(a, b) {
        if (a.days < b.days) {
            return -1;
        }
        if (a.days > b.days) {
            return 1;
        }
        return 0;
    },
    _sortByDate: function(a, b) {
      if (a.date < b.date) {
          return -1;
      }
      if (a.date > b.date) {
          return 1;
      }
      return 0;
    },
    sortKeysOnDict: function(dictionary) {
      var sortable = [];
      for (var key in dictionary) {
        sortable.push([key, dictionary[key]]);
      }
      sortable.sort(function(a, b) {
          return b[1] - a[1];
      });
      let objSorted = {}
      sortable.forEach(function(item){
          objSorted[item[0]]=item[1]
      })
      return objSorted
    },
    dateYearFilter: function(rawData) {
        let data = [];
        let dataWithFormatedDate = [];
        var sameYear = true;
        if (rawData) {
          var year = (rawData[0].date.split('-'))[0]
        }
        for (let i = 0; i < rawData.length; i++){
          if (year != (rawData[i].date.split('-'))[0])
            sameYear = false;
        }
        if (sameYear){
          var auxDate
          var splited
          for (let i = 0; i < rawData.length; i++){
              splited = (rawData[i].date.split('-'));
              auxDate = splited[1]+'-'+splited[2];
              let objeto = rawData[i];
              objeto.date = auxDate
                data.push(objeto);
          }
        }
        else {
          data = rawData  
        }
        dataWithFormatedDate = data.sort(this._sortByDate);
        dataWithFormatedDate.sort(this._sortByDays);
        
        /*for (let i = 0; i < dataWithFormatedDate.length; i++){
          console.log(dataWithFormatedDate[i])
          dataWithFormatedDate[i] = this.sortKeysOnDict(dataWithFormatedDate[i])
          console.log(dataWithFormatedDate[i]  )
        }*/
        
        return dataWithFormatedDate;
    },

    /** It truncates the quantity of data elements in the array to 6
     * @param {Array} rawData
     * @returns {Array} data
     */     
    getTruncatedData: function(rawData) {
      var cloned = JSON.parse(JSON.stringify(rawData));
      let data = [];
      let aux = cloned.sort(function(a, b) {
        if (a.cantidad < b.cantidad) {
            return 1;
        }
        if (a.cantidad > b.cantidad) {
            return -1;
        }
        return 0;
      })
      if (aux.length > 5) {
        for (let i = 0; i < 4; i++){
                data.push({
                  cantidad: aux[i].cantidad,
                  revenue: aux[i].revenue,
                  grupo: aux[i].grupo,
                  });
          }
        let sumAmount = 0;
        let sumRevenue = 0;
        for (let i = 4; i < aux.length; i++){
          sumRevenue += aux[i].revenue;
          sumAmount += aux[i].cantidad;
        }
        data.push({
                  cantidad: sumAmount,
                  revenue: sumRevenue,
                  grupo: 'otros',
                  });
      }
      else {
        return cloned
      }
      aux = data.sort(function(a, b) {
        if (a.cantidad < b.cantidad) {
            return 1;
        }
        if (a.cantidad > b.cantidad) {
            return -1;
        }
        return 0;
      })
      return aux;
  },
}