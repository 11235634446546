<template>
  <v-progress-linear 
      v-if="isLoading"
      indeterminate
      color="cyan"
  ></v-progress-linear>

  <v-container v-else class="main-container">
   <div v-if="!this.unavaibleIntegration">
    <div class="page-title"> {{ $t('Sessions.PageTitle')}} </div>

    <error v-if="error" style="margin-top: 10px"
      :errorMessage=" $t('errorMessage')" 
    />

    <v-row justify-content="flex-end">
      <time-range-filter-analytics 
      componente="Sessions"
      />
    </v-row>

    <v-row align="center" justify="space-between" style="margin-top: 32px" >

      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Sessions.sessionsCards.card-1.title')"
          :description= "$t('Sessions.sessionsCards.card-1.description')"
          :value= this.thousandSeparatorCall(getSessionsCardsData[0].value.toFixed(0))
          :changeRate= getSessionsCardsData[0].changeRate.toFixed(2)
          :sources="['googleAnalytics']"
        />
      </v-col>
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Sessions.sessionsCards.card-2.title')"
          :description= "$t('Sessions.sessionsCards.card-2.description')"
          :value= this.thousandSeparatorCall(getSessionsCardsData[1].value.toFixed(0))
          :changeRate= getSessionsCardsData[1].changeRate.toFixed(2)
          :sources="['googleAnalytics']"
        />
      </v-col>
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Sessions.sessionsCards.card-3.title')"
          :description= "$t('Sessions.sessionsCards.card-3.description')"
          :value= this.thousandSeparatorCall(getSessionsCardsData[2].value.toFixed(2))
          :changeRate= getSessionsCardsData[2].changeRate.toFixed(2)
          :sources="['googleAnalytics']"
        />
      </v-col>
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Sessions.sessionsCards.card-4.title')"
          :description= "$t('Sessions.sessionsCards.card-4.description')"
          :value= this.secondsToHms(getSessionsCardsData[3].value.toFixed(2))
          :changeRate= getSessionsCardsData[3].changeRate.toFixed(2)
          :sources="['googleAnalytics']"
        />
      </v-col>
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Sessions.sessionsCards.card-5.title')"
          :description= "$t('Sessions.sessionsCards.card-5.description')"
          :value= this.secondsToHms(getSessionsCardsData[4].value.toFixed(2))
          :changeRate= getSessionsCardsData[4].changeRate.toFixed(2)
          :sources="['googleAnalytics']"
        />
      </v-col>
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Sessions.sessionsCards.card-6.title')"
          :description= "$t('Sessions.sessionsCards.card-6.description')"
          :value= this.thousandSeparatorCall(getSessionsCardsData[5].value.toFixed(2))
          :changeRate= getSessionsCardsData[5].changeRate.toFixed(2)
          :sources="['googleAnalytics']"
        />
      </v-col>
      
    </v-row>

    <v-row >
      <v-col class="pa-0" >
        <client-cost
          :cardsData= getSessionsCardsData
          :infoCardsData= getBigCardsData
        />
      </v-col>      
    </v-row>


    <v-row class="margin-between-components pa-0 " >
      <v-col md="6">
        <sessions-by-day
        :sessionsByDayData = "getSessionsByDayData"
        />
      </v-col>     
      <v-col md="6">
        <bounce-rate
        :bounceData = "getSessionsBounceRateData"
        />
      </v-col>   
    </v-row>

    <v-row >
      <v-col class="pa-0 ">
        <new-users
        :usersBarData = "getUsersBarGraphData"
        :usersTableData = "getUsersTableData"
        />
      </v-col>      
    </v-row>

    <v-row >
      <v-col class="pa-0 ">
        <sessions-views
        :deviceSessionData="getDeviceSessionsData"
        :devicePageviewsData="getDevicePageviewsData"
        />
      </v-col>      
    </v-row>

    <v-row style="margin-bottom:128px;">
      <v-col class="pa-0 " >
        <sessions-pies-data
        :deviceData = "getDevicePieData"
        :sourceData = "getSourcePieData"
        :channelData = "getChannelsPieData"
        />
      </v-col>      
    </v-row>
  </div>
  <div  v-else>
    <v-card style="width:600px">
      <h2 class="app-font" style="padding:12px"> {{ $t('integrations.unavaible') }}</h2>
    </v-card>
  </div>

  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import RawMetricCard from '@/components/shared/RawMetricCard.vue';
import SessionsByDay from '../sessions/SessionsByDay.vue';
import BounceRate from '../sessions/BounceRate.vue';
import NewUsers from '../sessions/NewUsers.vue';
import SessionsPiesData from '../sessions/SessionsPiesData.vue';
import SessionsViews from '../sessions/SessionsViews.vue';
import TimeRangeFilterAnalytics from '../shared/TimeRangeFilterAnalytics.vue';
import Error from '../errors/error.vue';
import ClientCost from '../sessions/ClientCost.vue';
import ThousandSeparator from "@/helpers/thousandSeparator.helper";

export default {
  name: "Sessions",

  components: {
    RawMetricCard,
    SessionsByDay,
    BounceRate,
    NewUsers,
    SessionsPiesData,
    SessionsViews,
    TimeRangeFilterAnalytics,
    Error,
    ClientCost,
    
  },
  data: () => ({
    moneyString: "$",
  }),
  computed:{
    ...mapState([
    "sessionsPageData",
    "loadingSessionsPageData",
    "error",
    "mobile",
    "demo"
    ]),
    getSessionsCardsData() {
      return this.sessionsPageData['components'][0]['cards'];
    },
    getSessionsByDayData() {
      return this.sessionsPageData['components'][1]['data'];
    },
    getSessionsBounceRateData() {
      return this.sessionsPageData['components'][2]['data'];
    },
    getUsersBarGraphData() {
      return this.sessionsPageData['components'][3]['barsGraphData'];
    },
    getUsersTableData() {
      return this.sessionsPageData['components'][3]['tableData'];
    },
    getDeviceSessionsData() {
      return this.sessionsPageData['components'][4]['deviceAvgSessionData'];
    },
    getDevicePageviewsData() {
      return this.sessionsPageData['components'][4]['devicePageViewsData'];
    },
    getChannelsPieData() {
      return this.sessionsPageData['components'][5]['channelData'];
    },
    getDevicePieData() {
      return this.sessionsPageData['components'][5]['deviceData'];
    },
    getSourcePieData() {
      return this.sessionsPageData['components'][5]['sourceData'];
    },
    getBigCardsData() {
      return this.sessionsPageData['components'][7];
    },
    isLoading() {
      if ( this.sessionsPageData  != null && !this.loadingSessionsPageData){
        return false
      }
      return true
    },
    unavaibleIntegration(){
      if (this.sessionsPageData == 'integration unavaible')
        return true
      else
        return false
    }
  },
  methods: {
    ...mapActions([
      "getSessionsPageData",
      "loggedInCheck"      
    ]),
    ...mapMutations([
      "setMobile",
      "setSessionsPageData"   
    ]),
    thousandSeparatorCall(value) {
      return ThousandSeparator.formatNumber(value)
    },
    secondsToHms(d) {
      d = Number(d)*60;
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);

      var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? "s " : "s ") : "";
      return hDisplay + mDisplay + sDisplay; 
    }
  },

  created: function () {
    if(!this.demo){
      this.loggedInCheck();
      console.log("cargue sesiones")
      this.getSessionsPageData();
      }
    else{
      var data = {};
      data = require('../../json/sessions.json');
      this.setSessionsPageData(data);
    }
  },
};
</script>

<style scoped lang="scss">
.main-container {
  max-width: 100%;
  padding: 12px 30px 12px 30px;
}
  
.margin-between-components {
  margin-top:24px;
} 
</style>