<template>
<v-card v-if="!this.mobile"
    color="#FFFFFF"
    class="card"
  >
  <v-data-table
    :headers="headers"
    :items="agency_clients"
    :items-per-page="10"
    sort-by="update_date"
    class="users-table"
    :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-text': $t('Settings.UsersTable.rowText')
    }"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title> {{ $t('agencyClients.agencyClientsTable.title')}} </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="newClient"
            >
              {{ $t('agencyClients.agencyClientsTable.newClient')}}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    
                  >
                    <v-text-field
                      class="json-schema-form:focus"
                      v-model="editedItem.agency_client_name"
                      label="Cliente"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.agency_client_email"
                      label="Email"
                      :rules="emailRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <custom-alert-small
              style="margin:auto"/>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                {{ $t('Settings.UsersTable.dialogButtons.cancel') }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                {{ $t('Settings.JobStatus.send') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">{{ $t('agencyClients.agencyClientsTable.deleteClient') }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">{{ $t('agencyClients.agencyClientsTable.closeButton')}}</v-btn>
              <v-btn color="green darken-1" text @click="deleteItemConfirm">{{ $t('agencyClients.agencyClientsTable.deleteButton')}}</v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</v-card>
</template>

<script>
import i18n from '@/i18n.js'
import { mapState, mapActions, mapMutations } from 'vuex'
import CustomAlertSmall from '../shared/CustomAlertSmall.vue'

  export default {
  components: { CustomAlertSmall },
    data: () => ({
      valid:false,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Cliente', value: 'agency_client_name', align: 'start' },
        { text: 'Email', value: 'agency_client_email' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      agency_clients: [],
      editedIndex: -1,
      editedItem: {
        agency_client_name: '',
        agency_client_email: '',
      },
      emailRules: [ 
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ]
    }),
    props: [
      "agencyClients"
    ],
    computed: {
      ...mapState([
        "mobile",
      ]),
      validation(){
      if(this.editedItem.agency_client_name&& this.editedItem.agency_client_email)
        if(this.editedItem.agency_client_name != '' && this.editedItem.agency_client_email != '' ){
          if(this.editedItem.agency_client_email.includes('@') && this.editedItem.agency_client_email.includes('.')){
            return true
          }
          else
            return false
        }
        else
          return false
        else
          return false
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Cliente' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        for(var i=0; i< this.agencyClients.length; i++){
          this.agency_clients.push({
            agency_client_name: this.agencyClients[i].agency_client_name,
            agency_client_email: this.agencyClients[i].agency_client_email
          })
        }
      },
      ...mapActions([
        "postAgencyClient",
        "deleteAgencyClient",
      ]),
      ...mapMutations([
        "setAlertType",
        "setSmallAlertMessage"
      ]),
      editItem (item) {
        this.editedIndex = this.agency_clients.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.agency_clients.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        let data = {
          "name": this.agency_clients[this.editedIndex].agency_client_name
        }
        this.deleteAgencyClient({ data })
        this.closeDelete()
        this.agency_clients.splice(this.editedIndex, 1)
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.validation){
          var data = this.editedItem
          this.postAgencyClient({ data })
          if (this.editedIndex > -1) {
            Object.assign(this.agency_clients[this.editedIndex], this.editedItem)
          } else {
            this.agency_clients.push(this.editedItem)
          }

          this.close()
          }
        else{
          this.setAlertType('error')
          this.setSmallAlertMessage(i18n.t('Settings.JobStatus.required'))
        }
      },

      newClient(){
        
      },
    },
  }
</script>

<style>
.card {
  padding: 24px;
  max-height: 1200px;  
}
</style>