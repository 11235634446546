<template>
  <v-card
    color="#FFFFFF"
    class="card"
  >
    <v-row no-gutters class="cr-vs-cor">

      <v-col md="12" cols="12" >
      <v-col cols="12" class="pa-0" style="margin-bottom: 17px">
        <span class="graph-title">
          {{$t('PaidTraffic.ConversionRateVSCostOverRevenue.title')}}
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
            {{$t('PaidTraffic.ConversionRateVSCostOverRevenue.description')}}
            <data-sources
              :sources="['googleAnalytics', 'vtex']"
            />
          </v-tooltip>
        </span>
      </v-col> 
        <wj-flex-chart style="height: 195px" 
        bindingX="date" 
        :itemsSource="procesedData" 
        :stacking="true"
        :palette="this.palette"
        :legendToggle="true"
        :initialized="onChartInitialized"
        tooltipContent="" 
        >
            <wj-flex-chart-legend position="Bottom"></wj-flex-chart-legend>
            <wj-flex-chart-axis wjProperty="axisY" :min="0" format="p2" title="CoR" :axisLine="true"></wj-flex-chart-axis>
            <wj-flex-chart-series name="Conversion Rate" binding="convertionRate" format="p2">
              <wj-flex-chart-axis wjProperty="axisY" position="Right" title="Conversion Rate" format="p2" :min="0" :axisLine="true"></wj-flex-chart-axis>
            </wj-flex-chart-series>
            <wj-flex-chart-series name="CoR" binding="cor" chartType="Line" format="p2"></wj-flex-chart-series>
            <wj-flex-chart-animation></wj-flex-chart-animation>
            <wj-flex-chart-line-marker :isVisible="showMarker" lines="Vertical" interaction="Move" :content="getMarkerContent">
            </wj-flex-chart-line-marker>
        </wj-flex-chart>
      </v-col>      

    </v-row>
  </v-card>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import DataFilterHelper from "@/helpers/dataFilter.helper";
import ThousandSeparator from "@/helpers/thousandSeparator.helper";
import ui_kit from "@/constants/ui-kit";
import DataSources from '../shared/DataSources.vue';
import i18n from '@/i18n.js' 


export default {
  name: "ConversionRateVSCostOverRevenue",
  components: {
    DataSources
  },
  props: [
    'crVsCorData'
  ],
  data: function() {
    return {
      lmVisible: false,
      showMarker: false,
      palette: ui_kit.palette
    }
  },
  computed: {
    procesedData() {
      return DataFilterHelper.dateYearFilter(this.crVsCorData)
    },
  },
  methods: {
   onChartInitialized(sender) {
          sender.hostElement.addEventListener('mouseenter', () => this.showMarker = true);
          sender.hostElement.addEventListener('mouseleave', () => this.showMarker = false);
    },
    getMarkerContent(ht) {
        var localeKeys = 'keys.'
        if (ht.item) {
            let s = `<p style=" color: rgba(2, 8, 15, 0.5); contrast(50%); 
                                font-family: Open Sans, sans-serif;
                                font-weight: normal;
                                "> 
                                ${ht.item.date} </p>
                                <div class="row" >`;
            var i=0;
            var firstColumn = `<div class="col-lg-8 col-md-8 " >`
            var secondColumn = `<div class="col-lg-4 col-md-4 ">`
            var endCol = `</div>`
            var endRow = `</div>`
            
            for (let key in ht.item) {
                var keyString = localeKeys + key
                if (key !== 'date') {
                    firstColumn += `
                    <div style=" color: rgba(2, 8, 15, 0,85); contrast(85%); 
                    font-family: Open Sans, sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    float:left;
                    width:150px
                    ">
                    <div style="  
                            width:12px;
                            height:12px;
                            border:solid white 1px;
                            float:left;
                            margin:5px;
                            background-color:${ui_kit.palette[i]}">
                        </div>
                    <b>${i18n.t(keyString)} </b> </div> </br> `;
                    secondColumn += `
                    <div style=" rgba(2, 8, 15, 0.85);
                    font-family: Open Sans, sans-serif;
                    font-weight: normal;
                    font-size: 14px;
                    text-align:right;
                    float:right;
                    
                    "> ${ThousandSeparator.formatNumber(ht.item[key])}</div>
                      </br>  `;
                }
                i++;
            }
            return s + firstColumn + endCol + secondColumn + endCol + endRow;
        } else {
            return 'No items here...';
        }
    },
  }
};
</script>

<style scoped lang="scss">
.cr-vs-cor {
  .wj-flexchart {
    padding: 0px !important;
  }
}
.cr-vs-cor-title {
  color: #6f6f6f;
  padding-left: 20px;
}

.card {
  padding: 24px;
  height: 280px;
}
</style>