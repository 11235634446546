<template>
  <v-progress-linear 
      v-if="isLoading"
      indeterminate
      color="cyan"
  ></v-progress-linear>

  <v-container v-else class="main-container">
    <div class="page-title"> {{ $t('googleAdsScripting.title') }} </div>

    <custom-alerts />

    <script-builder 
      class="margin-between-components"
      :title="$t('googleAdsScripting.scriptBuilder.title')"
      :description="$t('googleAdsScripting.scriptBuilder.description')"
    />

    <overlay-loader />

    <tab-component 
      class="margin-between-components"
      :scriptRegisterData = this.scriptRegister
      :scriptExecutionsData = this.scriptExecutions
    />
    <builded-script-dialog
      style="margin-bottom:128px"
      class="margin-between-components"
    />

  </v-container>
</template>

<script>
import BuildedScriptDialog from '../google-ads-scripting/BuildedScriptDialog.vue'
import OverlayLoader from '../google-ads-scripting/OverlayLoader.vue'
import ScriptBuilder from '../google-ads-scripting/ScriptBuilder.vue'
import { mapActions , mapState} from 'vuex'
import CustomAlerts from '../shared/CustomAlerts.vue'
import TabComponent from '../agency-extractors/tabComponent.vue'

export default {
  components: { 
    ScriptBuilder,
    BuildedScriptDialog,
    OverlayLoader,
    CustomAlerts,
    TabComponent 
    },
methods: {
  ...mapActions([
    "getScriptRegister",
    "getAgencyClients",
    "getScriptExecutions"
  ])
},
computed: {
  ...mapState([
    "scriptRegister",
    "loadingScriptRegister",
    "showAlert",
    "successAlertMessage",
    "loadingAgencyClients",
    'agencyClients',
    'scriptExecutions'
  ]),
  isLoading() {
    if ( this.agencyClients != null && !this.loadingAgencyClients && this.scriptRegister != null && !this.loadingScriptRegister && this.scriptExecutions != null && !this.loadingScriptExecutions){
      return false
    }
    return true
  },
},
created: function () {
  this.getScriptRegister()
  this.getAgencyClients() 
  this.getScriptExecutions()
}
}
</script>

<style>
.main-container {
  max-width: 100%;
  padding: 12px 30px 12px 30px;
}

 .margin-between-components {
   margin-top:24px;
 } 
</style>