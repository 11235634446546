export default Object.freeze({
  DAILYSUMMARY: {
    icon: 'mdi-book-open',
    text: 'dailySummary',
    path: '/daily_summary'
  },
  ORDERS: {
    icon: 'mdi-cart',
    text: 'orders',
    path: '/orders'
  },
  PRODUCTS: {
    icon: 'mdi-tag',
    text: 'products',
    path: '/products'
  },
  STOCK: {
    icon: 'mdi-warehouse',
    text: 'stock',
    path: '/stock'
  },
  PAYMENTS: {
    icon: 'mdi-currency-usd',
    text: 'payments',
    path: '/payments'
  },
  SESSIONS: {
    icon: 'mdi-account-group',
    text: 'sessions',
    path: '/sessions'
  },
  TRAFFIC: {
    icon: 'mdi-traffic-light',
    text: 'traffic',
    path: '/traffic'
  },
  PAIDTRAFFIC: {
    icon: 'mdi-cash-multiple',
    text: 'paidTraffic',
    path: '/paid_traffic'
  },
});