<template>

  <v-progress-linear 
      v-if="isLoading"
      indeterminate
      color="cyan"
  ></v-progress-linear>

  <v-container v-else class="main-container" >
  <div v-if="!this.unavaibleIntegration">
    <div class="page-title"> {{ $t('Products.PageTitle')}} </div>

    <error v-if="error" style="margin-top: 10px"
      :errorMessage=" $t('errorMessage')" 
    />

    <v-row justify-content="flex-end" >
      <TimeRangeFilter 
      :componente="componentName"
      :orderStatusData="getOrderStatusData"
      />
    </v-row>

    <div class="d-flex align-center" v-if="!this.mobile" >
      <v-row justify-content="flex-end" >
        <order-status-filter
        />
      </v-row>
    </div>

    <v-row align="center" justify="space-between" style="margin-top: 24px">
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Products.productsCards.card-1.title')"
          :description= "$t('Products.productsCards.card-1.description')"
          :value= this.thousandSeparatorCall(getProductsCardsData[0].value.toFixed(2))
          :changeRate= getProductsCardsData[0].changeRate.toFixed(2)
          :sources="['vtex']"
        />
      </v-col>
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Products.productsCards.card-2.title')"
          :description= "$t('Products.productsCards.card-2.description')"
          :value= moneyString.concat(this.thousandSeparatorCall(getProductsCardsData[1].value))
          :changeRate= getProductsCardsData[1].changeRate.toFixed(2)
          :sources="['vtex']"
        />
      </v-col>
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Products.productsCards.card-3.title')"
          :description= "$t('Products.productsCards.card-3.description')"
          :value= moneyString.concat(this.thousandSeparatorCall(getProductsCardsData[2].value.toFixed(2)))
          :changeRate= getProductsCardsData[2].changeRate.toFixed(2)
          :sources="['vtex']"
        />
      </v-col>
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Products.productsCards.card-4.title')"
          :description= "$t('Products.productsCards.card-4.description')"
          :value= this.thousandSeparatorCall(getProductsCardsData[3].value.toFixed(2))
          :changeRate= getProductsCardsData[3].changeRate.toFixed(2)
          :sources="['vtex']"
        />
      </v-col>
      <v-col md="2" cols="6">
        <raw-metric-card 
          :title = "$t('Products.productsCards.card-5.title')"
          :description= "$t('Products.productsCards.card-5.description')"
          :value= this.thousandSeparatorCall(getProductsCardsData[4].value.toFixed(2))
          :changeRate= getProductsCardsData[4].changeRate.toFixed(2)
          :sources="['vtex']"
        />
      </v-col>
    </v-row>

    <v-row align="center" style="margin-top: 12px">
      <v-col md="4" cols="12">
        <info-card 
          :title = "$t('Products.bigCards.card-1.title')"
          :description= "$t('Products.bigCards.card-1.description')"
          :sources="['vtex']"
          cardIcon="trending"
          :cardData="getBigCardsData[0]"
        />
      </v-col>
      <v-col md="4" cols="12">
        <info-card 
          :title = "$t('Products.bigCards.card-2.title')"
          :description= "$t('Products.bigCards.card-2.description')"
          :sources="['vtex']"
          cardIcon="biggestGainners"
          :cardData="getBigCardsData[1]"
        />
      </v-col>
      <v-col md="4" cols="12">
        <info-card 
          :title = "$t('Products.bigCards.card-3.title')"
          :description= "$t('Products.bigCards.card-3.description')"
          :sources="['vtex']"
          cardIcon="biggestLoosers"
          :cardData="getBigCardsData[2]"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="margin-between-components pa-0 " style="margin-top:12px" >
      <items-details 
        :itemsDetailsData = "getItemsDetailsData"
      />
      </v-col>      
    </v-row>

    <v-row>
      <v-col class="margin-between-components pa-0 ">
      <BestSellerCategories
        :bestSellerData= "getBestSellerDepartmentsData"
        :bestSellerBarsData= "getMostSoldDepartmentsBardsGraph"
      />
      </v-col>      
    </v-row>

    <v-row v-if="!this.mobile">
      <v-col class="margin-between-components pa-0 " >
      <best-seller-products 
        :bestSellerProductsData = "getBestSellerItemsData"
      />
      </v-col>      
    </v-row>

    <v-row>
      <v-col class="margin-between-components pa-0 " style="margin-bottom: 128px">
        <component-container
          :title= "$t('Products.ComponentContainer.title')"
          :cardData="getCrossSellingData"
          :description= "$t('Products.ComponentContainer.description')"
          :sources="['vtex']"
        />
      </v-col>      
    </v-row>

    <v-row>
      <v-col class="margin-between-components pa-0 ">
      <items-dialog
      />
      </v-col>      
    </v-row>
  </div>
  <div  v-else>
    <v-card style="width:600px">
      <h2 class="app-font" style="padding:12px"> {{ $t('integrations.unavaible') }}</h2>
    </v-card>
  </div>
  </v-container>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import RawMetricCard from '@/components/shared/RawMetricCard.vue';
import TimeRangeFilter from '@/components/shared/TimeRangeFilter.vue';
import BestSellerCategories from '../products/BestSellerCategories.vue';
import BestSellerProducts from '../products/BestSellerProducts.vue';
import OrderStatusFilter from '../shared/OrderStatusFilter.vue';
import TimeRangeType from "@/enums/time-range-type.enum";
import TimeRangeTypeText from "@/constants/time-range-type-text";
import Error from '../errors/error.vue';
import ThousandSeparator from "@/helpers/thousandSeparator.helper";
import ItemsDetails from '../products/ItemsDetails.vue';
import ItemsDialog from '../shared/ItemsDialog.vue';
import InfoCard from '@/components/shared/InfoCard.vue';
import ComponentContainer from '../products/ComponentContainer.vue';


export default {
  name: "Products",

  components: {
    RawMetricCard,
    TimeRangeFilter,
    BestSellerCategories,
    BestSellerProducts,
    OrderStatusFilter,
    Error,
    ItemsDetails,
    ItemsDialog,
    InfoCard,
    ComponentContainer,
  },
  data: () => ({ 
    componentName: "Products",
    moneyString: "$ ",
    timeRangeTypes: [
        {
          text: TimeRangeTypeText[TimeRangeType.Custom],
          value: TimeRangeType.Custom
        }
      ],
      compareTodayRangeTypes: [
        {
          text: TimeRangeTypeText[TimeRangeType.CompareWithCustom],
          value: TimeRangeType.CompareWithCustom
        }
      ],
  }),
  computed: {
    ...mapState([
      "productsPageData",
      "loadingProductsPageData",
      "loadingFilterOptions",
      "filterOptions",
      "timeRange",
      "error",
      "mobile",
      "demo"
    ]),
    getOrderStatusData () {
      return this.filterOptions["Filters"][0]["values"]
    },
    isCustomSelected() {
      return this.timeRange.timeRangeType === TimeRangeType.Custom;
    },
    isCompareWithCustomSelected() {
      return this.timeRange.compareTimeRangeType === TimeRangeType.CompareWithCustom;
    },
    isLoading() {
      if ( this.productsPageData  != null && !this.loadingProductsPageData && this.filterOptions != null && !this.loadingFilterOptions){
        return false
      }
      return true
    },
    getProductsCardsData() {
      return this.productsPageData['components'][0]['cards'];
    },
    getBestSellerDepartmentsData() {
      return this.productsPageData['components'][1]['data'];
    },
    getBestSellerItemsData() {
      return this.productsPageData['components'][2]['data'];
    },
    getItemsDetailsData() {
      return this.productsPageData['components'][3]['data'];
    },
    getBigCardsData() {
      return this.productsPageData['components'][4]['cards'];
    },
    getCrossSellingData() {
      return this.productsPageData['components'][5]['data'];
    },
    getMostSoldDepartmentsBardsGraph() {
      return this.productsPageData['components'][6]['data'];
    },
    unavaibleIntegration(){
      if (this.productsPageData == 'integration unavaible')
        return true
      else
        return false
    }
  },
  methods: {
    ...mapActions([
      "getProductsPageData",  
      "getFilters",
      "loggedInCheck"     
    ]),
    ...mapMutations([
      "setMobile",   
      "setProductsPageData"
    ]),
    thousandSeparatorCall(value) {
      return ThousandSeparator.formatNumber(value)
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.setMobile(true)
        return true
      } else {
        return false
      }
    },
  },
  created: function () {
    this.isMobile();
    if(!this.demo){
      this.loggedInCheck();
      this.getProductsPageData();
      this.getFilters();
      }
    else{
      this.getFilters();
      var data = {};
      data = require('../../json/products.json');
      this.setProductsPageData(data);
    }
  },

};


</script>

<style scoped lang="scss">
.main-container {
  max-width: 100%;
  padding: 12px 30px 12px 30px;
}

.margin-between-components {
  margin-top:24px;
} 
</style>

