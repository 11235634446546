<template>

        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
             {{ $t('Settings.updatePassword') }}
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="text-h5">{{ $t('Settings.updatePassword') }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                  <v-text-field
                    v-model="editedItem.currentPassword"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    :label="$t('Settings.UsersTable.newUserDialog.currentPassword')"
                    :hint="$t('Settings.UsersTable.newUserDialog.minChar')"
                    counter
                    @click:append="show1 = !show1"
                  ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                  <v-text-field
                    v-model="editedItem.password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    :label="$t('Settings.UsersTable.newUserDialog.changePassword')"
                    :hint="$t('Settings.UsersTable.newUserDialog.minChar')"
                    counter
                    @click:append="show1 = !show1"
                  ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                  <v-text-field
                    v-model="editedItem.rePassword"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules2.required, rules2.min, passwordConfirmationRule]"
                    :type="show2 ? 'text' : 'password'"
                    name="input-10-2"
                    :label="$t('Settings.UsersTable.newUserDialog.changeRePassword')"
                    :hint="$t('Settings.UsersTable.newUserDialog.minChar')"
                    counter
                    @click:append="show2 = !show2"
                  ></v-text-field>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="save"
              >
                {{ $t('Settings.updatePassword') }}
              </v-btn>
              <v-btn
                color="red darken-1"
                text
                @click="close"
              >
                {{ $t('Settings.UsersTable.dialogButtons.cancel') }}
              </v-btn>
              
            </v-card-actions>
          </v-card>

        
        </v-dialog>

    
</template>

<script>
import i18n from '@/i18n.js' 
import { mapState , mapActions } from 'vuex'


export default {
  components: {  },
  props: [
    "usersData"
  ],

  data: () => ({
    dialog: false,
    dialogDelete: false,
    show1: true,
    show2: true,
    passwordUpdate: false,
    message: 'Success',
    type: '',
    currentPassword: 'currentPassword',
    password: 'password',
    rePassword: 'rePassword',
    users: [],
    editedIndex: -1,
    editedItem: {
      currentPassword: '',
      password: '',
      rePassword: '',

    },
    defaultItem: {
       currentPassword: '',
       password: '',
       rePassword: '',
    },
    rules: {
      required: value => !!value || i18n.t('Settings.UsersTable.newUserDialog.required'),
      min: v => v.length >= 8 || i18n.t('Settings.UsersTable.newUserDialog.minChar'),
    },
    rules2: {
      required: value => !!value || i18n.t('Settings.UsersTable.newUserDialog.required'),
      min: v => v.length >= 8 || i18n.t('Settings.UsersTable.newUserDialog.minChar'),
    },
  }),

  computed: {
  ...mapState([
    'userRole'
  ]),
  isFormFilled(){
    if (this.editedItem.password != '' && this.editedItem.rePassword != '' && this.editedItem.password == this.editedItem.rePassword )
      return true
    else
      return false
  },
  passwordConfirmationRule() {
    return () => (this.editedItem.password === this.editedItem.rePassword) || i18n.t('Settings.UsersTable.newUserDialog.match')
  }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
    
  },

  methods: {
    ...mapActions([
      'postNewUser',
      'updatePassword',
    ]),

    editItem (item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.users.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if(this.isFormFilled){
        if (this.editedIndex > -1) {
          Object.assign(this.users[this.editedIndex], this.editedItem)
        } else {
          var data = {
            "actual_password": this.editedItem.currentPassword,
            "new_password": this.editedItem.rePassword
          }
          var success
          success = this.updatePassword({ data })
          if(success){
              this.type='success';
              this.passwordUpdate=true;
          }
          else{
              this.type='error';
              this.passwordUpdate=true;
          }
        }
        this.close()
      }
    },
  },
}
</script>

<style scoped lang="scss">

</style>