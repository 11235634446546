<template>

  <v-row no-gutters class="stock-evolution">

    <v-col cols="12">
    <div class="container-fluid">
        <wj-flex-chart 
        style="height:300px;margin-bottom:0px!important;" 
        chartType="Bar"  
        stacking="Stacked100pc" 
        :itemsSource="this.selectedData"
        :palette="this.palette"
        :initialized="initializeChart"
        >
            <wj-flex-chart-legend position="Bottom"></wj-flex-chart-legend>

            <wj-flex-chart-series 
                v-for="item in this.dataKeys"
                :key="item"
                :name="item"
                :binding="String(item)"
            ></wj-flex-chart-series>
            <wj-flex-chart-axis wjProperty="axisY" :reversed="true" :labels="false"></wj-flex-chart-axis>
            <wj-flex-chart-animation></wj-flex-chart-animation>
        </wj-flex-chart>
    </div>
    </v-col> 
  </v-row>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.chart.animation';
import ui_kit from "@/constants/ui-kit";
import * as wjCore from '@grapecity/wijmo';

export default {
  name: "HorizontalBarGraph",
  props: [
    'dataSource',
    'bindingName',
    'binding',
    'title',
    'description',
    'bindingLabel'
  ],
  components: {

  },
    data: function () {
        return { 
            palette: ui_kit.palette,

        }
  },
  computed: {
    selectedData() {
      var data = {};
      var keysIndex = this.binding + 'Keys';
      var items = this.dataSource[keysIndex].filter(e => e !== 'bindingX')
      if (items.length > 5) {
        for(let i=0; i<5 ; i++) {

          data[items[i]] = this.dataSource[this.binding][0][items[i]]
        }
        data['Otros'] = 0;
        for(let i=5; i<items.length ; i++) {
          data['Otros'] += this.dataSource[this.binding][0][items[i]]
        }
        var datalist = []
        datalist.push(data)
        return datalist;
      }
      else
        return this.dataSource[this.binding]
    },
    dataKeys() {
      var data = [];
      var keysIndex = this.binding + 'Keys';
      var items = this.dataSource[keysIndex].filter(e => e !== 'bindingX')

      if (items.length > 5) {
        for(let i=0; i<5 ; i++) {
          data.push(items[i])
        }
        data.push("Otros")
        return data;
      }
      else
        return this.dataSource[keysIndex].filter(e => e !== 'bindingX');
    },
    name() {
      if (this.bindingName == 'quantity') {
        return 'Cantidad'
      }
      if (this.bindingName == 'revenue') {
        return 'Revenue'
      }
      else {
        return 'Valor'
      }
    } 
  },
  methods: {
    initializeChart: function(flex) {
        this.theChart = flex;
        let sd = this.theChart.collectionView.sortDescriptions;
        //
        sd.clear();
        sd.push(new wjCore.SortDescription(this.binding , false))
        this.theChart.axisY.position = 'Top';
        this.theChart.options = {
            groupWidth : 50,   // 50 pixels
        },
        this.theChart.plotMargin = '0 10 110 10';
        },

     getData() {
        return [
            {
                'year': '2014',
                'US': 17348075,
                'China': 10356508,
                'Japan': 4602367,
                'Germany': 3874437,
                'UK': 2950039,
            }] 
     }
  },
};
</script>

<style>
.graph-area {
  padding: 24px;
  max-height: 396px;
}



</style>
