<template>
    <v-alert
      dense 

      class="small-alert" 
      v-if="this.showAlertSmall == true"
      elevation="9"
      :type="this.alertType"
    > {{ this.alertMessage }}</v-alert>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data: () => ({
    }),
    methods: {
    },
    computed: {
        ...mapState([
            "showAlertSmall",
            "alertMessage",
            "alertType"
        ])
    },


    watch: {
    },
}
</script>

<style scoped lang="scss">

</style>