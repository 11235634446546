<template>
       
      <v-col md="12"  style="height: 100%">
        <wj-flex-chart v-if="this.binding == 'quantity'" style="height: 100%" bindingX="date"
         :palette="this.palette"  
         :itemsSource="barsQuantityData" >
            <wj-flex-chart-legend position="None"></wj-flex-chart-legend>
            <wj-flex-chart-axis wjProperty="axisY" :min="0"></wj-flex-chart-axis>
            <wj-flex-chart-series
                v-for="item in this.groups"
                :key="item.grupoName"
                :name="item.grupoName"
                :binding="item.grupoId"
              ></wj-flex-chart-series>
            <wj-flex-chart-animation></wj-flex-chart-animation>
        </wj-flex-chart>
        
        <wj-flex-chart v-if="this.binding == 'revenue'" style="height: 100%" bindingX="date"
         :itemsSource="barsRevenueData" 
         :palette="this.palette" 
         >
        <wj-flex-chart-legend position="None"></wj-flex-chart-legend>
            <wj-flex-chart-axis wjProperty="axisY" :min="0"></wj-flex-chart-axis>
            <wj-flex-chart-series
                v-for="item in this.groups"
                :key="item.grupoName"
                :name="item.grupoName"
                :binding="item.grupoId"
              ></wj-flex-chart-series>
            <wj-flex-chart-animation></wj-flex-chart-animation>
        </wj-flex-chart>
      </v-col>      

</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import ui_kit from "@/constants/ui-kit";

export default {
  name: "BarsGraphic",
  props: [
    'groups',
    'barsQuantityData',
    'barsRevenueData',
    'binding',
  ],
  components: {
  },
  data: function() {
    return {
      palette: ui_kit.palette
    }
  },
  computed: {

  },
  methods: {
  },
};
</script>

<style scoped lang="scss">

</style>