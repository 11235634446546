<template>
  <v-card
    color="#FFFFFF"
    class="graph-area"
  >  
  <v-row no-gutters class="sessions-by-day">
      <v-col cols="12" style="height:20px;margin-bottom:17px">
        <span class="graph-title">
          {{ $t('Sessions.SessionsByDay.title')}}  
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
          {{ $t('Sessions.SessionsByDay.description')}}  
            <data-sources 
              :sources="['googleAnalytics']"
            />
          </v-tooltip>
        </span>
      </v-col> 

      <v-col md="12" cols="12" style="height: 200px">
        <wj-flex-chart style="height: 100%" 
          :itemsSource="procesedData" 
          bindingX="date"
          chartType="Line"
          :legendToggle="true"
          tooltipContent=""
          :initialized="onChartInitialized"
          :palette="this.palette"
        >
            <wj-flex-chart-legend position="Bottom"></wj-flex-chart-legend>
            <wj-flex-chart-axis wjProperty="axisY" :min="0"></wj-flex-chart-axis>
            <wj-flex-chart-series binding="newUsers" :name="$t('keys.newUsers')"></wj-flex-chart-series>
            <wj-flex-chart-series binding="sessions" :name="$t('keys.sessions')"></wj-flex-chart-series>
            <wj-flex-chart-series binding="users" :name="$t('keys.users')"></wj-flex-chart-series>
            
            <wj-flex-chart-animation animationMode="Point"></wj-flex-chart-animation>
            <wj-flex-chart-line-marker :isVisible="showMarker" lines="Vertical" interaction="Move" :content="getMarkerContent">
            </wj-flex-chart-line-marker>
        </wj-flex-chart>
      </v-col>      
    </v-row>
  </v-card>
</template>

<script>
import '@grapecity/wijmo.styles/wijmo.css';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.chart.animation';
import DataFilterHelper from "@/helpers/dataFilter.helper";
import markerContentHelper from "@/helpers/markerContent.helper";
import ui_kit from "@/constants/ui-kit";
import DataSources from '../shared/DataSources.vue';

export default {
  name: "SessionsByDay",
  components: {
    DataSources
  },
  props: [
  'sessionsByDayData'
  ],
  data: function() {
    return {
      lmVisible: false,
      showMarker:false,
      palette: ui_kit.palette
        }
      },
  computed: {
    procesedData() {
      return DataFilterHelper.dateYearFilter(this.sessionsByDayData)
    },
  },
  methods: {
    onChartInitialized(sender) {
          sender.hostElement.addEventListener('mouseenter', () => this.showMarker = true);
          sender.hostElement.addEventListener('mouseleave', () => this.showMarker = false);
    },
    getMarkerContent(ht) {
        return markerContentHelper.markerContent(ht)
    }
  },
};
</script>

<style scoped lang="scss">
.sessions-by-day {
  .wj-flexchart {
    padding: 0px !important;
  }
}

.graph-area {
  height:280px;
  padding: 24px;
}
</style>