<template>

    <v-row no-gutters class="orders-status">

      <v-col v-if="!this.mobile" md="6" cols="12" style="height: 320px;padding-right:12px" >
      <v-card
        color="#FFFFFF"
        class="card"
      >
        <v-col v-if="!this.mobile" md="12" cols="12" style="height:20px;position:relative;">
          <span class="graph-title"  style = "  margin-top:24px;">
            {{$t('Products.BestSellerCategories.title')}}
            <v-tooltip bottom max-width="400">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                  mdi-information-outline
                </v-icon>
              </template>
            {{$t('Products.BestSellerCategories.description')}}
              <data-sources 
                :sources="['vtex']"
              />
            </v-tooltip>
            <div >
            <v-radio-group 
                v-model="bestSellerCategoriesVisualization"
                row
                class="selector"
            >
              <v-radio style="margin-top:12px"
                label="#"
                value="quantity"
              ></v-radio>
              <v-radio style="margin-top:12px"
                label="$"
                value="revenue"
              ></v-radio>
              <v-radio style="margin-top:12px"
                label="Profit"
                value="profit"
              ></v-radio>
            </v-radio-group>
            </div>
          </span>
        </v-col> 

      <!-- mobile -->
      <v-col v-if="this.mobile" md="6" cols="12" style="height:20px;position:relative;">
        <span class="graph-title"  style = "  margin-top:24px;">
          {{$t('Products.BestSellerCategories.title')}}
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
           {{$t('Products.BestSellerCategories.description')}}
            <data-sources 
              :sources="['vtex']"
            />
          </v-tooltip>
        </span>
      </v-col>

      
      <v-col v-if="this.mobile"  cols="10"  style="height:20px;position:relative;margin-top:20px;">
          <v-radio-group style="position:absolute;left:0"
              v-model="bestSellerCategoriesVisualization"
              row
              class="selector"
          >
            <v-radio 
              label="#"
              value="quantity"
            ></v-radio>
            <v-radio
              label="$"
              value="revenue"
            ></v-radio>
            <v-radio 
              label="Profit"
              value="profit"
            ></v-radio>
          </v-radio-group>
      </v-col> 

      <v-col v-if="this.mobile" cols="2" class="right-content" style="margin-top:10px">
        <v-btn
          tile
          small
          color="success"
          @click="save()"
        >
          <v-icon style="font-size: 22px;">
            mdi-microsoft-excel
          </v-icon>
        </v-btn>
      </v-col> 
      <!-- end mobile -->

        <horizontal-bar-graph
         :dataSource="this.bestSellerBarsData"
         :binding="bestSellerCategoriesVisualization"
         bindingName="bindingX"
        />
      </v-card>
      </v-col>  
    

      <v-col v-if="!this.mobile" md="6" cols="12" style="padding-left:12px">
      <v-card
        color="#FFFFFF"
        class="card" 
      >
        <v-col  md="12" cols="12" class="right-content" style="margin-bottom:-12px">
          <v-btn
            tile
            small
            color="success"
            @click="save()"
          >
            <v-icon style="font-size: 22px;">
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </v-col>
        <v-col md="12">
        <wj-flex-grid 
          :initialized="initializeFlexSheet" 
          :selectionMode="'ListBox'"
          :headersVisibility="'Column'"
          :itemsSource="this.data"
          :alternatingRowStep="1"
          style=" max-height: 288px;"
        >
        <wj-flex-grid-filter />
             <wj-flex-grid-column :header="'Departamentos'" :binding="'department'"  width="1*" :isReadOnly=true />
            <wj-flex-grid-column :header="'Cantidad'" :binding="'cantidad'" aggregate="Sum" width="*" format="n0" :isReadOnly=true />
            <wj-flex-grid-column :header="'Revenue'" :binding="'revenue'" aggregate="Sum" width="*" format="n0" :isReadOnly=true />
            <wj-flex-grid-column :header="'Net Profit'" :binding="'profit'" aggregate="Sum" width="*" format="n0" :isReadOnly=true />
        </wj-flex-grid>
         <v-col md="12" cols="12"  >
          <div v-on:click="updateViewPager($event)" class="update-view-pager" style="margin-top:-20px">
            <flex-grid-page-size-selector 
              style="display:flex;position: relative;inset: -15px 0 -15px -20px"
              @change="onChangeChild"
              :currentPageSize="this.currentPageSize"
              :pageSizeSelect="this.pageSizeSelect"
            />
              <button id="btnFirst" class="btn"><span class="wj-glyph-step-backward" style="position: relative;left: -10px; top: 1px" ></span></button>
              <button id="btnPrev" class="btn"><span class="wj-glyph-left" style="position: relative;left: -3px; " ></span></button>
              &nbsp;&nbsp;&nbsp;{{ $t('FlexGridPageSizeSelector.page')}} {{ this.pageIndex + 1 | number:0 }} {{ $t('FlexGridPageSizeSelector.of')}} {{ this.pageCount | number:0 }}&nbsp;&nbsp;&nbsp;
              <button id="btnNext" class="btn"><span class="wj-glyph-right" style="position: relative;right: -3px; "></span></button>
              <button id="btnLast" class="btn"><span class="wj-glyph-step-forward" style="position: relative;right: -10px; top: 1px"></span></button>
          </div>
        </v-col>
      </v-col>
      </v-card>
      </v-col>


    </v-row>


</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.core';
import '@grapecity/wijmo.vue2.chart';
import '@grapecity/wijmo.vue2.gauge';
import "@grapecity/wijmo.vue2.grid";
import '@grapecity/wijmo.vue2.grid.filter';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { mapState} from 'vuex';
import DataSources from '../shared/DataSources.vue';
import * as wijmo from '@grapecity/wijmo';
import HorizontalBarGraph from '../shared/HorizontalBarGraph.vue';
import FlexGridHelper from "@/helpers/flexGrid.helper";
import FlexGridPageSizeSelector from '../shared/FlexGridPageSizeSelector.vue';

export default {
  name: "BestSellerCategories",

  components: {
    DataSources,
    HorizontalBarGraph,
    FlexGridPageSizeSelector

  },
  props: [
  'bestSellerData',
  'bestSellerBarsData'
  ],
  data: function() {
    return {

      pieData: this.bestSellerData,    
      pieHeader: '2018 Product Sales',
      description: '',
      maxPageSize: '7',
      currentPageSize: '7',
      pageIndex: 0,
      pageCount: 1,
    }
  },
  computed: {
    ...mapState([
    "palette",
    "mobile"
    ]),
    bestSellerCategoriesVisualization: {
      get () {
        return this.$store.state.bestSellerCategoriesVisualization
      },
      set (value) {
        this.$store.commit('updateBestSellerCategoriesVisualization', value)
      }
    },
    data() {
      return new CollectionView(FlexGridHelper.fillData(this.bestSellerData, parseInt(this.currentPageSize)), {
              refreshOnEdit: true, // on-demand sorting and filtering
              pageSize: parseInt(this.currentPageSize)
            })
    },
    pageSizeSelect() {
      return FlexGridHelper.makeList()
    },
  },
  methods: {
    onChangeChild(value) {
      this.currentPageSize = value
      this.updatePagesIndex()
    },
    initializeFlexSheet: function(flex) {
      flex.columnFooters.rows.push(new wjcGrid.GroupRow());
      this.flex = flex;
    },
    initializePie(flex) {
      this.thePie = flex;
    },
    pieLabel(ht) {
      return `${wjCore.Globalize.format(ht.value / (ht.chart._sum || ht.chart._sums[0]), 'p2')}`;
    },
    save() {
      this.data.pageSize = 0;
      wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
          this.flex,
          {
              includeColumnHeaders: true,
              includeCellStyles: false,
              formatItem: null
          },
          'BestSellerCategories.xlsx'
      );
      this.data.pageSize = parseInt(this.currentPageSize);
    },
    updateViewPager(e) {
        let btn = wijmo.closest(e.target, 'button'),
            id = btn ? btn.id : '';
        //
        switch (id) {
            case 'btnFirst':
                this.data.moveToFirstPage();
                break;
            case 'btnPrev':
                this.data.moveToPreviousPage();
                break;
            case 'btnNext':
                this.data.moveToNextPage();
                break;
            case 'btnLast':
                this.data.moveToLastPage();
                break;
        }
        this.updatePagesIndex()
    },
    updatePagesIndex() {
      this.pageIndex = this.data.pageIndex
      this.pageCount = this.data.pageCount
    },
  }
};
</script>

<style scoped lang="scss">
.orders-status {

  .wj-flexchart {
    padding: 0px !important;
  }
}

.right-content {
  margin: auto; 
  text-align: right; 
  padding-right: 20px !important;
  position:relative;
}

.card {
  max-height: 1200px;
  padding:12px;
  background: white;
}


.selector {
  display: inline-block; 
  margin: 0px; 
  padding-left: 5px; 
  height: 30px; 
  position:absolute; 
  right: 0;
  bottom: -2px;
}

.update-view-pager {
  align-items: center; 
  text-align: right; 
  padding-right: 16px;
  display:flex;
  float:right;
  height:30px;
}
</style>