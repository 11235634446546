<template>

    <v-progress-circular
      :size="50"
      v-if="loadingScriptExecutions"
      indeterminate
      color="cyan"
      style=" margin: 20px 0 20px 50%"
    ></v-progress-circular>

  <v-card v-else
    color="#FFFFFF"
    class="card"
    style="overflow-y:scroll;max-height:500px"
  >
    <v-row  no-gutters class="best-seller-items">
      <v-col md="6" cols="12" style="height:20px">
        <span class="table-title">
          Ejecuciones de scripts
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="#a6b0c3" size=14 class="mdi-information-margins">
                mdi-information-outline
              </v-icon>
            </template>
          En esta pestaña se podra observar toda la informacion relevante sobre la ejecución de los scripts
          </v-tooltip>
        </span>
      </v-col> 

      <v-col md="4" cols="12" class="right-content">
      </v-col>

      <v-col md="2" cols="12" class="right-content" >

      </v-col>

      <v-col  md="12" cols="12" style="margin-top: 16px" v-if="!isLoading">

        <v-card-title>
          <v-text-field
            v-model="search"
            label="Buscar por Cliente o Script"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table  
            :headers="headers"
            :items="this.executionsData"
            :items-per-page="50"
            :sort-by="['start_date']"
            :sort-desc="[true]"
            class="users-table"
            :search="search"
            :custom-filter="filterOnlyCapsText"
            :hide-default-footer="true"
        >
        <template v-slot:item.status="{ item }">
            <img v-if="item.status == 'FINISHED'" :src="require('@/assets/finished.png')" style="height:25px"/>
            <img v-if="item.status == 'RUNNING'" :src="require('@/assets/running.png')" style="height:25px"/>
            <img v-if="item.status == 'ERROR'" :src="require('@/assets/error.png')" style="height:25px"/>
        </template>
        </v-data-table>

        <div class="text-center">
          <v-container>
            <v-row justify="center">
              <v-col cols="8">
                <v-container class="max-width">
                  <v-pagination
                    v-model="scriptExecutionOffset"
                    class="my-4"
                    :length="this.pagesAmount"
                  ></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </div>

      </v-col>
    </v-row>
</v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    props: [
        'scriptExecutions'
    ],
    data () {
        return {
            search: '',
            page: 1,
            headers: [
                { text: 'Cliente', value: 'agency_name'},
                { text: 'Script', value: 'script_name'},
                { text: 'Comienzo', value: 'start_date'},
                { text: 'Tiempo de ejecución', value: 'execution_time'},
                { text: 'Estado', value: 'status'}
            ],
        }
    },
    computed: {
      ...mapState([
        'loadingScriptExecutions'
      ]),
      isLoading() {
        return false
      },
      pagesAmount() {
        var pagesAmount = Math.floor(this.scriptExecutions['paging']['total'] / this.scriptExecutions['paging']['size']) 
        return pagesAmount
      },
      executionsData() {
        return this.scriptExecutions['data']
      },
      scriptExecutionOffset: {
        get () {
          return this.$store.state.scriptExecutionOffset
        },
        set (value) {
          this.$store.commit('updateScriptExecutionOffset', value)
          this.getScriptExecutions()
        }
      }
    },
    methods: {
      ...mapActions([
          'getScriptExecutions'
      ]),
      filterOnlyCapsText (value, search) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().indexOf(search) !== -1
      },
    }
}
</script>

<style>

</style>