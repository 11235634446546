<template>

  <v-progress-linear 
      v-if="isLoading"
      indeterminate
      color="cyan"
  ></v-progress-linear>

  <v-container v-else class="main-container" >
    <div v-if="!this.unavaibleIntegration">
      <div class="page-title"> {{ $t('Orders.PageTitle')}} </div>

      <error v-if="error" style="margin-top: 10px"
        :errorMessage="$t('errorMessage')" 
      />
      
      <v-row justify-content="flex-end" >
        <TimeRangeFilter 
          :componente="componentName"
          :orderStatusData="getOrderStatusData"
        />
      </v-row>

      <div class="d-flex align-center" >
        <v-row justify-content="flex-end" >
          <order-status-filter v-if="!this.mobile"
            :orderStatusData="getOrderStatusData"
          />
        </v-row>
      </div>
      
      <v-row align="center" justify="space-between" style="margin-top: 24px"  >
        <v-col md="2" cols="6">
          <raw-metric-card 
            :title="$t('Orders.ordersCards.card-1.title')"
            :description="$t('Orders.ordersCards.card-1.description')"
            :value= this.thousandSeparatorCall(getOrdersCardsData[0].value)
            :changeRate= getOrdersCardsData[0].changeRate.toFixed(2)
            :sources="['vtex']"
          />
        </v-col>
        <v-col md="2" cols="6">
          <raw-metric-card 
            :title="$t('Orders.ordersCards.card-2.title')"
            :description="$t('Orders.ordersCards.card-2.description')"
            :value=moneyString.concat(this.thousandSeparatorCall(getOrdersCardsData[1].value)) 
            :changeRate=getOrdersCardsData[1].changeRate.toFixed(2)
            :sources="['vtex']"
          />
        </v-col>
        <v-col md="2" cols="6">
          <raw-metric-card 
            :title="$t('Orders.ordersCards.card-3.title')"
            :description="$t('Orders.ordersCards.card-3.description')"
            :value=this.thousandSeparatorCall(getOrdersCardsData[2].value)
            :changeRate=getOrdersCardsData[2].changeRate.toFixed(2)
            :sources="['vtex']"
          />
        </v-col>
        <v-col md="2" cols="6">
          <raw-metric-card 
            :title="$t('Orders.ordersCards.card-4.title')"
            :description="$t('Orders.ordersCards.card-4.description')"
            :value=moneyString.concat(this.thousandSeparatorCall(getOrdersCardsData[3].value))
            :changeRate=getOrdersCardsData[3].changeRate.toFixed(2)
            :sources="['vtex']"
          />
        </v-col>
        <v-col md="2" cols="6">
          <raw-metric-card 
            :title="$t('Orders.ordersCards.card-5.title')"
            :description="$t('Orders.ordersCards.card-5.description')"
            :value=moneyString.concat(this.thousandSeparatorCall(getOrdersCardsData[4].value))
            :changeRate=getOrdersCardsData[4].changeRate.toFixed(2)
            :sources="['vtex']"
          />
        </v-col>
        <v-col md="2" cols="6">
          <raw-metric-card 
            :title="$t('Orders.ordersCards.card-6.title')"
            :description="$t('Orders.ordersCards.card-6.description')"
            :value= this.thousandSeparatorCall(getAbandonedCartCard.value)
            :changeRate=getAbandonedCartCard.changeRate.toFixed(2)
            :sources="['vtex']"
            :invertedChangeRate="true"
          />
        </v-col>
      </v-row>

      <v-row >
        <v-col class="pa-0" style=" margin-top: 12px">
          <graph-area 
            :revenueGraphData="getOrdersRevenueGraphData"
          />
        </v-col>      
      </v-row>
    
      <v-row >
        <v-col class="margin-between-components pa-0 ">
          <orders-by-date-graph
            :ordersByDateData="getOrdersByDateData"
          />
        </v-col>      
      </v-row>

      <v-row >
        <v-col class="margin-between-components pa-0 ">
          <revenue-por-categoria
            :revenueByCategoryData="getRevenueByCategoryData"
          />
        </v-col>      
      </v-row>

      <v-row >
        <v-col class="margin-between-components pa-0 " style="margin-bottom:128px;">
          <origin-pies
            :paymentsPiesData="getPaymentsPieData"
          />
        </v-col>      
      </v-row>
    </div>
    <div  v-else>
      <v-card style="width:600px">
        <h2 class="app-font" style="padding:12px"> {{ $t('integrations.unavaible') }}</h2>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import RawMetricCard from '@/components/shared/RawMetricCard.vue';
import TimeRangeFilter from '@/components/shared/TimeRangeFilter.vue';
import GraphArea from '@/components/graphs/GraphArea.vue';
import OrdersByDateGraph from '../orders/OrdersByDateGraph.vue';
import RevenuePorCategoria from '../orders/RevenuePorCategoria.vue';
import OrderStatusFilter from '../shared/OrderStatusFilter.vue';
import TimeRangeType from "@/enums/time-range-type.enum";
import TimeRangeTypeText from "@/constants/time-range-type-text";
import Error from '../errors/error.vue';
import OriginPies from '../orders/OriginPies.vue';
import ThousandSeparator from "@/helpers/thousandSeparator.helper";

export default {
  name: "Orders",
  components: {
    RawMetricCard,
    GraphArea,
    TimeRangeFilter,
    OrdersByDateGraph,
    RevenuePorCategoria,
    OrderStatusFilter,
    Error,
    OriginPies
  },
  data: () => ({ 
    moneyString: "$ ",
    componentName: 'Orders',
    timeRangeTypes: [
        {
          text: TimeRangeTypeText[TimeRangeType.Custom],
          value: TimeRangeType.Custom
        }
      ],
      compareTodayRangeTypes: [
        {
          text: TimeRangeTypeText[TimeRangeType.CompareWithCustom],
          value: TimeRangeType.CompareWithCustom
        }
      ],
  }),
  computed:{
    ...mapState([
    "ordersPageData",
    "loadingOrdersPageData",
    "loadingFilterOptions",
    "filterOptions",
    "timeRange",
    "error",
    "mobile",
    "demo"

    ]),
    getOrderStatusData () {
      return this.filterOptions["Filters"][0]["values"]
    },
    isCustomSelected() {
      return this.timeRange.timeRangeType === TimeRangeType.Custom;
    },
    isCompareWithCustomSelected() {
      return this.timeRange.compareTimeRangeType === TimeRangeType.CompareWithCustom;
    },
    getOrdersCardsData() {
      return this.ordersPageData['components'][0]['cards'];
    },
    getOrdersRevenueGraphData() {
      return this.ordersPageData['components'][1]['data'];
    },
    getOrdersByDateData() {
      return this.ordersPageData['components'][2]['data'];
    },
    getRevenueByCategoryData() {
      return this.ordersPageData['components'][3]['data'];
    },
    getPaymentsPieData() {
      return this.ordersPageData['components'][4]['data'];
    },
    getAbandonedCartCard() {
      return this.ordersPageData['components'][5]['data'];
    },
    isLoading() {
      if ( this.ordersPageData != null && !this.loadingOrdersPageData && this.filterOptions != null && !this.loadingFilterOptions){
        return false
      }
      return true
    },
    unavaibleIntegration(){
      if (this.ordersPageData == 'integration unavaible')
        return true
      else
        return false
    }
  },
 
  methods: {
    ...mapActions([
      "getOrdersPageData",  
      "getFilters",
      "loggedInCheck",
    ]),
    ...mapMutations([
      "setMobile",  
      "setOrdersPageData"
    ]),
    thousandSeparatorCall(value) {
      return ThousandSeparator.formatNumber(value)
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.setMobile(true)
        return true
      } else {
        return false
      }
    },
  },
  created: function () {
    this.isMobile();
    if(!this.demo){
      this.loggedInCheck();
      this.getOrdersPageData();
      this.getFilters();
      }
    else{
      this.getFilters();
      var data = {};
      data = require('../../json/orders.json');
      this.setOrdersPageData(data);
    }
  },
};
</script>

<style scoped lang="scss">
.main-container {
  max-width: 100%;
  padding: 12px 30px 12px 30px;
}

.margin-between-components {
  margin-top:24px;
} 

</style>